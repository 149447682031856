import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons"
import { Button, Spin, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { singlesalesdata } from "../../Api/user/userapi"
import Header from "../../Constant/Header/Header"
import MainSideBar from "../../Constant/MianSideBar/MainSideBar"
import SectionHeader from "../../Constant/SectionHeader/SectionHeader"

const ViewPortfolio=()=>{
    const [data,setdata] = useState();
    const [technology,settechnology]=useState("");
    const [loading,setloading] = useState(false)
    const {id} = useParams()
    const history=useHistory()

    const backbutton = () => {
        history.goBack();
    }
    const singledata=()=>{
        setloading(true)
        singlesalesdata(`portfolio?id=${id}`).then(res=>{
            if(res.success){
                setdata(res.data)
                console.log(res.data,"this is data")
                setloading(false)
               

            }
        })
    }
    useEffect(()=>{
        if(id){
            singledata()
        }
    },[])
    return<>
        <div className="main-wrapper" id="mainPannel">
            <div className="navbar-bg" />
            <Header />
            <MainSideBar />
        </div>
        <div className="main-content">
        <section className="section">
                <SectionHeader title="View Portfolio"/>
                <div>
                    <Tooltip title="Back Button">
                    <Button type="primary"
                        onClick={backbutton}
                        shape="circle" 
                        size={'large'}
                        className='btn btn-primary profile'
                        icon={<ArrowLeftOutlined />}
                    />                            
                    </Tooltip>
                </div>
                <Spin spinning={loading}>
                    <div>
                        <ul class="list-group">
                            <li class="list-group-item"><b>Name: </b> {data&&data.name}</li>
                            <li class="list-group-item"><b>Domain: </b> {data&&data.domain}</li>
                            <li class="list-group-item"><b>Start Date: </b> {data&&data.start_date}</li>
                            <li class="list-group-item"><b>End Date: </b> {data&&data.end_date}</li>
                            <li class="list-group-item text-capitalize"><b>Technology: </b> { data&&data.technology}</li>
                            <li class="list-group-item"><b>Platform: </b> {data&&data.platform}</li>
                            <li class="list-group-item"><b>Description: </b> {data&&data.description}</li>
                        </ul>
                    </div>
                </Spin>
            </section>
        </div>
    </>
}
export default ViewPortfolio