import React from 'react';
import { Form, Input, Button, Typography, message, Row, Col, Tooltip } from 'antd';
import { addPlatform } from '../../Api/user/userapi';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import Header from '../../Constant/Header/Header';
import {
    ArrowLeftOutlined
} from '@ant-design/icons';

const Platform = (props) => {
    const { Title } = Typography;

    const handleSubmit = (e) => {
        const payload = {
            name: e.name,
        };
        try {
            const response = addPlatform(payload);
            if (response) {
                console.log(response, 'res');
            }
            props.history.push('/allPlatform')
            message.success({
                content: 'Platform is added',
                style: {
                    marginTop: '20vh',
                },
            });
        } catch (e) {
            console.log(e);
            message.success({
                content: 'Platform is not added successfully',
                style: {
                    marginTop: '20vh',
                },
            });
        }
    };

    const [form] = Form.useForm();

    const renderSubmitBack = () => {
        props.history.push('/allPlatform')
    }
    return (

        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">

                <section className="section">

                    <SectionHeader title="Add Platform" />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: '55px',
                        marginBottom: '18px',

                    }}>
                        <Button type="primary"
                            onClick={renderSubmitBack}
                            shape="circle"
                            icon={<ArrowLeftOutlined />}
                            size={'large'}
                            style={{
                                background: '#535356',
                                borderColor: '#535356'
                            }}
                        />
                    </div>
                    <div>
                        <Form
                            form={form}
                            name="name"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={handleSubmit}
                        >
                            <Row gutter={22}>
                                <Col span={8}  offset={8}>
                                    <Form.Item
                                        name="name"
                                        rules={[{ required: true, message: 'Please input your name!' }]}
                                    >
                                        <Input type="text" placeholder="Enter your new name" />
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Form.Item>
                                <div>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button mb-2"

                                    >
                                        Submit
                                    </Button>
                                </div>

                            </Form.Item>
                        </Form>
                    </div>
                </section>
            </div>
        </div>



    );
};

export default Platform;