import { ArrowLeftOutlined } from "@mui/icons-material"
import { Button, Spin, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import SectionHeader from "../../Constant/SectionHeader/SectionHeader"
import ApiClient from "../../methods/api/apiClient"
import SinglePortfolioView from "./SinglePortfolioView"

const ViewLinkData = () => {
    const param = useParams()
    const key=param.key
    const [data, setdata] = useState()
    const [check, setcheck] = useState()
    const history = useHistory()
    const [loading, setloading] = useState()

    const linkdata=()=>{
        setloading(true)
        ApiClient.get(`portfolioShare?key=${key}`).then(res=>{
            setloading(false)
            if(res.success){
                setdata(res.data.selected_items);
            }
            console.log(res.isExpired , "Is Expired");
            setcheck(res.isExpired);
        })
    }
    useEffect(()=>{
        linkdata()
    },[])

    return <>
            {check==true?
                <h1 className="text-center">Page Expired</h1>
            :
            <Spin spinning={loading}>
            <section className="section">
                <SectionHeader title="Portfolio Data"/>
                    {data&&data.map(item=>{
                        return <>
                            <SinglePortfolioView id={item}/>
                        </>
                    })}
            </section>
            </Spin>
            } 
    </>
}
export default ViewLinkData