import React, { useEffect, useState, forwardRef } from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import { Table, Spin, notification, message, Select, Button } from 'antd';
import { ExcelExport } from "@progress/kendo-react-excel-export";

import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


import XLSX from 'xlsx'
import { ArrowLeftOutlined } from '@ant-design/icons';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { API_URL } from '../../Constant';
import { getWeeklyplanbyprojectID, getWeeklyplanjson, updatexlstatus, xlsingledata } from '../../Api/user/userapi';
import { useSidebar } from '../../Provider/Provider';


const Tabledataxls = (props) => {
    const { Option } = Select;

    const [employees, setEmployees] = useState([])
    console.log(employees, "employeesemployees");
    const [taskname, setTaskname] = useState([])
    console.log(taskname, "taskname[0]");
    const [newArr, setNewArr] = useState()

    console.log(newArr, "taskccccccccprogress");

    const [xltaskname, setxltaskname] = useState([])
    console.log(xltaskname, "xltasknamexltaskname");
    const token = localStorage.getItem("token");
    const { chapterListDetail, setCourseNameList, setEditCourseData, courseNameList,
        editCourseData
    } = useSidebar();


    console.log(courseNameList, 'courseNameList');
    console.log(editCourseData, 'editCourseData');


    const [Taskstatus, setTaskstatus] = useState([])
    console.log(Taskstatus, "Taskstatus");

    useEffect(() => {
        // getProject();
        getData();
        xlsingle();
    }, [])

    const tableID = localStorage.getItem("xlIdAdmin")

    const getData = async (id) => {
        // const response = await axios.get(`http://74.208.206.18:4009/getWeeklyplanjson?id=6194ec2f056cea4de26b8db1`)
        try {
            const resp = await getWeeklyplanjson(tableID);
            if (resp && resp.status === 200) {
                const list = resp;

                console.log(list.data.data, 'listasas');
                // setArray(list.data.data)
                setEmployees(list.data.data)



            }
        } catch (e) {
            console.log(e);
        }
        // fetch(`${API_URL}getWeeklyplanjson?id=${chapterListDetail}`, {
        //     method: 'GET',

        //     headers: {
        //         'Content-type': 'application/json',
        //         Authorization: `Bearer ${token}`,
        //     },
        // })
        //     .then(res => res.json())
        //     .then(res => {
        //         console.log(res.data, "111");
        //         setEmployees(res.data)
        //     });

    }

    const renderSubmitBack = () => {
        props.history.push('/allProject')
    }

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };


    const newlyAddedChapter =
        employees && employees.length
            ? employees.map((items) => {
                console.log(items, 'asdas');
                return {
                    ...items,
                };
            })
            : [];


    var changeArr = [];

    function handleChange(event, value) {

        changeArr.push({ status: event, taskname: value })

        console.log(changeArr, 'argggraye');

        localStorage.setItem("newArr", JSON.stringify(changeArr))

    }


    const xlsingle = async () => {
        try {


            const resp = await xlsingledata(tableID);
            if (resp && resp.status === 200) {
                const list = resp;

                console.log(list, 'lxddistasas');
                const arrayData = list.data.data.tasks;
                console.log(arrayData, 'asdasarrayData');

            }
        } catch (e) {
            console.log(e);
        }


    };


    const columns = [


        // {
        //     title: "Serial_No",
        //     dataIndex: "Serial_No",
        //     field: "Serial_No",
        //     render: (text, record) => (
        //         console.log(text, 'dfdffff'),



        //         <>
        //             {
        //                 text.tableData.id

        //             }


        //         </>
        //     ),

        // },
        {
            title: "Weekly-Tasks",
            dataIndex: "documents",
            field: "Week_18oct_to_22oct_2021",
            render: (text, record) => (
                console.log(text, 'recordddasdadasd'),

                setxltaskname(text.Week_18oct_to_22oct_2021),

                <>
                    {
                        text.Week_18oct_to_22oct_2021

                    }


                </>
            ),
        },


        {
            title: "Action",
            dataIndex: "Action",
            field: "Action",
            render: (text, record) => (
                console.log(text, 'recordddadadasdsdasd'),

                <>

                    {/* <input onChange={(e) => handleChange(e, text.Week_18oct_to_22oct_2021)}  type="text"></input> */}
                    {text && text.progress_status?
                        <span>

                            <Select
                                style={{ width: '100%' }}
                                placeholder="select one status"
                                defaultValue={[text && text.progress_status]}
                                onSelect={(e) => handleChange(e, text.Week_18oct_to_22oct_2021)}
                            >


                                <Option value="InProgress" label="InProgress">
                                    <div className="demo-option-label-item">
                                        <span role="img" aria-label="InProgress">
                                            InProgress
                                        </span>
                                    </div>
                                </Option>
                                <Option value="Success" label="Success">
                                    <div className="demo-option-label-item">
                                        <span role="img" aria-label="Success">
                                            Success
                                        </span>

                                    </div>
                                </Option>
                                <Option value="Failed" label="Failed">
                                    <div className="demo-option-label-item">
                                        <span role="img" aria-label="Failed">
                                            Failed
                                        </span>

                                    </div>
                                </Option>

                            </Select>
                        </span>


                    :<></>}
                </>
            ),


        },




    ];




    const submittask = async (e) => {
        const getArray = localStorage.getItem("newArr")

        const arrayParse = JSON.parse(getArray)

        console.log(arrayParse, "arrayParse>>>>>>>>>>>>")

        const payload = { tasks: arrayParse }
        console.log(payload, 'arrrrarar');


        try {


            const resp = await updatexlstatus(tableID, payload);
            console.log(resp, 'ddddqq111dd');
            if (resp && resp.status === 200) {
                xlsingle();
                props.history.push("/ProjectFile")
                // xlsingle();

                // message.success("updated successfully.");


                // setIsEditModalVisible(false);
            }
        } catch (e) {
            console.log(e, "dfffffffffffffffd");
        }
    }


    const _export = React.useRef(null);

    console.log(_export, "_export");

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    return (
        <>

            <div>

                <div className="main-wrapper" id="mainPannel">
                    <div className="navbar-bg" />
                    <Header />
                    <MainSideBar />
                </div>

                <div className="main-content">

                    <section className="section">

                        <SectionHeader title="XL File" />
                        <div>
                            <Button type="primary"
                                onClick={renderSubmitBack}
                                shape="circle"
                                icon={<ArrowLeftOutlined />}
                                size={'large'}
                                className="btn btn-primary profile"
                            />



                        </div>

                        <div>


                            <table >
                                <thead>
                                    {/* <tr>{renderHeader()}</tr> */}
                                </thead>
                                <tbody>
                                    {/* {renderBody()} */}
                                </tbody>
                            </table>
                        </div>

                        <div >
                            <div>



                            </div>



                            {/* <button
                                title="Export Excel"
                                className="k-button k-primary"
                                onClick={excelExport}
                            >
                                Export to Excel
                            </button> */}

                            <ExcelExport icons={tableIcons}
                                title="Project Data" columns={columns} data={newlyAddedChapter} ref={_export}>

                                <MaterialTable
                              
                                    icons={tableIcons}
                                    // title="Project Data"
                                    data={newlyAddedChapter}
                                    columns={columns}
                                    options={{
                                        exportButton: true,
                                        exportAllData: true,
                                        showTitle: false,
                                        draggable:false
                                    }}



                                />
                            </ExcelExport>



                        </div>

                    </section>
                </div>
                <button

                    className="login-form-button"
                    onClick={(e) => submittask(e)}

                >
                    Submit
                </button>

            </div >
        </>
    );
};

export default Tabledataxls;