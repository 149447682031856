import React, { useEffect, useState, forwardRef } from "react";
import Header from "../../Constant/Header/Header";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import { useHistory } from 'react-router-dom';
import { Table, Spin, Select, message, Button, notification } from "antd";
import parse from "html-react-parser";
import {
  getAllAttandance,
  getAllResign,
  updateAttandec,
  updateResign,
} from "../../Api/user/userapi";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useSidebar } from "../../Provider/Provider";
import { Pending } from "@mui/icons-material";
const { Option } = Select;

const options = [
  { value: "Accept" },
  { value: "Reject" },
  { value: "Cencel" },
  { value: "Panding" },
];

const Resignation = (props) => {
   const [loading, setLoading] = useState(false);
   const history=useHistory();
  const [array, setArray] = useState([]);
  const [id, setId] = useState();

  const { setCourseNameList, courseNameList, setCourseId, courseId } =
    useSidebar();

  console.log(id, "both");


  const Email = localStorage.getItem("dataKey");
  console.log(Email, "EmailEmail");

  useEffect(() => {
    userDetails();
  }, []);


  
  const userDetails = async () => {
    try {
      setLoading(true);

      const resp = await getAllResign();
      if (resp && resp.status === 200) {
        const list = resp;

        console.log(list, "listasas");
        const arrayData = list.data.data;
        setArray(arrayData);
      }
    } catch (e) {
      localStorage.clear();
      history.push("/")
    

      setTimeout(() => {
        notification.error({
          message: "Unauthorized",
          description: "Your Session Has Expired. Please logIn.",
        });
      }, 1000);
    }
    setLoading(false);
  };

  const handleChange = async (event, text) => {
    console.log(event.target.innerHTML, text, "evebntsss");
    const target = event.target.innerHTML;
    try {
      const resp = await updateResign(
        text.id,
        event.target.value,
        text.fromEmail,
        text.toEmail,
        Email
      );
      console.log(resp, "rsp");
      if (resp && resp.status === 200) {
        // setTimeout(() => {
        //     notification.open({
        //       message: 'Roles',
        //       description: 'updated successfully.',
        //     });
        //   }, 1000);

        // message.success("updated successfully.");
        userDetails();

        // setIsEditModalVisible(false);
      }
    } catch (e) { 
      notification.error({
        message:"Some error Occurred. Please try after Some Time."
      })
    }
  };
  const chapterIdHandle = (id) => {
    setCourseId(id);
    console.log(id, "djjasdk");

    localStorage.setItem("regiparticularid", id);
  };
  const columns = [
    {
      title: "Employee-Name",
      dataIndex: "employeeName",
      field: "employeeName",
      render: (text, record) => (
        <>
          {text && text.employeeName ? (
            <Link to={`/ResignDetails?id=${text._id}`}>
              <span className="text" onClick={() => chapterIdHandle(text.id)}>
                {text.employeeName}
              </span>
            </Link>
          ) : (
            "--"
          )}
        </>
      ),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   render: (text, record) => (
    //     <>
    //       {text && text.description ? (
    //         // <Link to={`/ResignDetails?id=${text.id}`}>

    //         <span onClick={() => chapterIdHandle(text.id)}>
    //           {parse(text.description)}
    //         </span>
    //       ) : (
    //         //  </Link>

    //         "--"
    //       )}
    //     </>
    //   ),
    // },
    {
      title: "Subject",
      dataIndex: "subject",
      field: "subject",
    },

    {
      title: "From-Email",
      dataIndex: "fromEmail",
      field: "fromEmail",
    },
    {
      title: "Status",
      dataIndex: "resign_status",
      key: "resign_status",
      render: (text, record) => (
        console.log(text, "te4554xtss"),
        (<>
        {text.resign_status === "Accepted" ||
             text.resign_status === "Cancelled"||text.resign_status=="Rejected"?
             <Button
                   size="middle" 
                   disabled
                 >
                  {text.resign_status}
             </Button>: <select onChange={(e) => handleChange(e, text)} >
            <option>Pending</option>
            <option value="Rejected">Reject</option>
            <option value="Accepted">Accept</option>
          </select>
             
             }
        </>
          // <>
          //   {text.resign_status === "Accepted" ||
          //   text.resign_status === "Cancelled" ? (
          //     <Button
          //       size="middle"
          //       onClick={(e) => handleChange(e, text)}
          //       disabled
          //     >
          //       {text.resign_status == "Accepted"
          //         ? "Accepted"
          //         : text.resign_status}
          //     </Button>
          //   ) : (
          //     <Button size="middle" onClick={(e) => handleChange(e, text)}>
          //       {text.resign_status !== "Rejected"
          //         ? "Accept"
          //         : text.resign_status}
          //     </Button>
          //   )}
          // </>
        )
      ),
    },
  ];

  const newlyAddedChapter =
    array && array.length
      ? array.map((items) => {
          console.log(items.id, "asdas");
          return {
            ...items,
          };
        })
      : [];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <div>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title="Resignations" />

          <Spin spinning={loading}>
            <MaterialTable
             options={{
              pageSize:10,
              pageSizeOptions:[10,20,30,40,50], 
               emptyRowsWhenPaging: false,
               showTitle:false,
               draggable:false
         }}   
              // title="Resignations"
              columns={columns}
              data={newlyAddedChapter}
              icons={tableIcons}
            />
            {/* <Table columns={columns} dataSource={newlyAddedChapter} pagination={{
                            defaultPageSize: 5,
                            showSizeChanger: true,
                            pageSizeOptions: ["5", "10", "15"],
                        }} /> */}
          </Spin>
        </section>
      </div>
    </div>
  );
};

export default Resignation;
