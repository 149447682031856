import { React, useEffect, useState, forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Table, Spin, Select, message, Button, Tooltip } from 'antd';
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from 'moment';
import MaterialTable from 'material-table';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ApiClient from '../../methods/api/apiClient';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const SingleuserAttendance = () => {
    const [loading, setLoading] = useState(false);
    const [array, setArray] = useState([])
    const [data, setdata] = useState([])
    const userid = useParams().id
    const date = useParams().date;
    const{enddate}=useParams();
    const history = useHistory()

    const columns = [
        {
            title: "Full-Name",
            dataIndex: "name",
            field: "name",
            render: (data) => (
                <>
                    { data&&data?.name }
                </>
            )
        },
        {
            title: "Email",
            dataIndex: "email",
            field: "email",
            render: (data) => (
                <>
                    { data&&data?.email }
                </>
            )
        },
        {
            title: "Check In Time",
            dataIndex: "checkIn",
            field: "checkIn",
            render: (data) => (
                <>
                    { moment(data.checkIn).format("hh:mm A") }
                </>
            ),
        },
        {
            title: "Check Out Time",
            dataIndex: "checkOut",
            field: "checkOut",
            render: (data) => {
             const timer=  moment(data.checkOut).format("hh:mm A") 
               return <>
 {timer=="Invalid date"?"":timer}
                </>
            },
        },
        {
            title: "Total Time",
            dataIndex: "totalTime",
            field: "totalTime",
            render: (data) => (
                <>
                    { data.totalTime }
                </>
            ),
        },
        {
            title:"Punch In Date",
            dataIndex:"punchInDate",
            field:"punchInDate",
            render:(data)=>(
                <>
                {moment(data.punchInDate).format("DD MMM YYYY")}
                </>
            )
        },
        {
            title: "Reason",
            dataIndex: "reason",
            field: "reason",
            render: (data) => (
                <>
                    { data&&data?.reason }
                </>
            )
        },
    ];

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const renderSubmitBack = () => {
        history.goBack();
    };

    const getdata=()=>{
        setLoading(true)
        const newdate=moment(date).format("YYYY-MM-DD") 
        ApiClient.get(`attendance/single/user?start_date=${newdate}&userID=${userid}&end_date=${enddate}`).then(res=>{
            setLoading(false)
            if(res.data){
                setdata(res.data)
                console.log(res.data,"data is here");
            }
        })
    }
    useEffect(()=>{
        getdata()
    },[])

    return (
        <>
            <div>
                <div className="main-wrapper" id="mainPannel">
                    <div className="navbar-bg" />
                    <Header />
                    <MainSideBar />
                </div>
                <div className="main-content">
                    <section className="section">
                        <SectionHeader title="Employee Attendance" />
                        <div>
                            <Tooltip title="Back Button">
                                <Button
                                type="primary"
                                onClick={renderSubmitBack}
                                shape="circle"
                                icon={<ArrowLeftOutlined />}
                                size={"large"}
                                className="btn btn-primary profile"
                                />
                            </Tooltip>
                        </div>
                        <Spin spinning={loading}>
                            <MaterialTable
                             options={{
                                pageSize:10,
                                pageSizeOptions:[10,20,30,40,50], 
                                 emptyRowsWhenPaging: false,
                                 showTitle:false,
                                 draggable:false
                           }}   
                                columns={columns}
                                data={data}
                                icons={tableIcons}
                            />
                        </Spin>
                    </section>
                </div>
            </div>
        </>
    );
};

export default SingleuserAttendance;