import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import StatsGarph from '../../Constant/StatsGraph';
import Stats from '../../Constant/Stats';
import { adminleavestatus, projectstatuscount } from '../../Api/user/userapi';
import { message } from "antd";
import { notification } from 'antd';
import Header from "../../Constant/Header/Header";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import { useHistory } from "react-router-dom";

const HrDashbord = () => {
    const history=useHistory();
    const [projectTotal, setprojectTotal] = useState();
    const [leavestatuscount, setleavestatuscount] = useState();
    const Projectstatus = async () => {
        try {
            const resp = await projectstatuscount();
            if (resp && resp.status === 200) {
                setprojectTotal(resp.data.data)
                // message.success("projectsttauscount");
            }
        } catch (e) {
            console.log(e);
        }
    };

    const leaveStatuscount = async () => {
        try {
            const resp = await adminleavestatus();
            if (resp && resp.status === 200) {
                setleavestatuscount(resp.data.data)
                // message.success("projectsttauscount");
            }
        } catch (e) {
        localStorage.clear();
        history.push("/")
            setTimeout(() => {
                notification.error({
                    message: 'Unauthorized',
                    description: 'Your Session Has Expired. Please logIn.',
                });
            }, 1000);
        }
    };
    useEffect(() => {
        Projectstatus();
        leaveStatuscount();
    }, []);

    return (
        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content" style={{ minHeight: '0px' }}>
                <section className="section">
                    <SectionHeader title="Welcome to HR-Dashboard" />
                    <Stats />
                    {/* <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                            ['Task', 'Hours per Day'],
                            ['Work', 11],
                            ['Eat', 2],
                            ['Commute', 2],
                            ['Watch TV', 2],
                            ['Sleep', 7],
                        ]}
                        options={{
                            title: 'My Daily Activities',
                            // Just add this option
                            is3D: true,
                        }}
                        rootProps={{ 'data-testid': '2' }}
                    /> */}
                    {/* <StatsGarph/> */}
                    {/* <div class="row">
                        <div class="col">
                            <Chart
                                width={'500px'}
                                height={'400px'}
                                chartType="PieChart"
                                loader={<div>Loading Chart</div>}
                                data={[
                                    ['Language', 'Speakers (in millions)'],
                                    ['Pending', leavestatuscount && leavestatuscount.Pending],
                                    ['Reject', leavestatuscount && leavestatuscount.Reject],
                                    ['Cancel', leavestatuscount && leavestatuscount.Cancel],
                                    ['accept', leavestatuscount && leavestatuscount.Accept],

                                ]}
                                options={{
                                    title: ' Total Leaves Data',
                                    legend: 'none',
                                    pieSliceText: 'label',
                                    slices: {
                                        4: { offset: 0.2 },
                                        12: { offset: 0.3 },
                                        14: { offset: 0.4 },
                                        15: { offset: 0.5 },
                                    },
                                }}
                                rootProps={{ 'data-testid': '5' }}
                            />
                        </div>
                    </div> */}
                </section>
            </div>
        </div>
    );
};

export default HrDashbord;