import React, { useEffect, useState } from 'react';
import { Button, Spin, notification } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useSidebar } from '../../Provider/Provider';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { getProjectdetails } from '../../Api/user/userapi';
import { API_URL } from '../../Constant';
import moment from 'moment';
import parse from "html-react-parser";
// import { Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import { useParams } from 'react-router-dom';
import { Popconfirm,Tooltip } from 'antd';


export default function ProjectAssociation() {
    const navigate=useHistory();
    const {id}=useParams();
    const [data,setdata]=useState([]);
    const [datamember,setdatamember]=useState([]);
    const renderSubmitBack = () => {
    navigate.goBack()
      }
      const [loading,setloading]=useState(false);
      const Getdata=()=>{
        setloading(true);
        ApiClient.get(`project?id=${id}`).then(res=>{
setdatamember(res.data.members)
setdata(res.data)
setloading(false);
        }).catch(err=>{
          setloading(false);
            notification.error({message:"Some error occurred"});
        })
      }

const hanldedelete=(employeeid)=>{
ApiClient.get(`project/member/remove?id=${id}&members=${employeeid}`).then(res=>{
  Getdata();
notification.success({message:"Developer removed successfully"})
}).catch(err=>notification.error({message:"Some error occurred.Please try again"}))

}

      useEffect(()=>{
        Getdata()
      },[])
      return (
        <div className="text-left">
          <div className="main-wrapper" id="mainPannel">
            <div className="navbar-bg" />
            <Header />
            <MainSideBar />
          </div>
          <div className="main-content">
            <section className="section">
              <SectionHeader title="Team Details" />
              <div>
                <Tooltip title="Back Button">
                  <Button type="primary"
                    onClick={renderSubmitBack}
                    shape="circle"
                    icon={<ArrowLeftOutlined />}
                    size={'large'}
                    className="btn btn-primary profile"
                  />
                </Tooltip>
              </div>
              <Spin spinning={loading}>
                {/* Do Coding Here */} 
                <div className="shadow p-4  ">
                <h5 className='text-capitalize'>Project Name<b> {data.name}</b></h5>
       
                <div className=' mt-4'> 
                <h5>Developers :-</h5> 
                {datamember.map((item,value)=>(
                  <div className='mt-1 d-inline-flex mx-3 '>
  <h6 >{value+1}. {item.fullName}({item.designation})         <Tooltip title="Delete">
                                  <Popconfirm
                                      title="Are you sure to Remove?"
                                      onConfirm={()=>hanldedelete(item.id)}
                                      onCancel={() => console.log("Cancled")}
                                      okText="Yes"
                                      cancelText="No"
                                  > <Button type="primary" htmlType="button" ><i style={{color:"red"}} className='fa fa-trash'></i></Button>
                                  </Popconfirm>
                                  </Tooltip>
                                  </h6>
  </div>
   ))
 }
          </div>
                </div>

                </Spin>
                </section>
                </div>
                </div>
  )
}
