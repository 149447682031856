import React, { useEffect, useState, forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MaterialTable from 'material-table';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const OnehrStatusReport = () => {
    const [data, setdata] = useState([])
    const [value, onChange] = useState('10:00');

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    const columns = [
        {
            title: "Task",
            dataIndex: "task",
            field: "task",
            render: (data) => (
                <>
                    <p className='text-capitalize'>
                        Do the Unit Testing
                    </p>
                </>
            ),
        },
        {
            title: "Estimate Time",
            dataIndex: "estimatetime",
            field: "estimatetime",
            render: (data) => (
                <>
                    <p className='text-capitalize'>
                        1 hr
                    </p>
                </>
            ),
        },
        {
            title: "Actual Time",
            dataIndex: "actualtime",
            field: "actualtime",
            render: (data) => (
                <>
                    <p className='text-capitalize'>
                        45 minutes
                    </p>
                </>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            field: "status",
            render: (data) => (
                <>
                    <p className='text-capitalize'>
                        Complete
                    </p>
                </>
            ),
        },
    ];

    return (
        <>
            <div>
                <div className="main-wrapper" id="mainPannel">
                    <div className="navbar-bg" />
                    <Header />
                    <MainSideBar />
                </div>
                <div className="main-content">
                    <section className="section">
                        <SectionHeader title="One hr Status Report" />
                        <div className='main_div'>
                        </div>
                            <MaterialTable
                             options={{
                                pageSize:10,
                                showTitle:false,
                                pageSizeOptions:[10,20,30,40,50], 
                                 emptyRowsWhenPaging: false,
                                 draggable:false
                           }}   
                                columns={columns}
                                data={data}
                                icons={tableIcons}
                            />
                    </section>
                </div>
            </div>
        </>
    );
};

export default OnehrStatusReport;