import React, { useEffect, useState, forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Table, Spin, Select, message, Button, notification, Tooltip } from 'antd';
import { getAllAttandance, mailinglist, updateAttandec } from '../../Api/user/userapi';
import { useSidebar } from '../../Provider/Provider';
import parse from "html-react-parser";
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useHistory } from 'react-router-dom';
import datepipeModel from '../../methods/datepipeline/datepipeModel';
const { Option } = Select;

const options = [{ value: 'Accept' }, { value: 'Reject' }, { value: 'Cencel' }, { value: 'Panding' }];

const Attandance = (props) => {
    const history=useHistory();
    const [loading, setLoading] = useState(false);
    const [array, setArray] = useState([])
    const [id, setId] = useState();
    const [disable,setdisable]=useState(false);
    const [maildata, setmaildata] = useState()
    const { setCourseNameList, courseNameList, setCourseId, courseId } = useSidebar();
    maildata && maildata.map((itemsdata) => {
        setCourseNameList(itemsdata.email)
        localStorage.setItem('dataKey', itemsdata.email);
        return itemsdata.email
    })
    const Email = localStorage.getItem('dataKey');
    useEffect(() => {
        userDetails();
        mailinglists();
    }, [])
    const userDetails = async () => {
        try {
            setLoading(true);
            const resp = await getAllAttandance();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                setArray(arrayData)
            }
        } catch (e) {
            console.log(e);
        }
        setLoading(false);

    };


    const mailinglists = async () => {
        try {
            setLoading(true);

            const resp = await mailinglist();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                setmaildata(arrayData)
            }
        } catch (e) {
            localStorage.clear();
            history.push("/")
            setTimeout(() => {
                notification.error({
                    message: 'Unauthorized',
                    description: 'Your Session Has Expired. Please logIn.',
                });
            }, 1000);
            console.log(e);
        }
        setLoading(false);
    };

    async function handleChange(value, event, fromEmail, toEmail,Email) {
        console.log(value, 'value', event, fromEmail, toEmail);
        try {
            const resp = await updateAttandec(value, event, fromEmail, toEmail,Email);
            if (resp && resp.status === 200) {
                setdisable(true);
                setTimeout(() => {
                    notification.success({
                        message: `Leave ${event}`,
                    });
                }, 1000);
                // message.success("updated successfully.");
                userDetails();
                // setIsEditModalVisible(false);
            }
        } catch (e) {
            console.log(e);
        }
    }
    const chapterIdHandle = (id) => {
        setCourseId(id);
        localStorage.setItem("leaveidparticular",id)
        console.log(id, 'djjasdk');
    };

    // Date & Time format
    const monthArray = [
        { value: 'Jan', id: 0 },
        { value: 'Feb', id: 1 },
        { value: 'Mar', id: 2 },
        { value: 'Apr', id: 3 },
        { value: 'May', id: 4 },
        { value: 'Jun', id: 5 },
        { value: 'Jul', id: 6 },
        { value: 'Aug', id: 7 },
        { value: 'Sep', id: 8 },
        { value: 'Oct', id: 9 },
        { value: 'Nov', id: 10 },
        { value: 'Dec', id: 11 }
    ]
    const monthfind = (id) => {
        let value = 0
        let ext = monthArray.find(itm => itm.id === id)
        if (ext) value = ext.value
        return value
    }
    const date = (p) => {
        let d = new Date(p)
        return `${d.getDate()} ${monthfind(d.getMonth())} ${d.getFullYear()}`
    }
    const time = (p) => {
        if (!p) return ''
        let v = p.split('T')
        if (v.length === 2) {
            return new Date(p).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        } else {
            return new Date(`2020-07-19 ${p}`).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        }
    }


    const columns = [
        {
            title: "Employee-Name",
            dataIndex: "employeeName",
            field: "employeeName",
            width:"1px",
            render: (text, record) => (
                <>
                    {text && text.employeeName ? (
                        <Link to={`/LeaveDetails?id=${text._id}`}>
                            <Tooltip title="View">
                                <span className="text" onClick={() => chapterIdHandle(text.id)}>
                                    {text.employeeName}
                                </span>
                            </Tooltip>
                        </Link>
                    ) : (
                        "--"
                    )}
                </>
            ),
        },
        // {
        //     title: "Description",
        //     dataIndex: "description",
        //     field: "description",
        //     render: (text, record) => (
        //         <>
        //             {text && text.description ? (
        //                 <Link to={`/LeaveDetails?id=${text.id}`}>

        //                     <span className="dec_dot" onClick={() => chapterIdHandle(text.id)}>
        //                         {parse(text.description)}
        //                     </span>

        //                 </Link>

        //             ) : (
        //                 "--"
        //             )}
        //         </>
        //     ),
        // },
        {
            title: "Type",
            dataIndex: "subject",
            field: "subject",
        },
        // {
        //     title: "Email",
        //     dataIndex: "fromEmail",
        //     field: "fromEmail",
        // },
        {
            title: "Applied On",
            dataIndex: "leaveStartDate",
            field: "leaveStartDate",
            render: (text, record) => (
                <>
                    {text&&text.createdAt
                    ?
                        datepipeModel.datetime(text && text.createdAt)    
                    :
                    "--"
                    }
                </>
            ),
        },
        {
            title: "Start-Date",
            dataIndex: "leaveStartDate",
            field: "leaveStartDate",
            render: (text, record) => (
                <>
                    {text&&text.leaveStartDate
                    ?
                        date(text && text.leaveStartDate)    
                    :
                    "--"
                    }
                </>
            ),
        },
        {
            title: "End-Date",
            dataIndex: "leaveEndDate",
            field: "leaveEndDate",
            width:"10%",
            cellStyle: {
                cellWidth: '5%'
              },
            render: (text, record) => (
                <>
                    {text&&text.leaveEndDate
                    ?
                        date(text && text.leaveEndDate)    
                    :
                    "--"
                    }
                </>
            ),
        },
        {
            title: "Leave-Status",
            dataIndex: "leave_status",
            field: "leave_status",
            render: (text, record) => (
                <>
                    {text && text.leave_status ? (
                        <>
                            <Select style={{ width: '100%' }}
                                placeholder="Select Project..."
                                defaultValue={[text.leave_status]}
                             disabled={text.leave_status!="Pending"}
                                onChange={(e) => handleChange(text.id, e, text.fromEmail, text.toEmail,Email)}
                            >
                                <Option  value="Accepted" label="Accept">
                                    <div className="demo-option-label-item">
                                        Accepted
                                    </div>
                                </Option>
                                <Option value="Rejected" label="Reject">
                                    <div className="demo-option-label-item">
                                        Rejected
                                    </div>
                                </Option>
                                <Option value="Pending" label="Pending">
                                    <div className="demo-option-label-item">
                                        Pending
                                    </div>
                                </Option>
                            </Select>
                        </>
                    ) : (
                        "--"
                    )}
                </>
            ),
        },
    ];

    const newlyAddedChapter =
        array && array.length
            ? array.map((items) => { 
                return {
                    ...items,
                };
            })
            : [];

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    return (
        <div >
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">
                <section className="section">
                    <SectionHeader title="Manage Leaves " />
                    <Spin spinning={loading}>
                        <MaterialTable
                        
                              options={{
                                showTitle:false,
                                pageSize:10,
                                pageSizeOptions:[10,20,30,40,50], 
                                 emptyRowsWhenPaging: false,
                                 draggable:false
                           }}   
                            // title="Leaves"
                            columns={columns}
                            data={newlyAddedChapter}
                            icons={tableIcons}
                        />
                        {/* <Table columns={columns} dataSource={newlyAddedChapter} pagination={{
                            defaultPageSize: 5,
                            showSizeChanger: true,
                            pageSizeOptions: ["5", "10", "15"],
                        }} /> */}
                    </Spin>
                </section>
            </div>
        </div>
    );
};

export default Attandance;