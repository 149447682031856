import React, { useEffect, useState } from 'react';

import {
    Button,
    Image,
    Spin
} from 'antd';
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { useSidebar } from '../../Provider/Provider';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { getPostDetails } from '../../Api/user/userapi';


const KnowledgeDetails = (props) => {
    const [names, setName] = useState()
    const [desc, setDescription] = useState()
    const [imgs, setImage] = useState();
    const [loading,setloading]=useState(false);


    const { courseId } = useSidebar();
    var HtmlToReactParser = require('html-to-react').Parser;


    


    var htmlToReactParser = new HtmlToReactParser();

    useEffect(() => {
        getProject();


    }, [])

    const getProject = async (i) => {
    setloading(true);
  const Id  = localStorage.getItem("knowledgeid")
        try {
            const resp = await getPostDetails(Id);
            if (resp && resp.status === 200) {
                const list = resp;

                console.log(list.data.data, 'listasas');
                const name = list.data.data.name;
                const description = list.data.data.description;
                const img = list.data.data.image;

                setName(name);
                setDescription(description);
                setImage(img)
                  setloading(false);
            }
            else{setloading(false)}
        } catch (e) {
            setloading(false)
            console.log(e);
        }
    };
    const renderSubmitBack = () => {
        props.history.push('/Knowledge')
    }
    return (
        <div className="text-left">
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">
            <section className="section">
                <SectionHeader title="Knowledge Detail" />
                <div>
                    <Button type="primary"
                        onClick={renderSubmitBack}
                        shape="circle"
                        icon={<ArrowLeftOutlined />}
                        size={'large'}
                        className="btn btn-primary profile"
                    />
                </div>
                <Spin spinning={loading}>
                <div className="container pl-0">
                    <div className="shadow p-3 w-100 d-flex">
                        <div class="row">
                            <div class="col-md-12">
                                <label className="text-bold">Topic-Name:</label>
                                <span > {names}</span>
                            </div>
                            <div class="col-md-12 col docs">
                                <label className="text-bold">Image:</label>
                                <span>  {<Image
                                    width={200}
                                    src={imgs}
                                />}</span>
                            </div>
                            <div class="col-md-12">
                                <label className="text-bold">Description:</label>
                                <span class="prettify">  {htmlToReactParser.parse(desc)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </Spin>
            </section>
            </div>
        </div>
    );
};

export default KnowledgeDetails;
