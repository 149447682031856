/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { withRouter, Link, NavLink } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';


const styles = {
  fontWeight: 'bold',
  color: '#535357'
};

const MainSidebar = ({ history }) => {
  function standby() {
    document.getElementById('foo').src = 'userimg.jpg'
  }
  const [isActive, setActive] = useState(false);
  const [img, setimg] = useState()
  const id = localStorage.getItem('userID')

  const getdata = () => {
    ApiClient.get(`userDetail?id=${id}`).then(res => {
      if (res.success) {
        setimg(res.data.image);
      }
    })
  }

  useEffect(() => {
    getdata();
    // eslint-disable-next-line
  },[])

  

  const toggleClass = () => {

    setActive(!isActive);
    document.getElementById("mainPannel").classList.toggle('isFull')
  };

  const role = localStorage.getItem('role');

  return (
    <>

      {

        role === "subadmin"

          ?
          <div className={`main-sidebar ${isActive ? 'your_className' : null}`}>
            <div className='bars d-none'
              onClick={toggleClass}
            ><i class="fa fa-bars" aria-hidden="true"  ></i></div>
            <aside id="sidebar-wrapper">
              <div className="sidebar-brand">

                <NavLink to="/hr_dashboard">

                  {img ?
                    <img src={img} id='foo' alt='profile' onError={standby} className="logo rounded-circle" width="100px" height="100px" />
                    :
                    <img src="userimg.jpg" alt="profile" className="logo rounded-circle" width="100px" height="100px" />
                  }
                </NavLink>
              </div>

              <ul className="sidebar-menu ">
                <li className="nav-item  ">
                  <NavLink to="/hr_dashboard" className="nav-link" activeStyle={styles}>
                    <i class="fa fa-object-group" aria-hidden="true" /><span>Dashboard</span>
                  </NavLink>
                </li>
                <li className='nav-item dropdown active'>
                  <a href="#homeSubmenuuu" data-toggle="collapse" aria-expanded="false"> <i className="fa-solid  fa-list" />Events <i class="fas fa-caret-down" aria-hidden="true"></i></a>
                  <ul class="collapse list-unstyled" id="homeSubmenuuu">
                    <li className='nav-item dropdown'>
                      <NavLink className="nav-link bg-secondary" to="/upcomingevents" activeStyle={styles}>
                        <i className="fa fa-calendar-check-o" /><span>Upcoming Events</span>
                      </NavLink>
                    </li>
                    <li className='nav-item dropdown'>
                      <NavLink to="/festivals" className="nav-link bg-secondary" activeStyle={styles}>
                        <i className="fa fa-hourglass-end" /><span>Holidays</span>
                      </NavLink>
                    </li>

                  </ul>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/ManageAttendance" activeStyle={styles}>
                    <i className="fa-solid fa-user-check" /> <span>Attendance</span>
                  </NavLink>
                </li>
                {/*Dropdown For Inventory */}
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/allUser" activeStyle={styles}>
                    <i className="fa-solid fa-users" /><span>Employees</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/systemcomplaint" activeStyle={styles}>
                    <i className="fa fa-tasks" />
                    <span>System Complaints</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/allCategories" activeStyle={styles}>
                    <i className="fa fa-list-alt" /><span>Categories</span>
                  </NavLink>
                </li>
                {/* Admin Department Work */}
                {/* <li className="nav-item ">
                  <NavLink className="nav-link" to="/department" activeStyle={styles}>
                    <i className="fas fa-clock" /> <span>Department</span>
                  </NavLink>
                </li> */}
                <li className='nav-item dropdown active'>
                  <a href="#homeSubmenu2" data-toggle="collapse" aria-expanded="false"> <i className="fa-solid fa-list" />Inventory Details</a>
                  <ul class="collapse list-unstyled" id="homeSubmenu2">
                    <li className='nav-item dropdown'>
                      <NavLink to="/inventory" className="nav-link bg-secondary" activeStyle={styles}>
                        <i className="fa fa-list" /><span>Inventory</span>
                      </NavLink>
                    </li>
                    <li>       <NavLink className="nav-link bg-secondary" to="/system" activeStyle={styles}>
                      <i className="fa fa-tv" /><span>System Information</span>
                    </NavLink></li>
                  </ul>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/laptop" activeStyle={styles}>
                    <i className="fa fa-tv" />
                    <span>Assign Devices</span>
                  </NavLink>
                </li>

                <li className="nav-item ">
                  <NavLink className="nav-link" to="/Leaves" activeStyle={styles}>
                    <i className="fa-solid fa-person-chalkboard" />  <span>Leaves</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/resignation" activeStyle={styles}>
                    <i class="fa-solid fa-user-pen" /> <span>Resignations</span>
                  </NavLink>
                </li>
                <li className='nav-item dropdown active'>
                  <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false"> <i className="fa-solid  fa-list" />HR Expenses <i class="fas fa-caret-down" aria-hidden="true"></i></a>
                  <ul class="collapse list-unstyled" id="homeSubmenu">
                    <li className='nav-item dropdown'>
                      <NavLink to="/hrexpensivetable" className="nav-link bg-secondary" activeStyle={styles}>
                        <i className="fa fa-fire" /><span>Hr Expenses</span>
                      </NavLink>
                    </li>
                    <li><NavLink className="nav-link bg-secondary" to="/hrwallet" activeStyle={styles}>
                      <i className="fa fa-wallet" /><span>Hr Wallet</span>
                    </NavLink></li>
                  </ul>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/view/announcements" activeStyle={styles}>
                    <i className="fa fa-bullhorn" />
                    <span>Announcements</span>
                  </NavLink>
                </li>
                <li className="nav-item  border-bottom">
                  <NavLink className="nav-link" to="/todo" activeStyle={styles}>
                    <i className="fa fa-list" /> <span>To Do List</span>
                  </NavLink>
                </li>
                {/* <li className="nav-item ">
                  <NavLink className="nav-link" to="/hrexpensivetable" activeStyle={styles}>
                    <i className="fas fa-window-close" /> <span>Hr Expenses</span>
                  </NavLink>
                </li> */}
                <li className="nav-item  border-bottom mb-3">
                  <NavLink className="nav-link" to="/appraisal" activeStyle={styles}>
                    <i className="fa fa-line-chart" /> <span>Appraisal</span>
                  </NavLink>
                </li>
              </ul>
            </aside>
          </div>

          :

          <div className={`main-sidebar ${isActive ? 'your_className' : null}`}>
            <div className='bars d-none'
              onClick={toggleClass}
            ><i class="fa fa-bars" aria-hidden="true"  ></i></div>
            <aside id="sidebar-wrapper">
              <div className='imgDiv'>
                <img src="/assets/img/logoImg.png" />
              </div>
              {/* <div className="sidebar-brand">
                {img?
                  <img src={img} id='foo' onError={standby} className="image_style" />
                  :
                  <img src="userimg.jpg" alt="profile image" className="image_style" />
                }
                <h6 className="profilename mt-2 mb-0">{Name}</h6>
                <p className='profilename2 '>{role=="admin"?"Administrator":role}</p>
              </div> */}

              <ul className="sidebar-menu ">
                <li className="nav-item  ">
                  <NavLink to="/dashboard" className="nav-link" activeStyle={styles}>
                    <i class="fa fa-object-group" aria-hidden="true" />
                    <span>Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/ManageAttendance" activeStyle={styles}>
                    <i className="fa-solid fa-user-check" /> <span>Attendance</span>
                  </NavLink>
                </li>
                <li className='nav-item dropdown active'>
                  <a href="#homeSubmenuww" data-toggle="collapse" aria-expanded="false"> <i className="fa-solid  fa-list" />Events <i class="fas fa-caret-down" aria-hidden="true"></i></a>
                  <ul class="collapse list-unstyled" id="homeSubmenuww">
                    <li className='nav-item dropdown'>
                      <NavLink className="nav-link bg-secondary" to="/upcomingevents" activeStyle={styles}>
                        <i className="fa fa-calendar-check-o" /><span>Upcoming Events</span>
                      </NavLink>
                    </li>
                    <li className='nav-item dropdown'>
                      <NavLink to="/festivals" className="nav-link bg-secondary" activeStyle={styles}>
                        <i className="fa fa-hourglass-end" /><span>Holidays</span>
                      </NavLink>
                    </li>

                  </ul>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/dailytasks" activeStyle={styles}>
                    <i className="fa fa-table" /> <span>Daily Task</span>
                  </NavLink>
                </li>
                {/* <li className='nav-item dropdown active'>
                  <a href="#homeSubmenu1" data-toggle="collapse" aria-expanded="false"> <i className="fas fa-tasks"/>Task Details</a>
                  <ul class="collapse list-unstyled" id="homeSubmenu1">
                  <li className='nav-item dropdown'>
                    <Link className="nav-link bg-secondary" to="/dailytasks" activeStyle={styles} >
                    <span><i className="fas fa-fire" /> Daily Task</span>
                    </Link>
                  </li>
                  <li><Link to="/onehrreport" className='bg-secondary'><i class="fa-solid fa-user-tie"></i> One hr Status Report</Link></li>
                  </ul>
                </li> */}

                <li className="nav-item ">
                  <NavLink className="nav-link" to="/view/announcements" activeStyle={styles}>
                    <i className="fa fa-bullhorn" />
                    <span>Announcements</span>
                  </NavLink>
                </li>

                <li className="nav-item ">
                  <NavLink className="nav-link" to="/allProject" activeStyle={styles}>
                    <i className="fa fa-project-diagram" />
                    <span>Projects</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/projectassign" activeStyle={styles}>
                    <i className="fa fa-file-text" />
                    <span>Assign Projects</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/interview" activeStyle={styles}>
                    <i className="fa fa-th-list" />
                    <span>Interview</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/reviewproject" activeStyle={styles}>
                    <i className="fa fa-tasks" />
                    <span>Review Projects</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/allUser" activeStyle={styles}>
                    <i className="fa-solid fa-users" /><span>Employees</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/laptop" activeStyle={styles}>
                    <i className="fa fa-tv" />
                    <span>Assign Devices</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/allCategories" activeStyle={styles}>
                    <i className="fa fa-list-alt" /><span>Categories</span>
                  </NavLink>
                </li>
                {/* Department Work which is pending */}
                {/* <li className="nav-item ">
                  <NavLink className="nav-link" to="/department" activeStyle={styles}>
                    <i className="fas fa-clock" /> <span>Department</span>
                  </NavLink>
                </li> */}
                {/* For Inventory */}
                <li className='nav-item dropdown active'>
                  <a href="#homeSubmenu2" data-toggle="collapse" aria-expanded="false"><i className="	fa fa-caret-square-o-down" />Inventory<i class="fas fa-caret-down" aria-hidden="true"></i></a>
                  <ul class="collapse list-unstyled" id="homeSubmenu2">
                    <li className='nav-item dropdown'>
                      <NavLink to="/inventory" className="nav-link bg-secondary" activeStyle={styles}>
                        <i className="fa fa-fire" /><span>Inventory</span>
                      </NavLink>
                    </li>
                    <li><NavLink className="nav-link bg-secondary" to="/system" activeStyle={styles}>
                      <i className="fa fa-laptop" /><span>System Information</span>
                    </NavLink></li>
                  </ul>
                </li>


                {/* <li className='nav-item dropdown'>
                  <NavLink className="nav-link" to="/sales" activeStyle={styles} onClick="">
                  <i className="fas fa-tasks"/><span>Sales</span>
                  </NavLink>
                </li> */}

                <li className='nav-item dropdown active'>
                  <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false"> <i className="fa fa-bar-chart " />Sales <i class="fas fa-caret-down" aria-hidden="true"></i></a>
                  <ul class="collapse list-unstyled" id="homeSubmenu">
                    <li className='nav-item dropdown'>
                      <Link className="nav-link bg-secondary" to="/sales" activeStyle={styles} >
                        <span><i className="fa  fa-fire" /> Sales </span>
                      </Link>
                    </li>
                    <li><Link to="/portfolio" className='bg-secondary'><i class="fa-solid fa-user-tie"></i> Portfolio</Link></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <NavLink className="nav-link" to="/AllRoles" activeStyle={styles}>
                    <i className="fa fa-address-book-o" /> <span>Roles</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/Leaves" activeStyle={styles}>
                    <i className="fa-solid fa-person-chalkboard" /> <span>Leaves</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/systemcomplaint" activeStyle={styles}>
                    <i className="fa fa-tasks" />
                    <span>System Complaints</span>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/resignation" activeStyle={styles}>
                    <i class="fa-solid fa-user-pen" /> <span>Resignations</span>
                  </NavLink>
                </li>

                <li className='nav-item dropdown active'>
                  <a href="#homeSubmenuu" data-toggle="collapse" aria-expanded="false"> <i className="fa fa-user-circle" />HR Expenses <i class="fas fa-caret-down" aria-hidden="true"></i></a>
                  <ul class="collapse list-unstyled" id="homeSubmenuu">
                    <li className='nav-item dropdown'>
                      <NavLink to="/hrexpensivetable" className="nav-link bg-secondary" activeStyle={styles}>
                        <i className="fa fa-fire" /><span>HR Expenses</span>
                      </NavLink>
                    </li>
                    <li><NavLink className="nav-link bg-secondary" to="/hrwallet" activeStyle={styles}>
                      <i className="fa fa-wallet" /><span>HR Wallet</span>
                    </NavLink></li>
                  </ul>
                </li>
                {/* <li className="nav-item ">
                  <NavLink className="nav-link" to="/hrexpensivetable" activeStyle={styles}>
                    <i className="fas fa-window-close" /> <span>Hr Expenses</span>
                  </NavLink>
                </li> */}
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/Knowledge" activeStyle={styles}>
                    <i class="fa fa-info-circle" aria-hidden="true" /> <span>Knowledge</span>
                  </NavLink>
                </li>
                <li className="nav-item  border-bottom">
                  <NavLink className="nav-link" to="/todo" activeStyle={styles}>
                    <i className="fa fa-list" /> <span>To Do List</span>
                  </NavLink>
                </li>
                <li className="nav-item  border-bottom mb-2">
                  <NavLink className="nav-link" to="/appraisal" activeStyle={styles}>
                    <i className="fa fa-line-chart" /> <span>Appraisal</span>
                  </NavLink>
                </li>
              </ul>
            </aside>
          </div>
      }
    </>
  );
};

export default withRouter(MainSidebar);
