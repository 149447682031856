import React from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Form, Input, Button, Tooltip, message, Row, Col, notification } from 'antd';
import { addRoles, getRoles } from '../../Api/user/userapi';
import {
    ArrowLeftOutlined
} from '@ant-design/icons';

const AddRoles = (props) => {

    const handleSubmit = async (e) => {
        const payload = {
            name: e.name,
        };
        try {
            const response = await addRoles(payload);
            if (response) {
                console.log(response, 'res');
            props.history.push('/AllRoles')
            setTimeout(() => {
                notification.success({
                  message: 'Role is Added Successfully.',
                });
              }, 1000);
            }

            // message.success({
            //     content: 'Role is added',
            //     style: {
            //         marginTop: '20vh',
            //     },
            // });
        } catch (e) {
            console.log(e);
            setTimeout(() => {
                notification.error({
                  message: 'Role Already Exist.',
                });
              }, 1000);
            // message.error({
            //     content: 'Role already exist.   ',
            //     style: {
            //         marginTop: '20vh',
            //     },
            // });
        }
    };


    const [form] = Form.useForm();

    const renderSubmitBack = () => {
        props.history.push('/AllRoles')
    }

    return (
        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">

                <section className="section">

                    <SectionHeader title="Add Roles" />

                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '18px',
                    }}>
                        <Tooltip title="Back Button">

                            <Button type="primary"
                                onClick={renderSubmitBack}
                                shape="circle"
                                icon={<ArrowLeftOutlined />}
                                size={'large'}
                                className='btn btn-primary profile'
                            />
                        </Tooltip>


                    </div>

                    <Form
                        form={form}
                        name="name"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={handleSubmit}
                    >
                        <Row gutter={22}>
                            <div className="col-sm-6 col-lg-8 mx-auto">
                                <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: 'Please Enter Role!' }]}
                                >
                                    <Input type="text" placeholder="Enter New Role" />
                                </Form.Item>
                            </div>
                        </Row>
                        <Row>
                        <Form.Item>
                            <div className='col-sm-12 col-lg-8 mx-auto text-right'>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button mb-2"
                                // block
                                >
                                    Submit
                                </Button>
                            </div>
                        </Form.Item>
                        </Row>
                    </Form>

                </section>
            </div>
        </div>
    );
};

export default AddRoles;