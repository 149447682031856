import axios from 'axios';
import { API_URL } from '../../Constant/index';

const token = localStorage.getItem('dataToken');
console.log(token, 'token>>');

export const addUser = (data) => {
const token = localStorage.getItem('dataToken');
  return axios.post(`${API_URL}add/user`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};
export const loginApi = (data) => {
  return axios.post(`${API_URL}admin/signin`, data);
};
export const forgotApi = (data) => {
  return axios.post(`${API_URL}forgotpassword`, data);

};
export const ChangePassApi = (data) => {
const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}changepassword`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};


export const updateprofileApi = (data) => {
const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}updateprofile`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getRoles = (data) => {
  return axios.get(`${API_URL}getAllRoles`, data);
};

export const changePassword = (data) => {
  return axios.put(`${API_URL}resetPassword`, data);
}
