import React, { useEffect, useState } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import {
  Row, Col, Form, Input, Button, Image, Spin,
  Typography,
  message,
  Divider,
  notification,
} from 'antd';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { updateProfile, userDetail } from '../../Api/user/userapi';
import { API_URL } from '../../Constant';
import { useHistory, useParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';

// import {
//   CountryDropdown,
//   RegionDropdown,
// } from 'react-country-region-selector';
const formItemLayout = {
  labelCol: {
    xs: {
      span: 18,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 18,
    },
    sm: {
      span: 16,
    },
  },
};
const { Title } = Typography;

const Editprofile = () => {
  const {id} = useParams();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [fullName, setFullName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [contactno, setcontactno] = useState('');
  const history = useHistory()
  const handleSubmit = async (e) => {
    const payload = {
      firstName,
      fullName,
      lastName,
      gender,
      image: images,
      mobileNo: contactno,
    };
    try {
      const response = await updateProfile(id, payload);
      // //   history.push('/');
      // console.log(response, 'payload');
      localStorage.setItem('userimage',images)
      notification.success({
        message:"Profile Updated"
      })
      // message.success('Profile Updated');
      userDetails();
      history.push('/profile')
    } catch (e) {
      message.error({
        content: 'Profile Not Updated',
        style: {
          marginTop: '20vh',
        },
      });
      //   history.push('/signup');
      console.log(e, 'error');
    }
    // window.location.reload(false);
  };

  useEffect(() => {
    userDetails();
  }, [])
  const userDetails = async () => {
    try {
      setLoading(true);
      const resp = await userDetail(id);
      if (resp && resp.status === 200) {
        const list = resp;  
        setLoading(false); 
        setEmail(list.data.data.email)
        setImages(list?.data?.data?.image);
        setFirstName(list.data.data.firstName)
        setLastName(list.data.data.lastName)
        setFullName(list.data.data.fullName)
        setGender(list.data.data.gender)
        setImg(list.data.data.image)
        setcontactno(list.data.data.mobileNo)
      }
      else{
        notification.open({
          message:"Some Error Occurred."
        })
      }
    } catch (e) {
      notification.error({
        message:"Some Error Occurred"
      })
    }
  };

  const [singleFile, setSingleFile] = useState(null);
  const [imgUrl, setImagesUrl] = useState('');
  const [images, setImages] = useState([]);
  const [img, setImg] = useState();
  const [token, setToken] = useState(localStorage.getItem("dataToken"))
  const handleInputChange = async (event) => {
    let file = event.target.files[0];
    if (file != null) {
      setLoading(true);
      const data = new FormData();
      data.append('modelName', 'users');
      data.append('file', file);
      console.log(data, 'responseJson');
      // Please change file upload URL
      let res = await fetch(
        `${API_URL}user/uploadProfileImage`,
        {
          method: 'post',
          body: data,
          headers: {
            Authorization: `Bearer ${token}`
          },
        }
      );
      let responseJson = await res.json();
      setLoading(false)
      if (responseJson) {
        setImagesUrl(responseJson?.data?.imagePath)
        console.log(responseJson?.data?.imagePath, 'response');
        // setTimeout(() => {
        //     notification.open({
        //         message: 'Project',
        //         description: 'Update Successfully',
        //     });
        // }, 100);
        setImages(API_URL + responseJson?.data?.imagePath);
        setImg(API_URL + responseJson?.data?.imagePath);
        notification.success({
          message:"Profile Image Uploaded Successfully."
        })
      }
    } else { 
      notification.error({
        message:"Please Upload the file"
      })
    }
  }

  const submit = async (e) => { 
    const fileToUpload = singleFile;
    if (singleFile != null) {
      setLoading(true);
      const data = new FormData();
      data.append('modelName', 'users');
      data.append('file', fileToUpload);
      // Please change file upload URL
      let res = await fetch(
        'http://74.208.206.18:4009/user/uploadProfileImage',

        {
          method: 'post',
          body: data,
          headers: {
            Authorization: `Bearer ${token}`
          },
        }
      );
      let responseJson = await res.json();
      setLoading(false)
      if (responseJson) {
        setImagesUrl(responseJson.data.imagePath)
        alert('Uploaded Successfully');
        setImages(API_URL + responseJson.data.imagePath);
        setImg(API_URL + responseJson.data.imagePath);

      }
    } else {
      // If no file selected the show alert
      alert('Please Select File First');
    }
  }

  const backbutton=()=>{
    history.goBack()
  }

  const clearimage=()=>{
    setImg('');
    setImages(null)
  }
  return (
    <div>
      <Spin spinning={loading}>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title="Edit Profile" />
          <Tooltip title="Back Button">
            <button className="btn btn-primary profile rounded-circle" onClick={e=>backbutton()}>
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </Tooltip>
          {/* <input type="file" name="file" onChange={handleChange} name="img" /> */}
          <div className='profilesection'>
          <div className="form">
            <div >
                {img&&img.length?
                
                  <img src={img} alt="profile image" className="image_style" />
                  :
                  <img src="userimg.jpg" alt="profile image" className="image_style" />
                }
              <div className="form-row">
                  <label className="text-white">Select File :</label>
                  <label>
                  <input accept="image/png, image/gif, image/jpeg" type="file" className="d-none" name="upload_file"
                    onChange={handleInputChange} />
                  <i className="fas fa-edit view icon" title='Upload'/>
                  </label>
                <span className='crossicon' onClick={e=>clearimage()}><i class="fa fa-times" aria-hidden="true" title='clear'></i></span>
                  
              </div>
            </div>
          </div>

          <div >
              <div className="sign_up">
                <Title className="sign_up_title" level={3} style={{
                  textAlign: 'center',
                  textTransform: 'capitalize'
                }}>
                  {/* Create your account */}
                </Title>
                {error && message.error(error)}
                <Form className="sign_up_field" name="basic" action="javascript:void(0);" onFinish={handleSubmit}
                  {...formItemLayout}
                >
                  <Divider orientation="center" >User Information</Divider>

                  <div className="row text-left">

                    <div className="col-md-6 ">

                      <label>First Name<span className='astracks'>*</span></label>
                      <Input  type="text" name="firstName" placeholder={firstName} onChange={(e) => setFirstName(e.target.value)} value={firstName && firstName}   rules={[{ required: true, message: 'Please Enter Your First Name!' }]}
                       required
                       />
                    </div>
                    <div className="col-md-6">

                      <label>
                        Last Name<span className='astracks'>*</span></label>
    
                      <Input
                        name="lastName"
                        placeholder={lastName}
                        onChange={(e) => setLastName(e.target.value)} value={lastName && lastName}
                        rules={[{ required: true, message: 'Please Enter Your Last Name!' }]}
                        required
                      />
                    </div>
                  </div>





                  <div className="row text-left">
                    {/* <div className="col-md-6">
                      <label>Full Name</label>
                      <Input disabled={(disabled) ? "" : "disabled"} placeholder={fullName} name="fullName" onChange={(e) => setFullName(e.target.value)} value={fullName && fullName} required />

                    </div> */}
                    <div className="col-md-6">

                      <label>Contact Number<span className='astracks'>*</span></label>
                      <Input  placeholder={contactno} name="contactno" onChange={(e) => setcontactno(e.target.value)} value={contactno && contactno} type='text' minLength={10} maxLength={10}   rules={[{ required: true, message: 'Please Enter Your Contact Number!' }]} required />

                    </div>

                  </div>
                  <div className="row align-items-end">
                    <div className='col-md-8'>
                    {/* <div className="form-row uploader mt-4">
                  <label className="text-black">Select File :</label>
                  <div className='upload'>
                  <label>
                  <input accept="image/png, image/gif, image/jpeg" type="file" className="uploadbtn" name="upload_file"
                    onChange={handleInputChange} />
                  upload images
                  </label>
                  </div>     
              </div> */}
                    </div>

                    <div className="col-md-4">
                      <Button
                        htmlType="submit"
                        className="login-form-button mt-3 text-left"
                      >
                        Save
                      </Button>
                    </div>
                  </div>

                </Form>
               
              </div>

            
          </div>


          </div>
        </section>
      </div>

      </Spin>

    </div>

  );
};

export default Editprofile;