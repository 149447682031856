import React, { useEffect, useState, forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Table, Spin, Select, message, Button, notification, Tooltip } from 'antd';
import { API_URL } from '../../Constant';
import { useHistory, useParams } from 'react-router-dom'
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import Chart from 'react-google-charts';


export default function Dailytasklist() {

    const { employeeid, projectid, value,enddate } = useParams();
    console.log("hhh123", useParams());
    const [loading, setloading] = useState(false);
    const [data, setdata] = useState([]);
    const navigate = useHistory();

    // Data for Graph
    const [linechartrunning, setlinechartrunning] = useState([]);
    const [linechartsuccess, setlinechartsuccess] = useState([]);
    const [linechartfailed, setlinechartfailed] = useState([]);
    // This is Dynamic Data
    // const Graphdata = [
    //     [
    //         "Date",
    //         "Success",
    //         "Failed",
    //         "Running",
    //       ],
    //       ...linechartsuccess&&linechartsuccess.map(item=>{
    //         return [`${item.day}/${item.month}/${item.year}`, item.totalEmployeeCounts, 0, 0]
    //       }),
    //       ...linechartfailed&&linechartfailed.map(item=>{
    //         return [`${item.day}/${item.month}/${item.year}`, 0, item.totalEmployeeCounts, 0]
    //       }),
    //       ...linechartrunning&&linechartrunning.map(item=>{
    //         return [`${item.day}/${item.month}/${item.year}`, 0, 0, item.totalEmployeeCounts]
    //       }),

    //     ];

    // Static Data

    // Options for Graph
    const options = {
        chart: {
            title: `Total Task: 20`,
            subtitle: "Task Details",
        },
    };

    // For Handling View Fuction
    const handleview=(id)=>{
        navigate.push(`/task/details/${id}`)
    }
    // For getting the Data Of the Employee
    const getdata = () => {
        setloading(true)
        const token = localStorage.getItem("dataToken");
        axios.get(`${API_URL}all/task?start_date=${value}&end_date=${enddate}&employeeId=${employeeid}&count=100&projectId=${projectid}`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => { 
            const arraydata = response.data.data[0];
            console.log(arraydata.tasks,"Taks that we needsfdsjdfgjsdgj");
            setdata(arraydata.tasks) 
            setloading(false)
            if (response.status === "error") {
                setloading(false)
                navigate.push("/dailytasks")
                notification.error({
                    message: "Some error Occurred.Please try after some time."
                })
            } else {
                setloading(false)
            }
        }).catch(err => setloading(false))
    }
    useEffect(() => {
        getdata()
    }, [])
    const backbutton = () => {
        navigate.push("/dailytasks");
    }
    return (
        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">
                <Spin spinning={loading}>
                    <section className="section">
                        <SectionHeader title="Daily Task List" />
                        <Tooltip title="Back Button">
                            <Button type="primary"
                                onClick={e => backbutton()}
                                shape="circle"
                                icon={<ArrowLeftOutlined />}
                                size={'large'}
                                className="btn btn-primary profile"
                            />
                        </Tooltip>
                    {/* Project Work Pending */}
                        {/* <div className="shadow p-4 border-rounded bg-white">
                            <Chart
                                chartType="Line"
                                width="100%"
                                height="400px"
                                // data={Graphdata}
                                data={[["Task", "Done"], [4, 5.5], [8, 12]]}
                                options={options}
                            />
                        </div> */}
                        <table class="shadow my-4 table table-bordered tab-bordered table-hover">
                            <thead className='thead-dark'>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Task</th>
                                    <th>Assign Date</th>
                                    <th>Expected Time</th>
                                    <th>Actual Time</th>
                                    <th scope="col">Status</th>
                                    {/* <th>Action</th> */}
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: "20px" }}>
                                {data && data.map((items, index) => (
                                    <tr>
                                        <th >{index + 1}</th>
                                        <td className='col-4 text-warning' style={{cursor:"pointer"}} onClick={()=>handleview(items.id)}>{items?.taskName}</td>
                                        <td>{moment(items?.assignDateAndTime).format("DD MMM YYYY")}</td>
                                        <td>{`${items?.expectedhours} ${items?.expectedminutes}`}</td>
                                        <td>{`${items.Actual_hours} ${items.Actual_minutes}`}</td>
                                        <td className={`text-${items?.status == "inProgress" ? "warning" : items?.status == "done" ? "success" : "danger"} text-capitalize`} >{items?.status}</td>
                                        {/* <td><Button type="primary" onClick={()=>handleview(items.id)} ><i className='fa fa-eye'></i></Button></td> */}
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </section>
                </Spin>
            </div>
        </div>
    )
}
