import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import XLSX from 'xlsx';
import { Form, Input, Button, Typography, notification, Divider, Spin, Row, Col, Select, Tooltip, Radio } from 'antd';
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { addProject, getEmployee } from '../../Api/user/userapi';
import { API_URL } from '../../Constant';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ApiClient from '../../methods/api/apiClient';

const ProjectChart = (props) => {
  const EXTENSIONS = ['xlsx', 'xls', 'csv'];
  const {id}=useParams();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [drop, setDrop] = useState();
  const [document, setdocument] = useState();
  const [token, setToken] = useState(localStorage.getItem("dataToken")); 
  const [projectValue, setProjectValue] = useState({});
  const [uploadfile,setuploadfile]=useState(null);
  const history = useHistory()

  // For file upload thats we need -------------------
  const dropchange = async (e) => {
    setLoading(true);
    const fileToUpload = [];
    let file = e.target.files[0];
    const data = new FormData();
    data.append("modelName", "users");
    data.append("file", file); 
    if(!file)
    { 
      setLoading(false);
    }
    if (fileToUpload) { 
      for (var i = 0; i < fileToUpload.length; i++) {
        data.append("file", fileToUpload[i]); 
      }
    } 
    axios.post(`${API_URL}project/uploadDocument`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {  
      setLoading(false);
      if (response.status === "error") {
        setTimeout(() => {
          notification.error({
            message: 'Error',
            description: 'Some Error Occurred while Uploading the Image Please try After some Time.',
          });
        }, 1000); 
      } else {
      const respo=response.data.data[0];
      setuploadfile(API_URL+respo.imagePath);
      // setuploadfile(e.target.files[0]);
      notification.success({message:"File Uploaded Successfully!"})
      }
    }).catch(err=>setLoading(false))
  };

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() - 0).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const { TextArea } = Input;
  const { Title } = Typography;
  const { Option } = Select;
  const [initialfile,setinitial]=useState(null);

  const getdata=()=>{
    setLoading(true);
    ApiClient.get(`project?id=${id}`).then(res=>{
      if(res.success){
        setLoading(false);
        setProjectValue(res?.data);
        setuploadfile(res?.data.uploadDocuments)
        setinitial(res?.data?.uploadDocuments)
      }
      setLoading(false);
    })
  }
  useEffect(()=>{
    if(id){
      getdata()
    }
  },[])

  const handleSubmit = async (e) => {
    // alert(JSON.stringify(projectValue))
    e.preventDefault()
    const payload = {
      ...projectValue,
    };
    const newupload=uploadfile==""||uploadfile==null||uploadfile==undefined?initialfile:uploadfile;
    if(!id){
      try {
        // setLoading(true);
        const userResp = await addProject({...payload, progress_status:"InProgress",uploadDocuments:newupload});
        props.history.push('/allProject')
        setTimeout(() => {
          notification.success({
            message: 'Project Added Successfully'
          });
        }, 1000);
      } catch (e) {
        notification.error({
          message: 'Project Already Exist'
        });
      } finally {
        setLoading(false);
      }
    }
    else{
      const newpayload1={uploadDocuments:newupload,name:projectValue.name,clientName:projectValue.clientName,description:projectValue.description,git:projectValue.git,totaltime:projectValue.totaltime,startDate:projectValue.startDate,endDate:projectValue.endDate,frontendtechnology:projectValue.frontendtechnology,backendtechnology:projectValue.backendtechnology,frontend_repository_name:projectValue.frontend_repository_name,frontend_owner_workspace_id:projectValue.frontend_owner_workspace_id,frontend_clone_link:projectValue.frontend_clone_link,frontend_clone_password:projectValue.frontend_clone_password,is_frontend_updated_branch_merged_with_master:projectValue.is_frontend_updated_branch_merged_with_master,updated_branch_frontend:projectValue.updated_branch_frontend,admin_repository_name:projectValue.admin_repository_name,admin_owner_workspace_id:projectValue.admin_owner_workspace_id,admin_clone_link:projectValue.admin_clone_link,admin_clone_password:projectValue.admin_clone_password,is_admin_updated_branch_merged_with_master:projectValue.is_admin_updated_branch_merged_with_master,updated_branch_admin:projectValue.updated_branch_admin,api_repository_name:projectValue.api_repository_name,api_owner_workspace_id:projectValue.api_owner_workspace_id,backend_clone_link:projectValue.backend_clone_link,backend_clone_password:projectValue.backend_clone_password,is_api_updated_branch_merged_with_master:projectValue.is_api_updated_branch_merged_with_master,updated_branch_api:projectValue.updated_branch_api,figmaLink:projectValue.figmaLink}
            ApiClient.put(`project/update?id=${id}`,newpayload1).then(res=>{
        if(res.success){
          props.history.push('/allProject')
          setTimeout(() => {
            notification.success({
              message: 'Project Updated Successfully'
            });
          }, 1000);
        }
      })
    }
  };

  const renderSubmitBack = () => {
    history.goBack()
  }

  useEffect(() => {
    getTeam()
  }, [])

  const getTeam = async () => {
    try {
      const resp = await getEmployee();
      if (resp && resp.status === 200) {
        const list = resp;
        const listing = list.data.data
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [form] = Form.useForm();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const [colDefs, setColDefs] = useState()
  const [data, setData] = useState()

  const getExention = (file) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    return EXTENSIONS.includes(extension) // return boolean
  }

  const convertToJson = (headers, data) => {
    const rows = []
    data.forEach(row => {
      let rowData = {}
      row.forEach((element, index) => {
        rowData[headers[index]] = element
      })
      rows.push(rowData)

    });
    return rows
  }

  const importExcel = (e) => {
    const file = e.target.files[0]

    const reader = new FileReader()
    reader.onload = (event) => {
      //parse data
      const bstr = event.target.result
      const workBook = XLSX.read(bstr, { type: "binary" })
      //get first sheet
      const workSheetName = workBook.SheetNames[0]
      const workSheet = workBook.Sheets[workSheetName]
      //convert to array
      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 })
      const headers = fileData[0]
      const heads = headers.map(head => ({ title: head, field: head }))
      setColDefs(heads)
      //removing header
      fileData.splice(0, 1)
      setData(convertToJson(headers, fileData))
    }

    if (file) {
      if (getExention(file)) {
        reader.readAsBinaryString(file)
      }
      else {
        notification.error({
          message:"Invalid file format."
        })
        return
      }
    } else {
      setData([])
      setColDefs([])
    }
  }

   const handlechango=(e)=>{
    console.log(e,"THis is the all file data thats we need which is earlier at the most of the field right ")
   }
  return (
    <div >
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div id="fulldiv"></div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title={!id?"Create Project":"Edit Project"} />
          <div>
            <Tooltip title="Back Button">
              <Button type="primary"
                onClick={renderSubmitBack}
                shape="circle"
                icon={<ArrowLeftOutlined />}
                size={'large'}
                className='btn btn-primary profile'
              />
            </Tooltip>
          </div>

          <div className=''>
            <Spin spinning={loading}>
              <div className="mainfrom">
                <form onSubmit={handleSubmit} >
                  <div className='row m-0'>
                    <div className="col-md-6" >
                        <label>Project Name<span className='star'>*</span></label>
                        <input className='form-control pl-2' value={projectValue.name} onChange={e=>setProjectValue({...projectValue, name:e.target.value})} placeholder='Enter project name' type="text" name='name' required></input>
                    </div>
                    <div className="col-md-6">
                        <label>Client Name<span className='star'>*</span></label>
                        <input className='form-control pl-2' value={projectValue.clientName?projectValue.clientName:""} onChange={e=>setProjectValue({ ...projectValue, clientName:e.target.value})} placeholder='Enter client name' type="text" name='clientname' required></input>
                    </div>
                    <div className="col-md-12">
                      <label>Description<span className='star'>*</span></label>
                      <textarea className="form-control w-100 ml-0 p-2" value={projectValue.description?projectValue.description:""} onChange={e=>setProjectValue({ ...projectValue, description:e.target.value})} placeholder='Enter project description' id="exampleFormControlTextarea1" rows="3" required></textarea>
                    </div>
                    <div className='col-md-6'>
                      <label>Bucket<span className='star'>*</span></label><br/>
                      <select
                        className='w-100 mb-2 border'
                        value={projectValue.git?projectValue.git:""}
                        onChange={e=>setProjectValue({...projectValue, git:e.target.value})}
                        required
                        >
                        <option value="">Select Bucket</option>
                        <option value="github">Github</option>
                        <option value="bitbucket">Bitbucket</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                        <label>Total Hours</label>
                        <input className="form-control pl-2" value={projectValue.totaltime?projectValue.totaltime:""} onChange={e=>setProjectValue({...projectValue, totaltime:e.target.value})} placeholder="Enter total-hours" type="number" id="appt" name="appt"></input>
                    </div>
                    <div className="col-md-6">
                        <label>Start Date<span className='star'>*</span></label>
                        <input className="form-control pl-2" name="startDate" value={projectValue.startDate?projectValue.startDate:""} onChange={e=>setProjectValue({...projectValue, startDate:e.target.value})} type="date" required/>
                    </div>
                    <div className="col-md-6">
                        <label>End Date<span className='star'>*</span></label>
                        <input type="date" name="endDate" className="form-control pl-2" selectsEnd value={projectValue.endDate?projectValue.endDate:""} onChange={e=>setProjectValue({...projectValue, endDate:e.target.value})} min={projectValue.startDate} required/>
                    </div>
                    <div className="col-md-6">      
                        <label>Frontend Technology<span className='star'>*</span></label><br/>
                        <select
                          className='w-100 border mb-4'
                          value={projectValue.frontendtechnology?projectValue.frontendtechnology:""}
                          onChange={e=>setProjectValue({...projectValue, frontendtechnology:e.target.value})}
                          required
                        >
                          <option value="">Select Frontend Technology</option>
                          <option value="React.js">React JS</option>
                          <option value="Next.js">Next JS</option>
                          <option value="Nest.js">Nest JS</option>
                          <option value="Angular.js">Angular</option>
                          <option value="HTML">HTML</option>
                          <option value="React-Native">React Native</option>
                          <option value="Vue.js">Vue JS</option>
                        </select>    
                    </div>
                    <div className="col-md-6">
                        <label>Backend Technology<span className='star'>*</span></label><br/>
                        <select
                          className='w-100 border mb-4'
                          value={projectValue.backendtechnology?projectValue.backendtechnology:""}
                          onChange={e=>setProjectValue({...projectValue, backendtechnology:e.target.value})}
                          required
                        >
                          <option value="">Select Backend Technology</option>
                          <option value="Python">Python</option>
                          <option value="Node JS">Node JS</option>
                          <option value="PHP">PHP</option>
                          <option value="Java">Java</option>
                          <option value="C#">C#</option>
                        </select>
                    </div>
                  </div>
                
                <Divider orientation="left">Frontend</Divider>
                <div className='row m-0'>
                  <div className='col-md-6'>
                    <label>Frontend Repository Name</label>
                    <input className='form-control pl-2' value={projectValue.frontend_repository_name?projectValue.frontend_repository_name:""} onChange={e=>setProjectValue({...projectValue, frontend_repository_name:e.target.value})} placeholder='Enter frontend repository name' type="text" name='repo' ></input>
                  </div>
                  <div className='col-md-6'>
                      <label>Frontend Owner Workspace Id</label>
                      <input className='form-control pl-2' value={projectValue.frontend_owner_workspace_id?projectValue.frontend_owner_workspace_id:""} onChange={e=>setProjectValue({...projectValue, frontend_owner_workspace_id:e.target.value})} placeholder='Enter frontend owner workspace id' type="text" name='workspaceid'></input>
                  </div>
                  <div className='col-md-6'>
                      <label>Frontend Clone Link</label>
                      <input className='form-control pl-2' value={projectValue.frontend_clone_link?projectValue.frontend_clone_link:""} onChange={e=>setProjectValue({...projectValue, frontend_clone_link:e.target.value})} placeholder='Enter frontend clone link' type="text" name='frontendcloneLink'></input>
                  </div>
                  <div className='col-md-6'>
                        <label>Frontend App Password</label>
                        <input type="text" className='form-control pl-2' value={projectValue.frontend_clone_password?projectValue.frontend_clone_password:""} onChange={e=>setProjectValue({...projectValue, frontend_clone_password:e.target.value})} placeholder='Enter frontend app password'/>
                  </div>
                  <div className='col-md-6'>
                    <label>Is Frontend Updated Branch Merge With Master Or Not</label><br/>
                    <select
                      className='w-100 border mb-2'
                      value={projectValue.is_frontend_updated_branch_merged_with_master?projectValue.is_frontend_updated_branch_merged_with_master:""}
                      onChange={e=>setProjectValue({...projectValue, is_frontend_updated_branch_merged_with_master:e.target.value})}
                    >
                      <option value="">Please Select</option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                  <div className='col-md-6'>
                      <label>Frontend Updated Branch</label>
                      <input className='form-control pl-2' value={projectValue.updated_branch_frontend?projectValue.updated_branch_frontend:""} onChange={e=>setProjectValue({...projectValue, updated_branch_frontend:e.target.value})} placeholder='Enter Frontend updated Branch name' type="text" name='branchName'></input>
                  </div>
                </div>

                <Divider orientation="left">Admin</Divider>
                <div className='row m-0'>
                  <div className='col-md-6'>
                    <label>Admin Repository Name</label>
                    <input className='form-control pl-2' value={projectValue.admin_repository_name?projectValue.admin_repository_name:""} onChange={e=>setProjectValue({...projectValue, admin_repository_name:e.target.value})} placeholder='Enter admin repository name' type="text" name='repo' ></input>
                  </div>
                  <div className='col-md-6'>
                      <label>Admin Owner Workspace Id</label>
                      <input className='form-control pl-2' value={projectValue.admin_owner_workspace_id?projectValue.admin_owner_workspace_id:""} onChange={e=>setProjectValue({...projectValue, admin_owner_workspace_id:e.target.value})} placeholder='Enter admin owner workspace id' type="text" name='workspaceid'></input>
                  </div>
                  <div className='col-md-6'>
                      <label>Admin Clone Link</label>
                      <input className='form-control pl-2' value={projectValue.admin_clone_link?projectValue.admin_clone_link:""} onChange={e=>setProjectValue({...projectValue, admin_clone_link:e.target.value})} placeholder='Enter admin clone link' type="text" name='admincloneLink'></input>
                  </div>
                  <div className='col-md-6'>
                        <label>Admin App Password</label>
                        <input type="text" className='form-control pl-2' value={projectValue.admin_clone_password?projectValue.admin_clone_password:""} onChange={e=>setProjectValue({...projectValue, admin_clone_password:e.target.value})} placeholder='Enter admin app password'/>
                  </div>
                  <div className='col-md-6'>
                    <label>Is Admin Updated Branch Merge With Master Or Not</label><br/>
                    <select
                      className='w-100 border mb-2'
                      value={projectValue.is_admin_updated_branch_merged_with_master?projectValue.is_admin_updated_branch_merged_with_master:""}
                      onChange={e=>setProjectValue({...projectValue, is_admin_updated_branch_merged_with_master:e.target.value})}
                    >
                      <option value="">Please Select</option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                  <div className='col-md-6'>
                      <label>Admin Updated Branch</label>
                      <input className='form-control pl-2' value={projectValue.updated_branch_admin?projectValue.updated_branch_admin:""} onChange={e=>setProjectValue({...projectValue, updated_branch_admin:e.target.value})} placeholder='Enter Admin updated branch name' type="text" name='branchName'></input>
                  </div>
                </div>

                <Divider orientation="left">API</Divider>
                <div className='row m-0'>
                    <div className='col-md-6'>
                      <label>API Repository Name</label>
                      <input className='form-control pl-2' value={projectValue.api_repository_name?projectValue.api_repository_name:""} onChange={e=>setProjectValue({...projectValue, api_repository_name:e.target.value})} placeholder='Enter api repository name' type="text" name='repo' ></input>
                    </div>
                    <div className='col-md-6'>
                        <label>API Owner Workspace Id</label>
                        <input className='form-control pl-2' value={projectValue.api_owner_workspace_id?projectValue.api_owner_workspace_id:""} onChange={e=>setProjectValue({...projectValue, api_owner_workspace_id:e.target.value})} placeholder='Enter api owner workspace id' type="text" name='workspaceid'></input>
                    </div>
                    <div className='col-md-6'>
                        <label>API Clone Link</label>
                        <input className='form-control pl-2' value={projectValue.backend_clone_link?projectValue.backend_clone_link:""} onChange={e=>setProjectValue({...projectValue, backend_clone_link:e.target.value})} placeholder='Enter api clone link' type="text" name='apicloneLink'></input>
                    </div>
                    <div className='col-md-6'>
                        <label>API App Password</label>
                        <input type="text" value={projectValue.backend_clone_password?projectValue.backend_clone_password:""} onChange={e=>setProjectValue({...projectValue, backend_clone_password:e.target.value})} className='form-control pl-2' placeholder='Enter api app password'/>
                    </div>
                    <div className='col-md-6'>
                      <label>Is API Branch Merge With Master Or Not</label><br/>
                      <select
                        className='w-100 border mb-2'
                        value={projectValue.is_api_updated_branch_merged_with_master?projectValue.is_api_updated_branch_merged_with_master:""}
                        onChange={e=>setProjectValue({...projectValue, is_api_updated_branch_merged_with_master:e.target.value})}
                      >
                        <option value="">Please Select</option>
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                      </select>
                    </div>
                    <div className='col-md-6'>
                        <label>API Updated Branch</label>
                        <input className='form-control pl-2' value={projectValue.updated_branch_api?projectValue.updated_branch_api:""} onChange={e=>setProjectValue({...projectValue, updated_branch_api:e.target.value})} placeholder='Enter API updated branch name' type="text" name='branchName'></input>
                    </div>
                </div>

                <div className='row'>
                <div className='col-md-6'>
                        <label className='form-label'>Figma Link</label>
                        <input className='form-control pl-2'  onChange={e=>setProjectValue({...projectValue, figmaLink:e.target.value})} placeholder='Enter Figma Link' type="url" name='figmaLink'></input>
                    </div>
                    <div className='col-md-6'>
                        <label className='form-label'>Upload File </label>
                        <input className='form-control p-2' name='uploadDocuments' onChange={(e)=>dropchange(e)} onFocus={e=>e.target.type="file"} value={''} placeholder='Upload the File'  type={`${id?"text":"file"}`} />
                    </div>
                    </div>

                  {/* {
                    document == undefined
                    ?
                      <>
                        <div className="col-md-12 ">
                          <DropzoneArea
                            multiselect
                            name="file"
                            acceptedFiles={["image/*", "video/*","zip/*", "application/*"]}
                            onChange={dropchange.bind()}
                            showFileNames
                            filesLimit={10000}
                            showAlerts={false}
                          />
                        </div>
                      </>
                    :
                      ''
                  } */}
                  <div className="col-md-12 my-3 text-right">
                      <button type="submit" className='btn btn-primary p-2'>
                        {!id?"Submit":"Update"}
                      </button>
                  </div>
                </form>
              </div>
            </Spin>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProjectChart;