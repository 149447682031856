import React, { useEffect, useState,forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Table, Spin, Select, message, Button, notification } from 'antd';
import parse from "html-react-parser";
import { getAllAttandance, getAllResign, updateAttandec, updateResign } from '../../Api/user/userapi';
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import ViewColumn from '@material-ui/icons/ViewColumn';
const { Option } = Select;

const options = [{ value: 'Accept' }, { value: 'Reject' }, { value: 'Cencel' }, { value: 'Panding' }];

const HrResignation = (props) => {
    const history=useHistory();
    const [loading, setLoading] = useState(false);
    const [array, setArray] = useState([])
    const [id, setId] = useState()

    useEffect(() => {
        userDetails();
    }, [])
    const userDetails = async () => {
        try {
            setLoading(true);
            const resp = await getAllResign();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                setArray(arrayData)
            }
        } catch (e) {
            localStorage.clear();
            history.push("/")
    
            setTimeout(() => {
                notification.error({
                  message: 'Unauthorized',
                  description: 'Your Session Has Expired. Please logIn Again.',
                });
              }, 1000);
        }
        setLoading(false);
    };

    const columns = [
        {
            title: "Employee-Name",
            dataIndex: "employeeName",
            field: "employeeName"
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (text, record) => (
                <>
                    {text && text.description ? (
                        <span>
                            {parse(text.description)}
                        </span>
                    ) : (
                        "--"
                    )}
                </>
            ),
        },
        {
            title: "Subject",
            dataIndex: "subject",
            field: "subject",
        },
        {
            title: "From-Email",
            dataIndex: "fromEmail",
            field: "fromEmail",
        },
    ];

    const newlyAddedChapter =
        array && array.length
            ? array.map((items) => {
                return {
                    ...items,
                };
            })
            : [];

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
        };

    return (
        <div >
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">
                <section className="section">
                    <SectionHeader title="Resignations" />
                    <Spin spinning={loading}>
                    <MaterialTable
                          options={{
                            pageSize:10,
                            pageSizeOptions:[10,20,30,40,50], 
                             emptyRowsWhenPaging: false,
                             showTitle:false,
                             draggable:false
                       }}   
                        //  title="Resignations"
                       columns={columns}
                       data={newlyAddedChapter}
                       icons={tableIcons}
                   />
                        {/* <Table columns={columns} dataSource={newlyAddedChapter} pagination={{
                            defaultPageSize: 5,
                            showSizeChanger: true,
                            pageSizeOptions: ["5", "10", "15"],
                        }} /> */}
                    </Spin>
                </section>
            </div>
        </div>
    );
};

export default HrResignation;