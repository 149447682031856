import { Header } from 'antd/lib/layout/layout'
import React, { useEffect, useState } from 'react'
import MainSideBar from '../../Constant/MianSideBar/MainSideBar'
import { ArrowLeftOutlined } from "@ant-design/icons";
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { useHistory } from 'react-router-dom';
import { Button, Spin, Tooltip, message, notification } from 'antd';
import ApiClient from '../../methods/api/apiClient';
import { useParams } from 'react-router-dom';

const AddDevice = () => {
    const history = useHistory();
    const { id } = useParams()
    const [loading, setloading] = useState(false)
    const [err, seterr] = useState(false)
    const [deviceType, setDeviceType] = useState("")
    const [devicedata, setdevicedata] = useState({ deviceName: '', os_type: '', serialno: '' })
    useEffect(() => {
        if (id) {
            getdevice(id)
        }
    }, [])

    const getdevice = (id) => {
        let payload = {
            id: id
        }
        setloading(true)
        ApiClient.get(`device`, payload).then(res => {
            if (res.success) {
                console.log(res.data, "editable")
                setDeviceType(res.data.device_type)
                setdevicedata({ ...devicedata, deviceName: res?.data?.device_name, os_type: res?.data?.os_type, serialno: res?.data?.serial_no })
            }else{
                notification.error({
                    message: "Record not found.",
                  });
            }
        })
        setloading(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!devicedata?.deviceName || !devicedata?.os_type || devicedata?.serialno == "" || devicedata?.serialno.length < 6) {
            seterr(true)
        } else {
            seterr(true)
            let sNo = devicedata?.serialno.toUpperCase()
            let payload = {
                device_name: devicedata?.deviceName,
                device_type: deviceType,
                os_type: devicedata?.os_type,
                serial_no: sNo
            }
            if (id) {
                let payload1 = {
                    ...payload, id: id
                }
                setloading(true)

                ApiClient.put(`device`, payload1).then(res => {
                    if (res.success) {
                        message.success(`Device update successfully`)
                        history.push('/laptop')
                    }
                    setloading(false)

                })
            } else {
                setloading(true)

                ApiClient.post(`device`, payload).then(res => {
                    if (res.success) {
                        message.success(res.message)
                    }
                    setloading(false)

                })
                setdevicedata({ ...devicedata, deviceName: '', os_type: '', serialno: '' })
                history.push('/laptop')
            }
        }
    }
    const renderSubmitBack = () => {
        history.goBack();
    };
    return (
        <div>
            <div className="main-wrapper">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">
                <section className="section">
                    <SectionHeader title={id ? `Edit Device` : `Add New Device`} />
                    <div>
                        <Tooltip title="Back Button">
                            <Button
                                type="primary"
                                onClick={renderSubmitBack}
                                shape="circle"
                                icon={<ArrowLeftOutlined />}
                                size={"large"}
                                className="btn btn-primary profile"
                            />
                        </Tooltip>
                    </div>
                    <div className="container shadow p-4">
                        <Spin spinning={loading}>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className='col-md-6'>
                                        <label>Device Type<span className="star">*</span></label>
                                        <select className="w-100 mb-2 border " required value={deviceType} onChange={e => setDeviceType(e.target.value)}>
                                            <option value="">Select Device Type...</option>
                                            <option value="mobile">Mobile</option>
                                            <option value="laptop/Macbook">Laptop/Macbook</option>
                                            <option value="tablet">Tablet</option>
                                        </select>
                                    </div>
                                    {deviceType && <div className="col-md-6">
                                        <label>Device Name<span className="star">*</span></label><br />
                                        <input type="text" className="form-control" placeholder=' Device Name' value={devicedata.deviceName} onChange={e => setdevicedata({ ...devicedata, deviceName: e.target.value })} />
                                        {err && !devicedata.deviceName ? <span className="span-red">This field is Required</span> : ''}
                                    </div>}
                                </div>
                                {deviceType && <div className="row">
                                    <div className='col-md-6'>
                                        <label>OS Type<span className="star">*</span></label>
                                        <select className="w-100 mb-2 border " value={devicedata.os_type} onChange={e => setdevicedata({ ...devicedata, os_type: e.target.value })}>
                                            <option value="">Select OS Type...</option>
                                            <option value="android">Android</option>
                                            <option value="ios">IOS</option>
                                            <option value="windows">Windows</option>
                                        </select>
                                        {err && !devicedata.os_type ? <span className="span-red">Please select an option</span> : ''}

                                    </div>
                                    <div className="col-md-6">
                                        <label>Serial No.<span className="star">*</span></label><br />
                                        <input type="text" className="form-control" placeholder=' Serial No.' value={devicedata.serialno} onChange={e => setdevicedata({ ...devicedata, serialno: e.target.value })} />
                                        {err && !devicedata.serialno || devicedata.serialno.length <= 7 ? <span className="span-red">Enter Valid serial No.</span> : ''}
                                        {/* { devicedata.serialno.length <= 7?<span className="span-red">Enter Valid serial No.</span> : ''} */}

                                    </div>

                                </div>}
                                {deviceType && <button
                                    type="primary"
                                    htmlType="submit"
                                    className="btn btn-warning mt-3"
                                >
                                    Submit
                                </button>}
                            </form>
                        </Spin>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default AddDevice
