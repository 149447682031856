import Header from "../../Constant/Header/Header"
import React, { forwardRef, useEffect, useState } from 'react'
import MainSideBar from '../../Constant/MianSideBar/MainSideBar'
import { Button, Spin, Tooltip, notification } from 'antd'
import SectionHeader from "../../Constant/SectionHeader/SectionHeader"
import {
    PlusSquareOutlined
} from '@ant-design/icons';
import { useHistory } from "react-router-dom"
import ApiClient from "../../methods/api/apiClient"


import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table"
import moment from "moment"
import { Calendar } from "react-calendar"

const Announcementslist = () => {
    const role=localStorage.getItem('role')
    console.log(role);
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [data, setdata] = useState()
    // const [subject, setsubject] = useState()
    const [singledata, setsingledata] = useState()
    // const [description, setdescription] = useState()
    // const [err, seterr] = useState(false)
    const [value, setvalue] = useState(new Date());

    useEffect(() => {
        getdata()

    }, [])

    const add = () => {
        history.push('/Add/Edit/announcement')
    }
    const getdata = () => {
        setLoading(true)
        ApiClient.get(`announcementListing?count=100000000`).then(res => {
            if (res.success) {
                setdata(res?.data)
                setLoading(false)
            }else{
                localStorage.clear();
                history.push("/")
                setTimeout(() => {
                    notification.error({
                        message: 'Unauthorized',
                        description: 'Your Session Has Expired. Please logIn.',
                    });
                }, 1000);
                setLoading(false)
            }
        })
    }
   
    const changeStatus = (type, value) => {
        console.log(type)
        let statuss = "done"
        if (type == "done") {
            statuss = "pending"
        } else { statuss = "done" }
        const payload = {
            id: value?.id,
            status: statuss,
        }
        if (window.confirm(`Do you really want to change status ?`)) {
            setLoading(true)
            ApiClient.put(`update/todo/list`, payload).then(res => {
                if (res?.success) {
                    getdata();
                    if (type == "active") {
                        notification.success({
                            message: 'Status Updated Successfully',
                        });
                        setLoading(false)
                    }
                    else {
                        notification.success({
                            message: 'Status Updated Successfully',
                        });
                        setLoading(false)
                    }
                }
            })

        }
    }
    const showmodal = (item) => {
        let payload = {
            id: item?._id
        }
        
        setLoading(true)
        ApiClient.get(`announcement`, payload).then(res => {
            if (res.success) {
                setsingledata(res?.data)
                setLoading(false)
                document.getElementById('exampleModal1').click()
            }else{
                localStorage.clear();
                history.push("/")
                setTimeout(() => {
                    notification.error({
                        message: 'Unauthorized',
                        description: 'Your Session Has Expired. Please logIn.',
                    });
                }, 1000);
            }
            setLoading(false);
        })
    }
    const closemodal=()=>{
        setsingledata()
    }

    const columns = [
        {
            title: "Event on",
            dataIndex: "start_date",
            field: "start_date",
            render: (text, record) => (
                <Tooltip title="View" >   <span style={{cursor:'pointer'}}  onClick={e => showmodal(text)}>{moment(text.createdAt).format("DD MMM YY")}</span></Tooltip>
            )
        },
        {
            title: "Event Name",
            dataIndex: "subject",
            field: "subject",

        },
        {
            title: "Announcement",
            dataIndex: "description",
            field: "description",

        },
       
    ]
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
        )),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
        )),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => (
            <ArrowDownward {...props} ref={ref} />
        )),
        ThirdStateCheck: forwardRef((props, ref) => (
            <Remove {...props} ref={ref} />
        )),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    };


    const newlyAddedData = data ? data.map((items) => {
        return { ...items }
    }) : []

    //calender
    const onChangeDatesss = (newDate) => {
        setLoading(true)
        setvalue(newDate);
        const startdate = moment(newDate[0]).format("YYYY-MM-DD");
        const enddat = moment(newDate[1]).format("YYYY-MM-DD");
        // let p = {
        //     start_date: startdate,
        //     end_date: enddat
        // }
        setLoading(true)
        ApiClient.get(`announcementListing?count=100000000&start_date=${startdate}&end_date=${enddat}`).then(res => {
            if (res.success) {
                setdata(res?.data)
            }
            setLoading(false)
        })
    };
    return (
        <>
            <>
                <div>
                    <div className="main-wrapper" id="mainPannel">
                        <div className="navbar-bg" />
                        <Header />
                        <MainSideBar />
                        <div className="main-content">
                            <Spin spinning={loading}>
                                <section className='section ml-3'>
                                    <SectionHeader title={"All Announcements"} />
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}>
                                        <Tooltip title="Add New">
                                            <Button type="primary"
                                                onClick={add}
                                                shape="circle"
                                                icon={<PlusSquareOutlined />}
                                                size={'large'}
                                                className="btn btn-primary profile"
                                            />
                                        </Tooltip>
                                      <div className=" d-flex justify-content-between col-md-12 mb-3">
                                    <Calendar
                                        selectRange={true}
                                        onChange={onChangeDatesss}
                                        value={value}
                                        className="calender"
                                    />
                                </div>
                                    </div>
                                </section>
                                <>  <div className="col-md-12">
                                    <div className="">
                                        <MaterialTable
                                            options={{
                                                showTitle: false,
                                                pageSize: 10,
                                                pageSizeOptions: [10, 20, 30, 40, 50],
                                                emptyRowsWhenPaging: false,
                                                draggable:false
                                            }}
                                            columns={columns}
                                            data={newlyAddedData}
                                            icons={tableIcons}
                                        />

                                    </div>
                                </div></>
                              
                                <button type="button" id="exampleModal1" hidden={true} className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                                    Launch demo modal
                                </button>

                                <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">Details</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body ml-3 mr-3 mt-3 shadow">
                                                <div className="row">
                                                    <div className="col-md-6 ">
                                                        <h6 className=""><b>Event</b></h6> <span>{singledata ? singledata?.subject : "--"}</span>
                                                    </div>
                                                    <div className="col-md-6 ">
                                                        <h6 className=""><b>Event Date</b></h6> <span>{moment(singledata?.date).format("DD MMM YY")}</span>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-6">
                                                        <h6 className=""><b>Announcement </b></h6> <span className="">{singledata ? singledata?.description : "--"}</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={e=>closemodal()}>Close</button>
                                                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Spin>
                        </div>
                    </div>
                </div>

            </>

        </>
    )
}

export default Announcementslist
