import React, { useEffect, useState } from "react";
import {
  Table,
  Spin,
  Select,
  message,
  Button,
  notification,
  Tooltip,
  Popconfirm,
} from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import Header from "../../../Constant/Header/Header";
import MainSideBar from "../../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../../Constant/SectionHeader/SectionHeader";
import ApiClient from "../../../methods/api/apiClient";

export default function AddTask() {
    const {id}=useParams(); 
    const [loading,setloading]=useState(false);
    const history=useHistory();
    const [memberarray,setmemberarray] = useState();
    const [arrayTeam, setArrayTeam] = useState();
    
    const initial={taskName:"",assignDateAndTime:"",employee:"",expected_time:"",description:""};
    const [form,setform]=useState([initial]);

    const handlechange=(e,index)=>{  
        const field = [...form];
        setform([...form], (field[index][e.target.name] = e.target.value));
    };
    const backbutton=()=>{
        history.goBack()
    };
    const handlesubmit=(e)=>{ 
        e.preventDefault();
        ApiClient.post(`task`,form).then(res=>{
          history.goBack();
          notification.success({message:"Task Added Successfully"});
        }).catch(err=>{
          notification.error({message:"Some error Occurred.Please try again"})
        })
    };
    const addmore=()=>{
        const newdata=initial
        setform([...form,newdata])
    };
    const remove=(i,e)=>{
        e.preventDefault();
        const myformdata = [...form];
        myformdata.splice(i, 1);
        setform(myformdata); 
    }
    const goback=()=>{
  history.goBack();
    }


    const userlist =() =>{
        setloading(true)
        ApiClient.get(`project?id=${id}`)
        .then(res=>{
            if(res.success){
                setArrayTeam(res.data.members)
                setloading(false)
            }
            setloading(false);
        })
    }
    useEffect(()=>{
userlist();
    },[])
  return (
    <div>  <div className="">
    <div className="main-wrapper" id="mainPannel">
      <div className="navbar-bg" />
      <Header />
      <MainSideBar />
    </div>
    <div className="main-content">
      <div className="inventry_section">
      <section className="section">
        <SectionHeader title={`Add Task`} />
        <Tooltip title="Back Button">
          <Button
            type="primary"
            onClick={goback}
            shape="circle"
            icon={<ArrowLeftOutlined />}
            size={"large"}
            className="btn btn-primary profile"
          />
        </Tooltip>
        <Spin spinning={loading}>

        <form className='mt-3 p-3' onSubmit={(e)=>handlesubmit(e)}>
                    {form.map((item,index)=>(
                        <div className='shadow p-3 mt-3'>
                       {index==0?null:    <div>
    <h5 className='d-inline'>{index==0?null: "Task"+" "+ (index+1)}</h5> <button type='button' style={{float:"right"}} onClick={(e)=>remove(index,e)} className='btn btn-danger'><i className='fa fa-trash'></i></button></div>}
                <label className='control-label'>Task Name</label>
                <input required placeholder='Task Name' className='form-control' name='taskName' value={item.taskName}  onChange={(e)=>handlechange(e,index)}/>
                <div className='row'>
                    <div className='col-6'>
                <label  className='control-label'>Assign Date</label>
                <input required placeholder='Assign Date' type='date' className='form-control' name='assignDateAndTime' value={item.assignDateAndTime}  onChange={(e)=>handlechange(e,index)}/>
                </div>
  
                <div className='col-6'>
                <label className='control-label'>Expected Time</label>
                <input required className='form-control' type='time' name='expected_time' value={item.expected_time}  onChange={(e)=>handlechange(e,index)}/> 
                </div>
                <select
                                                placeholder="Select Employee"
                                                name="employee"
                                                value={item.employee}
                                                onChange={e=>handlechange(e,index)}
                                                className='form-control mt-3 mb-2 col-10 ml-3'
                                                required
                                                >
                                                <option value=''>Select Employee</option>
                                                {arrayTeam && arrayTeam.map(item => (
                                                    <option key={item.id} value={item.id} >{item.fullName}{`(${item.designation})`}</option>
                                                ))}
                                            </select>
                </div>
                <label  className='control-label'>Stories</label>
                <textarea placeholder='Stories' required className='form-control' name='description' value={item.description}  onChange={(e)=>handlechange(e,index)}/> 
                </div>
                    ))} 
                    <button className="btn btn-warning mt-3" type="submit ">Submit</button>
                    <button className='btn btn-success mt-3' onClick={(e)=>addmore()} style={{float:"right",backgroundColor:"green"}} type='button' >Add More</button>
                    </form>     
            </Spin>
            </section>
            </div>
            </div>
            </div>
        </div>
  )
}
