import React, { useEffect, useState } from "react";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import Header from "../../Constant/Header/Header";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import { API_URL } from '../../Constant';
import axios from 'axios';
import {
    Form,
    Button,
    Spin,
    message,
    Select,
    notification,
    Tooltip,
} from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { addInterview } from "../../Api/user/userapi";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

function Addeditinterview() {
    const [loading, setLoading] = useState(false);
    const [employeeid,setemployeeid]=useState("")
    const [loginErrorMsg, setLoginErrorMsg] = useState("");
    const [id, setId] = useState("");
    const [arrayproject, setArrayproject] = useState([]);
    const [arrayemp, setArrayepm] = useState([]);
    const [projectValue, setProjectValue] = useState({});
    const [token, setToken] = useState(localStorage.getItem("dataToken"));
    const [uploadfile, setuploadfile] = useState("");
    const params = useParams();

    const dropchange = async (e) => {
        setLoading(true);
        const fileToUpload = [];
        let file = e.target.files[0];
        const data = new FormData();
        data.append("modelName", "users");
        data.append("file", file);
        if (!file) {
            setLoading(false);
        }
        if (fileToUpload) {
            for (var i = 0; i < fileToUpload.length; i++) {
                data.append("file", fileToUpload[i]);
            }
        }
        axios.post(`${API_URL}project/uploadDocument`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                setLoading(false);
                if (response.status === "error") {
                    setTimeout(() => {
                        notification.error({
                            message: 'Error',
                            description: 'Some Error Occurred while Uploading the Image Please try After some Time.',
                        });
                    }, 1000);
                } else {
                    const respo = response.data.data[0];
                    setuploadfile(API_URL + respo.imagePath);
                    setProjectValue({...projectValue,resume:API_URL + respo.imagePath})
                    console.log(respo.imagePath,"hello response");
                    notification.success({ message: "File Uploaded Successfully!" })
                }
            }).catch(err => setLoading(false))
    };

    const onlyNumber = ($event) => {
        //console.log($event.keyCode); //keyCodes value
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
            $event.preventDefault();
        }
    }

    const str = projectValue?.startTime;
    const strend = projectValue?.endTime;

    const chars = str?.split(":00.000Z");
    const charsend = strend?.split(":00.000Z");
 
    let history = useHistory();
    const renderSubmitBack = () => {
        history.goBack();
    };
    const Projectslist = () => {
        ApiClient.get(`projects?sortBy=asc&count=500`).then((res) => {
            setArrayproject(res.data);
        });
    };
    const Emplist = () => {
        ApiClient.get(`users?tl=true`).then((res) => {
            setArrayepm(res.data);
        });
    };

    const Empuserlist = () => {
        setLoading(true);
        ApiClient.get(`interview?id=${params.id}`).then((res) => {
            setProjectValue(res.data);
            setemployeeid(res.data.employeeId.id)
            setLoading(false);
        });
    };

    useEffect(() => {
        if (params.id) {
            Empuserlist();
        } else {
        }
        Projectslist();
        Emplist();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            ...projectValue,
            resume:uploadfile,
        };
        if (!params.id) {
            try {
                const userResp = await addInterview({ ...payload });
                history.push("/interview");
                setTimeout(() => {
                    notification.success({
                        message: "Added Successfully",
                    });
                }, 1000);
            } catch (e) {
                notification.error({
                    message: "Project Already Exist",
                });
            } finally {
                setLoading(false);
            }
        } else {
            console.log(payload,"This is our edit payload")
            delete payload.updatedAt;
            delete payload.isDeleted;
            delete payload.addedBy;
            delete payload.createdAt;
            const newpayload={...payload,employeeId:employeeid}
            ApiClient.put(`interview`, newpayload).then((res) => {
                if (res.success) {
                    history.push("/interview");
                    setTimeout(() => {
                        notification.success({
                            message: "Project Updated Successfully",
                        });
                    }, 1000);
                }
            });
        }
    };
    const [form] = Form.useForm();
    return (
        <div>
            <div className="main-wrapper">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">
                <section className="section">
                    <SectionHeader title="Interview" />
                    <div>
                        <Tooltip title="Back Button">
                            <Button
                                type="primary"
                                onClick={renderSubmitBack}
                                shape="circle"
                                icon={<ArrowLeftOutlined />}
                                size={"large"}
                                className="btn btn-primary profile"
                            />
                        </Tooltip>
                    </div>
                    <div className="container shadow p-4">
                        <Spin spinning={loading}>
                            <div>
                                {loginErrorMsg && message.error(loginErrorMsg)}
                                <form onSubmit={handleSubmit}>
                                    <Spin spinning={loading}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>
                                                    Select Employee<span className="star">*</span>
                                                </label>
                                                <br />
                                                <select
                                                    className="w-100 mb-2 border"
                                                    value={
                                                     params.id? employeeid
                                                            : projectValue.employeeId
                                                    }
                                                    onChange={(e) =>{
                                                        setemployeeid(e.target.value)
                                                        setProjectValue({
                                                            ...projectValue,
                                                            employeeId: e.target.value,
                                                        })
                                                    }
                                                    }
                                                    required
                                                >
                                                    <option value="">Select Employee...</option>
                                                    {arrayemp.map((user) => (
                                                        <option value={user.id}>{user.fullName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-6"> 
                                                <label>
                                                    Interview Date and Time<span className="star">*</span>
                                                </label>
                                                <br />
                                                <input
                                                    className="w-100 mb-2 border"
                                                    required
                                                    type="datetime-local"
                                                    value={
                                                        projectValue?.InterviewDateAndTime
                                                    }
                                                    onChange={(e) =>
                                                        setProjectValue({
                                                            ...projectValue,
                                                            InterviewDateAndTime: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label>
                                                    Way of Interview<span className="star">*</span>
                                                </label>
                                                <br />
                                                <select
                                                    className="w-100 mb-2 border"
                                                    value={
                                                        projectValue?.wayOfInterview ? projectValue.wayOfInterview : ""
                                                    }
                                                    onChange={(e) =>
                                                        setProjectValue({
                                                            ...projectValue,
                                                            wayOfInterview: e.target.value,
                                                        })
                                                    }
                                                    required
                                                >
                                                    <option value="">Select Interview type...</option>
                                                    <option value="Personal">Personal</option>
                                                    <option value="Telephonic">Telephonic</option>
                                                    <option value="Online">Online</option>
                                                </select>
                                            </div>
                                            {projectValue.wayOfInterview == "telephone_interview" ?
                                                <div className="col-md-6 text-left">
                                                    <label>Contact Number<span className="star">*</span></label>
                                                    <div className="">
                                                        <input
                                                            placeholder="+91"
                                                            name="contactNumber"
                                                            minLength={10}
                                                            maxLength={12}
                                                            type="text"
                                                            onKeyPress={onlyNumber}
                                                            className="form-control " style={{ paddingLeft: "10px" }}
                                                            onChange={(e) =>
                                                                setProjectValue({
                                                                    ...projectValue,
                                                                    contactNumber: e.target.value,
                                                                })
                                                            }
                                                            value={projectValue.contactNumber}
                                                        />
                                                    </div>
                                                </div> : ""}
                                            {projectValue.wayOfInterview == "Online_interview" ?
                                                <div className='col-md-6'>
                                                    <label> Online Interview Link</label>
                                                    <input className='form-control pl-2'
                                                        value={projectValue.interviewLink ? projectValue.interviewLink : ""}
                                                        onChange={e => setProjectValue({ ...projectValue, interviewLink: e.target.value })}
                                                        placeholder='Enter admin clone link' type="text" name='admincloneLink'></input>
                                                </div>
                                                : ""}
                                                <div className='col-md-6'>
                                                <label className='form-label'>Upload Resume</label>
                                                <input className='form-control p-2' name='uploadDocuments' 
                                                onChange={(e) => dropchange(e)} onFocus={e => e.target.type = "file"} 
                                               placeholder='Upload the File' type={`${id ? "text" : "file"}`} />
                                               {projectValue.resume==""||projectValue.resume==null?null: <img src={projectValue.resume} className="imaguploadcls" /> }
                                            </div>
                                            <div className="col-md-12">
                                                <div className="text-right mt-5">
                                                    <button
                                                        type="primary"
                                                        htmlType="submit"
                                                        className="btn btn-warning"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Spin>
                                </form>
                            </div>
                        </Spin>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Addeditinterview;
