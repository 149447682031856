import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, notification } from "antd";
// import {  updateCourses } from "../../Api/Courses/course";
import { updateProject, updateRoles } from "../../Api/user/userapi";


const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 18,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 18,
    },
    sm: {
      span: 16,
    },
  },
};

function EditRoles(props) {
  const { setIsEditModalVisible, editChapterData, chapterId, showData } = props;

  const [isEdit, setIsEdit] = useState(false);

  const [courseValue, setCourseValue] = useState({
    name: "",
  });

  const [form] = Form.useForm();

  const inputHandle = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    console.log(value, 'evet');

    setCourseValue({
      ...courseValue,
      [name]: value,
    });
  };

  useEffect(() => {
    if (editChapterData) {
      setCourseValue({
        name: editChapterData.name,
      });
      console.log(editChapterData, "editChapterData");
    }
  }, [editChapterData]);

  const editChapterHandle = async () => {
    setIsEdit(true);
    const payload = {
        chapterId,
        name: courseValue.name,
    };
    console.log(payload, 'payload');
    try {
      const resp = await updateRoles(chapterId, payload);
      if (resp && resp.status === 200) {
        showData();
        setTimeout(() => {
          notification.success({
            message: 'Updated Successfully.',
          });
        }, 500);
        // message.success("updated successfully.");
        setIsEditModalVisible(false);
      }
    } catch (e) {
      console.log(e);
      setTimeout(() => {
        notification.error({
          message: 'Role Not Updated.',
        });
      }, 500);
    }
  };

  const closemodal=()=>{
    props.onCancel()
  }
  return (
    <>
      <div>
        <Form onFinish={editChapterHandle} form={form}
        {...formItemLayout}
        >
         <div className="col-md-12">
           <lable nameclss>Name<span className="star">*</span></lable>
            <Input
            
              placeholder="Enter name"
              name="name"
              value={courseValue.name}
              onChange={inputHandle}
              required
            />
         
         </div>
          

          <Form.Item
          wrapperCol={{
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 8,
              offset: 10,
            },
          }}
          >
               <div className="forgote d-flex justify-content-between">
               <button type="button" className="bg-danger mt-4" onClick={e=>closemodal()}>Close</button>
            <Button type="primary" htmlType="submit" className='login-form-button mt-4'>
              Update Role
            </Button>
            </div>
          </Form.Item>
        </Form>
     
      </div>
    </>
  );
}

export default EditRoles;
