import React, { useEffect, useState, forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Table, Spin, Select, message, Button, notification } from 'antd';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { attendancce, datadates, getAttendace, getAttendaces } from '../../Api/user/userapi';
import moment from 'moment';
// impor.css';
import { API_URL } from '../../Constant';
import axios from 'axios';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useHistory } from 'react-router-dom';

const Attandace = () => {
    const history=useHistory();
    const [loading, setLoading] = useState(false);
    const [array, setArray] = useState([])
    const [array1, setArray1] = useState([])

    const [alldataa, setalldata] = useState([])
    console.log(array1, "array1array1");


    const [value, onChange] = useState(new Date());

    console.log(array, 'arrayarray');
    const [singleuserdata, setsingleuserdata] = useState()
    console.log(singleuserdata, "singleuserdata");

    const columns = [
        {
            title: "Full-Name",
            dataIndex: "fullName",
            field: "fullName",
        },
        {
            title: "Email",
            dataIndex: "email",
            field: "email",
        },
        {
            title: "Attendance",
            dataIndex: "attendancetime",
            field: "attendancetime",
        },
        {
            title: "Action",
            dataIndex: "",
            field: "",
            render: (text, record) => (
                <>
                    <Button onClick={(e) => AttendenceList(text.userid, text.currentDate)} className="btn btn-success">Attendence List</Button>
                </>
            ),

        },

    ];


    const columns1 = [
        {
            title: "Full-Name",
            dataIndex: "name",
            field: "name",
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: "Email",
            dataIndex: "email",
            field: "email",
            render: (text, record) => (
                console.log(text, 'recodddd'),
                <>
                    {
                        text.email
                    }
                </>
            ),
        },
        {
            title: "Check In-Time",
            dataIndex: "checkIn",
            field: "checkIn",
            render: (text, record) => (
                <>
                    {
                        moment(text.checkIn).format("hh:mm:ss:A")
                    }
                </>
            ),
        },
        {
            title: "Check Out-Time",
            dataIndex: "checkOut",
            field: "checkOut",
            render: (text, record) => (
                <>
                    {
                        moment(text.checkOut).format("hh:mm:ss:A")
                    }
                </>
            ),
        },
        {
            title: "Reason",
            dataIndex: "reason",
            field: "reason",
        },
    ];

    const newlyAddedChapter =
        array && array.length
            ? array.map((items) => {
                return {
                    ...items,
                };
            })
            : [];


    const newlyAddedChapter1 =
        array1 && array1.length
            ? array1.map((items) => {
                console.log(items, 'itemsss');
                return {
                    ...items,
                };
            })
            : [];



    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
    const token = localStorage.getItem("dataToken");

    const AttendenceList = async (data, e) => {
        const payload = {
            userid: data,
            currentDate: e
            // userid,
        }
        axios.get(`${API_URL}attendance/single/user?attendancedate=${e}&userID=${data}`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setsingleuserdata(response.data)
            console.log(response, "fdgfdgfdgf");
            const arrayData = response.data.data;
            setArray1(arrayData)
            if (response.status === "error") {
                alert("error");
                this.loading = false;
            } else {
            }
        });
    }

    let today = new Date().toISOString().slice(0, 10)


    useEffect(() => {
        renderData()
        onChangeDate();
    }, [])

    const renderData = async (newDate) => {
        onChange(newDate)
        const newDates = convert(newDate); 

        try {
            setLoading(true);

            const resp = await getAttendaces();
            if (resp && resp.status === 200) {
                const list = resp;

                console.log(list, 'listasas');
                const alldata = list.data.data;
                setArray1(alldata)

            }
        } catch (res) {  
            localStorage.clear();
            history.push("/")

            setTimeout(() => {
                notification.error({
                    message: 'Unauthorized',
                    description: 'Your Session Has Expired. Please logIn.',
                });
            }, 1000);

        }
        setLoading(false);



    }
    const onChangeDate = async (newDate) => {
        onChange(newDate)
        const newDates = convert(newDate);
        console.log(newDates, 'newDate');

        try {
            setLoading(true);

            const resp = await getAttendace(today);
            if (resp && resp.status === 200) {
                const list = resp;

                console.log(list, 'lisccccccccccctasas');
                const arrayData = list.data.data;
                setArray(arrayData)

            }
        } catch (res) {
            console.log(res, 'error');
            setArray('')

        }
        setLoading(false);



    }


    const onChangeDatesss = async (newDate) => {
        onChange(newDate)
        const newDates = convert(newDate);
        console.log(newDates, 'newDate');

        try {
            setLoading(true);

            const resp = await datadates(newDates);
            if (resp && resp.status === 200) {
                const list = resp;

                console.log(list, 'lisccccccccccctasas');
                const arrayData = list.data.data;
                setArray(arrayData)

            }
        } catch (res) {
            console.log(res, 'error');
            setArray('')

        }
        setLoading(false);



    }


    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    return (
        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content"> 
                <section className="section">

                    <SectionHeader title="Attendance" />
                    <div className='main_div'  >
                        <Calendar
                            onChange={onChangeDatesss}
                            value={value}
                            className="calender"
                        /> 
                    </div>

                    {
                        singleuserdata
                            ?
                            <Spin spinning={loading}>
                                <MaterialTable
                                 options={{
                                    pageSize:10,
                                    pageSizeOptions:[10,20,30,40,50], 
                                     emptyRowsWhenPaging: false,
                                     draggable:false
                               }}   
                                    // title="Attendance"
                                    columns={columns1}
                                    data={newlyAddedChapter1}
                                    icons={tableIcons}

                                />
                            </Spin>
                            :
                            <Spin spinning={loading}>
                                <MaterialTable
                                 options={{
                                    pageSize:10,
                                    pageSizeOptions:[10,20,30,40,50], 
                                     emptyRowsWhenPaging: false,
                                     draggable:false
                               }}   
                                    // title="Attendance"
                                    columns={columns}
                                    data={newlyAddedChapter}
                                    icons={tableIcons}

                                />
                            </Spin>
                    }
                </section>
            </div>
        </div>
    );
};

export default Attandace;