import MaterialTable from "material-table";
import { forwardRef, useEffect, useState } from "react";
import Header from "../../Constant/Header/Header";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import { LeftOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { deleteProject, documentsdownload,alldocumentsdownload, getPlatforms, getProjects, updateStatus } from '../../Api/user/userapi';

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Button, notification, Spin, Tooltip } from "antd";
import { useHistory, useParams } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import { API_URL } from "../../Constant";
import axios from "axios";


const Downloadtask = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  const backbutton = () => {
    history.goBack();
  };

  const download = (documents) => {
    // alert(documents)
    window.open(`${API_URL}${documents}`);
    notification.success({
      message: "Download Successfully.",
    });
  };




  const alldownload = (alldocumentsdownload) => {
    window.open(`${API_URL}getprojectdocuments?id=${id}`);
    notification.success({
      message: "Download Successfully.",
    });
  };



  const getdata = () => {
    setloading(true);
    ApiClient.get(`project?id=${id}`).then((res) => {
      if (res.success) {
        setloading(false);
        setdata(res.data.weekly_plan);
      } else {
        setloading(false);
      }
    });
  };



  useEffect(() => {
    getdata();
  }, []);

  const columns = [
    {
      title: "Start Date",
      dataIndex: "startDate",
      field: "startDate",
      render: (data) => <>{date(data && data?.startDate)}</>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      field: "endDate",
      render: (data) => <>{date(data && data?.endDate)}</>,
    },
    {
      title: "Download",
      dataIndex: "download",
      field: "download",
      render: (data) => (
        <>
          <Tooltip title="Download">
            <i
              className="fa fa-download"
              style={{ fontSize: "24px", color: "blue" }}
              onClick={(e) => download(data?.documents)}
            ></i>
          </Tooltip>
        </>
      ),
    },


    // {
    //   title: "Alldownload",
    //   dataIndex: "alldownload",
    //   field: "alldownload",
    //   render: (data) => (
    //     <>
    //       <Tooltip title="Download">
    //         <i
    //           className="fa fa-download"
    //           style={{ fontSize: "24px", color: "blue" }}
    //           onClick={(e) => download(data?.getprojectdocuments)}
    //         ></i>
    //       </Tooltip>
    //     </>
    //   ),
    // },
  ];




  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  // Date & Time format
  const monthArray = [
    { value: "Jan", id: 0 },
    { value: "Feb", id: 1 },
    { value: "Mar", id: 2 },
    { value: "Apr", id: 3 },
    { value: "May", id: 4 },
    { value: "Jun", id: 5 },
    { value: "Jul", id: 6 },
    { value: "Aug", id: 7 },
    { value: "Sep", id: 8 },
    { value: "Oct", id: 9 },
    { value: "Nov", id: 10 },
    { value: "Dec", id: 11 },
  ];

  const monthfind = (id) => {
    let value = 0;
    let ext = monthArray.find((itm) => itm.id === id);
    if (ext) value = ext.value;
    return value;
  };

  const date = (p) => {
    let d = new Date(p);
    return `${d.getDate()} ${monthfind(d.getMonth())} ${d.getFullYear()}`;
  };

  const time = (p) => {
    if (!p) return "";
    let v = p.split("T");
    if (v.length === 2) {
      return new Date(p).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    } else {
      return new Date(`2020-07-19 ${p}`).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    }
  };

  return (
    <>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title="Download Weekly Task" />
          <Tooltip title="Back Button">
            <Button
              type="primary"
              onClick={(e) => backbutton()}
              shape="circle"
              icon={<ArrowLeftOutlined />}
              size={"large"}
              className="btn btn-primary profile"
            />
          </Tooltip>

          <div>
            <Spin spinning={loading}>
              <MaterialTable
                icons={tableIcons}
                options={{
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50],
                  emptyRowsWhenPaging: false,
                  showTitle: false,
                  draggable:false
                }}
                data={data}
                columns={columns}
              />
            </Spin>

            <div className="text-right mt-4">
              <button className="btn btn-primary"  onClick={(e) => alldownload()}>Download All </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Downloadtask;
