import React, { useEffect, useState } from "react";
import { Button, Spin, Tooltip } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import ApiClient from "../../methods/api/apiClient";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import moment from "moment";
import Header from "../../Constant/Header/Header";
import { EditOutlined } from "@ant-design/icons";

export default function Viewinterview() {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  const history = useHistory();
  const [projectValue, setProjectValue] = useState({});
  console.log(projectValue?.wayOfInterview, "projectValue");
  const backbutton = () => {
    history.goBack();
  };
  const getdata = () => {
    setloading(true);
    ApiClient.get(`interview?id=${id}`).then((res) => {
      setdata(res.data);
      console.log(res.data);
      setloading(false);
    });
  };
  useEffect(() => {
    getdata();
  }, []);

  const editdetails=()=>{
    history.push(`/addeditinterview/${id}`)
  }
  return (
    <div>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title="Interview Details" />
          <Tooltip title="Back Button">
            <Button
              type="primary"
              onClick={(e) => backbutton()}
              shape="circle"
              icon={<ArrowLeftOutlined />}
              size={"large"}
              className="btn btn-primary profile"
            />
          </Tooltip>
          <Spin spinning={loading}>
            <div className="shadow">
              <span>
                <Tooltip title="Edit">
                    <Button type="primary" className="edit_btn edit-button mr-2 mt-2" onClick={e=>editdetails()}>
                        {<EditOutlined  />}
                    </Button>
                </Tooltip>
              </span>
              <div className="row p-4">
                <div className="col-md-6">
                  <label className="form-label text-bold">Employee Name:</label>
                  <span className="datacls">
                    {data.employeeId && data.employeeId.fullName}
                  </span>
                </div>

                <div className="col-md-6">
                  <label className="form-label text-bold">
                    Way of Interview:
                  </label>
                  <span className="datacls">{data.wayOfInterview}</span>
                </div>

                {data.wayOfInterview == "telephone_interview" ? 
                  <div className="col-md-6">
                    <label className="form-label text-bold">
                      Contact Number:
                    </label>
                    <span className="datacls">{data.contactNumber}</span>
                  </div>
                : 
                  ""
                }

                {data.wayOfInterview == "Online_interview" ? 
                  <div className="col-md-6">
                    <label className="form-label text-bold">
                      {" "}
                      Online Interview Link:
                    </label>
                    <span className="datacls">{data.interviewLink}</span>
                  </div>
                : 
                  ""
                }

                <div className="col-md-6">
                  <label className="form-label text-bold">
                    Interview Date:
                  </label>
                  <span className="datacls">
                    {moment(data.InterviewDateAndTime).format("DD MMM YYYY")}
                  </span>
                </div>

                <div className="col-md-6">
                  <label className="form-label text-bold">
                    Interview Time:
                  </label>
                  <span className="datacls">
                    {moment(data.InterviewDateAndTime).format('LT')}
                  </span>
                </div>

                {data.resume ? (
                  <div className="col-md-6">
                    <label className="form-label text-bold">
                       Resume:
                    </label>
                    {/* <span className="datacls">{data.resume}</span> */}
                 <img src={data.resume} className="imaguploadcls" /> 
                  </div>
                ) : null}

              </div>
            </div>
          </Spin>
        </section>
      </div>
    </div>
  );
}
