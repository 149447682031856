import { Button, notification, Popconfirm, Select, Spin, Tooltip } from "antd"
import MaterialTable from "material-table"
import { forwardRef, useEffect, useState } from "react"
import { Link, Redirect, useHistory } from "react-router-dom"
import MainSideBar from "../../Constant/MianSideBar/MainSideBar"
import SectionHeader from "../../Constant/SectionHeader/SectionHeader"
import { PlusSquareOutlined } from '@ant-design/icons';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Option } from "antd/lib/mentions";
import { addsalesdata, deletesales, editsales, salesdata } from "../../Api/user/userapi";
import Header from "../../Constant/Header/Header"
import ApiClient from "../../methods/api/apiClient"
const Sales=()=>{
    const [loading, setLoading] = useState(false);
    const [data,setdata] = useState([])
    const history=useHistory()
        
    // For edit the data
    const salesedit=(data)=>{
        // alert("Edit the data")
        history.push(`/addeditsales/${data}`)
    }
    //For view data
    const viewdata=(id)=>{
        history.push(`/viewsales/${id}`)
    }
    //For status change
    const change=(id,e)=>{
        let method="put"
        let payload={
            "id":id,
            "status": e.target.value,
        }
        addsalesdata(`lead?id=${id}`,payload,method).then(res=>{
            if(res.success){
                getdata()
                setTimeout(() => {
                    notification.success({
                        message: 'Status Changed Successfully',
                    });
                }, 100);
            }
        })
    }

    const columns = [
        {
            title:"Title",
            dataIndex:"title",
            field:"title",
            render: data => (
                <>
                    {data&&data?.title}
                </>
            ),
        },
        // {
        //     title:"Date",
        //     dataIndex:"date",
        //     field:"date",
        //     render: data => (
        //         <>
        //             {date(data&&data?.createdAt)},{time(data&&data?.createdAt)}
        //         </>
        //     ),
        // },
        // {
        //     title:"UpWork URL",
        //     dataIndex:"upwork-url",
        //     field:"upwork-url",
        //     render: data => (
        //         <>
        //             {data&&data?.upworkurl}
        //         </>
        //     ),
        // },
        {
            title:"Country",
            dataIndex:"country",
            field:"country",
            render: data => (
                <>
                    {data&&data?.country}
                </>
            ),
        },
        {
            title:"Status",
            dataIndex:"status",
            field:"Status",
            render: data => (
                <>
                    <select class="form-select" aria-label="Default select example" value={data.status} onChange={e=>change(data&&data?.id,e)}>
                        <option value="Success">Success</option>
                        <option value="Failed">Failed</option>
                    </select>
                </>
            ),
        },
        {
            title:"Actions",
            dataIndex:"action",
            field:"action",
            render: data => (
                <>
                    <Tooltip title="Edit">
                        <button className="p-2 m-1 bg-info rounded" onClick={e=>salesedit(data&&data?.id)}><i class="fa-solid fa-pen"></i></button>
                    </Tooltip>
                    <Tooltip title="Delete">
                    <Popconfirm
    title="Are you sure you want to Delete?"
    onConfirm={() =>removesales(data&&data?.id)}
    onCancel={()=>console.log("Cancled")}
    okText="Yes"
    cancelText="No"
>
                        <button className="p-2 m-1  rounded deletebtn" ><i class="fa-solid fa-trash"></i></button>
                        </Popconfirm>
                    </Tooltip>
                    <Tooltip title="View">
                        <button className="p-1 m-1 bg-info rounded" onClick={e=>viewdata(data&&data?.id)}><i class="fa-solid fa-eye"></i></button>
                    </Tooltip>
                </>
            ),
        },
    ];

    // const newlyAddedChapter =
    //     array && array.length
    //         ? array.map((items) => {
    //             console.log(items.id, 'asdas');
    //             localStorage.setItem('projectId', items.id);
    //             return {
    //                 ...items,
    //             };
    //         })
    //     : [];
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    //Sales data get api
    const getdata=()=>{
        setLoading(true)
        ApiClient.get(`leads?count=1000`).then(res=>{
            setLoading(false)
            if(res.success){
                setdata(res?.data?.data)
            }
        })
    }
    useEffect(()=>{
        getdata()
    },[])

    //Sales delete
    const removesales=(data)=>{ 
            deletesales(`lead?id=${data}`).then(res=>{
                getdata()
                setTimeout(() => {
                    notification.success({
                        message: 'Deleted Successfully',
                    });
                }, 100);
            })
    }
    return<>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">

                <section className="section">

                    <SectionHeader title="Sales" />

                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',

                    }} >
                    <Link to="/addeditsales">
                        <Tooltip title="Add Sales">
                        <Button type="primary"
                            shape="circle"
                            icon={<PlusSquareOutlined />}
                            size={'large'}
                            className="btn btn-primary profile" />
                        </Tooltip>
                    </Link>
                    </div>
                    <Spin spinning={loading}>
                        <MaterialTable
                            options={{
                                pageSize:10,
                                pageSizeOptions:[10,20,30,40,50], 
                                 emptyRowsWhenPaging: false,
                                 showTitle:false,
                                 draggable:false
                           }}   
                            columns={columns}
                            data={data}
                            icons={tableIcons}
                        />
                    </Spin>

                </section>
            </div>
    </>
}
export default Sales