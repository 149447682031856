import { LOGIN_USER, LOGOUT_USER } from "../../../Constant/index"

const initialState = {
    login: "",
    logout : ""
}

const reducerFunctions = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case LOGIN_USER: {
            return {
                ...state, login: payload
            }
        }
        case LOGOUT_USER: {
            return {
                ...state, logout: payload
            }
        }
        default: {
            return state
        }

    }
}

export default reducerFunctions