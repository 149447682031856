import {ArrowLeftOutlined} from '@ant-design/icons';
import { Button, notification, Spin, Tooltip } from "antd"
import { useState,useEffect } from "react"
import { useHistory,useParams} from "react-router-dom"
import Header from "../../Constant/Header/Header"
import MainSideBar from "../../Constant/MianSideBar/MainSideBar"
import SectionHeader from "../../Constant/SectionHeader/SectionHeader"
import ApiClient from "../../methods/api/apiClient"


const AddPortfolio = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState()
    const [domain, setDomain] = useState()
    const [platform, setPlatform] = useState()
    const [details, setDetails] = useState()
    const [technology, setTechnology] = useState()
    const [startdate, setStartdate] = useState()
    const [enddate, setEnddate] = useState()
    const {id}=useParams()

    const history = useHistory()

    const backbutton = () => {
        history.goBack();
    }

    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() - 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    const salesadded = (e) => {
        e.preventDefault();
        let payload = {
                    "name": name,
                    "domain": domain,
                    "platform": platform,
                    "description": details,
                    "technology": technology,
                    "start_date": startdate,
                    "end_date": enddate,
        }
        setLoading(true)
        if (!id) {
            ApiClient.post(`portfolio`, payload,).then(res => {
                setLoading(false)
                if (res.success) {
                    history.push('/portfolio')
                    setTimeout(() => {
                        notification.success({
                            message: 'Project Added Successfully',
                        });
                    }, 100);
                }
                
            })
        }
        else {
            
            let payload = {
                "id":id,
                "name": name,
                    "domain": domain,
                    "platform": platform,
                    "description": details,
                    "technology": technology,
                    "start_date": startdate,
                    "end_date": enddate,
            }
            ApiClient.put(`portfolio`, payload,).then(res => {
                setLoading(false)
                if (res.success) {
                    history.push('/portfolio')
                    setTimeout(() => {
                        notification.success({
                            message: 'Project Updated Successfully',
                        });
                    }, 100);
                }
            })
        }
    }

    const update=()=>{
        setLoading(true);
        ApiClient.get(`portfolio?id=${id}`).then(res=>{
            setLoading(false)
            if(res.success){
                setName(res?.data?.name)
                setDomain(res?.data?.domain)
                setPlatform(res?.data?.platform)
                setDetails(res?.data?.description)
                setTechnology(res?.data?.technology)
                setStartdate(res?.data?.start_date)
                setEnddate(res?.data?.end_date)
            }
            setLoading(false);
        })
    }
    useEffect(()=>{
        if(id){
            update()
        }
    },[])
    return <>
        <div className="main-wrapper" id="mainPannel">
            <div className="navbar-bg" />
            <Header />
            <MainSideBar />
        </div>
        <div className="main-content">
            <section className="section">
                <SectionHeader title= {id ? 'Edit Project' : 'Add Project'}/>
                <div>
                    <Tooltip title="Back Button">
                        <Button type="primary"
                        onClick={e=>backbutton()}
                        shape="circle"
                        icon={<ArrowLeftOutlined />}
                        size={'large'}
                        className='btn btn-primary profile'
                        />
                    </Tooltip>
                </div>
                <div className='container'>
                    <Spin spinning={loading}>
                    <form onSubmit={salesadded}>
                        <div>
                            <div className="mb-3">
                                <input type="text" value={name} onChange={e => setName(e.target.value)} className="form-control w-75 pl-2" id="exampleFormControlInput1" placeholder="Name" required />
                            </div>
                            <div className="mb-3">
                                <input type="text" value={domain} onChange={e => setDomain(e.target.value)} className="form-control w-75 pl-2" id="exampleFormControlInput1" placeholder="Domain" required />
                            </div>
                            <div className="mb-3">
                                <input type="text" value={platform} onChange={e => setPlatform(e.target.value)} className="form-control w-75 pl-2" id="exampleFormControlInput1" placeholder="Platform" required />
                            </div>
                            <div class="form-floating">
                                <textarea value={details} onChange={e => setDetails(e.target.value)} className="form-control mb-3 w-75 pl-2" placeholder="Enter the details here" id="floatingTextarea"></textarea>
                            </div>
                            <input type="text" min={disablePastDate()} value={startdate} onChange={e => setStartdate(e.target.value)} onFocus={(e) => e.target.type = 'date'} onBlur={(e) => e.target.type = "text"} className="mt-3 w-75" required placeholder="Start Date"></input>
                            <input type="text" min={startdate} value={enddate} onChange={e => setEnddate(e.target.value)} onFocus={(e) => e.target.type = 'date'} onBlur={(e) => e.target.type = "text"} className="mt-3 w-75" required placeholder="End Date"></input>
                            <div className="mb-3">
                                <select value={technology} onChange={e => setTechnology(e.target.value)} className="form-select form-select-lg w-25 mr-1 mt-3" aria-label=".form-select-lg example" required>
                                    <option selected>Select the Technology</option>
                                    <option value="react">React</option>
                                    <option value="react_native">React Native</option>
                                    <option value="worldpress">Worldpress</option>
                                    <option value="angular">Angular</option>
                                </select>
                            </div>
                            <button type="submit" className="btn btn-primary rounded">Submit</button>
                        </div>
                    </form>
                    </Spin>
                </div>
            </section>
        </div>
    </>
}
export default AddPortfolio