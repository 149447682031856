import React, { useEffect, useState, forwardRef } from "react";
import Header from "../../Constant/Header/Header";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import {
  Table,
  Spin,
  Select,
  message,
  Button,
  notification,
  Tooltip,
} from "antd";
import { API_URL } from "../../Constant";
import { useHistory, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ApiClient from "../../methods/api/apiClient";
import moment from "moment";

export default function ViewTask() {
  const { id } = useParams();
  const [mydata, setmydata] = useState([]);
  const data = () => {
    setloading(true);
    ApiClient.get(`task?id=${id}`)
      .then((res) => {
        console.log(res.data);
        setmydata(res.data);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        notification.error({ message: "Some error Occurred" });
      });
  };

  useEffect(() => {
    data();
  }, []);
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const backbutton = () => {
    history.goBack();
  };
  return (
    <div>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <Spin spinning={loading}>
          <section className="section">
            <SectionHeader title="Task Details" />
            <Tooltip title="Back Button">
              <Button
                type="primary"
                onClick={(e) => backbutton()}
                shape="circle"
                icon={<ArrowLeftOutlined />}
                size={"large"}
                className="btn btn-primary profile"
              />
            </Tooltip>
            <Spin spinning={loading}>
              <div className="shadow mt-5 p-3">
                <div className="row">
                  <div className=" col-md-12 d-inline">
                    <label className="text-bold">Task Name:</label>
                    <span>{mydata?.taskName}</span>
                  </div>
                  <div className=" col-md-4">
                    <label className="text-bold">Assign Date:</label>
                    <span>
                      {moment(mydata?.assignDateAndTime).format("DD MMM YYYY")}
                    </span>
                  </div>
                  <div className=" col-md-4">
                    <label className="text-bold">Expected Time:</label>
                    <span>{`${mydata?.expectedhours} ${mydata?.expectedminutes}`}</span>
                  </div>
                  <div className=" col-md-4">
                    <label className="text-bold">Status:</label>
                    <span
                      className={`text-${
                        mydata?.status == "inProgress"
                          ? "warning"
                          : mydata?.status == "done"
                          ? "success"
                          : "danger"
                      } text-capitalize`}
                    >
                      {mydata?.status}
                    </span>
                  </div>
                  {mydata?.status == "inProgress" ? null : mydata?.status ==
                    "done" ? (
                    <div className=" col-md-4">
                      <label className="text-bold">Actual Time:</label>
                      <span>{`${mydata?.Actual_hours} ${mydata?.Actual_minutes}`}</span>
                    </div>
                  ) : (
                    <>
                      <div className=" col-md-12">
                        <label className="text-bold">Reason:</label>
                        <span>{mydata?.reason}</span>
                      </div>
                    </>
                  )}
                  <div className=" col-md-4">
                    <label className="text-bold">Created At:</label>
                    <span>
                      {moment(mydata?.createdAt).format("DD MMM YYYY")}
                    </span>
                  </div>
                  <div className="col-md-12">
                    <label className="text-bold">Stories:</label>
                    <span>{mydata?.description}</span>
                  </div>
                </div>
              </div>
            </Spin>
          </section>
        </Spin>
      </div>
    </div>
  );
}
