import axios from 'axios';
import { API_URL } from '../../Constant/index';

// const token = localStorage.getItem('dataToken');
// console.log(token,"Token")
const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json',
    "Authorization": `Bearer ${localStorage.getItem('dataToken')}`,
  },
});

export const userDetail = (id) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}userDetail?id=${id}`, {
    headers: {
      'Content-type': 'application/json',
      "Authorization": `Bearer ${token}`,
    },
  });
};

export const dashbordData = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}analatics?type=yearly`, {
    headers: {
      'Content-type': 'application/json',
      "Authorization": `Bearer ${token}`,
    },
  });
};

export const userdelete = (url, param) => {
  return new Promise((resolve, reject) => {
    instance.put(url, param).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const laptopdelete = (url, param) => {
  return new Promise((resolve, reject) => {
    instance.delete(url, param).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const Interviewdelete = (url, param) => {
  return new Promise((resolve, reject) => {
    instance.delete(url, param).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const tasklist = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const totalemployeecount = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const totalassignedtaskcount = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const totalcompletetaskcount = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const totalnewemployeecount = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const loginactivities = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const loginusers = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const totalproject = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const successproject = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const runningproject = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const failedproject = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const linechart = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const salesdata = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const editsales = (url, payload) => {
  return new Promise((resolve, reject) => {
    instance.put(url, payload).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const deletesales = (url) => {
  return new Promise((resolve, reject) => {
    instance.delete(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const addsalesdata = (url, param, method, id) => {
  if (method == 'put') {
    return new Promise((resolve, reject) => {
      instance.put(url, param).then(res => {
        return resolve(res.data)
      }).catch(err => {
        return reject(err)
      })
    })
  } else if (method == 'post') {
    return new Promise((resolve, reject) => {
      instance.post(url, param).then(res => {
        return resolve(res.data)
      }).catch(err => {
        return reject(err)
      })
    })
  }
};

export const singlesalesdata = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};


export const deletetask = (url, param) => {
  return new Promise((resolve, reject) => {
    instance.delete(url, param).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
};

export const gettaskdetail = (url, payload, param) => {
  return new Promise((resolve, reject) => {
    instance.get(url, param, payload).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
}

export const updatatask = (url, param, method, id) => {
  if (method == 'put') {
    return new Promise((resolve, reject) => {
      instance.put(url, param).then(res => {
        return resolve(res.data)
      }).catch(err => {
        return reject(err)
      })
    })
  } else if (method == 'post') {
    return new Promise((resolve, reject) => {
      instance.post(url, param).then(res => {
        return resolve(res.data)
      }).catch(err => {
        return reject(err)
      })
    })
  }
};

export const getallPortfolio = (url) => {
  return new Promise((resolve, reject) => {
    instance.get(url).then(res => {
      return resolve(res.data)
    }).catch(err => {
      return reject(err)
    })
  })
}

//HrWallet
export const addAdvance = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.post(`${API_URL}add/moneyto/Hrwallet`, data, {
    headers: {
      'Content-Type': "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};



export const addProject = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.post(`${API_URL}project/add`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addInterview = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.post(`${API_URL}interview`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addreview = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.post(`${API_URL}project-review`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addappraisal = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.post(`${API_URL}add/appraisal`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addPlatform = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.post(`${API_URL}platform/add`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addRoles = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.post(`${API_URL}role/add`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPlatforms = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}platforms`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCategories = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}allcategories`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUsers = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}users?sortBy=asc&count=1000&isDeleted=false`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getLaptopdata = () => {
  return new Promise((resolve, reject) => {
    instance.get(`users?sortBy=asc&count=1&isDeleted=false`).then(res => {
      return resolve(res)
    })
      .catch(err => {
        return reject(err)
      })
  })
};

export const getUsersdata = () => {
  return new Promise((resolve, reject) => {
    instance.get(`users?sortBy=asc&count=1000&isDeleted=false`).then(res => {
      return resolve(res)
    })
      .catch(err => {
        return reject(err)
      })
  })
};

export const getUsersdetails = (id) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}userDetail?id=${id}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProjectdetails = (id) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}project?id=${id}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getWeeklyplanbyprojectID = (id) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}getWeeklyplanbyprojectID?id=${id}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getWeeklyplanjson = (id) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}getWeeklyplanjson?id=${id}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};



export const getsingleleave = (id) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}getsingleleave?id=${id}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getsingleresign = (id) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}getsingleresign?id=${id}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPostDetails = (id) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}post?id=${id}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};


export const getProjects = (status) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}projects?projectstatus=${status ? status : ''}&sortBy=asc&count=10000`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getEmployee = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}users?role=employee&count=100`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const alappraisal = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}getallappraisal?count=100000`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};




export const userassignlist = (id) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}project?id=${id}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};


export const projectAddTeam = (id, memberId) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}project/add/member?id=${id}&memberId=${memberId}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};


export const getRoles = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}getAllRoles?sortBy=asc&count=10000`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getKownledge = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}posts?sortBy=asc&count=10000`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllAttandance = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}allleaves?count=10000`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};


export const xlsingledata = (id) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}getsingleWeeklyplan?id=${id}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const mailinglist = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}user/mailingList`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllResign = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}allresign?sortBy=asc&count=10000`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteProject = (id) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}project/delete?id=${id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}
export const deletePlatform = (id) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}platform/delete?id=${id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}
export const getAttendace = (today) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}checkoutattendances?start_date=${today}&end_date=${today}&count=10000 `, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export const hrAttendence = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}getallhrexpenses`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export const datadates = (startDate, endDate) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}checkoutattendances?start_date=${startDate}&end_date=${endDate}&count=100000`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export const hrdateshow = (start_date, endDate) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}getallhrexpenses?start_date=${start_date}&end_date=${endDate}&count=10000`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export const dailytasks = (start_date, endDate) => {
  const token = localStorage.getItem('dataToken');
  if (endDate == undefined) { endDate = start_date }
  return axios.get(`${API_URL}all/task?start_date=${start_date}&end_date=${endDate}&count=10000`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export const Laptoplistfilter = (start_date, endDate) => {
  const token = localStorage.getItem('dataToken');
  if (endDate == undefined) { endDate = start_date }
  return axios.get(`${API_URL}all/assigned/devices?search&page=1&count=50000&start=${start_date}&end=${endDate}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}

export const Interviewlistfilter = (start_date, endDate) => {
  const token = localStorage.getItem('dataToken');
  if (endDate == undefined) { endDate = start_date }
  return axios.get(`${API_URL}/interview/list?page=1&count=100&search`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}
export const attendancce = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.post(`${API_URL}attendance/checkin&count=1000`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,

    },
  })
}

export const Weeklyplan = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.post(`${API_URL}add/Weeklyplan`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,

    },
  })
}



export const getAttendaces = (today) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}checkinattendances?start_date=${today}&end_date=${today}&count=5000`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}
export const getCheckInAttendances = (startdate, enddate) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}checkinattendances?start_date=${startdate}&end_date=${enddate}&count=5000`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}



export const deleteRoles = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}delete`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}


// export const deleteKnowledge = (id, data) => {
//   return axios.put(`${API_URL}post/delete?id=${id}`,data, {
//     headers: {
//       'Content-type': 'application/json',
//       Authorization: `Bearer ${token}`,
//     },
//   });
// }


export const deleteKnowledge = (id, data) => {
  const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}post/delete?id=${id}`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}
export const updateStatusUser = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}update/status?id=${data.id}&status=${data.status}`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}
export const updateStatusUser1 = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}edit/assigned/device?id=${data.id}&status=${data.status}`, data, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export const updateProject = (id, data) => {
  const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}project/update/?id=${id}`, data, {
    headers: {
      "Authorization": `Bearer ${token}`,

    }
  })
}

export const updateUser = (id, data) => {
  const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}editProfile?id=${id}`, data, {
    headers: {
      "Authorization": `Bearer ${token}`,
      // "Access-Control-Request-Method": "PATCH",
      // "Access-Control-Allow-Origin": "http://localhost:3000"
    }
  })
}
export const UpdateStatus = (id, data) => {
  const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}editProfile?id=${id}`, data, {
    headers: {
      "Authorization": `Bearer ${token}`,
      // "Access-Control-Request-Method": "PATCH",
      // "Access-Control-Allow-Origin": "http://localhost:3000"
    }
  })
}



export const updateAttandec = (id, leaveStatus, fromemail, toemail, Email) => {
  return axios.put(`${API_URL}leaves/status/change?id=${id}&leaveStatus=${leaveStatus}&fromemail=${Email}&toemployeeemail=${fromemail}&tomanagementemail=${toemail}`,
  )
}

export const updatexlstatus = (id, data) => {
  const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}Weeklyplan?id=${id}`, data, {
    headers: {
      "Authorization": `Bearer ${token}`,

    }
  })

}

export const updateResign = (id, resignStatus, fromemail, toemail, Email) => {
  return axios.put(`${API_URL}resign/status/change?id=${id}&resignStatus=${resignStatus}&fromemail=${Email}&toemployeeemail=${fromemail}&tomanagementemail=${toemail}`,

  )
}




export const updatePlatform = (id, data) => {
  const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}platform/update/?id=${id}`, data, {
    headers: {
      "Authorization": `Bearer ${token}`,
      // "Access-Control-Request-Method": "PATCH",
      // "Access-Control-Allow-Origin": "http://localhost:3000"
    }
  })
}

export const updateRoles = (id, data) => {
  const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}role/?id=${id}`, data, {
    headers: {
      "Authorization": `Bearer ${token}`,
      // "Access-Control-Request-Method": "PATCH",
      // "Access-Control-Allow-Origin": "http://localhost:3000"
    }
  })
}

export const updateKnowledge = (id, data) => {
  const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}post/update?id=${id}`, data, {
    headers: {
      "Authorization": `Bearer ${token}`,
      // "Access-Control-Request-Method": "PATCH",
      // "Access-Control-Allow-Origin": "http://localhost:3000"
    }
  })
}

export const updateProfile = (id, data) => {
  const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}editProfile?id=${id}`, data, {
    headers: { "Authorization": `Bearer ${token}` }
  })
}

export const updateStatus = (id, status, data) => {
  const newdata = { projectStatus: status }
  const token = localStorage.getItem('dataToken');
  return axios.put(`${API_URL}project/update/?id=${id}`, newdata, {
    headers: { "Authorization": `Bearer ${token}` }
  })
}

export const documentsdownload = (id) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}getprojectdocuments?id=${id}`, {
    headers: { "Authorization": `Bearer ${token}` }
  })
}

export const alldocumentsdownload = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}getprojectdocuments`, data, {
    headers: { "Authorization": `Bearer ${token}` }
  })
}

export const uploadProfile = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.post(`${API_URL}user/uploadProfileImage`, data, {
    headers: { "Authorization": `Bearer ${token}` }
  })
}

export const projectstatuscount = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}getadminprojectstatuscount`, {
    headers: { "Authorization": `Bearer ${token}` }
  })
}

export const graphtotalcount = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}upcomingappraisals`, {
    headers: { "Authorization": `Bearer ${token}` }
  })
}

export const adminleavestatus = () => {
  const token = localStorage.getItem('dataToken');
  return axios.get(`${API_URL}getadminleavesstatuscount`, {
    headers: { "Authorization": `Bearer ${token}` }
  })
}

export const addHrExpensive = (data) => {
  const token = localStorage.getItem('dataToken');
  return axios.post(`${API_URL}add/hrexpenses`, data, {
    headers: { "Authorization": `Bearer ${token}` }
  })
}

