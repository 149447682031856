import React, { useEffect, useState } from "react";
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import * as yup from 'yup'
import {
    Form,
    Button,
    Row,
    Input,
    Tooltip,
    Spin,
} from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { UploadOutlined } from '@ant-design/icons';
import { ArrowLeftOutlined,EditOutlined } from '@ant-design/icons';
import { message, notification } from 'antd';
import { addHrExpensive } from "../../Api/user/userapi";
import { API_URL } from "../../Constant";
import { useHistory, useParams } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import { Formik } from "formik";

const Hrexpensive = (props) => {
    const [MakeDisable,setMakedisable]=useState(false);
    const Validation = yup.object({
        Description: yup.string("Please Enter the Description").min(3, "Please Describe it more").max(440, "This is too big Description").required("Description is required"),
        startDate: yup.date("Please Enter the Start Date").required("Start Date is required"),
        Amount: yup.string().required("Please enter the amount")
    })
    const { id } = useParams();
    const [hrexpensedata, sethrexpensedata] = useState({});
    const [form] = Form.useForm();
    const [singleFile, setSingleFile] = useState("");
    const [imgUrl, setImagesUrl] = useState('');
    const [images, setImages] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory()
    const [data, setdata] = useState([]);

    // for getting data by id
    const  getdatabyid=()=>{
        setLoading(true);
        ApiClient.get(`hrexpenses/${id}`).then(res=>{
            setdata(res)
            setLoading(false);
            console.log(res.description)
            setImages(res.file)
        }).catch(err=>{
            setLoading(false);
            notification.error({
                message: "Some Error Occurred"
            })
        })
    }
    useEffect(() => {
        if (id) {
            getdatabyid();
        }
    },[]);

    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() - 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    //     const handleSubmit = async (e) => {

    //         const payload = {
    //             description: e.description,
    //             date: e.startDate,
    //             amount: e.Amount,
    //             file: images
    //         }
    //         try {
    //             // setLoading(true);
    //         if(id)
    //         {
    //             const newpayload={...payload,"id":id}; 
    //             console.log(newpayload)
    //             // ApiClient.put("hrexpenses",newpayload).then(res=>{
    //             //     notification.success({
    //             //         message:"Updated Successfully"
    //             //     })
    //             // }).catch(err=>{
    //             //     notification.error({
    //             //         message:"Some error Occurred"
    //             //     })
    //             // }) 
    // //   props.history.push('/hrexpensivetable')
    //         }
    //         else{


    //             const userResp = await addHrExpensive(payload);
    //              props.history.push('/hrexpensivetable')
    //             setTimeout(() => {
    //                 notification.success({
    //                     message: 'Hr Expense Added Successfully',
    //                 });
    //             }, 1000);
    //         }
    //             // message.success({
    //             //   content: 'Project Add successfully',
    //             //   style: {
    //             //     marginTop: '20vh',
    //             //   },
    //             // });
    //         } catch (e) {
    //             message.error('Project already exist.')
    //         } finally {
    //             //   setLoading(false);
    //         }
    //     };

    const handleInputChange = (event) => {
        let file = event.target.files[0];
        setSingleFile(file)
    }
    const submit = async (e) => {
        setLoading(true);
        let file = await e.target.files[0];
        setSingleFile(file)
        const token = localStorage.getItem('dataToken');
        const fileToUpload = file;
        const data = new FormData();
        data.append('modelName', 'users');
        data.append('file', fileToUpload);
        // Please change file upload URL
        let res = await fetch(
            `${API_URL}project/uploadDocument`,
            {
                method: 'post',
                body: data,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        );
        let responseJson = await res.json();
        if (responseJson.success === true) {
            setLoading(true);
            setImagesUrl(responseJson.data[0].imagePath)
            notification.success({
                message: 'Uploaded Successfully.'
            })
            setImages(API_URL + responseJson.data[0].imagePath);
        }
        else {
            notification.error({
                message: "Some Error while Uploading the Image. Please Try After Some Time"
            })
        }
        setMakedisable(false)
        setLoading(false);
    }
    const backbutton = () => {
        history.goBack()
    }
    const disableFutureDate = () => {
        const today = new Date();
        const dd = String(today.getDate() - 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
    return (
        <>
            <div>
                <div className="main-wrapper" id="mainPannel">
                    <div className="navbar-bg" />
                    <Header />
                    <MainSideBar />
                </div>
                <div className="main-content">
                    <section className="section">
                        <SectionHeader title={`${!id ? "Add HR Expense" : "Edit HR Expense"} `} />
                        <Tooltip title="Back Button">
                            <Button type="primary"
                                onClick={e => backbutton()}
                                shape="circle"
                                icon={<ArrowLeftOutlined />}
                                size={'large'}
                                className='btn btn-primary profile'
                            />
                        </Tooltip>
                    <Spin spinning={loading}>
                        <div >
                            <div className="container_div">
                                <div >
                                    <h3 style={{ marginLeft: "-16%" }} className="form"></h3>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            startDate: !id ? "" : data?.date,
                                            Amount: !id ? "" : data.amount,
                                            Description: !id ? "" : data.description
                                            // Description:!id?"":data.description
                                        }}
                                        validationSchema={Validation}
                                        onSubmit={async (values, { resetForm }) => {
                                            const payload = {
                                                description: values.Description,
                                                date: values.startDate,
                                                amount: values.Amount,
                                                file: images
                                            }
                                            try {
                                                if (id) {
                                                    const newpayload = { ...payload, "id": id };
                                                    ApiClient.put("hrexpenses", newpayload).then(res => {
                                                        history.push("/hrexpensivetable");
                                                        notification.success({
                                                            message: "HR Expense Updated Successfully!"
                                                        })
                                                    }).catch(err => {
                                                        notification.error({
                                                            message: "Some Error Occurred"
                                                        })
                                                    })
                                                }
                                                else {
                                                    const userResp = await addHrExpensive(payload);
                                                    props.history.push('/hrexpensivetable')
                                                    setTimeout(() => {
                                                        notification.success({
                                                            message: 'Hr Expense Added Successfully',
                                                        });
                                                    }, 1000);
                                                }
                                            } catch (e) {
                                                message.error('Some Error Occurred.')
                                            }
                                        }}
                                    >
                                        {({ handleBlur, handleChange, handleSubmit, values, errors, touched }) => (
                                            <form onSubmit={e=>{handleSubmit(e);setMakedisable(true)}}>
                                                <div className="col-12 mb-2 ">
                                                    <label htmlFor="startDate" className="form-label">Start Date-:</label>
                                                    <input className="form-control pl-2" max={disableFutureDate()} type="date" name="startDate" onChange={handleChange} onBlur={handleBlur} value={values.startDate} />
                                                    {errors.startDate && touched.startDate ? <p className="text-danger">{errors.startDate}</p> : null}
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <label htmlFor="Amount" className="form-label">Amount-:</label>
                                                    <input className="form-control pl-2" name="Amount" placeholder="₹ 100" type="number" value={values.Amount} onBlur={handleBlur} onChange={handleChange} />
                                                    {errors.Amount && touched.Amount ? <p className="text-danger">{errors.Amount}</p> : null}
                                                </div>
                                                <div className="col-md-12 mb-2" >
                                                    <label htmlFor="Description" className="form-label">Description-:</label>
                                                    <textarea name="Description" className="form-control pl-2" cols="100" rows="5" value={values.Description} onBlur={handleBlur} onChange={handleChange}></textarea>
                                                    {errors.Description && touched.Description ? <p className="text-danger">{errors.Description}</p> : null}
                                                </div>
                                                <div className="col-md-12 my-3">
                                                    <label className="form-label">Select Reciept :</label>
                                                    <Input accept="image/*" multiple="multiple" disabled={loading} className="col-md-6" type="file" onChange={(e) => submit(e)} icon={<UploadOutlined />} />
                                                    {id && images != "" ? !loading ? <img className="col-md-2" src={`${!images == "" ? images : hrexpensedata?.data}`} width={100} height={100} /> : "Uploading...." : ""}
                                                </div>
                                                <div className="col-md-12 text-right mt-3">
                                                    <Button type="primary" disabled={MakeDisable} htmlType="submit">
                                                        {!id?"Submit":"Update"}
                                                    </Button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        </Spin>
                    </section>
                </div>
            </div>
        </>
    );
};

export default Hrexpensive;