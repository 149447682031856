import { Formik } from 'formik/dist'
import React, { useEffect, useState, forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Spin } from 'antd/lib';
import { Form, Input, Button, Tooltip, notification } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../Constant';
import ApiClient from '../../methods/api/apiClient';
// import { log } from 'console';

export default function AddSystem() {
  // Getting id from the url by using useParams
  const { id } = useParams();
  const [system,setsystem]=useState("");

  // For Validation in Form we use Yup 
  // const Validateform = Yup.object({
  //   operating_system: Yup.string("Please Select the Operating System").required("Operating System is required"),
  //   // serialnumber
  //   os_type: Yup.string("Please Enter Os Type").required("OS Type is required"),
  //   os_version: Yup.string("Please Enter the OS Version").required("OS Version is rquired"),
  //   ram: Yup.string("Please enter a valid Ram").required("Ram is required"),
  //   processor: Yup.string("Please enter the Processor Name").required("Processor Name is required"),
  //   cpu_code: Yup.string("Please enter the CPU Code").required("CPU Code is required"),
  //   cpu_serial_number: Yup.string("Please enter CPU Serial number").required("CPU Serial Number is required"),
  //   screen_code: Yup.string("Please enter the Screen Code").required("Screen Code is required"),
  //   screen_serial_number: Yup.string("Please enter the Screen Serial Number").required("Screen Serial Number is required"),
  //   drives: Yup.string("Please select a Drive").required("Drive is required"),
  //   drives_size: Yup.string("Please enter a valid size").required("Drive storage is required"),
  //   user_name: Yup.string("Please enter the UserName").required("User Name is required"),
  //   system_password: Yup.string("Please enter the password").required("Password is required"),
  //   seat_number: Yup.number("Please enter a valid Seat Number").required("Seat Number is Required"),
  //   keyboard_code: Yup.string("Please enter the Keyboard Code").required("Keyboard Code is required"),
  //   keyboard_serial_number: Yup.string("Please enter the Keyboard Serial Number").required("Keyboard Serial Number is  required"),
  //   mouse_code: Yup.string("Please enter the Mouse Code").required("Mouse Code is required"),
  //   mouse_serial_number: Yup.string("Please enter the Mouse Serial Number").required("Mouse Serial Number is Required"),
  //   smps: Yup.string("Please enter the SMPS").required("SMPS is required"),
  //   // battery: Yup.string("Please enter the battery details").required("Battery details is required")
  // });

  const navigate = useHistory();
  const [loading, setloading] = useState(false);
  const [editdata, seteditdata] = useState([]);
  const renderSubmitBack = () => {
    navigate.goBack();
  }

  const handlesystem=(e)=>{
    setsystem(e.target.value);
  }
  const [Assignable,setAssignable]=useState(null)

  const handleradio=(e)=>{ 
    setAssignable(e.target.value)
  
  }

  // get data by id for Editing the details
  const getdata = () => {
    setloading(true);
    ApiClient.get(`system?id=${id}`).then(res => {
      seteditdata(res.data)
      setAssignable(res.data.isAssignable);
      setsystem(res.data.system);
      setloading(false);
    }).catch(err => setloading(false))
  }
  useEffect(() => {
    if (id) {
      getdata();
    }
  }, [])

  
  return (
    <div >
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title={`${!id ? "Add" : "Edit"} System Information`} />
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '18px',
          }}>
            <Tooltip title="Back Button">
              <Button type="primary"
                onClick={renderSubmitBack}
                shape="circle"
                icon={<ArrowLeftOutlined />}
                size={'large'}
                className='btn btn-primary profile'
              />
            </Tooltip>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            // marginRight: '55px',
          }}>
          </div>
          <Spin spinning={loading}>
            {/* For More Details */}
            <Formik
              enableReinitialize
              // For Initial Values
              initialValues={{
                operating_system: id ? editdata?.operating_system : "",
                // serialnumber: "",
                os_type: id ? editdata?.os_type : "",
                os_version: id ? editdata?.os_version : "",
                ram: id ? editdata?.ram : "",
                processor: id ? editdata?.processor : "",
                cpu_code: id ? editdata?.cpu_code : "",
                cpu_serial_number: id ? editdata?.cpu_serial_number : "",
                ups_code: id ? editdata?.ups_code : "",
                ups_serial_number: id ? editdata?.ups_serial_number : "",
                screen_code: id ? editdata?.screen_code : "",
                screen_serial_number: id ? editdata?.screen_serial_number : "",
                drives: id ? editdata?.drives : "",
                drives_size: id ? editdata?.drives_size : "",
                user_name: id ? editdata?.user_name : "",
                system_password: id ? editdata?.system_password : "",
                seat_number: id ? editdata?.seat_number : "",
                keyboard_code: id ? editdata?.keyboard_code : "",
                keyboard_serial_number: id ? editdata?.keyboard_serial_number : "",
                mouse_code: id ? editdata?.mouse_code : "",
                mouse_serial_number: id ? editdata?.mouse_serial_number : "",
                smps: id ? editdata?.smps : "",
                battery: id ? editdata?.battery : "",
                laptopName:id?editdata?.laptopName:"",
                ip:id?editdata?.ip:"",
                description:id?editdata?.description:""
              }}
              // validationSchema={Validateform}

              // For Submitting the Form
              onSubmit={async (values) => {
                console.log(values.os_type)
                const myvalues = { ...values,system:system,isAssignable:Assignable};
                if (id) {
                  const mynewvalues = { ...values, id,system,isAssignable:Assignable }
                  ApiClient.put("system", mynewvalues).then(res => {
                    notification.success({ message: "System Updated Successfully." });
                    navigate.push("/system");
                  }).catch(err => notification.error({ message: "Some Error Occurred" }))
                }
                else {
                  delete myvalues.isAssignable;
                  axios.post(`${API_URL}system`, myvalues).then((res) => {
                    notification.success({ message: "System added Successfully." });
                    navigate.push("/system");
                  }).catch((err) => notification.error({ message: "Some error occurred.Please try again." }))
                }
              }}
            >
              {({ handleBlur, handleChange, values, handleReset, handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit} className='shadow p-3'>
                    <div className='form-group text-left'>
                    <label className='control-label'  >Select System</label>
                    <select  className='form-control' name='system' required onChange={(e)=>handlesystem(e)} value={system}  onBlur={handleBlur}>
                      <option value="">Select a System</option>
                      <option value="laptop">Laptop</option>
                      <option value="desktop">Desktop</option> 
                    </select>
                    </div>
                    {system!=""? <div className='mt-3'>
                      {system=="laptop"?
                        <div className='row'>
                          <div className='form-group col-md-6 text-left'>
                            <label className='control-label'>Laptop Name</label>
                            <input className='form-control pl-3' name='laptopName' placeholder='Laptop Name' onChange={handleChange} onBlur={handleBlur} value={values.laptopName} />
                          </div>
                          <div className='form-group col-md-6 text-left'>
                            <label className='control-label'>IP/Mac Address</label>
                            <input className='form-control pl-3 ' placeholder='IP Address'  name='ip' onChange={handleChange} onBlur={handleBlur} value={values.ip}/>
                          </div>
                        </div>:null
                      }
                  <div className="form-row">
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">Operating System </label>
                      <div className="">
                        <select
                          name='operating_system'
                          value={values.operating_system}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          className="form-control " style={{ paddingLeft: "10px" }}
                        >
                          <option value="" selected>Please Select your Operating System-</option>
                          <option value="windows">Windows</option>
                          <option value="linux">Linux</option>
                          <option value="mac">Mac</option>
                        </select>
                        {errors.operating_system && touched.operating_system ? <p className='text-danger pl-2'>{errors.operating_system}</p> : null}

                      </div>
                    </div>
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">Processor</label>
                      <div className="">
                        <Input
                          name='processor'
                          value={values.processor}
                          type="text"
                          placeholder='Processor Name '
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.processor && touched.processor ? <p className='text-danger pl-2'>{errors.processor}</p> : null}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                  <div className="form-group col-md-6 text-left">
                      <label className="control-label">OS Type </label>
                      <div className="">
                        <select
                          name='os_type'
                          value={values.os_type}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          className="form-control " style={{ paddingLeft: "10px" }}
                        >
                          <option value="" selected>Please Select your OS Type</option>
                          <option value="32-Bit">32-Bit</option>
                          <option value="64-Bit">64-Bit</option>
                          
                        </select>
                        {errors.os_type && touched.os_type ? <p className='text-danger pl-2'>{errors.os_type}</p> : null}
                      </div>
                    </div>
                    {/* <div className="form-group col-md-6 text-left">
                      <label className="control-label">OS Type </label>
                      <div className="">
                        <Input
                          name='os_type'
                          value={values.os_type}
                          placeholder='OS Type'
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.os_type && touched.os_type ? <p className='text-danger pl-2'>{errors.os_type}</p> : null}
                      </div>
                    </div> */}
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">OS Version</label>
                      <div className="">
                        <Input
                          name='os_version'
                          placeholder='OS Version'
                          value={values.os_version}
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          type="text"
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.os_version && touched.os_version ? <p className='text-danger pl-2'>{errors.os_version}</p> : null}

                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">RAM </label>
                      <div className="">
                        <Input
                          name='ram'
                          placeholder='RAM'
                          value={values.ram}
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          type="text"
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.ram && touched.ram ? <p className='text-danger pl-2'>{errors.ram}</p> : null}
                      </div>
                    </div>
                    <div className="form-group col-md-6 text-left">
                      <label className={`control-${system=="desktop"?"label":""}`}>Seat Number</label>
                      <div className="">
                        <Input
                          name='seat_number'
                          value={values.seat_number}
                          min={0}
                          type="number"
                          placeholder='Seat Number'
                          onChange={handleChange}
                          onBlur={handleBlur} required={system=="desktop"}
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.seat_number && touched.seat_number ? <p className='text-danger pl-2'>{errors.seat_number}</p> : null}
                      </div>
                    </div>
                  </div>

{system=="laptop"?null:
<>
                  <div className="form-row">
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">CPU Code </label>
                      <div className="">
                        <Input
                        placeholder='CPU Code'
                          name='cpu_code'
                          value={values.cpu_code}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.cpu_code && touched.cpu_code ? <p className='text-danger pl-2'>{errors.cpu_code}</p> : null}

                      </div>
                    </div>
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">CPU Serial No.</label>
                      <div className="">
                        <Input
                          name='cpu_serial_number'
                          placeholder='CPU Serial Number'
                          value={values.cpu_serial_number}
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          type="text"
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.cpu_serial_number && touched.cpu_serial_number ? <p className='text-danger pl-2'>{errors.cpu_serial_number}</p> : null}
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 text-left">
                      <label className="form-label">UPS Code </label>
                      <div className="">
                        <Input
                          name='ups_code'
                          placeholder='UPS Code'
                          value={values.ups_code}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur} 
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />

                      </div>
                    </div>
                    <div className="form-group col-md-6 text-left">
                      <label className="form-label">UPS Serial No.</label>
                      <div className="">
                        <Input
                          name='ups_serial_number'
                          placeholder='UPS Serial Number'
                          value={values.ups_serial_number}
                          onChange={handleChange}
                          onBlur={handleBlur} 
                          type="text"
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">Screen Code </label>
                      <div className="">
                        <Input
                          name='screen_code'
                          placeholder='Screen Code'
                          value={values.screen_code}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.screen_code && touched.screen_code ? <p className='text-danger pl-2'>{errors.screen_code}</p> : null}


                      </div>
                    </div>
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">Screen Serial No.</label>
                      <div className="">
                        <Input
                          name='screen_serial_number'
                          placeholder='Screen Serial Number'
                          value={values.screen_serial_number}
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          type="text"
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.screen_serial_number && touched.screen_serial_number ? <p className='text-danger pl-2'>{errors.screen_serial_number}</p> : null}
                      </div>
                    </div>
                  </div> </>}

                  <div className="form-row">
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">Drive</label>
                      <div className="">
                        <select
                          value={values.drives}
                          name='drives'
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          className="form-control " style={{ paddingLeft: "10px" }}
                        >
                          <option value="" selected>Please Select your Drive</option>
                          <option value="SSD">SSD</option>
                          <option value="HDD">Hard Disk</option>
                        </select>
                        {errors.drives && touched.drives ? <p className='text-danger pl-2'>{errors.drives}</p> : null}

                      </div>
                    </div>
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">Drive Storage</label>
                      <div className="">
                        <Input
                          name='drives_size'
                          placeholder='Drive Storage'
                          value={values.drives_size}
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          type="text"
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.drives_size && touched.drives_size ? <p className='text-danger pl-2'>{errors.drives_size}</p> : null}
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">User Name </label>
                      <div className="">
                        <Input
                          name='user_name'
                          placeholder='User Name'
                          value={values.user_name}
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          type="text"
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.user_name && touched.user_name ? <p className='text-danger pl-2'>{errors.user_name}</p> : null}

                      </div>
                    </div>
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">Password</label>
                      <div className="">
                        <Input
                          name='system_password'
                           placeholder='Password'
                          value={values.system_password}
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          type="password"
                          className="form-control" style={{ paddingLeft: "10px" }}
                        />
                        {errors.system_password && touched.system_password ? <p className='text-danger pl-2'>{errors.system_password}</p> : null}
                      </div>
                    </div>
                  </div>
                  {system=="laptop"?null:<>
                  <div className="form-row">
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">Keyboard Code</label>
                      <div className="">
                        <Input
                        placeholder='Keyboard Code'
                          name='keyboard_code'
                          value={values.keyboard_code}
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          type="text"
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.keyboard_code && touched.keyboard_code ? <p className='text-danger pl-2'>{errors.keyboard_code}</p> : null}
                      </div>
                    </div>
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">Keyboard Serial Number</label>
                      <div className="">
                        <Input
                        placeholder='Keyboard Serial Number'
                          name='keyboard_serial_number'
                          value={values.keyboard_serial_number}
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          type="text"
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.keyboard_serial_number && touched.keyboard_serial_number ? <p className='text-danger pl-2'>{errors.keyboard_serial_number}</p> : null}
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">Mouse Code</label>
                      <div className="">
                        <Input
                          name='mouse_code'
                          placeholder='Mouse Code'
                          value={values.mouse_code}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.mouse_code && touched.mouse_code ? <p className='text-danger pl-2'>{errors.mouse_code}</p> : null}

                      </div>
                    </div>
                    <div className="form-group col-md-6 text-left">
                      <label className="control-label">Mouse Serial No.</label>
                      <div className="">
                        <Input
                        placeholder='Mouse Serial Number'
                          value={values.mouse_serial_number}
                          name='mouse_serial_number'
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur} required
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.mouse_serial_number && touched.mouse_serial_number ? <p className='text-danger pl-2'>{errors.mouse_serial_number}</p> : null}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6 text-left">
                      <label className="">SMPS</label>
                      <div className="">
                        <Input
                        placeholder='SMPS'
                          name='smps'
                          value={values.smps}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur} rquired
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {errors.smps && touched.smps ? <p className='text-danger pl-2'>{errors.smps}</p> : null}

                      </div>
                    </div>
                    <div className="form-group col-md-6 text-left">
                      <label className="form-label">Battery</label>
                      <div className="">
                        <Input
                        placeholder='Battery'
                          value={values.battery}
                          name='battery'
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur} 
                          className="form-control " style={{ paddingLeft: "10px" }}
                        />
                        {/* {errors.battery && touched.battery ? <p className='text-danger pl-2'>{errors.battery}</p> : null} */}
                      </div>
                    </div>
                  </div>
                  </>}
                  {
                    system=="laptop"?
                    <div className="form-group col-md-6 text-left">
                    <label className='control-label' >Is Assignable</label>
                    <label className='mx-3'>
                    <input type='radio' checked={Assignable=="Y"} required className='mx-1' name='isassingable' onChange={(e)=>handleradio(e)} value="Y"/>Yes</label>
                    <input type='radio' required checked={Assignable=="N"}  name='isassingable'  className='mx-1' value="N" onChange={(e)=>handleradio(e)} />No
                  </div>:null
                  }
                  <div className="form-row">
                    <div className="form-group col-md-12 text-left">
                      <label className='form-label'>Description</label>
                      <div>
                        <textarea placeholder='Enter the Description' id='description' name='description' className='w-50 form-control p-2' value={values.description} onChange={handleChange} onBlur={handleBlur}></textarea>
                      </div>
                    </div>
                  </div>
                  <button type='submit' className="btn btn-warning  space-right2">Submit</button>
                  </div>
                  :null}
                </form>
              )}
            </Formik>
          </Spin>
        </section>
      </div>
    </div>
  )
}
