import React, { useEffect, useState, forwardRef } from "react";
import Header from "../../Constant/Header/Header";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import Calendar from "react-calendar";
import {
  Table,
  Spin,
  Select,
  message,
  Button,
  notification,
  Tooltip,
} from "antd";
import MaterialTable from "material-table";
import { Link, useHistory } from "react-router-dom";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { dailytasks } from "../../Api/user/userapi";
import axios from "axios";
import { API_URL } from "../../Constant";
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";

const Dailytask = (props) => {
  const [array1, setArray1] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentdat = new Date();
  const formatdate = moment(currentdat).format("YYYY-MM-DD");
  const [value, setonChange] = useState(new Date());
  const [Singletask, setSingletask] = useState();
  const [array, setArray] = useState([]);
  const [startdate1, setstartdate1] = useState(formatdate);
  const [enddate1, setenddate1] = useState(formatdate);
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const columns1 = [
    {
      title: "Employee Name",
      dataIndex: "addedBy.fullName",
      field: "addedBy.fullName",
      render: (text, record) => (
        <>
          <p className="text-capitalize">{text && text?.employee_name}</p>
        </>
      ),
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
      field: "project_name",
      render: (text, record) => (
        <>
          {text ? (
            <span className="dec_dot text-capitalize">{text.project_name}</span>
          ) : (
            "--"
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      field: "action",
      render: (text, record) => (
        <>
          <Button
            onClick={(e) =>
              DailyTaskList(text?._id.employee_id, text?._id.project_id)
            }
            className="btn btn-success"
          >
            Task List
          </Button>
        </>
      ),
    },
  ];

  const newlyAddedChapter1 = array1
    ? array1.map((items) => {
        return {
          ...items,
        };
      })
    : [];

  const navigate = useHistory();
  const DailyTaskList = async (employeeid, projectid) => {
    // const currentdate = moment(date).format("YYYY-MM-DD")
    // console.log(currentdate,"fgujdhgdf");
    navigate.push(
      `tasklist/${employeeid}/${projectid}/${startdate1}/${enddate1}`
    );
  };

  let today = new Date().toISOString().slice(0, 10);

  const onChangeDatesss = async (newDate) => {
    setonChange(newDate);
    try {
      setLoading(true);
      const resp = await dailytasks(today);
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        setArray1(arrayData);
        console.log(arrayData, "THis is the data");
      }
    } catch (res) {
      setArray1("");
    }
    setLoading(false);
  };

  const onChangeDat = async (newDate) => {
    setonChange(newDate);
    const startDate = convert(newDate[0]);
    setstartdate1(startDate);
    const endDate = convert(newDate[1]);
    setenddate1(endDate);
    console.log(startDate, "This is the startdate thats we need for sometimes");
    console.log(endDate, "This is the enddate thats we need for sometimes");
    try {
      setLoading(true);
      const resp = await dailytasks(startDate, endDate);
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        setArray1(arrayData);
      }
    } catch (res) {
      setArray1("");
    }
    setLoading(false);
  };

  useEffect(() => {
    onChangeDatesss();
  }, []);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <>
      <div>
        <div className="main-wrapper" id="mainPannel">
          <div className="navbar-bg" />
          <Header />
          <MainSideBar />
        </div>
        <div className="main-content">
          <Spin spinning={loading}>
            <section className="section">
              <SectionHeader title="Daily Task" />

              <div className="main_div ">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Select Date
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton" onClick={(event)=>event.stopPropagation()} 
                  >
                    <Calendar
                      selectRange={true}
                      maxDate={new Date()}
                      onChange={onChangeDat}
                      value={value}
                      className="calender"
                    />
                  </div>
                </div>
              </div>
              <MaterialTable
           
           options={{
            showTitle:false,
            pageSize:10,
            pageSizeOptions:[10,20,30,40,50], 
             emptyRowsWhenPaging: false,
             draggable:false
       }}   
                columns={columns1}
                data={newlyAddedChapter1}
                icons={tableIcons}
              />
            </section>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default Dailytask;
