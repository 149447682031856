import Header from "../../Constant/Header/Header"
import React, { useEffect, useState } from 'react'
import MainSideBar from '../../Constant/MianSideBar/MainSideBar'
import { Button, Spin, Tooltip, notification } from 'antd'
import SectionHeader from "../../Constant/SectionHeader/SectionHeader"
import {
  ArrowLeftOutlined
} from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom"
import ApiClient from "../../methods/api/apiClient"

const AddFestival = () => {
    const { id } = useParams()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [sdate, setsdate] = useState()
    const [edate, setedate] = useState()
    const [holiday, setholiday] = useState()
    const [description, setdescription] = useState()
    const [err, seterr] = useState(false)

    useEffect(()=>{
        if(id){
            getdata(id)
        }
    },[])
    const goback = () => {
        history.goBack()
    }
    const getdata=(id)=>{
        setLoading(true)
        ApiClient.get(`holiday?id=${id}`).then(res=>{
            if(res.success){
                setsdate(res?.data?.start_date)
                setedate(res?.data?.end_date)
                setholiday(res?.data?.event)
                setdescription(res?.data?.description)
            }
        })
        setLoading(false)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!sdate || !edate || !holiday || !description) {
            seterr(true)
        } else {
            let data = {
                event: holiday,
                start_date: sdate,
                end_date: edate,
                description: description
            }
            setLoading(true)
            if (id) {
                let data1 = {
                    ...data, id: id
                }
                ApiClient.put(`holiday`, data1).then(res => {
                    if (res.success) {
                        notification.success({ description: 'Event Updated Successfully' })
                        history.push("/festivals")
                        setLoading(false)
                    } else {
                        notification.error({ description: 'Something went Wrong' })
                        setLoading(false)
                    }
                })
            } else {

                ApiClient.post(`holiday`, data).then(res => {
                    if (res.success) {
                        notification.success({ description: 'Event Added Successfully' })
                        history.push("/festivals")
                        setLoading(false)
                    } else {
                        notification.error({ description: 'Something Wrong' })
                        setLoading(false)
                    }
                })
            }
        }
    }
    return (
        <>
            <>
                <div>
                    <div className="main-wrapper" id="mainPannel">
                        <div className="navbar-bg" />
                        <Header />
                        <MainSideBar />
                        <div className="main-content">
                            <Spin spinning={loading}>
                                <section className='section'>
                                    <SectionHeader title='Holidays' />
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}>
                                        <Tooltip title="Go Back">
                                            <Button type="primary"
                                                onClick={goback}
                                                shape="circle"
                                                icon={<ArrowLeftOutlined />}
                                                size={'large'}
                                                className="btn btn-primary profile"
                                            />
                                        </Tooltip>
                                    </div>
                                </section>
                                <div className="row">
                                    <form className="col-md-12" onSubmit={e => handleSubmit(e)}>
                                        <div className="form-row">
                                            <div className='form-group col-md-6 text-left'>
                                                <div>
                                                    <label for="ggg" className='control-label mt-3 '><b>Start Date</b></label>
                                                    <input type='Date' id='ggg' className="form-control " style={{ paddingLeft: "10px" }} value={sdate} onChange={e => setsdate(e.target.value)} placeholder='Select Start Date' />
                                                    {err && !sdate ? <span className="span-red">This field is Required</span> : ''}
                                                </div>
                                            </div>
                                            <div className='form-group col-md-6 text-left'>
                                                <div>
                                                    <label for="ggfg" className='control-label mt-3 '><b>End Date</b></label>
                                                    <input type='Date' id='ggfg' className="form-control " style={{ paddingLeft: "10px" }} value={edate} onChange={e => setedate(e.target.value)} placeholder='Select End Date' />
                                                    {err && !edate ? <span className="span-red">This field is Required</span> : ''}
                                                </div>
                                            </div>
                                            <div className='form-group col-md-6 text-left'>
                                                <div>
                                                    <label for="das" className='control-label mt-3 '><b>Event</b></label>
                                                    <input type='text' id='das' className="form-control " style={{ paddingLeft: "10px" }} value={holiday} onChange={e => setholiday(e.target.value)} placeholder='eg:Diwali' />
                                                    {err && !holiday ? <span className="span-red">This field is Required</span> : ''}
                                                </div>
                                            </div>
                                            <div className='form-group col-md-6 text-left'>
                                                <div>
                                                    <label for="aa" className='control-label mt-3 '><b>Description</b></label>
                                                    <input type='text' id='aa' className="form-control " style={{ paddingLeft: "10px" }} value={description} onChange={e => setdescription(e.target.value)} placeholder='Add description' />
                                                    {err && !description ? <span className="span-red">This field is Required</span> : ''}

                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn btn-warning mb-4" type="submit">Submit</button>
                                    </form>

                                </div>




                            </Spin>
                        </div>
                    </div>
                </div>

            </>

        </>
    )
}

export default AddFestival
