import React, { useEffect, useState, forwardRef } from "react";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import Header from "../../Constant/Header/Header";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { PlusSquareOutlined, EditOutlined } from "@ant-design/icons";
import {
  laptopdelete,
  updateStatusUser1,
  getAllAttandance,
  Laptoplistfilter,
} from "../../Api/user/userapi";
import MaterialTable from "material-table";
import moment from "moment";
import Calendar from "react-calendar";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom";
import {
  Table,
  Spin,
  Select,
  message,
  Button,
  notification,
  Tooltip,
  Popconfirm,
} from "antd";

const { Option } = Select;

function Laptoplist() {
  const [value, setonChange] = useState(new Date());
  const [array1, setArray1] = useState([]);
  const [array, setArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentdat = new Date();
  const formatdate = moment(currentdat).format("YYYY-MM-DD");
  const [startdate1, setstartdate1] = useState("");
  const [enddate1, setenddate1] = useState("");
  let today = new Date().toISOString().slice(0, 10);
  let history = useHistory();

  const DailyTaskList = async (employeeid, projectid) => {
    navigate.push(
      `tasklist/${employeeid}/${projectid}/${startdate1}/${enddate1}`
    );
  };
  const showEditModalHandle = (id) => {
    history.push(`/addeditlaptop/${id}`);
  };

  const userDetails = async () => {
    // try {
    //   setLoading(true);
    //   const resp = await getAllAttandance();
    //   if (resp && resp.status === 200) {
    //     const list = resp;
    //     console.log(list, 'listasas');
    //     const arrayData = list.data.data;
    //     setArray(arrayData)
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
    // setLoading(false);
  };

  async function handleChange(value, event) {
    const Payload = {
      model: "laptopassign",
      status: event,
      id: value?.id,
    };
    try {
      const resp = await updateStatusUser1(Payload);
      if (resp && resp.status === 200) {
        getLaptopdata();
        const newvalue = event.charAt(0).toUpperCase() + event.slice(1);
        notification.success({
          message: `Device is ${newvalue}`,
        });
        // message.success("updated successfully.");
        // setIsEditModalVisible(false);
      } else {
        notification.error({
          message: "Some error Occurred Please try again",
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  // Delete employee recode
  const deleterecord = (id) => {
    let param = {
      id: id,
    };
    let url = `remove/assigned/device?id=${id}`;
    laptopdelete(url).then((res) => {
      if (res.success) {
        setTimeout(() => {
          notification.success({
            message: "Employee Deleted Successfully",
            
          });
        }, 600);
        getLaptopdata();
      }
    });
  };
  useEffect(() => {
    getLaptopdata();
    getdevicelist();
  }, []);
  const getLaptopdata = () => {
    setLoading(true);
    ApiClient.get(
      `all/assigned/devices?search&page=1&count=500&start=${startdate1}&end=${enddate1}`
    ).then((res) => {
      setArray1(res.data);
      setLoading(false);
    });
  };

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  // For View Function
  const handleview = (id) => {
    history.push(`/view/laptop/${id}`);
  };

  const columns1 = [
    {
      title: "Employee Name",
      dataIndex: "employeeId.fullName",
      field: "employeeId.fullName",
      render: (text, record) => (
        <>
             <Tooltip title="View">
          <span onClick={()=>handleview(text.id)} className="dec_dot text-capitalize text-warning" style={{cursor:"pointer"}}>
          <p className="text-capitalize">{text && text?.employeeId.fullName}</p>
          </span>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Project Name",
      dataIndex: "projectId.name",
      field: "projectId.name",
      render: (text, record) => (
        <>
            {text && text.projectId.name}
        </>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "projectId.startDate",
      field: "projectId.startDate",
      render: (text, record) => (
        <>
          {text && text.projectId ? (
            <span className="dec_dot text-capitalize">
              {moment(text.startTime).format("DD MMM YYYY")}
            </span>
          ) : (
            "--"
          )}
        </>
      ),
    },
    {
      title: "End Date ",
      dataIndex: "projectId.endDate",
      field: "projectId.endDate",
      render: (text, record) => (
        <>
          {text && text.projectId ? (
            <span className="dec_dot text-capitalize">
              {moment(text.endTime).format("DD MMM YYYY")}
            </span>
          ) : (
            "--"
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "Status",
      field: "Status",
      render: (text, record) => (
        <>
          <>
           {text&&text?.status=="assigned"? <select
              style={{ width: "100%" }}
              placeholder="Select Status..."
              className="form-control pl-3"
              // defaultValue={text.Free}
              value={text.status}
              onChange={(event) => handleChange(text, event.target.value)}
            >
              <option value="assigned" label="Assigned">
                <div className="demo-option-label-item">Assigned</div>
              </option>
              <option value="accepted" label="Received">
                <div className="demo-option-label-item">Received</div>
              </option>
            </select>:<><Tooltip className='mx-2'>
                                <Button type="primary"  className='text-capitalize' >
                                    {text.status}
                                </Button>
                            </Tooltip></>}
          </>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      field: "actions",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            // justifyContent: 'space-evenly',
            maxWidth: "118px",
          }}
        >
          <span
            className="edit_icon"
            style={{
              verticalAlign: "text-bottom",
            }}
          >
            <Tooltip title="Edit">
              <Button
                type="primary"
                className="edit_btn"
                onClick={(e) => showEditModalHandle(text && text.id)}
              >
                {<EditOutlined />}
              </Button>
            </Tooltip>
          </span>
          {/* <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to Delete?"
              onConfirm={() => deleterecord(text.id)}
              onCancel={() => console.log("Cancled")}
              okText="Yes"
              cancelText="No"
            >
              <Button type=" deletebtn" className="ml-2">
                <i class="fa-solid fa-trash"></i>
              </Button>
            </Popconfirm>
          </Tooltip> */}
        </div>
      ),
    },
  ];
  const columns2 = [
    {
      title: "Device Type",
      dataIndex: "device_type",
      field: "device_type",
    },
    {
      title: "Device Name",
      dataIndex: "device_name",
      field: "device_name",
    },
    {
      title: "Serial Number",
      dataIndex: "serial_no",
      field: "serial_no",
    },
    {
      title: "OS Type",
      dataIndex: "os_type",
      field: "os_type",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      field: "actions",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            // justifyContent: 'space-evenly',
            maxWidth: "118px",
          }}
        >
          <span
            className="edit_icon"
            style={{
              verticalAlign: "text-bottom",
            }}
          >
            <Tooltip title="Edit">
              <Button
                type="primary"
                className="edit_btn"
                onClick={(e) => EditDevice(text && text._id)}
              >
                {<EditOutlined />}
              </Button>
            </Tooltip>
          </span>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to Delete?"
              onConfirm={() => deletedevice(text._id)}
              onCancel={() => console.log("Cancled")}
              okText="Yes"
              cancelText="No"
            >
              <Button type=" deletebtn" className="ml-2">
                <i class="fa-solid fa-trash"></i>
              </Button>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  const navigate = useHistory();
  const onChangeDatesss = async (newDate) => {
    setonChange(newDate);
    try {
      setLoading(true);
      const resp = await Laptoplistfilter(today);
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        setArray1(arrayData);
        console.log(arrayData, "THis is the data");
      }
    } catch (res) {
      setArray1("");
    }
    setLoading(false);
  };
  const goback = () => {
    navigate.push("/addeditlaptop");
  };

  const newlyAddedChapter1 = array1
    ? array1.map((items) => {
        return {
          ...items,
        };
      })
    : [];

  const onChangeDat = async (newDate) => {
    setonChange(newDate);
    const startDate = convert(newDate[0]);
    setstartdate1(startDate);
    const endDate = convert(newDate[1]);
    setenddate1(endDate);
    console.log(startDate, "This is the startdate thats we need for sometimes");
    console.log(endDate, "This is the enddate thats we need for sometimes");
    try {
      setLoading(true);
      const resp = await Laptoplistfilter(startDate, endDate);
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        setArray1(arrayData);
      }
    } catch (res) {
      setArray1("");
    }
    setLoading(false);
  };
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [showdevices,setshowdevices]=useState(false)
  const [array3,setarray3]=useState(false)
  const adddevice=()=>{
    history.push("/adddevice")
  }
  const alldevices=()=>{
    setshowdevices(!showdevices)
  }
  const deletedevice=(id)=>{
    let payload={
      id:id
    }
    setLoading(true)
    ApiClient.delete(`device`,payload).then(res=>{
      if(res.success){
        notification.success({
          message: "Device Deleted",
        });
        getdevicelist()
      }else{
        notification.error({
          message: "Record not found",
        });
      }
     
      setLoading(false)
    })
  }
  const EditDevice=(id)=>{
    history.push(`/adddevice/${id}`)
  }
  const getdevicelist=()=>{
    setLoading(true)
    ApiClient.get(`deviceList`).then(res=>{
      if(res.success){
        setarray3(res.data)
      }
      setLoading(false)
    })
  }
  const newlyAddedChapter2 = array3
  ? array3.map((items) => {
      return {
        ...items,
      };
    })
  : [];


  return (
    <div>
      <div className="main-wrapper">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title={showdevices?`Device List`:`Employees List`} />
          <Tooltip title="Add Device">
              <Button
                type="primary"
                onClick={adddevice}
                shape="circle"
                // icon={<PlusSquareOutlined />}
                size={"large"}
                className="btn btn-primary profile mr-5"
              >Add Device</Button>
            </Tooltip>
          <Tooltip title="Assign Device">
            <Button
              type="primary"
              onClick={goback}
              shape="circle"
              icon={<PlusSquareOutlined />}
              size={"large"}
              className="btn btn-primary profile"
            />
          </Tooltip>
          <div className="main_div ">
           {!showdevices? <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Select Date
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"  onClick={(event)=>event.stopPropagation()}>
                <Calendar
                
                  selectRange={true}
                  maxDate={new Date()}
                  onChange={onChangeDat}
                  value={value}
                  className="calender"
                />
              </div>
            </div>:<></>}
            <button
                class="btn btn-primary ml-3"
                type="button"
                title= {showdevices?"Show all Employees":"Show all devices"}
                onClick={()=>alldevices()}
              >
              {showdevices?"Show Employees":"All Devices"}
              </button>
          </div>
          <Spin spinning={loading}>
            {!showdevices?<MaterialTable
              options={{
                showTitle: false,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50],
                emptyRowsWhenPaging: false,
                draggable:false
              }}
              enableColumnOrdering
              columns={columns1}
              data={newlyAddedChapter1}
              icons={tableIcons}
            />:
             <MaterialTable
              options={{
                showTitle: false,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50],
                emptyRowsWhenPaging: false,
                draggable:false
              }}
              columns={columns2}
              data={newlyAddedChapter2}
              icons={tableIcons}
            />}
          </Spin>
        </section>
      </div>
    </div>
  );
}

export default Laptoplist;
