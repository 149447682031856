import React, { useEffect, useState, forwardRef, useRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import {  Spin, Tooltip, Button, notification, Popconfirm } from 'antd';
import {
    PlusSquareOutlined,
    EditOutlined,
} from '@ant-design/icons';
import { getUsers, getUsersdata, updateStatusUser, userdelete } from '../../Api/user/userapi';
import './user.css';
import { Link, useHistory } from 'react-router-dom';
import { useSidebar } from '../../Provider/Provider';
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ApiClient from '../../methods/api/apiClient';
import ApiKey from '../../methods/ApiKey';

const AllUsers = (props) => {
    const [releavestatus, setreleavestatus] = useState(false);
    const documentopen = useRef()
    const [array, setArray] = useState([]);
    const [images, setimages] = useState([]);
    const [documents, setdocuments] = useState([]);
    // const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    // const [editChapterData, setEditChapterData] = useState({});
    const [loading, setLoading] = useState(false);
    // const [detailId, setDetailId] = useState();
    const history = useHistory()
    const { setCourseId,  } = useSidebar();
    const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
    const [userid, setUserdetails] = useState({});
    const [status, setstatus] = useState("")

    useEffect(() => {
        userDetails();
        // eslint-disable-next-line
    }, [])
    const imageResult = (e) => {
        if (e.target.files.length > 0) {
            setDocumentUploadLoading(true)
            const files = e.target.files;
            let newarray = documents
            let imgarrays = []
            let i = 0;
            let original = []
            for (let items of files) {
                imgarrays.push(items);
            }
            for (let item of imgarrays) {
                let file = files.item(i)
                console.log("i", i)
                original.push(file.name);
                console.log("file", file)
                ApiClient.multiImageUpload("multiple/documents?modelName=doc", { data: file }).then(res => {
                    if (res.success) {
                        console.log(res?.data?.imagePath[0], "THis is Image Path")
                        const path = res?.data?.imagePath[0]
                        newarray.push(path)
                        setDocumentUploadLoading(false)

                    } else {
                        setDocumentUploadLoading(false)
                        notification.error({ message: "Some error Occurred ! Try Again!" })
                    }
                })

                i++
            }
            setdocuments(newarray)
            setimages([...images, ...original])
        }

        else {
            notification.error({ message: "Please Upload the Documents" })
        }
    }
    //  For Deleting the Document
    const HanldDocumentDelete = (e, index) => {
        e.preventDefault();
        const array = [...images];
        array.splice(index, 1);
        setimages(array)
    }
    const userDetails = async () => {
        try {
            setLoading(true);
            const resp = await getUsers();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                const newarraydata = arrayData.filter((item) => {
                    return item.role != "subadmin"
                })
                // console.log("Here is the data that we want to check",arrayData)
                setArray(newarraydata)
                setstatus('')
            }
        } catch (e) {
            localStorage.clear();
            history.push("/")
            setTimeout(() => {
                notification.error({
                    message: 'Unauthorized',
                    description: 'Your Session Has Expired. Please logIn.',
                });
            }, 1000);
        }
        setLoading(false);
    };

    // const handleCancel = () => {
    //     // setIsModalVisible(false);
    //     setIsEditModalVisible(false);
    //     // setModalVisible(false)
    //     // message.error("Chapter is not deleted!");
    // };

    const showEditModalHandle = (id) => {
        history.push(`/addeditUser/${id}`)
    };

    // const removeChapterHandle = async (data) => {
    //     console.log(data, "delete");
    //     const Payload = {
    //         id: data.id,
    //         status: data.status,
    //         model: "users",

    //     }
    //     console.log(Payload, 'Payload');
    //     try {
    //         const resp = await updateStatusUser(Payload);
    //         if (resp && resp.status === 200) {
    //             userDetails();
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    const chapterIdHandle = (id) => {
        setCourseId(id);
    };

    const changeStatus = async (type, value) => {
        console.log(type)
        let statuss = "active"
        if (type == "active") {
            statuss = "deactive"
        } else { statuss = "active" }
        const payload = {
            model: "users",
            status: statuss,
            id: value?.id
        }
        if (window.confirm(`Do you really want to change status ?`)) {
            const resp = await updateStatusUser(payload)
            if (resp && resp.status === 200) {
                if (type == "active") {
                    notification.success({
                        message: 'Employee is De-Active.',
                    });
                }
                else {
                    notification.success({
                        message: 'Employee is Active.',
                    });
                }
                userDetails();
            }
        }
    }

    // async function handleChange(value, event) {
    //     const Payload = {
    //         model: "users",
    //         status: event ? "active" : "deactive",
    //         //  value.status==='active'?'deactive':'active',
    //         id: value?.id
    //     }
    //     try {
    //         const resp = await updateStatusUser(Payload);
    //         if (resp && resp.status === 200) {
    //             if (event == false) {
    //                 notification.success({
    //                     message: 'Employee is In-Active.',
    //                 });
    //             }
    //             else {
    //                 notification.success({
    //                     message: 'Employee is Active.',
    //                 });
    //             }
    //             // message.success("updated successfully.");   
    //             userDetails();
    //             // setIsEditModalVisible(false);
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    // Delete employee recode
    const deleterecord = (id) => {
        let param = {
            id: id,
            model: 'users'
        }
        let url = 'delete'
        userdelete(url, param).then(res => {
            if (res.success) {
                setTimeout(() => {
                    notification.success({
                        message: 'Employees',
                        description: res.message,
                    });
                }, 600);
                getusersData()
            }
        })
    }
    const getusersData = () => {
        getUsersdata().then(res => {
            let arrayData = res.data.data
            setArray(arrayData)
            setstatus('')
            console.log(status)
        })
    }

    const HandleReleaveSubmit = (e) => {
        e.preventDefault();
        // alert(userid)
        ApiClient.put(`editProfile?id=${userid}`, { releave: !releavestatus, releaveDocument: documents }).then(res => {
            document.getElementById("releavemodalclose").click();
            getusersData();
            setstatus('')

        })
    }

    const columns = [
        {
            title: "Full Name",
            dataIndex: "fullName",
            field: "fullName",
            render: (text, record) => (
                <>
                    {text && text.fullName ? (
                        <Link to={`/userDetails/${text._id}`}>
                            <span className="text" onClick={() => chapterIdHandle(text.id)}>
                                {text.fullName}
                            </span>
                        </Link>
                    ) : (
                        "--"
                    )}
                </>
            ),
        },
        {
            title: "Email",
            dataIndex: "email",
            field: "email",
            render: (text, record) => (
                <>
                    {text && text.email ? (
                        <span  >
                            {text.email}
                        </span>
                    ) : (
                        "--"
                    )}
                </>
            ),
        },
        {
            title: "Unique Id",
            dataIndex: "employee_code",
            field: "employee_code",
        },
        {
            title: "Designation",
            dataIndex: "designation",
            field: "designation",
        },
        {
            title: "Contact Number",
            dataIndex: "contactNumber",
            field: "contactNumber",
        },
        {
            title: "Status",
            dataIndex: "status",
            field: "status",
            render: (text, record) => (
                <>
                    {text && text.status ? (
                        <>
                            {/* {text.status =="active" ?
                                <Switch  defaultChecked onChange={(e) => handleChange(text, e)} />
                                : 
                                <Switch   onChange={(e) => handleChange(text, e)} />} */}
                            {/* <button className="bg bg-warning" onClick={e=>changeStatus(text.status)}>{text?.status}</button> */}
                            {text.status == "deactive" ?
                             <button id="buttons"
                                type="button"
                                className='text-capitalize'
                                onClick={() => changeStatus(text.status,text)}
                                style={{
                                    backgroundColor: "#f44336",
                                    border: 'none',
                                    color: 'white',
                                    padding: '3px 7px',
                                    textAlign: 'center',
                                    textDecoration: 'none',
                                    display: 'inline-block',
                                    fontSize: '16px',
                                    margin: '1px 2px',
                                    cursor: 'pointer',
                                    borderRadius: '3px'
                                }}
                            >
                                {text.status}
                            </button>:<Tooltip className='mx-2'>
                                <Button type={`${text.status == "active" ? "primary" : "danger"} `} className='text-capitalize' onClick={() => changeStatus(text.status, text)} >
                                    {text.status}
                                </Button>
                            </Tooltip>}
                            
                        </>
                    ) : (
                        "--"
                    )}
                </>
            ),
        },
        {
            title: "Actions",
            dataIndex: "actions",
            field: "actions",
            render: (text, record) => (
                <div
                    style={{
                        display: 'flex',
                        // justifyContent: 'space-evenly',
                        maxWidth: '118px'
                    }}
                >
                    <span className="edit_icon"
                        style={{
                            verticalAlign: 'text-bottom'
                        }}>
                        <Tooltip title="Edit">
                            <Button type="primary" className="edit_btn" onClick={e => showEditModalHandle(text && text.id)}>
                                {<EditOutlined />}
                            </Button>
                        </Tooltip>
                    </span>
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure you want to Delete?"
                            onConfirm={() => deleterecord(text.id)}
                            onCancel={() => console.log("Cancled")}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" className='deletebtn ml-2' >
                                <i class="fa-solid fa-trash"></i>
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                    <Tooltip title={`${text.releave ? "Releaved" : "Releave"} `} className='mx-2'>
                        <Button type={`${text.releave ? "secondary" : "primary"} `} data-toggle="modal" onClick={e => { text.releave == true ? setreleavestatus(true) : setreleavestatus(false); setimages(text?.releaveDocument ? text.releaveDocument : []); setUserdetails(text._id) }} data-target="#releavemodal">
                            {text.releave == true ? "Releaved" : "Releave"}
                        </Button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const newlyAddedChapter =
        array && array.length
            ? array.map((items) => {
                localStorage.setItem('rolesid', items.id);
                return {
                    ...items,
                };
            })
            : [];

    const renderSubmit = () => {
        props.history.push('/addeditUser')
    }

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    const handlestatus = (e) => {
        setstatus(e)
        let payload = {
            status: e,
            sortBy: 'asc',
            count: 1000,
            isDeleted: false
        }
        setLoading(true)
        ApiClient.get(`users`, payload).then(res => {
            if (res.success) {
                setArray(res.data)

            }
        })
        setLoading(false)
    }
    return (
        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">
                <section className="section">
                    <SectionHeader title="All Employees" />
                    <div className="form-group col-md-2 text-left">
                        {/* <label className="form-control"> </label> */}
                        <div className="">
                            <select
                                name='Status'
                                value={status}
                                type="text"
                                onChange={e => handlestatus(e.target.value)}

                                className="form-control " style={{ paddingLeft: "10px" }}
                            >
                                <option value="" selected>Status</option>
                                <option value="active">Active</option>
                                <option value="deactive">De-Active</option>

                            </select>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <Tooltip title="Add Employee">
                            <Button type="primary"
                                onClick={renderSubmit}
                                shape="circle"
                                icon={<PlusSquareOutlined />}
                                size={'large'}
                                className="btn btn-primary profile"
                            />
                        </Tooltip>
                    </div>
                    <Spin spinning={loading}>
                        <MaterialTable
                            options={{
                                showTitle: false,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 30, 40, 50],
                                emptyRowsWhenPaging: false,
                                draggable:false
                            }}
                            tooltip="See all Employees here"
                            columns={columns}
                            data={newlyAddedChapter}
                            icons={tableIcons}
                            aling="center"
                        />

                    </Spin>
                </section>
            </div>
            {/* <Modal
                footer={null}
                title="Edit Roles"
                visible={isEditModalVisible}
                onCancel={handleCancel}
            >
                <div className="crossicon_class" >
                    <button onClick={() => handleCancel()} className="btn " tabindex="-1" type="button" aria-label="Clear Search"><span class="MuiIconButton-label"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" aria-label="clear"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></span></button>
                </div>
                <EditUser
                    chapterId={id}
                    setIsEditModalVisible={setIsEditModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                    onCancel={handleCancel}
                />
            </Modal> */}


            <div class="modal fade" id="releavemodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">{releavestatus ? "Releaved Documents" : "Upload the Documents"}</h5>
                            <button type="button" id='releavemodalclose' class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className='col-md-12'>
                                {releavestatus == true ?
                                    <div className='d-flex'>

                                        {images.map((item, index) => (
                                            <div>
                                                <img onClick={e => window.open(ApiKey.api + "images/doc/" + item)} width={60} height={60} src='https://static.thenounproject.com/png/727718-200.png' alt='' />
                                            </div>
                                        ))}
                                    </div>
                                    : <div class="dropzone-wrapper pt-4 mt-1">
                                        <div class="dropzone-desc">
                                            <input multiple={true} type='file' ref={documentopen} onChange={e => imageResult(e)} className='d-none' />
                                            <button onClick={e => documentopen.current.click()} className='btn btn-warning'>Upload Documents</button>
                                            <div class="imagesRow">
                                                {DoumentUploadLoading == true ? <div className="text-success">Uploading... <i className="fa fa-spinner fa-spin"></i></div> : images.map((item, index) => (<div>
                                                    <p className='text-capitalize'>{item} <i className='fa fa-trash text-danger shadow-danger' style={{ cursor: "pointer" }} onClick={e => HanldDocumentDelete(e, index)}></i></p>
                                                </div>
                                                ))}
                                                {/* {DoumentUploadLoading==true? <div className="text-success">Uploading... <i className="fa fa-spinner fa-spin"></i></div>:images.map((item,index)=>(<div>
                                                    <img src={ApiKey+item} width={200} height={200} onClick={e=>Handle}/>
                                                    </div>
                                                ))} */}
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        {releavestatus == true ? null : <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            {images.length > 0 ? <button type="button" onClick={e => HandleReleaveSubmit(e)} class="btn btn-primary">Submit</button> : <button type="button" class="btn btn-primary ant-input-disabled" disabled >Submit</button>}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllUsers;