import React, { useEffect, useState, forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useSidebar } from '../../Provider/Provider';
import { getWeeklyplanbyprojectID, xlsingledata } from '../../Api/user/userapi';

const UpdateXLsheet = () => {

    const [array, setArray] = useState([])

    console.log(array,"allarraydatat");
    const { courseId, setChapterListDetail } = useSidebar();


    const id  = localStorage.getItem('updatedxlsheetdata');

    const tableID = localStorage.getItem("xlIdAdmin")

    const xlsingle = async () => {
        try {


            const resp = await xlsingledata(tableID);
            if (resp && resp.status === 200) {
                const list = resp;

                console.log(list, 'lxddistasas');
                const arrayData = list.data.data.tasks;
                console.log(arrayData, 'asdasarrayData');
                setArray(arrayData)
            }
        } catch (e) {
            console.log(e);
        }


    };

    useEffect(() => {
     
        xlsingle()
    }, [])

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };


    const columns = [
        {
            title: "Task-Name",
            dataIndex: "taskname",
            field: "taskname",
        },
        {
            title: "Status",
            dataIndex: "status",
            field: "status",
           
        },
    ];
    const newlyAddedChapter =
    array && array.length
        ? array.map((items) => {
            //  localStorage.setItem('projectId', items.id);
            return {
                ...items,
            };
        })
        : [];
    return (
        <>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">

                <section className="section">

                    <SectionHeader title="Updated XL File" />
                    {/* <div>
                        <Button type="primary"
                            onClick={renderSubmitBack}
                            shape="circle"
                            icon={<ArrowLeftOutlined />}
                            size={'large'}
                            className="btn btn-primary profile"
                        />



                    </div> */}

                    <div>
                      
                        <table id='employee'>
                            <thead>
                                {/* <tr>{renderHeader()}</tr> */}
                            </thead>
                            {/* <tbody>
                                {renderBody()}
                            </tbody> */}
                        </table>
                    </div>

                    <div >




                        <MaterialTable icons={tableIcons}
                            // title="Project Data"
                            options={{
                                showTitle: false,
                                draggable:false
                           }}
                            data={newlyAddedChapter}
                            columns={columns} />



                    </div>

                </section>
            </div>
        </>
    );
};

export default UpdateXLsheet;