import React, { useEffect, useState } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Button, Spin, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './user.css';
import { getUsersdetails } from '../../Api/user/userapi';
import { useHistory,useParams } from 'react-router-dom';
import { EditOutlined } from "@ant-design/icons";

const UserDetails = (props) => {
  const [loading,setloading]=useState(false)
  const [alldata,setalldata]=useState({})
  const history=useHistory()
  const {id}=useParams();

  useEffect(() => {
    getProject();
    // eslint-disable-next-line
  },[])

  const getProject = async (i) => { 
    setloading(true);
    try {
      const resp = await getUsersdetails(id);
      setloading(false);
      if (resp && resp.status === 200) { 
        setalldata(resp.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const renderSubmitBack = () => {
    // props.history.push('/allUser')
    history.goBack();
  }

  // Date & Time format
  const monthArray = [
    { value: 'Jan', id: 0 },
    { value: 'Feb', id: 1 },
    { value: 'Mar', id: 2 },
    { value: 'Apr', id: 3 },
    { value: 'May', id: 4 },
    { value: 'Jun', id: 5 },
    { value: 'Jul', id: 6 },
    { value: 'Aug', id: 7 },
    { value: 'Sep', id: 8 },
    { value: 'Oct', id: 9 },
    { value: 'Nov', id: 10 },
    { value: 'Dec', id: 11 }
]
const monthfind = (id) => {
    let value = 0
    let ext = monthArray.find(itm => itm.id === id)
    if (ext) value = ext.value
    return value
}

const date = (p) => {
  if(p==""||p==undefined||p==null){
    return ""
  }
  else
  {
    let d = new Date(p)
    return `${d.getDate()} ${monthfind(d.getMonth())} ${d.getFullYear()}` 
  }
}


const editdetails=()=>{
  history.push(`/addeditUser/${id}`)
}

  return (
    <div className="text-left">
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title="Employee Details" />
          <div>
            <Button type="primary"
              onClick={renderSubmitBack}
              shape="circle"
              icon={<ArrowLeftOutlined />}
              size={'large'}
              className="btn btn-primary profile"
            />
          </div>
          <Spin spinning={loading}>
          <div className="container pl-0">
            <h4 className="left detailss ml-2">Personal Details
              <span>
                <Tooltip title="Edit">
                    <Button type="primary" className="edit_btn edit-button" onClick={e=>editdetails()}>
                        {<EditOutlined  />}
                    </Button>
                </Tooltip>
              </span>
            </h4>
            <div className="shadow p-3 w-100 d-flex">
              <div class="row">
                <div class="col-md-4">
                  <label className="text-bold">Full-Name:</label>
                  <span>{alldata.fullName}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Father-Name:</label>
                  <span>{alldata.fatherName}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Mother-Name:</label>
                  <span>{alldata.motherName}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Gender:</label>
                  <span>{alldata.gender}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">DOB:</label>
                  <span>{date(alldata.dob)}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Gmail:</label>
                  <span className='text-capitalize'>{alldata.gmail}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">MaritalStatus:</label>
                  <span className='text-capitalize'>{alldata.maritalStatus}</span>
                </div>
                <div class={`col-md-4 d-${alldata.maritalStatus=="married"?"":"none"} `}>
                  <label className="text-bold">SpouseName:</label>
                  <span className='text-capitalize'>{alldata.spouseName}</span>
                </div>

                <div class="col-md-4">
                  <label className="text-bold">Contact Number:</label>
                  <span className='text-capitalize'>{alldata.contactNumber}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Parents Contact Number:</label>
                  <span className='text-capitalize'>{alldata.mobileNo}</span>
                </div>
              </div>
            </div>

            <h4 className="left detailss ml-2">Address Details</h4>
            <div className="shadow p-3 w-100 d-flex">
              <div class="row">
                <div class="col-md-8">
                  <label className="text-bold">Primary-Address:</label>
                  <span>{alldata.primaryAddress}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Primary-State:</label>
                  <span>{alldata.primaryState}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Zip-Code:</label>
                  <span>{alldata.primaryZipcode}</span>
                </div>
                <div class="col-md-12">
                  <label className="text-bold">Secondary-Address:</label>
                  <span>{alldata.secondaryAddress1}</span>
                </div>
              </div>
            </div>

            <h4 className="detailss left ml-2" >Other Details</h4>
            <div className="shadow p-3 w-100">
              <div class="row">
                <div class="col-md-6">
                  <label className="text-bold">Designation:</label>
                  <span>{alldata.designation}</span>
                </div>
                <div class="col-6">
                  <label className="text-bold">Role:</label>
                  <span className='text-capitalize'>{alldata.role}</span>
                </div>
                <div class="col-6">
                  <label className="text-bold">Date of joining:</label>
                  <span className='text-capitalize'>{date(alldata.dateOfJoining)}</span>
                </div>
                <div class="col-6">
                  <label className="text-bold">Email:</label>
                  <span className=''>{alldata.email}</span>
                </div>
                <div class="col-12">
                  <label className="text-bold">Skills:</label>
                  <span>{alldata.skill&&alldata.skill.map((item,i)=>(
                    <span className='text-capitalize pl-2'>{i+1}.{item}</span>
                  ))}</span>
                </div>
              </div>
            </div>

            <h4 className="left detailss ml-2">Personal Document</h4>
            <div className="shadow p-3 w-100 d-flex">
            <div className='row'>
              <div className='col-md-6'>
                <label className="text-bold">Aadhar Card Number:</label>
                <span className='text-capitalize'>{alldata.aadharCardno}</span>
              </div>
              <div className='col-md-6'>
                <label className="text-bold">Votor Card Number:</label>
                <span className='text-capitalize'>{alldata.votercardNo}</span>
              </div>
              <div className='col-md-6'>
                <label className="text-bold">Pan Card Number:</label>
                <span className='text-capitalize'>{alldata.pancardNo}</span>
              </div>
              <div className='col-md-6'>
                <label className="text-bold">Passport Number:</label>
                <span className='text-capitalize'>{alldata.passportNo}</span>
              </div>
            </div>
            
            </div>
          </div>
          </Spin>
        </section>
      </div>
    </div>
  );
};

export default UserDetails;
