import React, { useEffect, useState } from "react";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import Header from "../../Constant/Header/Header";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import {
  Form,
  Button,
  Spin,
  message,
  Select,
  notification,
  Tooltip,
} from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { addreview } from "../../Api/user/userapi";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

function Addreviewproject() {
  const [loading, setLoading] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState("");
  const [id, setId] = useState("");
  const [arrayproject, setArrayproject] = useState([]);
  const [arrayemp, setArrayepm] = useState([]);
  const [projectValue, setProjectValue] = useState({});

  const str = projectValue?.startTime;
  const strend = projectValue?.endTime;

  const chars = str?.split(":00.000Z");
  const charsend = strend?.split(":00.000Z");

  const params = useParams();
  let history = useHistory();
  const renderSubmitBack = () => {
    history.goBack();
  };
  const Projectslist = () => {
    ApiClient.get(`projects?sortBy=asc&count=500`).then((res) => {
      setArrayproject(res.data);
    });
  };
  const Emplist = () => {
    ApiClient.get(`getAllDevelopers?sortBy=asc&count=1000&isDeleted=false`).then((res) => {
      setArrayepm(res.data);
    });
  };

  const Empuserlist = () => {
    setLoading(true);
    ApiClient.get(`project-review?id=${params.id}`).then((res) => {
      setProjectValue(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (params.id) {
      Empuserlist();
    } else {
    }

    Projectslist();
    Emplist();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      ...projectValue,
    };
    if (!params.id) {
      try {
        const userResp = await addreview({ ...payload });
        history.push("/reviewproject");
        setTimeout(() => {
          notification.success({
            message: "Added Successfully",
          });
        }, 1000);
      } catch (e) {
        notification.error({
          message: "Project Already Exist",
        });
      } finally {
        setLoading(false);
      }
    } else {
      delete payload.updatedAt;
      delete payload.isDeleted;
      delete payload.createdAt;
      ApiClient.put(`project-review`, payload).then((res) => {
        if (res.success) {
          history.push("/reviewproject");
          setTimeout(() => {
            notification.success({
              message: "Project Updated Successfully",
            });
          }, 1000);
        }
      });
    }
  };
  const [form] = Form.useForm();
  return (
    <div>
      <div className="main-wrapper">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title="Review Project" />
          <div>
            <Tooltip title="Back Button">
              <Button
                type="primary"
                onClick={renderSubmitBack}
                shape="circle"
                icon={<ArrowLeftOutlined />}
                size={"large"}
                className="btn btn-primary profile"
              />
            </Tooltip>
          </div>

          <div className="container shadow p-4">
            <Spin spinning={loading}>
              <div>
                {loginErrorMsg && message.error(loginErrorMsg)}

                <form onSubmit={handleSubmit}>
                  <Spin spinning={loading}>
                    <div className="row">
                      {/* <div span={12} className="row"> */}
                      <div className="col-md-6">
                          <label>
                          Reviewer name<span className="star">*</span>
                          </label>
                          <br />
                          <select
                            className="w-100 mb-2 border"
                            value={
                              projectValue.employeeId
                                ? projectValue.employeeId
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                employeeId: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="">Select Employee...</option>
                            {arrayemp.map((user) => (
                              <option value={user.id}>{user.fullName}</option>
                            ))}
                          </select>
                        </div>

                        
                        <div className="col-md-6">
                          <label>
                            Select Project<span className="star">*</span>
                          </label>
                          <br />
                          <select
                            className="w-100 mb-2 border"
                            value={
                              projectValue?.projectId
                                ? projectValue.projectId
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                projectId: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="">Select Project...</option>
                            {arrayproject.map((user) => (
                              <option value={user.id}>{user.name}</option>
                            ))}
                          </select>
                        </div>

                        
                        
                        {/* <div className="col-md-6">
                          <label>
                          Reviewer Start Date<span className="star">*</span>
                          </label>
                          <br />
                          <input
                            className="w-100 mb-2 border"
                            required
                            type="date"
                            value={
                              projectValue.id
                                ? chars[0]
                                : projectValue.startTime
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                startTime: e.target.value,
                              })
                            }
                          />
                        </div> */}

                        {/* <div className="col-md-6">
                          <label>
                          Reviewer End Date <span className="star">*</span>
                          </label>
                          <br />
                          <input
                            className="w-100 mb-2 border"
                            required
                            type="date"
                            value={
                              projectValue.id
                                ? charsend[0]
                                : projectValue.endTime
                            }
                            min={projectValue.startTime}
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                endTime: e.target.value,
                              })
                            }
                          />
                        </div> */}
                        <div className="col-md-6">
                          <label>
                          Review Type<span className="star">*</span>
                          </label>
                          <br />
                          <select
                            className="w-100 mb-2 border"
                            value={
                              projectValue.reviewType
                                ? projectValue.reviewType
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                reviewType: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="">Select Reviewer Type...</option>
                           
                              <option value="project review"> Project Review </option>
                              <option value="code review"> Code Review </option>
                          
                          </select>
                        </div>
                        <div className="col-md-12 pl-0 mt-3">
                      <div className="text-right">
                        <button
                          type="primary"
                          htmlType="submit"
                          className="btn btn-warning"
                        >
                          Submit
                        </button>
                        </div>
                        </div>
                      </div>
                     
                     
                    {/* </div> */}
                  </Spin>
                </form>
              </div>
            </Spin>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Addreviewproject;
