import React, { useEffect } from 'react'
import Header from '../../../Constant/Header/Header';
import MainSideBar from '../../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../../Constant/SectionHeader/SectionHeader';
import {
    Table,
    Spin,
    Select,
    message,
    Button,
    notification,
    Tooltip,
    Popconfirm,
  } from "antd";
  import { useHistory } from "react-router-dom";
  import { ArrowLeftOutlined } from "@ant-design/icons";
  import { useParams } from "react-router-dom";
import { useState } from 'react';
import { Formik } from 'formik';
import ApiClient from '../../../methods/api/apiClient';

export default function ADDEditbugs() {
    const {id,editid}=useParams(); 
    const [editdata,seteditdata]=useState([]);
    const [loading,setloading]=useState(false);
    const history=useHistory();
    const goback=()=>{
        history.goBack();
    }
// Get initial data when the api is called 
    const geteditdata=()=>{
      // Please put the api url befor makingn it live 
     ApiClient.get("").then(res=>{
               seteditdata(res.data)
     }).catch(err=>{
      notification.error({message:"Some error Occurred.Please try again"})
     })
    }
    useEffect(()=>{
    if(editid){
      geteditdata();
    }
    },[])
  return (
    <div className="">
        
    <div className="main-wrapper" id="mainPannel">
      <div className="navbar-bg" />
      <Header />
      <MainSideBar />
    </div>
    <div className="main-content">
      <div className="inventry_section">
      <section className="section">
        <SectionHeader title={`${id?"Add":"Edit"} Bug`} />
        <Tooltip title="Back Button">
          <Button
            type="primary"
            onClick={goback}
            shape="circle"
            icon={<ArrowLeftOutlined />}
            size={"large"}
            className="btn btn-primary profile"
          />
        </Tooltip>
        <Spin spinning={loading}>  
        <div className='shadow pl-3'>
            <Formik
              enableReinitialize
              initialValues={{
                bugname:"",
                description:""
              }}
              onSubmit={(values)=>{
                      alert(JSON.stringify(values));
              }}
            >
                {({values,handleChange,handleBlur,handleSubmit,errors,touched})=>(
                    <form onSubmit={handleSubmit}>
                        <div className='p-3'>
                      <label  >Bug Name <span className='text-danger'>*</span></label>
                      <input placeholder='Bug Name' name='bugname'  required className='form-control pl-3' value={values.bugname} onChange={handleChange} onBlur={handleBlur} />
                      <label className='form-label'>Description <span className='text-danger' >*</span></label>
                      <textarea placeholder='Description' name='description' required className='form-control pl-3 pt-3' value={values.description} onChange={handleChange} onBlur={handleBlur} />
                      <button type='submit' className='btn btn-warning mt-3' >{!editid?"Submit":"Update"}</button>
                      </div>
                    </form>
                )}

            </Formik>

        </div>
        </Spin>
        </section>
        </div>
        </div>
        </div>
  )
}
