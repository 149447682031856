import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { graphtotalcount } from '../Api/user/userapi';
import { Link } from 'react-router-dom';
import ApiClient from '../methods/api/apiClient';
import { Spin, notification } from 'antd/lib';

const Stats = ({ history, blogCount, userCount, totalCount }) => {
  const [loading,setloading]=useState(false);
  const [projectalltotal, setprojectalltotal] = useState();
  const [appraisalcount, setappraisalcount] = useState();

  useEffect(() => {
    setloading(true);
    grpahcount();
    getallemployees();
    setTimeout(() => {
      
      setloading(false)
    },1000);
  },[])

  const getallemployees=()=>{
    ApiClient.get(`users?sortBy=asc&count=1000&isDeleted=false`).then(res=>{
      if(res.success){
        setprojectalltotal(res.total)
      }else{
notification.error({message:res.message})
      }
    })
  }

  const grpahcount = async () => {
    try {
      const resp = await graphtotalcount();
      if (resp && resp.status === 200) {
        setappraisalcount(resp.data.total)
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (

    <Spin spinning={loading}>
    <div className="col">
      <div className="col-md-2"></div>
        <div class="row">
          <div class="col-md-4">
            <div
              className="card card-statistic-1"
              style={{
                cursor: 'pointer',
                boxShadow: '0 3px 10px rgb(0 0 0 / 20%)'
              }}
            >
              <Link to={'/allUser'} className="card-wrap">
              <div className="card-icon" style={{ backgroundColor: "DodgerBlue" }} >
                <i className="far fa-user" />
              </div>
                <div className="card-header">
                  <h4>Employees</h4>
                </div>
                <div className="card-body">{projectalltotal}</div>
              </Link>
            </div>
          </div>
          {/* <div class="col-md-4">
            <div
              className="card card-statistic-1"
              style={{
                cursor: 'pointer',
                boxShadow: '0 3px 10px rgb(0 0 0 / 20%)'
              }}
            >
              <Link to={'/resignation'} className="card-wrap">
              <div className="card-icon " style={{ backgroundColor: "Tomato" }}  >
                <i className="fas fa-circle" />
              </div>
                <div className="card-header">
                  <h4>Resignation</h4>
                </div>
                <div className="card-body">{projectalltotal && projectalltotal.resignation}</div>
              </Link>
            </div>
          </div> */}


          {/* <div class="col-md-4">
            <div
              className="card card-statistic-1"
              style={{
                cursor: 'pointer',
                boxShadow: '0 3px 10px rgb(0 0 0 / 20%)'
              }}
            >
              <Link to={'/Leaves'} className="card-wrap">
              <div className="card-icon " style={{ backgroundColor: "#00bcd4" }}  >
                <i className="fas fa-circle" />
              </div>
                <div className="card-header">
                  <h4>Total Leaves</h4>
                </div>
                <div className="card-body">{totalLeaves && totalLeaves.total}</div>
              </Link>
            </div>
          </div> */}
          <div class="col-md-4">
            <div
              className="card card-statistic-1"
              style={{
                cursor: 'pointer',
                boxShadow: '0 3px 10px rgb(0 0 0 / 20%)'
              }}
            >
              <Link to={'/appraisal'} className="card-wrap">
              <div className="card-icon " style={{ backgroundColor: "rgb(227 132 15)" }}  >
                <i className="fas fa-circle" />
              </div>
                <div className="card-header">
                  <h4>Upcoming Appraisal</h4>
                </div>
                <div className="card-body">{appraisalcount}</div>
              </Link>
            </div>
          </div>
          {/* <div class="col-md-4">
            <div
              className="card card-statistic-1"
              style={{
                cursor: 'pointer',
                boxShadow: '0 3px 10px rgb(0 0 0 / 20%)'
              }}
            >
              <Link to={'/Leaves'} className="card-wrap">
              <div className="card-icon " style={{ backgroundColor: "#00bcd4" }}  >
                <i className="fas fa-circle" />
              </div>
                <div className="card-header">
                  <h4>Pending Leaves</h4>
                </div>
                <div className="card-body">{pendingleaves && pendingleaves.Pending}</div>
              </Link>
            </div>
          </div> */}
        </div>
    </div>
    </Spin>
  );
};

export default withRouter(Stats);
