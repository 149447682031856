import Header from "../../Constant/Header/Header"
import React, { forwardRef, useEffect, useState } from 'react'
import MainSideBar from '../../Constant/MianSideBar/MainSideBar'
import { Button, Popconfirm, Spin, Tooltip, notification } from 'antd'
import SectionHeader from "../../Constant/SectionHeader/SectionHeader"
import { PlusSquareOutlined, EditOutlined, } from '@ant-design/icons';
import { useHistory } from "react-router-dom"

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table"
import moment from "moment"
import ApiClient from "../../methods/api/apiClient"

const Festivals = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [festivaldata, setfestivaldata] = useState()
    const [filter, setfilter] = useState({ page: 1, count: 10000000000, sortBy: '' })
    useEffect(()=>{
        getfestivals()
    },[])

    const Addfestival = () => {
       
        history.push("/Addfestivals" )
    }
    const getfestivals = (p = {}) => {
        let payload = { ...filter, ...p }
        setLoading(true)
        ApiClient.get(`holidaylist`,payload).then(res => {
            if (res.success) {
                setfestivaldata(res.data)
            }
            setLoading(false)
        })
    }
    const deleterecord=(item)=>{
        let payload={
            id:item
        }
        setLoading(true)
        ApiClient.delete(`holiday`,payload).then(res=>{
            if(res.success){
                notification.success({message: 'Festival Deleted'})
            }
            getfestivals()
            setLoading(false)
        })
    }
    const editfest=(item)=>{
        history.push(`/Addfestivals/${item}`)
    }










    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
        )),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
        )),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => (
            <ArrowDownward {...props} ref={ref} />
        )),
        ThirdStateCheck: forwardRef((props, ref) => (
            <Remove {...props} ref={ref} />
        )),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    };

    const columns = [
        {
            title: "Start Date",
            dataIndex: "start_date",
            field: "start_date",
            render: (text, record) => (
                <span>{moment(text.start_date).format("DD MMM YY")}</span>
            )
        },
        {
            title: "End Date",
            dataIndex: "end_date",
            field: "end_date",
            render: (text, record) => (
                <span>{moment(text?.end_date).format("DD MMM YY")}</span>
            )
        },
        {
            title: "Event",
            dataIndex: "event",
            field: 'event'
        },
        {
            title: "Actions",
            dataIndex: "actions",
            field: "actions",
            render: (text, record) => (
                <div
                    style={{
                        display: "flex",
                        // justifyContent: 'space-evenly',
                        maxWidth: "118px",
                    }}
                >
                    <span
                        className="edit_icon"
                        style={{
                            verticalAlign: "text-bottom",
                        }}
                    >
                        <Tooltip title="Edit">
                            <Button
                                type="primary"
                                className="edit_btn"
                                onClick={e=>editfest(text._id)}
                            >
                                {<EditOutlined />}
                            </Button>
                        </Tooltip>
                    </span>
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure you want to Delete?"
                            onConfirm={() => deleterecord(text._id)}
                            onCancel={() => console.log("Cancled")}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="deletebtn" className='deletebtn ml-2' >
                                <i class="fa-solid fa-trash"></i>
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </div>

            ),
        },
    ]
    const newlyAddedData = festivaldata ? festivaldata.map((items) => {
        return {
            ...items,
        };
    }) : [];
    return (
        <>
            <>
                <div>
                    <div className="main-wrapper" id="mainPannel">
                        <div className="navbar-bg" />
                        <Header />
                        <MainSideBar />
                        <div className="main-content">

                            <section className='section'>
                                <SectionHeader title='Holidays' />
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}>
                                    <Tooltip title="Add Holidays">
                                        <Button type="primary"
                                            onClick={Addfestival}
                                            shape="circle"
                                            icon={<PlusSquareOutlined />}
                                            size={'large'}
                                            className="btn btn-primary profile"
                                        />
                                    </Tooltip>
                                </div>
                            </section>
                            <div>
                                <Spin spinning={loading}>
                                    <div className="">
                                        <MaterialTable
                                            options={{
                                                showTitle: false,
                                                pageSize: 10,
                                                pageSizeOptions: [10, 20, 30, 40, 50],
                                                emptyRowsWhenPaging: false,
                                                draggable:false
                                            }}
                                            columns={columns}
                                            data={newlyAddedData}
                                            icons={tableIcons}
                                        />

                                    </div>
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div>

            </>

        </>
    )
}

export default Festivals
