import React, { useEffect, useState,forwardRef } from 'react';
import { Link } from "react-router-dom";

import { deleteProject, deleteRoles, getPlatforms, getProjects, getRoles } from '../../Api/user/userapi';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Table, Spin, Tooltip, Popconfirm, message, Button, notification } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import {
    DeleteOutlined,
    EditOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import Modal from 'antd/lib/modal/Modal';
// import EditProject from './EditProject';
import Header from '../../Constant/Header/Header';
import EditRoles from './EditRoles';
import AddRoles from './AddRoles';
import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


const ids = localStorage.getItem('rolesid');

const AllRoles = (props) => {
    const [array, setArray] = useState([])
    const [id, setId] = useState();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editChapterData, setEditChapterData] = useState({});
    const [loading, setLoading] = useState(false);




    console.log(props, 'array');
    useEffect(() => {
        userDetails();
    }, [])
    const userDetails = async () => {
        try {
            setLoading(true);

            const resp = await getRoles();
            if (resp && resp.status === 200) {
                const list = resp;

                console.log(list, 'listasas');
                const arrayData = list.data.data;
                setArray(arrayData)

            }
        } catch (e) {
            console.log(e);
        }
        setLoading(false);

    };


    const chapterIdHandle = (id) => {
        // setCourseId(id);
        console.log('click');
    };

    const showEditModalHandle = (data) => {
        setIsEditModalVisible(true);
        setEditChapterData(data)
        setId(data._id); 
        console.log('click');
    };


    const removeChapterHandle = async (id) => {
        console.log(id, "delete");
        const Payload = {
            id: id,
            model: "roles"
        }

        // setId(editID)
        console.log(Payload, 'Payload');
        try {
            const resp = await deleteRoles(Payload);
            if (resp && resp.status === 200) {
                setTimeout(() => {
                    notification.success({
                        message: 'Deleted Successfully.',
                    });
                }, 1000);
                // message.success("deleted successfully");
                // showChapter();
                userDetails();
            }
        } catch (e) {
            console.log(e);
            // message.error("Role note Deleted..");
            setTimeout(() => {
                notification.error({
                    message: 'Role Not Deleted..',
                });
            }, 1000);

        }
    };

    function cancelDeleteHandle(e) {
        message.error("Cancel Deleting");
    }
    function removeRender(e) {
        message.success("Deleting....");
    }
    const handleCancel = () => {
        // setIsModalVisible(false);
        setIsEditModalVisible(false);
        // setModalVisible(false)
        // message.error("Chapter is not deleted!");

    };
    function confirm(e) {
        console.log(e);
        message.success('Deleting...');
    }

    function cancel(e) {
        console.log(e);
        // message.error('Data is not deleted');
    }


    const columns = [ 
        {
            title: "Name",
            dataIndex: "name",
            field: "name",
        }, 
        {
            title: "Actions",
            dataIndex: "actions",
            field: "actions",
            render: (text, record) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        maxWidth: '118px'
                    }}
                >
                    <span className="edit_icon"
                        style={{
                            verticalAlign: 'text-bottom'
                        }}>
                        <Tooltip placement="left" title="Edit">

                            <Button type="primary" className="edit_btn">

                                {<EditOutlined onClick={() => showEditModalHandle(text)} />}
                            </Button>
                        </Tooltip>
                    </span>
                    <span>
                        <Tooltip  placement="rightTop" title="Delete">
                            <Popconfirm
                                title="Are you sure you want to delete?"
                                onConfirm={() => removeChapterHandle(text._id)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" className="button delete_btn">
                                    <DeleteOutlined
                                    />
                                </Button>
                            </Popconfirm>
                        </Tooltip>
                    </span>
                </div>
            ),
        },
    ];



    const newlyAddedChapter =
        array && array.length
            ? array.map((items) => {
                console.log(items.id, 'asdas');
                localStorage.setItem('rolesid', items.id);
                return {
                    ...items,
                };
            })
            : [];

    const renderSubmit = () => {
        props.history.push('/addRoles')
    }

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };

    return (
        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">
                <section className="section">
                    <SectionHeader title="Roles" />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        // marginRight: '55px',
                    }}>
                        <Tooltip title="Add Role">
                            <Button type="primary"
                                onClick={renderSubmit}
                                shape="circle" icon={<PlusSquareOutlined />}
                                size={'large'}
                                className='btn btn-primary profile'
                            />
                        </Tooltip>
                    </div>
                    <Spin spinning={loading}>
                    <MaterialTable
                           options={{
                            pageSize:10,
                            pageSizeOptions:[10,20,30,40,50], 
                             emptyRowsWhenPaging: false,
                             showTitle:false,
                             draggable:false
                       }}   
                        //   title="Roles"
                       columns={columns}
                       data={newlyAddedChapter}
                       icons={tableIcons}
                   />
                        {/* <Table columns={columns} dataSource={newlyAddedChapter} pagination={{
                            defaultPageSize: 5,
                            showSizeChanger: true,
                            pageSizeOptions: ["5", "10", "15"],
                        }} /> */}
                    </Spin>
                </section>
            </div>
            <Modal
                footer={null}
                title="Edit Role"
                visible={isEditModalVisible}
                onCancel={handleCancel}
            >
                <div className="crossicon_class" >
                    <button onClick={()=>handleCancel()} className="btn " tabindex="-1" type="button" aria-label="Clear Search"><span class="MuiIconButton-label"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" aria-label="clear"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></span></button>
                </div>
                <EditRoles
                    chapterId={id}
                    setIsEditModalVisible={setIsEditModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                    onCancel={handleCancel}
                />
            </Modal>
        </div>
    );
};

export default AllRoles;