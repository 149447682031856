import React, { useEffect, useState, forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Table, Spin, Modal, Tooltip, message, Button, Select, notification, Popconfirm } from 'antd';
import {PlusSquareOutlined, DeleteOutlined,EditOutlined,} from '@ant-design/icons';
import { deleteRoles, getUsers, getUsersdata, updateStatusUser, userdelete } from '../../Api/user/userapi';
import { Link, useHistory } from 'react-router-dom';
import { useSidebar } from '../../Provider/Provider';
import { Switch } from 'antd';
import MaterialTable from 'material-table' 
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ApiClient from '../../methods/api/apiClient';
import axios from 'axios';
const { Option } = Select;
export default function Categories() {
    const [array, setArray] = useState([])
    const [id, setId] = useState();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editChapterData, setEditChapterData] = useState({});
    const [loading, setLoading] = useState(false);
    const [detailId, setDetailId] = useState();
    const history = useHistory()
    const { setCourseId, setChaptersId, courseId } = useSidebar();

    useEffect(() => {
        getcategory();
    }, [])
    const getcategory = async () => {
        try {
            setLoading(true);
            setLoading(true);
            ApiClient.get("allcategories").then(res=>{
                setArray(res.data);
                setLoading(false);
            })

            // }
        } catch (e) {
            setLoading(false)
            localStorage.clear();
            history.push("/")
            setTimeout(() => {
                notification.error({
                    message: 'Unauthorized',
                    description: 'Your Session Has Expired. Please logIn.',
                });
            }, 1000);
        } 
    };

    const handleCancel = () => {
        setIsEditModalVisible(false);
    };

    const showEditModalHandle = (id) => {
        history.push(`/addeditcategory/${id}`)
    };

 


    // Delete Category recode
    const deleterecord = (id) => {
    ApiClient.delete(`category?id=${id}`).then(res=>{
        notification.success({message:"Category deleted Successfully"})
        getcategory();
    }).catch(err=>{notification.error({message:"Some error occurred"})})
    }
 

    const columns = [
        {
            title: "Category-Type",
            dataIndex: "cat_type",
            field: "cat_type",
        },
        {
            title: "Category-Name",
            dataIndex: "name",
            field: "name",
            render:(text)=>(

                <span className='text-capitalize'>{text.name}</span>
            )
            
          
        },
        {
            title: "Actions",
            dataIndex: "actions",
            field: "actions",
            render: (text, record) => (
                <div
                    style={{
                        display: 'flex',
                        // justifyContent: 'space-evenly',
                        maxWidth: '118px'
                    }}
                >
                    <span className="edit_icon"
                        style={{
                            verticalAlign: 'text-bottom'
                        }}>
                        <Tooltip title="Edit">
                            <Button type="primary" className="edit_btn" onClick={e => showEditModalHandle(text && text.id)}>
                                {<EditOutlined />}
                            </Button>
                        </Tooltip>
                    </span>
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure you want to Delete?"
                            onConfirm={() => deleterecord(text.id)}
                            onCancel={() => console.log("Cancled")}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type=" deletebtn" className='ml-2' >
                                <i class="fa-solid fa-trash"></i>
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const newlyAddedChapter =
        array && array.length
            ? array.map((items) => {
                localStorage.setItem('rolesid', items.id);
                return {
                    ...items,
                };
            })
            : [];

    const renderSubmit = () => {
         history.push('/addeditcategory')
    }

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    return (
        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">
                <section className="section">
                    <SectionHeader title="All Categories" />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <Tooltip title="Add Category">
                            <Button type="primary"
                                onClick={renderSubmit}
                                shape="circle"
                                icon={<PlusSquareOutlined />}
                                size={'large'}
                                className="btn btn-primary profile"
                            />
                        </Tooltip>
                    </div>
                    <Spin spinning={loading}>
                        <MaterialTable
                           options={{
                            showTitle:false,
                            pageSize:10,
                            pageSizeOptions:[10,20,30,40,50], 
                             emptyRowsWhenPaging: false,
                             draggable:false
                       }}   
                            columns={columns}
                            data={newlyAddedChapter}
                            icons={tableIcons}
                        />
                     
                    </Spin>
                </section>
            </div>
    
        </div>
    );
}
