import React, { useEffect, useState } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar'; 
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Tooltip, Popconfirm,
  Form,
  Input,
  Button,
  Typography,
  Col,
  Spin,
  message,
  Row,
  Select,
  notification
} from 'antd';
import {
  UserOutlined,
  AlignCenterOutlined,
} from '@ant-design/icons';
import { getEmployee, getProjects, getTeams, getUsers, projectAddTeam, userassignlist } from '../../Api/user/userapi';
import ApiClient from '../../methods/api/apiClient';


const { Option } = Select;

const ProjectAssign = (props) => {

  const [loading, setLoading] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const [id, setId] = useState('');

  console.log(id,"newiddata");
  const [idTeam, setIdTeam] = useState('');
  const [array, setArray] = useState([]);
  const [arrayTeam, setArrayTeam] = useState([]);
  const [memberarray,setmemberarray] = useState([]);

  console.log(idTeam, 'idTeam');
  // function handleChange(values) {
  //   console.log(`selected ${values}`);
  //   setIdTeam(`${values}`)
  // }
  useEffect(() => {
    userDetails();
}, [])

const userDetails = async () => {
    try {
        setLoading(true);

        const resp = await getUsers();
        if (resp && resp.status === 200) {
            const list = resp;
            const arrayData = list.data.data;
            const newarraydata=arrayData.filter((item)=>{
              return item.role!="HR" && item.role!="subadmin"
          })
          setArrayTeam(newarraydata) 
          setLoading(false);
        }
        else{
          setLoading(false)
        }
    } catch (e) {
      setLoading(false)
      localStorage.clear();
      props.history.push("/")
        setTimeout(() => {
            notification.error({
              message: 'Unauthorized',
              description: 'Your Session Has Expired. Please logIn.',
            });
          }, 1000);
    } 

};

const employeechange=(event)=>{
  console.log(event,"data thats we need")
  setmemberarray(event)
}
const [memberdata,setmemberdata]=useState([]);

  async function handleChange1 (value) {
    const id=value.split(",")
setLoading(true);
    console.log(`valueid thats we need,${id[0]}`);
    setId(`${id[0]}`)
    try {
      const resp = await userassignlist(id[0]);
      if (resp && resp.status === 200) {
        const newdata=resp.data.data;
        setmemberdata(newdata.members);
//         const memberodata=newdata.members;
//         console.log(arrayTeam,"The array data thats role checking")
//         console.log(memberodata,"THe data that are about assinged employee")
//        const filtereddata= arrayTeam.filter(s=>{memberodata.some(c=>{
// console.log(c==s.id,"THis is c .id")
//        })})
//        console.log(filtereddata,"The employee listing without that")
        setLoading(false);


      }
    } catch (e) {
      setLoading(false)
      console.log(e);
    }

  }
// For deleting the member from the assign project
const handleDelete=(empid)=>{
  ApiClient.get(`project/member/remove?id=${id}&members=${empid}`).then(res=>{
    notification.success({message:"Developer Removed Successfully"})
    handleChange1(id)
  }).catch(err=>notification.error({message:"Some error occurred. Please try again."}))

}


  const handleSubmit = async (e) => {
    
    try { 
      const data=memberarray.split(","); 
      setLoading(true);
      const userResp = await projectAddTeam(id, data[0]);
      console.log(userResp, 'res');
      form.resetFields();
      // props.history.push('/allProject')
      setTimeout(() => {
        notification.success({
          message: 'Project Assigned Successfully.',
        });
        setmemberdata([])
      }, 1000);
    } catch (e) {
      setTimeout(() => {
        notification.error({
          message: 'Project not Assigned..',
        });
      }, 1000);
      console.log(e, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProject();
    // getTeam()
 
  }, []);

  const getProject = async () => {
    try {
      const resp = await getProjects();
      if (resp && resp.status === 200) {
        const list = resp;
        const listing = list.data.data;
        // Not to show the HR 
        const newlisting=listing.filter((item)=>{
          return item.role!="HR"
        })
        setArray(newlisting) 
      }
    } catch (e) {
      console.log(e);

      setTimeout(() => {
        notification.error({
          message: 'Unauthorized',
          description: 'Your Session Has Expired. Please logIn.',
        });
      }, 1000);
    }
  };

  const getTeam = async () => {
    try {
      const resp = await getEmployee();
      if (resp && resp.status === 200) {
        console.log(resp, 'showRoles');
        // const list = resp;
        // console.log(list.data.data, 'teamdata');
        // const listing = list.data.data
        // setArrayTeam(listing)

      }
    } catch (e) {
      console.log(e);
    }
  };


  const userassign = async () => {
    try {
      const resp = await userassignlist(id);
      if (resp && resp.status === 200) {
     console.log(resp,"allcommentdata");
    
        // localStorage.setItem('listing', JSON.stringify(list.data.data));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [form] = Form.useForm();
  return (
    <div>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg"/>
        <Header/>
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title="Assign Projects" />
          <div className='container'>
            <Spin spinning={loading}>
              <div >
                {loginErrorMsg && message.error(loginErrorMsg)}
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={handleSubmit}
                  form={form}
                >
                  <div className="row" >
                    <div  span={12} className=" assingproject col-sm-12 col-md-8  mx-auto">
                      <Form.Item
                        name="project"
                        rules={[
                          { required: true, message: 'Please Select Project!' },
                        ]}
                      >
                        <Select style={{ width: '100%' }}
                        showSearch={true}
                          placeholder="Select Project..."
                          onChange={handleChange1}>
                          {array && array.map(item => (
                            <Option key={[item.id,item.name]} >{item.name}</Option>

                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item 
                        name="member"
                        rules={[
                          { required: true, message: 'Please Select your Employees' },
                        ]}
                      >
                        <Select 
                          //  mode="multiple"
                          showSearch={true}
                           isSearchable={true}
                          style={{ width: '100%' }}
                          placeholder="Select Employee"
                          onChange={employeechange}
                        > 
                          {arrayTeam && arrayTeam.map(item => (
                            // console.log(item.fullName,"dsfdsfdsfds"),
                            <Option key={[item.id,item.fullName]} >{item.fullName}{`     (${item.designation})`}</Option>
                          ))}
                        </Select> 
                      </Form.Item> 
                      {memberdata.map((value,index)=>(
                          <span className='pl-1 pt-3'>{index+1}.{value.firstName}({value.designation})
                                  <Tooltip title="Delete">
                                  <Popconfirm
                                      title="Are you sure to Remove?"
                                      onConfirm={() => handleDelete(value.id)}
                                      onCancel={() => console.log("Cancled")}
                                      okText="Yes"
                                      cancelText="No"
                                  >
                         <Button style={{marginLeft:"-14px"}} className="bg-transparent border-0 " >  <i style={{color:"red",cursor:"pointer"}}  className='fa fa-trash ' ></i></Button>
                          </Popconfirm>
                          </Tooltip>
                          </span>
                      ))}
                      <br/>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button mt-4style mt-3 "
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                  {/* <Form.Item>
                    <div className="forgote" style={{
                      display: 'contents'
                    }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form.Item> */}
                </Form>
              </div>
            </Spin>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProjectAssign;