import React, { useEffect, useState, forwardRef } from "react";
import Header from "../../Constant/Header/Header";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import { Table, Spin, Select, message, notification } from "antd";
import { Button } from "antd";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  attendancce,
  datadates,
  getAttendace,
  getAttendaces,
  getCheckInAttendances,
} from "../../Api/user/userapi";
import moment from "moment";
import "./ManageAttandace.css";
import { API_URL } from "../../Constant";
import axios from "axios";
import { Link } from "react-router-dom";
import Singleuserlist from "./SingleuserAttendance";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ManageAttandace = () => {
  const [checkstatus, setcheckstatus] = useState("Check in");
  let today = new Date().toISOString().slice(0, 10);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [punchintime, setpunchtime] = useState();
  const [punchouttime, setpunchouttime] = useState();
  const [array, setArray] = useState([]);
  const [array1, setArray1] = useState([]);
  const [alldataa, setalldata] = useState([]);
  const [value, onChange] = useState(new Date());
  const [singleuserdata, setsingleuserdata] = useState();
  const [startdate, setstartdate] = useState(today);
  const [enddate, setendate] = useState(today);
  // const [lengthcheckin,setcheckinlength]=useState("");
  // const [lengthcheckout,setcheckoutlength]=useState("");

  // Convert TIme
  function ConvertTIme(timeString) {
    const [hourString, minute] = timeString.split(":");
    let newmin = "";
    if (minute.length <= 1) {
      newmin = "0" + minute;
    }
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + newmin + (hour < 12 ? " AM" : " PM");
  }

  // For Formatting the time which is coming from the Backend
  function formattime(userTime) {
    const [hours, minutes] = userTime.split(" ");
    if (minutes == null || undefined) {
      return `${hours}`;
    } else {
      return `${hours} ${minutes}`;
    }
  }

  const columns = [
    {
      title: "Full-Name",
      dataIndex: "name",
      field: "name",
    },
    // {
    //     title: "Check-In Date",
    //     dataIndex: "date",
    //     field: "date",
    //     render: (text, record) => (
    //         <>
    //             {moment(text?.checkIn).format("DD MMM YYYY")}
    //         </>
    //     ),
    // },
    // {
    //     title:"Check-Out Date",
    //     dataIndex:"checkOut",
    //     field:"checkOut",
    //     render:(text,record)=>(
    //         <>
    //          {moment(text?.checkOut).format("DD MMM YYYY")}
    //         </>
    //     )
    // },
    {
      title: "CheckIn",
      dataIndex: "checkIn",
      field: "checkIn",
      render: (text, record) => <>{moment(text.checkIn).format("hh:mm A")}</>,
    },
    {
      title: "CheckOut",
      dataIndex: "checkOut",
      field: "checkOut",
      render: (text, record) => <>{moment(text.checkOut).format("hh:mm A")}</>,
    },
    {
      title: "Attendance-Time",
      dataIndex: "totalTime",
      field: "totalTime",
      render: (text, record) => <>{formattime(text?.totalTime)}</>,
    },
    {
      title: "Action",
      dataIndex: "",
      field: "",
      render: (text, record) => (
        <>
          <Button
            onClick={(e) => AttendenceList(text.userId, text.currentDate)}
            className="btn btn-success"
          >
            Attendance List
          </Button>
        </>
      ),
    },
  ];
  // FOr single data ------------------
  const columns1 = [
    {
      title: "Full-Name",
      dataIndex: "name",
      field: "name",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Email",
      dataIndex: "email",
      field: "email",
      render: (text, record) => <>{text.email}</>,
    },
    {
      title: "Check In-Time",
      dataIndex: "checkIn",
      field: "checkIn",
      render: (text, record) => (
        <>{moment(text.checkIn).format("hh:mm:ss:A")}</>
      ),
    },
    // {
    //     title:"Check In-Date",
    //     dataIndex:"checkIn1",
    //     field:"checkIn1",
    //     render:(text,render)=>(
    //         <>
    //      {moment(text.checkIn).format("DD MMM YYYY")}
    //         </>
    //     )
    // }
  ];
  const newlyAddedChapter =
    array && array.length
      ? array.map((items) => {
          console.log(
            items,
            "This is the items data thats we need -----------------------------"
          );
          return {
            ...items,
          };
        })
      : [];

  const newlyAddedChapter1 =
    array1 && array1.length
      ? array1.map((items) => {
          return {
            ...items,
          };
        })
      : [];

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const token = localStorage.getItem("dataToken");

  const AttendenceList = (id, date) => {
    const newdato = moment(startdate).format("YYYY-MM-DD");
    const newstart = newdato == "Invalid date" ? today : newdato;
    const enddato = moment(enddate).format("YYYY-MM-DD");
    const newend = enddato == "Invalid date" ? today : enddato;
    history.push(`/singleuserattendance/${id}/${newstart}/${newend}`);
  };

  useEffect(() => {
    renderData();
    onChangeDate();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const renderData = async (newDate) => {
    onChange(newDate);
    const newDates = convert(new Date());
    try {
      const resp = await getAttendaces(newDates);
      if (resp && resp.status === 200) {
        const list = resp;
        const alldata = list.data.data;
        setArray1(alldata);
      }
    } catch (res) {
      localStorage.clear();
      history.push("/");
      setTimeout(() => {
        notification.error({
          message: "Unauthorized",
          description: "Your Session Has Expired. Please logIn.",
        });
      }, 1000);
    }
  };

  const handlecheckin = () => {
    setcheckstatus("Check in");
  };
  const handlecheckout = () => {
    setcheckstatus("Check out");
  };
  const onChangeDate = async (newDate) => {
    onChange(newDate);
    const newDates = convert(newDate);
    setstartdate(newDates);
    setendate(newDates);
    try {
      setLoading(true);
      const resp = await getAttendace(today);
      if (resp && resp.status === 200) {
        const list = resp;
        // const arrayData = list.data.data;
        setArray(list.data.data);
      }
    } catch (res) {
      setArray("");
    }
    setLoading(false);
  };

  const onChangeDatesss = async (newDate) => {
    onChange(newDate);
    const startDate = convert(newDate[0]);
    const endDate = convert(newDate[1]);
    setstartdate(startDate);
    setendate(endDate);
    console.log(newDate, "Here is the Start Date & End Date");
    console.log(startDate, "Start Date");
    console.log(endDate, "End Date");
    try {
      setLoading(true);
      getCheckInAttendances(startDate, endDate)
        .then((res) => {
          setArray1(res.data.data);
        })
        .catch((err) =>
          notification.error({
            message: "Some error occurred. Please try again",
          })
        );
      const resp = await datadates(startDate, endDate);
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        const newcheckouttim = moment(arrayData.checkOut).format("HH:MM");
        setpunchouttime(newcheckouttim);
        const checkintime = moment(arrayData.checkIn).format("HH:MM");
        setpunchtime(checkintime);
        setArray(arrayData);
      }
    } catch (res) {
      setArray("");
    }
    setLoading(false);
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <div>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <Spin spinning={loading}>
          <section className="section">
            <SectionHeader title="Manage Attendance" />
            <div className="d-flex justify-content-between">
            <div className="main_div mb-4">
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Select Date
                </button>
                <div class="dropdown-menu"   aria-labelledby="dropdownMenuButton" onClick={(event)=>event.stopPropagation()}>
                
                  <Calendar
                    maxDate={new Date()}
                    // activeStartDate={new Date()}
                    selectRange={true}
                    onChange={onChangeDatesss}
                    value={value}
                    className="calender"
                  />
                </div>
              </div>
            </div>
          <div>
            <button
              type="button"
              className={`mr-2 btn btn-${
                checkstatus == "Check in" ? "success" : "danger"
              }`}
              onClick={handlecheckin}
            >
              Checked in List
            </button>
            <button
              type="button"
              className={`btn btn-${
                checkstatus != "Check in" ? "success" : "danger"
              }`}
              onClick={handlecheckout}
            >
              Checked Out List
            </button>
            </div>
            </div>
            {startdate == "NaN-aN-aN" ? (
              ""
            ) : (
              <div className="mb-3">
                ({startdate} To {enddate})
              </div>
            )}
            {checkstatus == "Check in" ? (
              <MaterialTable
              options={{
                pageSize:10,
                pageSizeOptions:[10,20,30,40,50], 
                 emptyRowsWhenPaging: false,
                 showTitle:false,
                 draggable:false
           }}   
                title="Attendance List"
                columns={columns1}
                data={newlyAddedChapter1}
                icons={tableIcons}
              />
            ) : (
              <MaterialTable
              options={{
                pageSize:10,
                pageSizeOptions:[10,20,30,40,50], 
                 emptyRowsWhenPaging: false,
                 showTitle:false,
                 draggable:false
           }}   
                title="Attendance List"
                columns={columns}
                data={newlyAddedChapter}
                icons={tableIcons}
              />
            )}
          </section>
        </Spin>
      </div>
    </div>
  );
};

export default ManageAttandace;
