import React, { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { updatePlatform } from "../../Api/user/userapi";


const { TextArea } = Input;

function EditPlatform(props) {
  const { setIsEditModalVisible, editChapterData, chapterId, showData } = props;

  const [isEdit, setIsEdit] = useState(false);

  const [courseValue, setCourseValue] = useState({
    name: "",
  });

  const [form] = Form.useForm();

  const inputHandle = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    console.log(value, 'evet');

    setCourseValue({
      ...courseValue,
      [name]: value,
    });
  };

  useEffect(() => {
    if (editChapterData) {
      setCourseValue({
        name: editChapterData.name,

      });
      console.log(editChapterData, "editChapterData");
    }
  }, [editChapterData]);

  const editChapterHandle = async () => {
    setIsEdit(true);
    const payload = {
        name: courseValue.name,
    };
    console.log(payload, 'payload');
    try {
      const resp = await updatePlatform(chapterId, payload);
      if (resp && resp.status === 200) {
        showData();
        message.success("updated successfully.");
        setIsEditModalVisible(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div>
        <Form onFinish={editChapterHandle} form={form}>
        <Form.Item label="Name">
            <Input
              placeholder="Enter Name"
              name="name"
              value={courseValue.name}
              onChange={inputHandle}
              required
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" block>
              Update Platform
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default EditPlatform;
