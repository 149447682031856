import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, notification } from "antd";
// import {  updateCourses } from "../../Api/Courses/course";
import { updateKnowledge, updateProject, updateRoles } from "../../Api/user/userapi";
import { useHistory } from "react-router-dom";


const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 18,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 18,
    },
    sm: {
      span: 16,
    },
  },
};

function EditKno(props) {
  const history=useHistory()
  const { setIsEditModalVisible, editChapterData, chapterId, showData } = props;
  const [isEdit, setIsEdit] = useState(false);

  const [courseValue, setCourseValue] = useState({
    name: "",
    description: ""
  });

  console.log(courseValue,"courseValue");

  const [form] = Form.useForm();

  const inputHandle = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    console.log(value, 'evet');
    setCourseValue({
      ...courseValue,
      [name]: value,
    });
  };

  useEffect(() => {
    if (editChapterData) {
      setCourseValue({
        name: editChapterData.name,
        description: editChapterData.description,
      });
      console.log(editChapterData, "editChapterData");
    }
  }, [editChapterData]);

  const editChapterHandle = async () => {
    setIsEdit(true);
    const payload = {
      name: courseValue.name,
      description: courseValue.description,

    };
    console.log(payload, 'payload');
    try {
      const resp = await updateKnowledge(chapterId, payload);
      if (resp && resp.status === 200) {
        showData();
        setTimeout(() => {
          notification.success({
            message: 'Knowledge',
            description: 'Updated Successfully.',
          });
        }, 1000);
        // message.success("updated successfully.");
        setIsEditModalVisible(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const closemodal=()=>{
    props.onCancel()
  }

  return (
    <>
      <div className="w-100 m-0">
        <Form onFinish={editChapterHandle} form={form}
          {...formItemLayout}
      >
          <Form.Item label="Topic-Name"
            // name="name"
            // rules={[{ required: true, message: 'Please Enter Topic-Name!' }]}
          >
            <Input
              placeholder="Enter Topic-Name"
              name="name"
              // placeholder={courseValue.name}
              value={courseValue.name}
              onChange={inputHandle}
              required
            />
          </Form.Item>
          <Form.Item label="Description"
            //  name="description"
            //  rules={[{ required: true, message: 'Please Input Project Name!' }]}
          >
            <Input
              placeholder="Enter Description"
               name="description"
              value={courseValue.description}
              onChange={inputHandle}
              required
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 8,
                offset: 10,
              },
            }}
          >

          <div className="forgote d-flex justify-content-between">
          <button type="button" className="bg-danger" onClick={e=>closemodal()}>Close</button>
            <Button type="primary" htmlType="submit" className='login-form-button'>
              Update Knowledge
            </Button>
            </div>
          </Form.Item>
        </Form>
        
      </div>
    </>
  );
}

export default EditKno;
