import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";
import { Button, Spin, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useSidebar } from '../../Provider/Provider';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { getProjectdetails, getsingleleave } from '../../Api/user/userapi';
import moment from 'moment';

const LeaveDetails = (props) => {
  const [names, setName] = useState()
  const [desc, setDescription] = useState()
  const [frontTech, setFrontTechnology] = useState()
  const [backTech, setBackTech] = useState()
  const [clientNames, setClientName] = useState()
  const [status, setStatus] = useState("----------")
  const [type, setType] = useState("-------")
  const[startTime,setstartTime]=useState();
  const [LeaveStatus, setLeave] = useState();
  const [reason, setreason] = useState()
  const [loading,setloading]=useState(false);
  const [endTime,setendTime]=useState();

  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

const courseid = localStorage.getItem("courseid")
  useEffect(() => {
    getProject();
  }, []);
  // For converting the simple date into 12 hr format --------------------------------------------------------------------------------
  function formatTime(timeString) {
    const [hourString, minute] = timeString.split(":");
    let newmin="";
    if(minute.length<=1){ newmin="0"+minute
    }
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + newmin + (hour < 12 ? " AM" : " PM");
  }

  const getProject = async (i) => {
    setloading(true);
    const Id  = localStorage.getItem("leaveidparticular")
    try {
      const resp = await getsingleleave(Id);
      if (resp && resp.status === 200) {
        const list = resp; 
        setName(list.data.data.employeeName);
        setDescription(list.data.data.description);
        setFrontTechnology(list.data.data.fromEmail);
        setBackTech(list.data.data.toEmail);
        setClientName(list.data.data.subject);
        setStatus(list.data.data.leaveStartDate);
        setType(list.data.data.leaveEndDate);
        setLeave(list.data.data.leave_status);
        setloading(false);
        let strt=formatTime(list.data.data.leaveStarttime)
        setstartTime(strt);
        setreason(list.data.data.description)
        let end=formatTime(list.data.data.leaveEndtime)
        setendTime(end);
      }
    } catch (e) {
      setloading(false);
    }
  }; 

  const renderSubmitBack = () => {
    props.history.push('/Leaves')
  }
  return (
    <div className="text-left">
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <Spin spinning={loading}>
        <section className="section">
          <SectionHeader title="Leave Details" />
          <div>
            <Tooltip title="Back Button">
              <Button type="primary"
                onClick={renderSubmitBack}
                shape="circle"
                icon={<ArrowLeftOutlined />}
                size={'large'}
                className="btn btn-primary profile"
              />
            </Tooltip>
          </div>
          <div className="container pl-0">
            <div className="shadow p-3 w-100 d-flex">
              <div class="row">
                <div class="col-md-4">
                  <label className="text-bold">Employee-Name:</label>
                  <span > {names}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">subject:</label>
                  <span> {clientNames}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Leave-Start-Date:</label>
                  <span> {moment(status).format('DD MMM YYYY')}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Leave-End-Date:</label>
                  <span>{moment(type).format('DD MMM YYYY')}</span>
                </div>
                {clientNames=="half day Leave"||clientNames=="short Leave"?<><div class="col-md-4">
                  <label className="text-bold">Start-Time:</label>
                  <span>{startTime}</span>
                </div><div class="col-md-4">
                  <label className="text-bold">End-Time:</label>
                  <span>{endTime}</span>
                </div></>:null}
                <div class="col-md-6 d-inline-flex">
                  <label className="text-bold">From-Email:</label>
                  <span className='mt-2'> {frontTech}</span>
                </div>
                <div class="col-md-8 d-inline-flex">
                  <label className="text-bold ">To-Email:</label>
                  <span className='mt-2'> {backTech}</span>
                </div>
                <div class="col-md-12 d-flex">
                  <label className="text-bold">Reason:</label>
                  <span className='mt-2'>{htmlToReactParser.parse(reason)}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        </Spin>
      </div>
    </div>
  );
};

export default LeaveDetails;
