import React from 'react';

const Singleuserdailytask = () => {
    return (
       <>
       hello 
       </>
    );
};

export default Singleuserdailytask;