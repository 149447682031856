import React, { useEffect, useState } from 'react';
import { deletePlatform, getPlatforms } from '../../Api/user/userapi';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Table, Modal, Popconfirm, Button, message, Spin } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';

import {
    DeleteOutlined,
    EditOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import Header from '../../Constant/Header/Header';
import EditPlatform from './EditPlatform';



const AllPlatforms = (props) => {
    const [array, setArray] = useState([])
    const [id, setId] = useState()
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editChapterData, setEditChapterData] = useState({});
    const [loading, setLoading] = useState(false);


    console.log(id, 'state');

    useEffect(() => {
        userDetails();
    }, [])
    const userDetails = async () => {
        try {
            setLoading(true);

            const resp = await getPlatforms();
            if (resp && resp.status === 200) {
                const list = resp;

                console.log(list, 'listasas');
                const arrayData = list.data.data;
                setArray(arrayData)

            }
        } catch (e) {
            console.log(e);
        }
        setLoading(false);

    };


    const handleCancel = () => {
        // setIsModalVisible(false);
        setIsEditModalVisible(false);
        // setModalVisible(false)
        // message.error("Chapter is not deleted!");

    };

    const showEditModalHandle = (data) => {
        setIsEditModalVisible(true);
        setEditChapterData(data)
        setId(data._id);


        console.log('click');
    };

    const removeChapterHandle = async (id) => {
        console.log("delete");

        // setId(editID)
        try {
            const resp = await deletePlatform(id);
            if (resp && resp.status === 200) {
                message.success("deleted successfully");
                // showChapter();
                userDetails();
            }
        } catch (e) {
            console.log(e);
        }
    };

    function confirm(e) {
        console.log(e);
        message.success('Deleting...');
    }

    function cancel(e) {
        console.log(e);
        message.error('Data is not deleted');
    }


    const columns = [
        // {
        //   title: "Course",
        //   dataIndex: "course",
        //   key: "_id",
        //   render: (text, record) => (
        //     <>
        //       {record && record.course && record.course.name ? (
        //         <Link to={`/items/detail${record._id}`}>
        //           <span onClick={() => itemsIdHandle(record._id)}>
        //             {record.course.name}
        //           </span>
        //         </Link>
        //       ) : (
        //         "--"
        //       )}
        //     </>
        //   ),
        // },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        maxWidth: '118px'
                    }}
                >

                    <span className="edit_icon">
                        <Button type="primary" className="edit_btn">
                            {<EditOutlined onClick={() => showEditModalHandle(record)} />}
                        </Button>
                    </span>
                    <span>
                        <Popconfirm
                            title="Are you sure you want to delete?"
                            onConfirm={() => removeChapterHandle(record._id)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" className="button">
                                <DeleteOutlined
                                // onClick={() => removeChapterHandle(record._id)}
                                //   onCancel={cancelDeleteHandle}
                                />
                            </Button>

                        </Popconfirm>
                    </span>
                </div>
            ),
        },
    ];


    const newlyAddedChapter =
        array && array.length
            ? array.map((items) => {

                return {
                    ...items,
                };
            })
            : [];


    // setState({ state : items.id })

    const renderSubmit = () => {
        props.history.push('/platform')
    }
    return (
        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">

                <section className="section">

                    <SectionHeader title="All Platforms" />
                    <div className='plus_btn'>
                        <Button type="primary"
                            onClick={renderSubmit}
                            shape="circle" icon={<PlusSquareOutlined />}
                            size={'large'}
                            className="btn_form"
                        />

                    </div>
                    <Spin spinning={loading}>

                        <Table columns={columns} dataSource={newlyAddedChapter} pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "20"],
                        }} />
                    </Spin>

                </section>
            </div>
            <Modal
                footer={null}
                title="Edit Platform"
                visible={isEditModalVisible}
                onCancel={handleCancel}


            >
                <EditPlatform
                    chapterId={id}
                    setIsEditModalVisible={setIsEditModalVisible}
                    showData={userDetails}
                    editChapterData={editChapterData}
                />
            </Modal>
        </div>

    );
};

export default AllPlatforms;