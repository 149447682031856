import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Typography, message, Modal, notification, Spin } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import './forgot.css';
import { Link } from 'react-router-dom';
import { forgotApi } from '../../../Api/auth/signup';
import { TramRounded } from '@mui/icons-material';

const { Title } = Typography;

function ForgotPassword(props) {
  const  [loading,setloading] = useState(false)
  console.log(props, 'props');
  
  const handleSubmit = async (e) => {
    setloading(true)
    const payload = {
      email: e.email,
    };
    try {
      const response = await forgotApi(payload);
      if (response.status == 200) {
        console.log(response, 'rdadasdaes');
      
       setloading(false)
        props.history.push('/resetPassword')
        setTimeout(() => {
          notification.success({
            message: 'Code Sent On Your Email-Id',
            description: '',
          });
        }, 1000);
      } else {
        setloading(false)
        props.history.push('/forgotPassword')
        console.log(response, 'rdadasddadasdaes');
        setTimeout(() => {
          notification.error({
            message: 'This User Doesnot Exists',
          });
        }, 1000);
      }
    } catch (e) {
      setloading(false)
      console.log(e);
      setTimeout(() => {
        notification.error({
          message: 'This User Doesnot Exists',
        });
      }, 1000);
    }
  };
  useEffect(()=>{
    forgotApi()
    setloading(true)
    setTimeout(() => {
      setloading(false)
    }, 500);
  },[])
  return (
    // <Modal className="forgotModal" visible={true} footer={null}>
    <div className="container forgotwraper">
      <div className="row w-100 mx-auto
    ">
        <div className="col-md-6 jc_login  p-0">
          <div className="sign_in">
          <div className='loginsection'>
             
             <img src="/assets/img/Forgot password-cuate.png" className="forget " />
             </div>
            {/* <div className="logo_css">
            <div>
            <img src="/assets/imgpsh_fullsize_anim.png" className="login_logo" /><br></br>
          </div>

        </div> */}
            <div className=" forgot_jc ">
              <Title className="forgot_password_title" level={2}
                style={{
                  textAlign: 'center',
                  textTransform: 'capitalize'
                }}
              >

              </Title>
              {/* <h3 className="forgotpass">Forgot Password</h3> */}
            </div>
          </div>
        </div>
          <div className="col-md-6 login_jc shadow p-4">
        <Spin spinning={loading}>
            <Form
              name="email"
              className="login-form text-left"
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
            >
              <img src="/assets/imgpsh_fullsize_anim.png" className="login_logo" /><br></br>
              <h4 className="forgotpassclss text-center mt-2"><b>Forgot Password</b></h4>
              <Form.Item
                name="email"
                rules={[{ required: true, type: "email" , message: 'Enter valid Email!' }]}
              >
                {/* <label><b>Enter Email</b></label> */}
                <Input type="text" placeholder="Enter Email" />
              </Form.Item>
              <Form.Item>
                <div className='loginbtns'>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Submit
                  </Button>
                  <Link className="frogotlink mt-0" to={'/'}>Log In?</Link>
                </div>

              </Form.Item>
            </Form>
        </Spin>
          </div>
      </div>
    </div>

    // </Modal>







    ////

    // <Modal className="forgotModal" visible={true} footer={null}>
    //   <div className="sign_in">

    //   <div className="logo_css">
    //           <img src="assets/img/favicon1.ico" className="login_logo"/>

    //           </div>
    //     <Title className="forgot_password_title" level={2}
    //       style={{
    //         textAlign: 'center',
    //         textTransform: 'capitalize'
    //       }}
    //     >
    //       Forgot Password
    //     </Title>
    //     <Form
    //       name="email"
    //       className="login-form"
    //       initialValues={{ remember: true }}
    //       onFinish={handleSubmit}
    //     >
    //       <Form.Item
    //         name="email"
    //         rules={[{ required: true, message: 'Enter Email!' }]}
    //       >
    //         <Input type="text" placeholder="Enter Email" />
    //       </Form.Item>
    //       <Form.Item>
    //         <div className='container'>
    //           <Button
    //             type="primary"
    //             htmlType="submit"
    //             className="login-form-button"
    //           >
    //             Submit
    //           </Button>
    //           <Link className="frogotlink" to={'/'}>Log In?</Link>
    //         </div>

    //       </Form.Item>
    //     </Form>
    //   </div>
    // </Modal>
  );
}

export default ForgotPassword;
