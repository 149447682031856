import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Typography, Modal, Spin, message, Row, Col, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link,useHistory } from 'react-router-dom';
import './login.css'
import { useDispatch } from 'react-redux' 
import { saveState, saveUserId } from '../../localstorage/LocalStorage';
import { getRoles, loginApi } from '../../../Api/auth/signup';  

const { Title } = Typography;

let passwordError = '';
function Login(props) {

  const navigate=useHistory();

  // For Login Check 
  const check=()=>{
    const token=localStorage.getItem("dataToken");
    const role=localStorage.getItem("role");
    if(token){
      if(role=="admin"){
       navigate.push("/dashboard")
      }
      else if(role=="subadmin"){
        navigate.push("/hr_dashboard")
      }
    }
  }
  useEffect(()=>{
check()
  },[])

  const { history } = props;

  const [loading, setLoading] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const dispatch = useDispatch();

  const errorMsg = "Incorrect Email or Password. Please try again";

  const [newerror, setnewerror] = useState(
    'Incorrect Email or Password. Please try again'
  );

  const userName = localStorage.getItem('fullname');

  const handleSubmit = async (e) => { 
    const newemail=e.email;
    if(newemail.includes("@jcsoftwaresolution.com")){
      const payload = {
        email: e.email,
        password: e.password,
      };
    try {
      setLoading(true);
      const userResp = await loginApi(payload);
      localStorage.setItem('userimage',userResp.data.data.image);
      localStorage.setItem('userID', userResp.data.data.id);
      localStorage.setItem('username', userResp.data.data.fullName);
      const userToken = userResp.data.data.access_token;
      const data = {
        token: userToken,
      };
      console.log(data.token, 'data');
      localStorage.setItem('dataToken', data.token);
      localStorage.setItem('role', userResp.data.data.role)
      if (userResp.data.data.role === "admin") {
        console.log(userResp.data.data.role,"Role")
        console.log(userResp.data.message,"Toggle res")
        // dispatch((userResp.data.message))
        // ToastsStore.success(userResp.data.message)
        setTimeout(() => {
          history.push("/dashboard");
          notification.success({
            message: 'Login Successfully',
          });
        }, 1000);

      } else if (userResp.data.data.role == "subadmin") {

        history.push("/hr_dashboard");

        setTimeout(() => {
          notification.success({
            message: 'Login Successfully',
          });
        }, 1000);

      } else {
        history.push("/");
        setTimeout(() => {
          notification.error({
            message: 'Login Failed',
          });
        }, 1000);
      }

      // window.location.reload(false);
    } catch (e) {
      console.log(e, 'error');
      setTimeout(() => {
        notification.error({
          message: 'Wrong Credentials',
        });
      }, 1000);
    } finally {
      setLoading(false);
    }
  }
  else{
    notification.error({
      message:"Please enter a valid Email"
    })
  }
  };
  useEffect(()=>{
    loginApi()
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 500);
  },[])

  return (
    <div className='container loginwraper '>
  
          <div className="row w-100 m-auto">
            <div className="col-md-6 jc_login p-0">
              <div className='loginsection'>
             
              <img src="/assets/img/login.png" className="loginbg " />
              </div>
          
              <div>
              </div>


            </div>

            <div className="col-md-6 login_jc shadow p-3">

              <Spin spinning={loading}>
                <div className="sign_in">


                  {loginErrorMsg && message.error(loginErrorMsg)}
                  <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={handleSubmit}
                  >

                    <div className="col-md-12">
                       <img src="/assets/imgpsh_fullsize_anim.png" className="login_logo " /><br></br>
                      <h3 className="logintext text-center"><b>Log in</b></h3>
                    </div>
                    <Form.Item
                      name="email"
                      rules={[{ required: true, type: "email", message: 'Please Enter Valid Email!' }]}
                    >
                      <Input

                        // prefix={<UserOutlined className="site-form-item-icon form-control " />}
                        placeholder="Enter Email"
                      />
                      {/* <i class="fa-solid fa-user"></i> */}

                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: 'Please Enter Your Password!' },
                      ]}
                    >
                      <Input.Password
                        type="password" className="form-control"
                        maxLength={20}
                        placeholder="Enter Password"
                      />
                    </Form.Item>
                    <Form.Item>
                      <div className="loginbtns ">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="primary-btn submitbtn"
                        >
                          Log in
                        </Button>

                        <Link to={'/forgotPassword'} className="frogotlink ">
                          Forgot Password?
                        </Link>
                      </div>

                    </Form.Item>
                  </Form>

                </div>
              </Spin>

              {/* 
      </Modal> */}
              {/* <Modal className="loginModal" visible={true} footer={null}>
        <div className='container'>
          <Spin spinning={loading}>
            <div className="sign_in">
   
              <Title className="sign_in_title" level={2} style={{
                textAlign: 'center',
                textTransform: 'capitalize'
              }}>
                Log in
              </Title>
              {loginErrorMsg && message.error(loginErrorMsg)}
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
              >
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Please enter your Email!' }]}
                >
                  <label className="Username "><b>Username or Email Address</b></label>
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    // placeholder="Enter email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: 'Please enter your Password!' },
                  ]}
                >
                  <label className="text-left"><b>Password</b></label>
                  <Input.Password
                    type="password"
                    placeholder="Enter password"
                  />
                </Form.Item>
                <Form.Item>
                  <div className="container">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Log in
                    </Button>

                    <Link to={'/forgotPassword'} className="frogotlink mt-3">
                      Forgot Password?
                    </Link>
                  </div>

                </Form.Item>
              </Form>

            </div>
          </Spin>
        </div>

      </Modal>  */}

            </div>
          </div>
       

    </div>

  );
}

export default Login;
