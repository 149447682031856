import React, { useState } from 'react';
import { Form, Input, Button, Typography, notification, Spin } from 'antd';
import './reset.css';
import { Link } from 'react-router-dom';
import { changePassword } from '../../../Api/auth/signup';

const { Title } = Typography;

function ResetPassword(props) {
  const [loading,setloading] = useState(false)
  const handleSubmit = async (e) => {
    console.log('here we have this')
    if(e.newPassword!=e.confirmPassword){
      notification.error({
        message: "Confirm Password and New Password not match."
      });
      return
    }
    const payload = {
      code: e.code,
      newPassword: e.newPassword,
      confirmPassword: e.confirmPassword,

    };
    try {
      const response = await changePassword(payload);
      if (response.status == 200) {
        console.log(response, 'res');
        setloading(true)
      }
      setloading(false)
      props.history.push('/')
      setTimeout(() => {
        notification.success({
          message: 'Password Reset Successfully.',
        });
      }, 1000);
      // message.success({
      //   content: 'Password reset successfully.',
      //   style: {
      //     marginTop: '20vh',
      //   },
      // });
    } catch (e) {
      console.log(e);
      props.history.push('/resetPassword')
      setTimeout(() => {
        notification.error({
          message: 'Verification Code Wrong',
        });
      }, 1000);
      // message.error({
      //   content: 'Verification code wrong',
      //   style: {
      //     marginTop: '20vh',
      //   },
      // });
    }
  };
  return (

    <div className="container forgotwraper">
      <div className="row w-100 mx-auto
    ">
    <div className="col-md-6 jc_login  p-0">
          <div className="sign_in">
          <div className='loginsection'>
             
             <img src="/assets/img/resetpassword.png" className="forget " />
             </div>
            {/* <div className="logo_css">
            <div>
            <img src="/assets/imgpsh_fullsize_anim.png" className="login_logo" /><br></br>
          </div>

        </div> */}
            <div className=" forgot_jc ">
              <Title className="forgot_password_title" level={2}
                style={{
                  textAlign: 'center',
                  textTransform: 'capitalize'
                }}
              >

              </Title>
              {/* <h3 className="forgotpass">Forgot Password</h3> */}
            </div>
          </div>
          </div>
          <div className="col-md-6 login_jc shadow">
    <div className="forgotModal" visible={true} footer={null}>
      <div className="forgot_password">
      <img src="/assets/imgpsh_fullsize_anim.png" className="login_logo" /><br></br>
        <h3 className="logintext mt-2" level={2}
          style={{
            textAlign: 'center',
           
          }}
        >
         <b> Reset Password</b>
        </h3>
        <Spin spinning={loading}>

        <Form
          name="basic"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="code"
            rules={[{ required: true, message: 'Please Enter Code!' }]}
          >
            <Input type="text" placeholder="Enter code" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[{ required: true, message: 'Please Enter Your New Password!' }]}
          >
            <Input.Password type="text" minLength = {8} maxLength ={20} placeholder="Enter New Password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[{ required: true,  message: 'Please Enter Your Confirm Password!' }]}
          >
            <Input.Password type="text" minLength = {8} maxLength ={20} placeholder="Enter Confirm Password" />
          </Form.Item>
          <Form.Item>
            <div className="loginbtns">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button mb-2"
              >
                Submit
              </Button>
              <Link className="frogotlink" to={'/'}>Log In?</Link>
            </div>

          </Form.Item>
        </Form>
        </Spin>
      </div>
      
    </div>
    </div>
    </div>
    </div>

  );
}

export default ResetPassword;
