import React from 'react';

const SectionHeader = ({ title }) => {
 
  return (
    <div className="section-header">
      <h1>{title}</h1>
      </div>
  );
};

export default SectionHeader;
