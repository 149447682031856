import React, { useEffect, useState,forwardRef } from 'react';
import { Spin, Select, notification, Tooltip } from 'antd';
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import ApiClient from '../../methods/api/apiClient';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';

const { Option } = Select;

export default function SystemComplaint() {
  const [loading,setloading]=useState(false)
  const [dataa,setdata] = useState()
  const history = useHistory()
  const getdetails=()=>{
    setloading(true)
    ApiClient.get(`complains`).then(res=>{
      setdata(res.data)
      console.log(res.data);
      setloading(false)
    })
  }
  useEffect(()=>{
    getdetails()
  },[])

  const viewcomplaint=(id)=>{
    history.push(`viewsystemcomplaint/${id}`)
  }
  const data=dataa ? dataa.map(item=>{
    return {...item}
  }):[]

  const columns = [
    {
        title: "Employee Name",
        dataIndex: "employee_name",
        field: "employee_name",
        render: (data) => (
          <>
            <div onClick={e=>viewcomplaint(data.id)} className='text'>
          <Tooltip title="View">
                {data.employee_name}
          </Tooltip>
            </div>
          </>
        ),
    },
    {
        title: "Device Name",
        dataIndex: "deviceName",
        field: "deviceName",
        render: (data) => (
          <>{data.deviceName}</>
        ),
    },
    {
        title: "Date",
        dataIndex: "date",
        field: "date",
        render: (data) => (
          <>{moment(data.createdAt).format("DD MMM YYYY")}</>
        ),
    },
    {
        title: "Description",
        dataIndex: "description",
        field: "description",
        render: (data) => (
          <>{data.description}</>
        ),
    },
    {
        title: "Status",
        dataIndex: "status",
        field: "status",
        render: (data) => (
          <>
            <select className='w-50 p-2' value={data?.status} onChange={e=>statuschange(e.target.value,data.id)}>
              <option value="pending">Pending</option>
              <option value="completed">Done</option>
            </select>
          </>
        ),
    },
  ];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

  const statuschange=(e,id)=>{
    let payload={
      status:e,
      id:id
    }
    ApiClient.put(`complain`,payload).then(res=>{
      getdetails()
      notification.success({
        message:'Status Updated Successfully'
      })
    })
  }

  return (
    <div>
      <div>
        <div className="main-wrapper" id="mainPannel">
            <div className="navbar-bg" />
            <Header />
            <MainSideBar />
        </div>
        <div className="main-content">
            <section className="section">
                <SectionHeader title="System Complaints" />
                <Spin spinning={loading}>
                    <MaterialTable
                      options={{
                      pageSize:10,
                      pageSizeOptions:[10,20,30,40,50], 
                        emptyRowsWhenPaging: false,
                        showTitle:false,
                        draggable:false
                    }}   
                      columns={columns}
                      data={data}
                      icons={tableIcons}
                    />
                </Spin>
            </section>
        </div>
      </div>
    </div>
  )
}
