import React, { useEffect, useState } from 'react';
import { Button, Spin, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useSidebar } from '../../Provider/Provider';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { getProjectdetails } from '../../Api/user/userapi';
import moment from 'moment';
import { EditOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ProjectDetails = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');
  const [loading,setloading]=useState(false);
  const [figmaLink,setfigmaLink]=useState(null);
  const [names, setName] = useState()
  const [desc, setDescription] = useState()
  const [frontTech, setFrontTechnology] = useState()
  const [backTech, setBackTech] = useState()
  const [clientNames, setClientName] = useState()
  const [status, setStatus] = useState()
  const [type, setType] = useState()
  const [time,settime]=useState()
  const [dates, setDate] = useState()
  const [enddate,setenddate]=useState("")
  const [docs, setDocs] = useState()
  const { courseId } = useSidebar();

  const [frontendclonelink, setfrontendclonelink] = useState()
  const [frontendclonepassword, setfrontendclonepassword] = useState()
  const [adminclonelink, setadminclonelink] = useState()
  const [adminclonepassword, setadminclonepassword] = useState()
  const [apiclonelink, setapiclonelink] = useState()
  const [apiclonepassword, setapiclonepassword] = useState()
  const [frontendownerid, setfrontendownerid] = useState()
  const [adminownerid, setadminownerid] = useState()
  const [apiownerid, setapiownerid] = useState()
  const [apibranch, setapibranch] = useState()
  const [adminbranch, setadminbranch] = useState()
  const [frontendbranch, setfrontendbranch] = useState()
  const history = useHistory()

  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

  useEffect(() => {
    getProject();
  },[])

  const getProject = async (i) => { 
    const Id  = localStorage.getItem("courseid")
    setloading(true)
    try {
      const resp = await getProjectdetails(id);
      setloading(false);
      if (resp && resp.status === 200) {
        const list = resp;
        setName(list.data.data.name);
        setDescription(list.data.data.description);
        setFrontTechnology(list.data.data.frontendtechnology);
        setBackTech(list.data.data.backendtechnology);
        setClientName(list.data.data.clientName);
        setStatus(list.data.data.progress_status);
        setType(list.data.data.project_type);
        setDate(list.data.data.startDate);
        setenddate(list.data.data.endDate)
        setDocs(list.data.data.documents);
        settime(list.data.data.totaltime);
        setfrontendclonelink(list.data.data.frontend_clone_link)
        setfrontendclonepassword(list.data.data.frontend_clone_password)
        setadminclonelink(list.data.data.admin_clone_link)
        setadminclonepassword(list.data.data.admin_clone_password)
        setapiclonelink(list.data.data.backend_clone_link)
        setapiclonepassword(list.data.data.backend_clone_password)
        setfrontendownerid(list.data.data.frontend_owner_workspace_id)
        setadminownerid(list.data.data.admin_owner_workspace_id)
        setapiownerid(list.data.data.api_owner_workspace_id)
        setadminbranch(list.data.data.updated_branch_admin)
        setfrontendbranch(list.data.data.updated_branch_frontend)
        setapibranch(list.data.data.updated_branch_api)
        setfigmaLink(list.data.data.figmaLink)
      }else{setloading(false)}
      setloading(false);
    } catch (e) {
      setloading(false)
      console.log(e);
    }
  };
  const renderSubmitBack = () => {
    props.history.push('/allProject')
  }

  const hanldeview=()=>{
    props.history.push(`/projectassociation/list/${id}`)
  }

  const editdetail=()=>{
    history.push(`/createProject/${id}`)
  }
  return (
    <div className="text-left">
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title="Project Detail" />
          <div>
            <Tooltip title="Back Button">
              <Button type="primary"
                onClick={renderSubmitBack}
                shape="circle"
                icon={<ArrowLeftOutlined />}
                size={'large'}
                className="btn btn-primary profile"
              />
            </Tooltip>
          </div>
          <Spin spinning={loading}>
          <div className="container pl-0">
            <div className="shadow p-3 w-100 ">
            <h3 className='text-capitalize text-center heddingcl'><span className='text-bold prohead'>{names}</span>
              <Tooltip title="Edit">
                <Button type="primary" className="edit_btn edit-button" onClick={e=>editdetail()}>
                    {<EditOutlined />}
                </Button>
              </Tooltip>
            </h3>
              <div class="row mt-3 mt-5">
                <div class="col-md-4 mb-3">
                  <label className="text-bold">Frontend-Technology:</label>
                  <span className="datacls"> {frontTech}</span>
                </div>
                <div class="col-md-4 mb-3">
                  <label className="text-bold">Backend-Technology:</label>
                  <span className="datacls"> {backTech}</span>
                </div>
                <div class="col-md-4 mb-3">
                  <label className="text-bold">Client-Name:</label>
                  <span className="datacls">   {clientNames}</span>
                </div>
                <div class="col-md-4 mb-3">
                  <label className="text-bold">Total Time:</label>
                  <span className="datacls"> {time}</span>
                </div>
                <div class="col-md-4 mb-3">
                  <label className="text-bold">Start-Date:</label>
                  <span className="datacls"> {moment(dates).format('DD MMM YYYY')}</span>
                </div>   
                  <div class="col-md-4 mb-3">
                  <label className="text-bold">End-Date:</label>
                  <span className="datacls"> {moment(enddate).format('DD MMM YYYY')}</span>
                </div>
                <div class="col-md-4 mb-3">
                  <label className="text-bold">Project-Type:</label>
                  <span className="datacls"> {type}</span>
                </div>
  
                <div class="col-md-4 mb-3 d-inline-flex text-break">
                  <label className="text-bold ">Frontend Owner Workspace Id:</label>
                  <span className='datacls mt-2' >{frontendownerid}</span>
                </div>
                <div class="col-md-4 mb-3 d-inline-flex text-break">
                  <label className="text-bold">Admin Owner Workspace Id:</label>
                  <span className=' datacls mt-2'>{adminownerid}</span>
                </div>
                <div class="col-md-4 mb-3 d-inline-flex text-break">
                  <label className="text-bold">API Owner Workspace Id:</label>
                  <span className='datacls mt-2'>{apiownerid}</span>
                </div>
                <div class="col-md-4 mb-3">
                  <label className="text-bold">Admin Updated Branch:</label>
                  <span className="datacls">{adminbranch}</span>
                </div>
                <div class="col-md-4 mb-3">
                  <label className="text-bold">Frontend Updated Branch:</label>
                  <span className="datacls">{frontendbranch}</span>
                </div>
                <div class="col-md-4 mb-3">
                  <label className="text-bold">API Updated Branch:</label>
                  <span className="datacls">{apibranch}</span>
                </div>
               
                <div class="col-md-4 mb-3 d-inline-flex text-break">
                  <label className="text-bold">Frontend Clone Link:</label>
                  <span className='datacls mt-2'>{frontendclonelink}</span>
                </div>
                <div class="col-md-4 mb-3 d-inline-flex text-break pl-3">
                  <label className="text-bold d-inline-flex text-break">Frontend App Password:</label>
                  <span className='datacls d-inline-flex text-break mt-2'>{frontendclonepassword}</span>
                </div>
                <div class="col-md-4 mb-3 d-inline-flex text-break">
                  <label className="text-bold">Admin Clone Link:</label>
                  <span className=' datacls mt-2'>{adminclonelink}</span>
                </div>
                <div class="col-md-4 mb-3 d-inline-flex text-break pl-3">
                  <label className="text-bold">Admin App Password:</label>
                  <span className='datacls mt-2'>{adminclonepassword}</span>
                </div>
                <div class="col-md-4 mb-3 d-inline-flex text-break">
                  <label className="text-bold d-flex">API Clone Link:</label>
                  <span className='datacls mt-2 d-inline-flex text-break'>{apiclonelink}</span>
                </div>
                <div class="col-md-4 mb-3 d-inline-flex">
                  <label className="text-bold d-inline-flex text-break ">API App Password:</label>
                  <span className='datacls d-inline-flex text-break mt-2'>{apiclonepassword}</span>
                </div>
                <div class="col-md-4 mb-3 d-inline-flex">
                  <label className="text-bold d-inline-flex text-break ">Figma Link:</label>
                  <span className='datacls d-inline-flex text-break mt-2'>{figmaLink}</span>
                </div>
                <div class="col-md-12 d-inline-flex">
                  <label className="text-bold">Description:</label>
                  <p className='datacls mt-2'>{htmlToReactParser.parse(desc)}</p>
                </div>
              </div>
            <button className='btn btn-primary ' style={{float:"right"}} onClick={hanldeview} >Team <i className='fa fa-arrow-right'></i></button>
            </div>
          </div>
          </Spin>
        </section>
      </div>
    </div>
  );
};

export default ProjectDetails;
