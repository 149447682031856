import React, { useEffect, useState } from "react";
import {
  Table,
  Spin,
  Select,
  message,
  Button,
  notification,
  Tooltip,
  Popconfirm,
} from "antd";

import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { PlusSquareOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import Header from "../../../Constant/Header/Header";
import MainSideBar from "../../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../../Constant/SectionHeader/SectionHeader";
import ApiClient from "../../../methods/api/apiClient";
import { forwardRef } from "react";
import { getProjects } from "../../../Api/user/userapi";

export default function Bugs() {
    const {id}=useParams();
    const [Loading,setLoading]=useState([]);
    const [array, setArray] = useState([])
    const [loading,setloading]=useState(false);
    const history=useHistory();  
    const getbugs = async () => {
        try {
            setLoading(true);
  const resp=200;
            // const resp = await getProjects();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                // setArray(arrayData)
                setArray([])
            }
        } catch (e) {
          localStorage.clear();
          history.push("/")

            setTimeout(() => {
                notification.error({
                    message: 'Unauthorized',
                    description: 'Your Session Has Expired. Please logIn.',
                });
            }, 1000);
        }
        setLoading(false);

    };
    useEffect(()=>{
  getbugs();
    },[])
    
    const goback=()=>{
  history.push(`/addeditbugs/${id}`);
    } 

    const columns = [

        {
            title: "Bug-Name",
            dataIndex: "bugname",
            field: "bugname",

        },
   
        {
            title: "Description",
            dataIndex: "description",
            field: "description",
        },
        {
            title: "Status",
            dataIndex: "status",
            field: "status",
        },
        {
            title: "Actions",
            dataIndex: "actions",
            field: "actions",
        },
    ];
    // For Table Icons
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    // THis is the data
    const newlyAddedChapter =
    array && array.length
        ? array.map((items) => {
            localStorage.setItem('projectId', items.id);
            return {
                ...items,
            };
        })
        : [];
  return (
      <div className="">

    <div className="main-wrapper" id="mainPannel">
      <div className="navbar-bg" />
      <Header />
      <MainSideBar />
    </div>
    <div className="main-content">
      <div className="inventry_section">
      <section className="section">
        <SectionHeader title={`Bugs`} />
        <Tooltip title="Add Bugs">
          <Button
            type="primary"
            onClick={goback}
            shape="circle"
            icon={<PlusSquareOutlined />}
            size={"large"}
            className="btn btn-primary profile"
          />
        </Tooltip>
        <Spin spinning={loading}> 
        <MaterialTable
                         options={{
                            pageSize:10,
                            pageSizeOptions:[10,20,30,40,50], 
                             emptyRowsWhenPaging: false,
                             showTitle:false,
                             draggable:false
                       }}   
                            columns={columns}
                            data={newlyAddedChapter}
                            icons={tableIcons}
                        />
    </Spin>
    </section>
    </div>
    </div>
    </div>
      )
}
