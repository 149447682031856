import React, { useEffect, useState } from "react";
import Header from "../../../Constant/Header/Header";
import MainSideBar from "../../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../../Constant/SectionHeader/SectionHeader";
import {
  Spin,
  Button,
  notification,
  Tooltip,
} from "antd";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import ApiClient from "../../../methods/api/apiClient";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";

export default function InventoryDetails() {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const history = useHistory()
  const goback = () => {
    history.goBack();
  };
  // FOr getting details by the id
  const getdata = () => {
    setloading(true);
    ApiClient.get(`inventory?id=${id}`)
      .then((res) => {
        setdata(res.data);
        setloading(false);
      })
      .catch((err) => {
        notification.error({
          message: "Some error occurred.Please try again.",
        });
        setloading(false);
        history.goBack();
      });
  };
  useEffect(() => {
    if (id) {
      getdata();
    }
  }, []);

  const editdetails=()=>{
    history.push(`/addedit/inventory/${id}`)
  }
  return (
    <div>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title={`Inventory Details`} />
          <Tooltip title="Back Button">
            <Button
              type="primary"
              onClick={goback}
              shape="circle"
              icon={<ArrowLeftOutlined />}
              size={"large"}
              className="btn btn-primary profile"
            />
          </Tooltip>
          <Spin spinning={loading}>
            <div className="container pl-0">
              <div className="shadow w-100">
                <span>
                  <Tooltip title="Edit">
                      <Button type="primary" className="edit_btn edit-button mr-2 mt-2 f-right" onClick={e=>editdetails()}>
                          {<EditOutlined  />}
                      </Button>
                  </Tooltip>
                </span>
                <div class="row p-4">
                  <div class="col-md-6">
                    <label className="text-bold ">Device Name:</label>
                    <span className="text-capitalize">
                      {data?.category_name}{" "}
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label className="text-bold ">Device Code:</label>
                    <span className="text-capitalize">{data?.code} </span>
                  </div>
                  <div class="col-6 d-flex-inline">
                    <label className="text-bold ">Device Serial Number:</label>
                    <span className="text-capitalize  ">
                      {data?.serial_number}{" "}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <label className="text-bold">CreatedOn</label>
                    <span> {moment(data?.createdAt).format("DD MMM YYYY")}</span>
                  </div>
                  <div class="col-md-6">
                    <label className="text-bold ">Device Type:</label>
                    <span className="text-capitalize">{data?.type} </span>
                  </div>
                  {data.type == "replaced" ? (
                    <>
                      <div class="col-md-6">
                        <label className="text-bold ">Replaced Code:</label>
                        <span className="text-capitalize">
                          {data?.replacedcode}{" "}
                        </span>
                      </div>
                      <div class="col-md-6">
                        <label className="text-bold ">
                          Replaced Serial No.:
                        </label>
                        <span className="text-capitalize">
                          {data?.replacedserial_number}{" "}
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                      <div class="col-md-6">
                    <label className="text-bold ">Image:</label>
                    <span className="text-capitalize"><img src={data?.InventoryImage} width={50} height={50}/> </span>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </section>
      </div>
    </div>
  );
}
