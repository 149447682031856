import React, { useEffect, useState ,forwardRef} from 'react';
import Header from '../../Constant/Header/Header';
import parse from "html-react-parser";
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import {  Spin, Tooltip, Popconfirm, Modal, Button, notification } from 'antd';

import MaterialTable from 'material-table'
import { Link } from "react-router-dom";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {
    DeleteOutlined,
    EditOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import EditKnowledge from './EditKnowledge';
import { deleteKnowledge, getKownledge } from '../../Api/user/userapi';
import { useSidebar } from '../../Provider/Provider';
import { useHistory } from 'react-router-dom';

const Knowledge = (props) => {
    const history=useHistory();
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [array, setArray] = useState([])
    const [id, setId] = useState();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editChapterData, setEditChapterData] = useState({});
    const [loading, setLoading] = useState(false);
    const { setCourseId, setChaptersId, courseId } = useSidebar();

    const options = {
        filter: true,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight
    };
    const showEditModalHandle = (data) => { 
        console.log(data, 'adadadas');
        setIsEditModalVisible(true);
        setEditChapterData(data)
        setId(data._id);
        console.log('click');
    };

    useEffect(() => {
        knowledgeDeatil();
    }, [])
    const knowledgeDeatil = async () => {
        try {
            setLoading(true);
            const resp = await getKownledge();
            if (resp && resp.status === 200) {
                const list = resp;
                setLoading(false)
                console.log(list, 'listasas');
                const arrayData = list.data.data;
                setArray(arrayData)
            }
        } catch (e) {
            localStorage.clear();
            history.push("/")
            setLoading(false);
        
            setTimeout(() => {
                notification.error({
                    message: 'Unauthorized',
                    description: 'Your Session Has Expired. Please logIn.',
                });
            }, 1000);
        }
        setLoading(false);
    };

    const handleCancel = () => {
        setIsEditModalVisible(false);
    };

    const removeChapterHandle = async (id) => {
        console.log(id, "delete");
        // const Payload = {
        //     id: id,
        //     // model: "roles"
        // }

 
        // console.log(Payload, 'Payload');
        try {
            setLoading(true);
            const resp = await deleteKnowledge(id);
            if (resp && resp.status === 200) {
                setLoading(false)
                setTimeout(() => {
                    notification.success({
                        message: 'Deleted Successfully.',
                    });
                }, 1000);
        
                knowledgeDeatil();
            }
        } catch (e) {
            setLoading(false);
            console.log(e);
  
            setTimeout(() => {
                notification.error({
                    message: 'Knowledge',
                    description: 'Knowledge is not Deleted..',
                });
            }, 1000);
        }
    };

    function cancel(e) {
        console.log(e);
        // setTimeout(() => {
        //     notification.error({
        //         message: 'Knowledge',
        //         description: 'Data is not deleted..',
        //     });
        // }, 1000);
        // message.error('Data is not deleted');
    }

    const chapterIdHandle = (id) => {
        setCourseId(id);
        localStorage.setItem("knowledgeid",id)
    };
    const columns = [
        {
            title: "Topic-Name",
            dataIndex: "name",
            field: "name",
            render: (text, record) => (
                <>
                    {text && text.name ? (
                        <Link to={`/KnowledgeDetails?id=${text.id}`}>
                            <span className="text" onClick={() => chapterIdHandle(text.id)}>
                                {parse(text.name)}
                            </span>
                        </Link>
                    ) : (
                        "--"
                    )}
                </>
            ),
        },
        {
            title: "Description",
            dataIndex: "description",
            field: "description",
            render: (text, record) => (
                <>
                    {text && text.description ? (
                            <span onClick={() => chapterIdHandle(text.id)} className="description_field">
                                {parse(text.description)}
                            </span>
                    ) : (
                        "--"
                    )}
                </>
            ),
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        maxWidth: '118px'
                    }}
                >
                    {
                        console.log(text, "textresult")
                    }
                    <span className="edit_icon"
                        style={{
                            verticalAlign: 'text-bottom'
                        }}>
                        <Tooltip title="Edit">

                            <Button type="primary" onClick={() =>showEditModalHandle(text)} className="edit_btn">

                                {<EditOutlined />}
                            </Button>
                        </Tooltip>
                    </span>
                    <span>
                        <Tooltip title="Delete">

                            <Popconfirm
                                title="Are you sure you want to delete?"
                                onConfirm={() => removeChapterHandle(text.id)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" className="button delete_btn">

                                    <DeleteOutlined
                                    // onClick={() => removeChapterHandle(record._id)}
                                    // onCancel={cancelDeleteHandle}
                                    />
                                </Button>
                            </Popconfirm>
                        </Tooltip>
                    </span>
                </div>
            ),
        },
    ];



    const newlyAddedChapter =
        array && array.length
            ? array.map((items) => {
                console.log(items.id, 'asdas');
                localStorage.setItem('rolesid', items.id);
                return {
                    ...items,
                };
            })
            : [];

 
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };
    return (
        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content" style={{ minHeight: '0px' }}>
                <Spin spinning={loading}>
                <section className="section">
                    <SectionHeader title="Knowledges" />
                    <Spin spinning={loading}>
                        <MaterialTable
                             options={{
                                pageSize:10,
                                pageSizeOptions:[10,20,30,40,50], 
                                 emptyRowsWhenPaging: false,
                                 showTitle:false,
                                 draggable:false
                           }}   
                        //   title="Knowledges"
                            columns={columns}
                            data={newlyAddedChapter}
                            icons={tableIcons}
                            className="text-center"
                        />
                    </Spin>
                </section>
                </Spin>
            </div>
            <Modal
                footer={null}
                title="Edit Knowledge"
                visible={isEditModalVisible}
                onCancel={handleCancel}
                >
                <div className="crossicon_class" >
                    <button onClick={()=>handleCancel()} className="btn " tabindex="-1" type="button" aria-label="Clear Search"><span class="MuiIconButton-label"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" aria-label="clear"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></span></button>
                </div>
                <EditKnowledge
                    chapterId={id}
                    setIsEditModalVisible={setIsEditModalVisible}
                    showData={knowledgeDeatil}
                    editChapterData={editChapterData}
                    onCancel={handleCancel}
                />
            </Modal>
        </div>
    );
};

export default Knowledge;