import { Header } from 'antd/lib/layout/layout'
import React, { useState } from 'react'
import MainSideBar from '../../Constant/MianSideBar/MainSideBar'
import SectionHeader from '../../Constant/SectionHeader/SectionHeader'
import { Table, Spin, Modal, Tooltip, message, Button, Select, notification, Popconfirm,Switch } from 'antd';
import {
  ArrowLeftOutlined, DeleteOutlined,
    EditOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

export default function Setting() {
  const [loading,setloading]=useState(false);
  const history=useHistory()
  const renderSubmit=()=>{
  history.goBack()
  }
  const [status,setstatus]=useState('active')

  async function handleChange(value, event) {
    const Payload = {
        model: "users",
        status:event==false?'deactive':'active',
        id: value?.id
    }
    setstatus(Payload.status)

            if(Payload.status=='active'){ 
                notification.success({
                    message: 'Cash Enhancement is Enable Now.',
                });    
            }
            else{ 
                notification.success({
                    message: 'Cash Enhancement is Disable Now.',
                });
            } 
}  
  return (
    <div  style={{marginTop:"-15px"}}>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />

            </div>

            <div className="main-content">

                <section className="section">

                    <SectionHeader title="Settings" />
                    <Spin spinning={loading}>
                      <div className='mt-3 shadow p-4'>
                      <label className='form-label text-bold'>Cash Enhancement</label>
                                <Switch  defaultChecked={status=='active'} onChange={(e) => handleChange("text", e)} />

                      </div>
                      </Spin>
                      </section>
                      </div>
                      </div>
  )
}
