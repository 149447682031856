import Header from "../../Constant/Header/Header"
import React, { useEffect, useState } from 'react'
import MainSideBar from '../../Constant/MianSideBar/MainSideBar'
import { Button, Spin, Tooltip, notification } from 'antd'
import SectionHeader from "../../Constant/SectionHeader/SectionHeader"
import { ArrowLeftOutlined
} from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom"
import ApiClient from "../../methods/api/apiClient"


const AddEditAnnounce = () => {
    const { id } = useParams()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
 
    const [data, setdata] = useState()
    const [subject, setsubject] = useState()
    const [description, setdescription] = useState()
    const [err, seterr] = useState(false)

    useEffect(() => {
        if(id){
            getdata()
        }

    }, [])
    const goback = () => {
        history.push("/view/announcements")
    }
    const getdata = () => {
        setLoading(true)
        ApiClient.get(`announcement?id=${id}`).then(res => {
            if (res.success) {
                setdata(res?.data)
                setLoading(false)
            }
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!subject || !description) {
            seterr(true)
        } else {
            let data = {
                subject: subject,
                description: description
            }
            setLoading(true)
            if (id) {
                let data1 = {
                    ...data, id: id
                }
                ApiClient.put(`announcement`, data1).then(res => {
                    if (res.success) {
                        notification.success({ description: 'Data Updated Successfully' })
                        history.push("/festivals")
                        setLoading(false)
                    } else {
                        notification.error({ description: 'Something went Wrong' })
                        setLoading(false)
                    }
                })
            } else {
                setLoading(true)
                ApiClient.post(`announcement`, data).then(res => {
                    if (res.success) {
                        notification.success({ description: 'Announcement Added Successfully' })
                        history.push('/view/announcements')
                        setLoading(false)
                    } else {
                        notification.error({ description: 'Something Wrong' })
                        setLoading(false)
                    }
                })
            }
        }
    }
   
    return (
        <>
            <>
                <div>
                    <div className="main-wrapper" id="mainPannel">
                        <div className="navbar-bg" />
                        <Header />
                        <MainSideBar />
                        <div className="main-content">
                            <Spin spinning={loading}>
                                <section className='section ml-3'>
                                    <SectionHeader title={!id?"Add Announcement":"Edit Announcement"} />
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}>
                                        <Tooltip title="Go Back">
                                            <Button type="primary"
                                                onClick={goback}
                                                shape="circle"
                                                icon={<ArrowLeftOutlined />}
                                                size={'large'}
                                                className="btn btn-primary profile"
                                            />
                                        </Tooltip>
                                    </div>
                                </section>
                                <div className="row">
                                    <form className="col-md-12" onSubmit={e => handleSubmit(e)}>
                                        <div className="form-row">
                                            <div className='form-group col-md-6 text-left'>
                                                <div>
                                                    <label for="das" className='control-label mt-3 '><b>Event</b></label>
                                                    <input type='text' id='das' className="form-control " style={{ paddingLeft: "10px" }} value={subject} onChange={e => setsubject(e.target.value)} placeholder='Event Title ' />
                                                    {err && !subject ? <span className="span-red">This field is Required</span> : ''}
                                                </div>
                                            </div>
                                            <div className='form-group col-md-6 text-left'></div>
                                            <div className='form-group col-md-6 text-left'>
                                                <div>
                                                    <label for="aa" className='control-label mt-3 '><b>Add Announcement</b></label>
                                                    <textarea type='text' id='aa' className="form-control " style={{ paddingLeft: "10px" }} value={description} onChange={e => setdescription(e.target.value)} placeholder='Add Announcement' />
                                                    {err && !description ? <span className="span-red">This field is Required</span> : ''}

                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn btn-warning mb-4" type="submit">Submit</button>
                                    </form>

                                </div>

                              
                            </Spin>
                        </div>
                    </div>
                </div>

            </>

        </>
    )
}

export default AddEditAnnounce
