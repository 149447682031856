import React, { useEffect, useState } from 'react'
import Header from '../../Constant/Header/Header'
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { useParams, useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, notification, Spin, Tooltip } from "antd"
import { Formik } from 'formik';
import axios from 'axios';
import { API_URL } from '../../Constant';
import ApiClient from '../../methods/api/apiClient';
export default function AddEditCategory() {
    const { id } = useParams();
    const [data, setdata] = useState([]);
    const [loading, setloading] = useState(false);
    const navigate = useHistory();
    const backbutton = () => {
        navigate.goBack();
    }
    const getdata = () => {
        setloading(true)
        ApiClient.get(`category?id=${id}`).then(res => {
            setdata(res.data); setloading(false);
        }).catch(err => notification.error(err => { notification.error({ message: "Some error occurred." }); setloading(false) }))
    }
    useEffect(() => {
        if (id) { getdata(); }
    }, [])

    return (
        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">
                <section className="section">
                    <SectionHeader title={id ? 'Edit Category' : 'Add Category'} />
                    <div>
                        <Tooltip title="Back Button">
                            <Button type="primary"
                                onClick={e => backbutton()}
                                shape="circle"
                                icon={<ArrowLeftOutlined />}
                                size={'large'}
                                className='btn btn-primary profile'
                            />
                        </Tooltip>
                    </div>
                    <div className='container addcategory'>
                        <Spin spinning={loading}>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    name: id ? data.name : "",
                                    cat_type: id ? data.cat_type : ""
                                }}

                                onSubmit={(values) => {
                                    let url = "category";
                                    const str=values.cat_type
                                    let result = str.charAt(0).toUpperCase() + str.slice(1);

                                    let payload = { name: values.name, cat_type: result }
                                    if (id) {
                                        axios.put(`${API_URL}${url}`, { ...payload, id: id }).then(res => {
                                            navigate.push("/allCategories"); notification.success({ message: "Category Updated successfully" })
                                        }).catch(err => notification.error({ message: "Category Already exists" }))
                                    } else {
                                        axios.post(`${API_URL}${url}`, payload).then(res => {
                                            notification.success({ message: "Category Added successfully" }); navigate.push("/allCategories");
                                        }).catch(err => notification.error({ message: "Category Already exists" }))
                                    }
                                }}
                            >
                                {({ values, handleBlur, handleChange, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label className='control-label'>Name</label>
                                                <input className='form-control pl-2' required name='name' value={values.name} onBlur={handleBlur} onChange={handleChange} />
                                            </div>
                                            <div className='col-md-6'>
                                                <label className='control-label'>Category Type</label>
                                                <input className='form-control pl-2' name='cat_type' required value={values.cat_type} onBlur={handleBlur} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <Button htmlType='submit' className='primary-btn my-3 submitbtn' type='primary'>Submit</Button>
                                    </form>
                                )}

                            </Formik>
                        </Spin>
                    </div>
                </section>
            </div>
        </div>
    )
}
