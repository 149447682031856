import React, { useEffect, useState, forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Tooltip, Button, Select, notification } from 'antd';
import { Link } from "react-router-dom";
import {
    DeleteOutlined,
    EditOutlined,
    ArrowLeftOutlined,
} from "@ant-design/icons";
import {

    Spin,

    Popconfirm,
    message,



} from 'antd';


import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useSidebar } from '../../Provider/Provider';
import { API_URL, SHOW_FILE } from '../../Constant';
import { useDispatch } from 'react-redux'
import { getWeeklyplanbyprojectID } from '../../Api/user/userapi';


const { Option } = Select;

const ProjectFile = (props) => {

    const dispatch = useDispatch()
    const [array, setArray] = useState([])
    console.log(array, "allarayyagag");
    const [employees, setEmployees] = useState([])
    const [testdocuments, settestdocuments] = useState()
    const [loading, setLoading] = useState(false);
    const xlFileID = localStorage.getItem("xlFileID");
    const token = localStorage.getItem("dataToken");
    const [docs, setDocs] = useState()

    const URL = testdocuments;

    const { courseId, setChapterListDetail } = useSidebar();



    const getData = async (id) => {
        localStorage.setItem("xlIdAdmin", id)

        setChapterListDetail(id)

    }

const chapterIdHandle  = async (id) => {
    localStorage.setItem("updatedxlsheetdata",id)
}

    const columns = [
        {
            title: "Documents",
            dataIndex: "documents",
            field: "documents",
            render: (text, record) => (
                console.log(text, 'recorsdcsddd'),
                settestdocuments(text.documents),
                <>
                    {
                        <>

                            <Link to={`/Tabledataxls?id=${text.id}`}>
                                <span onClick={(e) => getData(text.id)}>

                                    {text.documents}
                                </span>
                            </Link>
                        </>

                    }


                </>
            ),
        },


        {
            title: "Start Date",
            dataIndex: "startDate",
            field: "startDate",



        },
        {
            title: 'End Date',
            dataIndex: "endDate",
            field: 'endDate',

        },
        {
            title: 'Updated Task',
            dataIndex: "endDate",
            field: 'endDate',
            render: (text, record) => (

                console.log(text, "alltexteddata"),
                <>
                    {(
                        <Link to={`/updatedtask?id=${text.id}`}>
                            <span className="text" onClick={() => chapterIdHandle(text.id)}>
                            <i className="fas fa-file-excel"
                                style={{
                                    fontSize: 'xx-large'
                                }}
                            >

                            </i>
                            </span>



                        </Link>


                    )}




                </>
            ),
        },



    ];

    const newlyAddedChapter =
        array && array.length
            ? array.map((items) => {
                //  localStorage.setItem('projectId', items.id);
                return {
                    ...items,
                };
            })
            : [];

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    useEffect(() => {
     
        getProject()
    }, [])


    const id  = localStorage.getItem('courseid');
    const getProject = async (i) => {

        try {
            const resp = await getWeeklyplanbyprojectID(id);
            if (resp && resp.status === 200) {
                const list = resp;

                console.log(list.data.data, 'ddddd');
                setArray(list.data.data)


            }
        } catch (e) {
            console.log(e);
        }
    };
    // const Knowledgesharingtable = () => {
    //     setLoading(true)

    //     fetch(`${API_URL}getWeeklyplanbyprojectID?id=${courseId}`, {
    //         method: 'GET',

    //         headers: {
    //             'Content-type': 'application/json',
    //             Authorization: `Bearer ${token}`,
    //         },
    //     })
    //         .then(res => res.json())
    //         .then(res => {
    //             console.log(res.data, "111");


    //             // setArray(res.data)

    //             // setDocs(res.data)

    //             if (res && res.data && res.data.access_token) {



    //                 dispatch({
    //                     type: SHOW_FILE,
    //                     payload: res
    //                 })
    //                 //history.push("/dashboard")
    //             } if (res.code === 401) {
    //                 setTimeout(() => {
    //                     notification.open({
    //                         message: 'Unauthorized',
    //                         description: 'Your Session Has Expired. Please logIn.',
    //                     });
    //                 }, 1000);
    //             }
    //             setLoading(false)
    //         });
    // }


    const renderBody = () => {
        return employees && employees.map((items) => {
            console.log(items, "dfcdsfds");

            return (
                <tr >
                    <td>{items.Week_18oct_to_22oct_2021}
                    </td>
                </tr>
            )
        })
    }
    const renderSubmitBack = () => {
        props.history.push('/allProject')
    }



    return (
        <>

            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">

                <section className="section">

                    <SectionHeader title="XL File" />
                    <div>
                        <Button type="primary"
                            onClick={renderSubmitBack}
                            shape="circle"
                            icon={<ArrowLeftOutlined />}
                            size={'large'}
                            className="btn btn-primary profile"
                        />



                    </div>

                    <div>
                       
                        <table id='employee'>
                            <thead>
                                {/* <tr>{renderHeader()}</tr> */}
                            </thead>
                            <tbody>
                                {renderBody()}
                            </tbody>
                        </table>
                    </div>

                    <div >




                        <MaterialTable icons={tableIcons}
                         options={{
                            pageSize:10,
                            pageSizeOptions:[10,20,30,40,50], 
                             emptyRowsWhenPaging: false,
                             showTitle:false,
                             draggable:false
                       }}   
                            // title="Project Data"
                            data={newlyAddedChapter}
                            columns={columns} />



                    </div>

                </section>
            </div>

        </>
    );
};

export default ProjectFile;