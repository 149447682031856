import {ArrowLeftOutlined} from '@ant-design/icons';
import { Button, notification, Spin, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { useHistory, useParams } from 'react-router-dom';
import { addsales, addsalesdata, editsales, getUsersdata, singlesalesdata } from "../../Api/user/userapi";
import Header from "../../Constant/Header/Header"
import MainSideBar from "../../Constant/MianSideBar/MainSideBar"
import SectionHeader from "../../Constant/SectionHeader/SectionHeader"

const AddEditSales=()=>{
    const [loading, setLoading] = useState(false);
    const [title,settitle] = useState()
    const [date,setdate] = useState()
    const [url,seturl] = useState()
    const [country,setcountry] = useState()
    const [technology,settechnology] = useState()
    const [status,setstatus] = useState()
    const history=useHistory()
    const {id}=useParams()

    const backbutton = () => {
        history.goBack();
    }
    const salesadded=(e)=>{
        e.preventDefault();
        let payload={
            "bids": [
                {
                    "title": title,
                    "status": status,
                    "upworkurl": url,
                    "country": country,
                    "technology": technology,
                    "date": date
                }
            ]
        }
        if(!id){
            let method="post"
            addsalesdata(`lead`,payload,method).then(res=>{
                if(res.success){
                    history.push('/sales')
                    setTimeout(() => {
                        notification.success({
                            message: 'Sales Added Successfully',
                        });
                    }, 100);
                }
            })
        }
        else{
            let method="put"
            let payload={
                "id":id,
                "country":country,
                "technology":technology,
                "title": title,
                "status": status,
                "upworkurl": url,
                "date": date
            }
            addsalesdata(`lead?id=${id}`,payload,method).then(res=>{
                if(res.success){
                    history.push('/sales')
                    setTimeout(() => {
                        notification.success({
                            message: 'Sales Updated Successfully',
                        });
                    }, 100);
                }
            })
        }
    }

    const update=()=>{
        singlesalesdata(`lead?id=${id}`).then(res=>{
            if(res.success){
                settitle(res?.data?.title)
                setdate(res?.data?.date)
                seturl(res?.data?.upworkurl)
                setcountry(res?.data?.country)
                console.log(res.data,"data")
                settechnology(res?.data?.technology)
                setstatus(res?.data?.status)
            }
        })
    }
    useEffect(()=>{
        if(id){
            update()
        }
    },[])

   const mystyle={paddingLeft:"15px"};
    
    return<>
        <div className="main-wrapper" id="mainPannel">
            <div className="navbar-bg" />
            <Header />
            <MainSideBar />
        </div>
        <div className="main-content">
            <section className="section">
                <SectionHeader title={!id?"Add Sales":"Edit Sales"}/>
                <div>
                    <Tooltip title="Back Button">
                        <Button type="primary"
                        onClick={e=>backbutton()}
                        shape="circle"
                        icon={<ArrowLeftOutlined />}
                        size={'large'}
                        className='btn btn-primary profile'
                        />
                    </Tooltip>
                </div>
                <div className='container'>
                    <Spin spinning={loading}></Spin>
                    <form onSubmit={salesadded}>
                        <div className="mb-3">
                            <input type="text"  style={mystyle} value={title} onChange={e=>settitle(e.target.value)} className="form-control w-75" id="exampleFormControlInput1" placeholder="Title" required/>
                        </div>
                        <div className="mb-3">
                            <input type="date" style={mystyle} value={date} onChange={e=>setdate(e.target.value)} className="form-control w-75" id="exampleFormControlInput1" required/>
                        </div>
                        <div className="mb-3">
                            <input type="url" style={mystyle} value={url} onChange={e=>seturl(e.target.value)} className="form-control w-75" id="exampleFormControlInput1" placeholder="Upwork URL" required/>
                        </div>
                        {/* <div className="mb-3">
                            <input type="text" value={technology} onChange={e=>settechnology(e.target.value)} className="form-control w-75" id="exampleFormControlInput1" placeholder="Enter Technology" required/>
                        </div>
                        <div className="mb-3">
                            <input type="text" value={country} onChange={e=>setcountry(e.target.value)} className="form-control w-75" id="exampleFormControlInput1" placeholder="Enter Country" required/>
                        </div> */}
                        <div className="mb-3">
                            <select className="form-select form-select-lg w-25 mr-1" value={country} onChange={e=>setcountry(e.target.value)} aria-label=".form-select-lg example" required={true}>
                                <option value=""  >Select the Country</option>
                                <option value="United states">United States</option>
                                <option value="Canada">Canada</option>
                                <option value="Australia">Australia</option>
                                <option value="France">France</option>
                            </select>
                            <select className="form-select form-select-lg w-25 mr-1" value={technology} onChange={e=>settechnology(e.target.value)} aria-label=".form-select-lg example" required={true}>
                                <option value=""  >Select the Technology</option>
                                <option value="React JS">React JS</option>
                                <option value="React Native">React Native</option>
                                <option value="Mean Stack">Mean Stack</option>
                                <option value="Mern Stack">Mern Stack</option>
                                <option value="Worldpress">Worldpress</option>
                                <option value="PHP">PHP</option>
                                <option value="Designing">Designing</option>
                                <option value="QA">QA</option>
                            </select>
                            <select className="form-select form-select-lg w-25" value={status} onChange={e=>setstatus(e.target.value)}  required={true}>
                                <option value="" >Select the Status</option>
                                <option value="Success">Success</option>
                                <option value="Failed">Failed</option>
                            </select>
                        </div>
                        <div>
                            <button type="submit" className="btn btn-primary rounded">{!id?"ADD":"Update"}</button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </>
}
export default AddEditSales