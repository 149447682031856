import React, { useEffect, useState } from "react";
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import Chart from "react-google-charts";
import StatsGarph from '../../Constant/StatsGraph';
import Stats from '../../Constant/Stats';
import { adminleavestatus, dashbordData, failedproject, getUsers, getUsersdata, linechart, loginactivities, loginusers, projectstatuscount, runningproject, successproject, totalassignedtaskcount, totalcompletetaskcount, totalemployeecount, totalnewemployeecount, totalproject, userdelete } from '../../Api/user/userapi';
import { message, Modal, Popconfirm, Spin, Tooltip } from "antd";
import { notification } from 'antd';
import EditUser from "../User/EditUser";
import { Link, useHistory } from "react-router-dom";
import { useSidebar } from "../../Provider/Provider";
import ApiClient from "../../methods/api/apiClient";
import moment from "moment";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";


const Dashbord = (props) => {
    const history = useHistory()
    const [loading, setLoading] = useState(false);
    const [array, setArray] = useState([])
    const [id, setId] = useState();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editChapterData, setEditChapterData] = useState({});
    const { setCourseId, setChaptersId, courseId } = useSidebar();
    const [employeecount,setemployeecount] = useState(0)
    const [newemployeecount,setnewemployeecount] = useState(0)
    const [taskassigned,settaskassigned] = useState(0)
    const [assignedtaskcomplete,setassignedtaskcomplete] = useState(0)
    const [activitiesoflogin,setactivitiesoflogin] = useState([])
    const [activeusers,setactiveusers] = useState()
    const [projecttotal,setprojecttotal] = useState(0)
    const [totalsuccessproject,settotalsuccessproject] = useState(0)
    const [totalfailedproject,settotalfailedproject] = useState(0)
    const [totalrunningproject,settotalrunningproject] = useState(0)
    const [linechartsuccess,setlinechartsuccess] = useState([])
    const [linechartfailed,setlinechartfailed] = useState([])
    const [linechartrunning,setlinechartrunning] = useState([]);
    const [appraisal, setappraisal] = useState(0)

    const userDetails = async () => {
        setLoading(true)
        try {
            const resp = await getUsers();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data;
                const newarrayData=arrayData.data.filter((item)=>{
                    // console.log(item.role,"Item role is here");
                    return item.role!="subadmin"
                })
                setArray(newarrayData);
            setLoading(false)
            }else{setLoading(false)}
        } catch (e) {
            setLoading(false);
            localStorage.clear();
            setTimeout(() => {
                notification.error({
                    message: 'Unauthorized',
                    description: 'Your Session Has Expired. Please LogIn.',
                });
            }, 1000);
        }
    };
    useEffect(() => {
        userDetails(); 
        getappraisal() 
    }, [])

    const getappraisal=()=>{
        setLoading(true);
        ApiClient.get(`upcomingappraisals`).then(res=>{ 
                setappraisal(res.total);
                setLoading(false);
        }).catch(err=>setLoading(false))
    }

    const data = [
        [
            "Date",
            "Success",
            "Failed",
            "Running",
          ],
          ...linechartsuccess&&linechartsuccess.map(item=>{
            return [`${item.day}/${item.month}/${item.year}`, item.totalEmployeeCounts, 0, 0]
          }),
          ...linechartfailed&&linechartfailed.map(item=>{
            return [`${item.day}/${item.month}/${item.year}`, 0, item.totalEmployeeCounts, 0]
          }),
          ...linechartrunning&&linechartrunning.map(item=>{
            return [`${item.day}/${item.month}/${item.year}`, 0, 0, item.totalEmployeeCounts]
          }), 
        //   [2, 30.9, 69.5, 32.4],
        //   [3, 25.4, 57, 25.7],
        //   [4, 11.7, 18.8, 10.5],
        //   [5, 11.9, 17.6, 10.4],
        //   [6, 8.8, 13.6, 7.7],
        //   [7, 7.6, 12.3, 9.6],
        //   [8, 12.3, 29.2, 10.6],
        //   [9, 16.9, 42.9, 14.8],
        //   [10, 12.8, 30.9, 11.6],
        //   [11, 5.3, 7.9, 4.7],
        //   [12, 6.6, 8.4, 5.2],
        //   [13, 4.8, 6.3, 3.6],
        //   [14, 4.2, 6.2, 3.4],
        ];
        const options = {
            chart: {
                title: `Total Projects: ${projecttotal}`,
                subtitle: "Project Details",
              },
        };
        const dataDonutChart=[
            ["Task", "Hours per Day"],
            ["Success", totalsuccessproject],
            ["Failed", totalfailedproject],
            ["Running",totalrunningproject],
        ];
        const optionsDonutChart={
            title: `Total Projects: ${projecttotal}`,
            fontsize:30,
            pieHole: 0.4,
            is3D: false,
        };
        //For time
        const time = (p) => {
            if (!p) return ''
            let v = p.split('T')
            if (v.length === 2) {
                return new Date(p).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            } else {
                return new Date(`2020-07-19 ${p}`).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            }
        }
        //Add employee data
        const addemployee=()=>{
            props.history.push('/addeditUser')
        }
const [pendingleaves,setpendingleaves]=useState("0");
        // For getting the Pending leaves list 
        const getpendingLeaves=()=>{
            setLoading(true)
            ApiClient.get("getadminleavesstatuscount").then(res=>{
                setpendingleaves(res.data.Pending);
                setLoading(false);
            }).catch(err=>{notification.error({message:"Some error occurred. Please try again"});setLoading(false)})
        }
        useEffect(()=>{
         getpendingLeaves();
        },[])

        //Edit employee detail
        const editemployee=(data)=>{
            history.push(`/addeditUser/${data?._id}`)
            // setIsEditModalVisible(true);
            setEditChapterData(data)
            setId(data._id);
        }
        const handleCancel = () => {
            setIsEditModalVisible(false);
        };
        //Delete employee data
        const deleteemployee=(id)=>{
            // console.log(id,"Employee id")
            let param={
                id:id,
                model:'users'
            }
            let url ='delete'
                userdelete(url,param).then(res=>{
                    if(res.success){
                        // console.log(res,'here we have')
                        // console.log(res.message)
                        setTimeout(() => {
                            notification.open({
                                message: 'Employees',
                                description: res.message,
                            });
                        }, 600);
                        userDetails()
                    }
                })
        } 

        //Single user detail
        const chapterIdHandle = (id) => {
            setCourseId(id);
            // console.log(id, 'Here is the id');
        };

        //For the analatics
        const dashbordCard = async () => {
            try {
                setLoading(true);
                const resp = await dashbordData();
                if (resp && resp.status === 200) {
                    const list = resp;
                   const totalData = list.data;
                    setLoading(false);
                }
            } catch (e) {
                setLoading(false)
                localStorage.clear();
                history.push("/")
                setTimeout(() => {
                    notification.error({
                        message: 'Unauthorized',
                        description: 'Your Session Has Expired. Please logIn.',
                    });
                }, 1000);
            } 
        };
        useEffect(() => {
            dashbordCard();
        }, [])

        //For Total employees
        const totalemployee=()=>{
            setLoading(true);
            ApiClient.get(`total/employee`).then(res=>{  
                    setemployeecount(res)
                    setLoading(false);
            }).catch(err=>setLoading(false));
        }
        useEffect(() => {
            totalemployee();
        },[])

        //For new employees
        const newemployees=()=>{
            setLoading(true);
            ApiClient.get(`newEmployee`).then(res=>{ 
                    setnewemployeecount(res);
                    setTimeout(() => { 
                        setLoading(false);
                    }, 1000);
            }).catch(err=>setLoading(false))
        }
            //For total assigned tasks
            const totalassignedtask=()=>{
                setLoading(true);
                ApiClient.get(`total/task?status=inProgress`).then(res=>{ 
                        settaskassigned(res)
                        setLoading(false);
                }).catch(err=>setLoading(false))
            } 
        useEffect(()=>{
            newemployees();
            totalassignedtask();
        },[])

        //For total complete tasks
        const totalcompletetask=()=>{
            setLoading(true)
            ApiClient.get(`total/task?status=done`).then(res=>{
                    setassignedtaskcomplete(res)
                setLoading(false);
            }).catch(err=>setLoading(false))
        }
        useEffect(()=>{
            totalcompletetask();
        },[])

        //For the login activities

        const detailofloginactivities=()=>{
            setLoading(true);
            ApiClient.get(`activities`).then(res=>{  
                    setactivitiesoflogin(res.data) 
                    setLoading(false);
            }).catch(err=>setLoading(false))
        }
        useEffect(()=>{
            detailofloginactivities();
        },[])
        //For the active status
        const todayloginuser=()=>{
            setLoading(true);
            ApiClient.get('active').then(res=>{ 
                   if(res.success){ setactiveusers(res);
                    setLoading(false);}
            }).catch(err=>setLoading(false))
        }
        useEffect(()=>{
            todayloginuser()
        },[])

        //For the charts
        const charttotalproject=()=>{
            setLoading(true);
            ApiClient.get(`all/projects`).then(res=>{ 
                    setprojecttotal(res.TotalProjects)
                setLoading(false);
            }).catch(err=>setLoading(false))
        }
        useEffect(()=>{
            charttotalproject()
        },[])

        const chartsuccessproject=()=>{
            setLoading(true);
            ApiClient.get(`projects/status?status=Success`).then(res=>{  
                    settotalsuccessproject(res.TotalProjects);
                    setLoading(false)
            }).catch(err=>setLoading(false))
        }
        useEffect(()=>{
            chartsuccessproject()
        },[])

        const chartrunningproject=()=>{
            setLoading(true)
            ApiClient.get(`projects/status?status=InProgress`).then(res=>{
                    settotalrunningproject(res.TotalProjects);
                    setLoading(false);
            }).catch(err=>setLoading(false))
        }
        useEffect(()=>{
            chartrunningproject()
        },[])

        const chartfailedproject=()=>{
            setLoading(true);
            ApiClient.get(`projects/status?status=Failed`).then(res=>{ 
                // console.log(res.TotalProjects,"This is the data thats we need for the earlier member ----------------+++++++++++++++++++++")
                    settotalfailedproject(res.TotalProjects.length!=0?res.TotalProjects:0);
                    setLoading(false);
            }).catch(err=>setLoading(false))
        }

        const linechartdata=()=>{
            setLoading(true);
            ApiClient.get(`analatics?type=daily`).then(res=>{ 
                    // console.log(res.totalSuccessProjectsCount,"Line chart data Myiner which is Mine")
                    setlinechartsuccess(res.totalSuccessProjectsCount.response)
                    setlinechartfailed(res.totalFailedProjectsCount.response)
                    setlinechartrunning(res.totalInProgressProjectsCount.response?res.totalInProgressProjectsCount.response:[])
                    setLoading(false)
            }).catch(err=>setLoading(false))
        }
        useEffect(()=>{
            linechartdata()
            chartfailedproject()
            getholidays()
        },[])
        const [holidays,setholidays]=useState()
        const [holidaydata,setholidaydata]=useState([])

        const getholidays=()=>{
            setLoading(true)
            ApiClient.get(`holidaylist`).then(res=>{
                if (res.success){
                    setholidays(res?.total)
                    setholidaydata(res?.data?res.data:[])
                    // console.log(res?.data)
                }
                setLoading(false)
            })
        }
        const event=()=>{
            history.push('/festivals')
        }
    return (
        <Spin spinning={loading}>
        <div className="bgImg">
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content" style={{ minHeight: '0px' }}>
            <section className="section bgimg">
            <div className="bg_img">
<div className="row">
    <div className="col-md-12">
        <h3 className="heading">DASHBOARD</h3>
    </div>
     <div className="col-md-12">
        <div className="row">
            {/* <div className="col-md-6 col-sm-6">
                <div className="shadow p-4 border-rounded bg-white">
                    <h4>Total Employees</h4>
                    <div className="d-flex align-items-center">
                    <i class="fa fa-users icons" aria-hidden="true"></i> <b className="number mb-2 ml-3">{employeecount&&employeecount?.totalEmployeeCounts}</b></div>
                    <p className="m-0 mb-2"><i class="green fa fa-line-chart" aria-hidden="true"></i><span className="trending"> 10% Since</span></p> 
                </div>
                <div className="shadow p-4 border-rounded bg-white mt-5">
                    <h4>Tasks Assigned</h4>
                    <div className="d-flex align-items-center">
                    <i class="fa fa-calendar-check-o" aria-hidden="true"></i> <i class="fa fa-calendar-check calendar" aria-hidden="true"></i><b className="number mt-2 ml-3">{taskassigned&&taskassigned?.totalTaskCount}</b></div> 
                    <p className="m-0 mb-2"><i class="red fa fa-line-chart" aria-hidden="true"></i><span className="trending"> 10% Since</span></p>
                </div>
            </div> */}
            {/* <div className="col-md-6 col-sm-6">
                <div className="shadow p-4 border-rounded bg-white">
                    <h4>New Employees</h4>
                    <div className="d-flex align-items-center">
                    <i class="fa fa-users icons2" aria-hidden="true"></i> <b className="number mb-2 ml-3">{newemployeecount&&newemployeecount?.newEmployee}</b></div>
                    <p className="m-0 mb-2"><i class="green fa fa-line-chart" aria-hidden="true"></i><span className="trending"> 10% Since</span></p> 
                </div> 
                <div className="shadow p-4 border-rounded bg-white mt-5">
                    <h4>Tasks Completed</h4>
                    <div className="d-flex align-items-center">
                    <i class="fa fa-calendar-check-o" aria-hidden="true"></i><i class="fa fa-calendar-check calendar2" aria-hidden="true"></i><b className="number mt-2  ml-3">{assignedtaskcomplete&&assignedtaskcomplete?.totalTaskCount}</b></div>
                    <p className="m-0 mb-2"><i class="red fa fa-line-chart" aria-hidden="true"></i><span className="trending"> 10% Since</span></p>
                </div>
            </div> */}
            <div className="col-md-4">
            <div className="shadow p-4 border-rounded bg-white">
                    <h4>Upcoming Appraisal</h4>
                    <div className="d-flex align-items-center">
                    <i class="fas fa-clock icons" aria-hidden="true"></i> <b className="number mt-1 ml-3">{appraisal}</b></div>
                    <p className="m-0 mb-2"><i class="green fa fa-line-chart" aria-hidden="true"></i><span className="trending"> 10% Since</span></p> 
           
            </div>
            
{/* Pending Leaves  */}
{/* <div className="col-md-6 col-sm-6 text-dark" style={{marginTop:"-20px"}}>
    <Link to="/Leaves">
                <div className="shadow  p-4 border-rounded bg-white text-dark">
                    <h4 className="text-dark">Pending Leaves</h4>
                    <div className="d-flex align-items-center">
                    <i class="fas fa-clock icons" aria-hidden="true"></i> <b className="number mt-1 ml-3">{pendingleaves}</b></div>
                    <p className="m-0 mb-2"><i class="green fa fa-line-chart" aria-hidden="true"></i><span className="trending"> 10% Since</span></p> 
                </div>
                </Link>
            </div> */}
            {/* <div className="col-md-12">
                <div className="shadow p-4 border-rounded bg-white">
                    <Chart
                        chartType="PieChart"
                        width="100%"
                        height="300px"
                        data={dataDonutChart}
                        options={optionsDonutChart}
                    />
                </div>
            </div> */}
        </div>
        <div className="col-md-4 "><div title="Show Holidays" style={{cursor:'pointer'}} onClick={e=>event()} className="shadow p-4 border-rounded bg-white ">
                    <h4 >Upcoming Events</h4>
                    <div className="d-flex align-items-center pointer"  >
                    <i class="fas fa-clock icons" style={{cursor:'pointer'}} aria-hidden="true"></i> <b className="number mt-1 ml-3">{holidays?holidays:0}</b>
                    </div>
                    <p className="m-0 mb-2"><i class="green fa fa-line-chart" aria-hidden="true"></i><span className="trending"> 20% Holidays</span></p> 
           
            </div></div>
        
     </div>
     {/* //to show who login to system and when */}
     <div className="col-md-6 ">
        
     </div>
            <div className="shadow p-4 border-rounded bg-white">
                    <Chart
                        chartType="PieChart"
                        width="100%"
                        height="300px"
                        data={dataDonutChart}
                        options={optionsDonutChart}
                    />
                </div>
     

        {/* <div className="shadow p-4 border-rounded bg-white">
            <div className="activites_section">
            <h4 className="active">Activities</h4>
            {activitiesoflogin&&activitiesoflogin.map(item=>{
                return<>
                    <div className="activities">
                        <div className="d-flex align-items-center">
                            <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="" className="userImg mr-2" />
                            <a><b>{item.fullName}</b> logged in the system.</a>
                        </div>
                        <p className="mb-0 ">{time(item.createdAt)}</p>
                    </div>
                </>
            })}
            </div> 
        </div> */}
    </div>
</div>
    <div className="shadow p-4 border-rounded bg-white">
        <Chart
            chartType="Line"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
    </div>
<div className="row">
    {/* <div className="col-md-8">
<div className="tableDiv mt-5">
    <div className="headerTable">
        <h1>Employees List</h1>
    </div>
    <div className="layout d-flex justify-content-between">
        <div>
    <button type="button" className="btn btn-primary addButton" onClick={e=>addemployee()}>Add New+</button>
    </div>
    <div>
    </div>
    </div>
    <div className="tableLyout">
        <table className="tableD">
            <tr>
                <th>Employee Name</th>
                <th>Designation </th>
                <th>Date</th>  
                <th>Actions</th>
            </tr>
            {array&&array?.map(item=>{
                return<>
                        <tr>
                            <td><Link to={`/userDetails/${item.id}`} onClick={e=>chapterIdHandle(item.id)}>{item.fullName}</Link></td>
                            <td>{item.designation}</td>
                            <td>{moment(item.dateOfJoining).format("D MMM YYYY")}</td> 
                            <td>
                                <Tooltip title="Edit">
                                    <button className="p-2 m-1 bg-info rounded" onClick={e=>editemployee(item)}><i class="fa-solid fa-pen"></i></button>
                                </Tooltip>
                                <Tooltip title="Delete">
                                <Popconfirm
                                    title="Are you sure you want to Delete?"
                                    onConfirm={() =>deleteemployee(item.id)}
                                    onCancel={()=>console.log("Cancled")}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                <button className="p-2 m-1  deletebtn" ><i class="fa-solid fa-trash"></i></button>
                                </Popconfirm>
                                </Tooltip>
                            </td>
                        </tr>
                    </>                
                })}
        </table>
    </div>
</div> 
</div>  */}
<div className="col-md-4">
    <div className="rightLayout mt-5">
        <h1>Online</h1>
        {activeusers&&activeusers?.data?.map(item=>{
        return <>
            <div className="layoutList">
                <div>
                    <i class="fa fa-circle" aria-hidden="true"></i>
                </div>
                <div>
                    <p>{item.fullName}</p>
                    <p className="Ptext">Available</p>
                </div>
            </div>
            </>
        })}
    </div> 
    </div> 
</div> 
</div>
</section>
                <Modal
            footer={null}
            title="Edit Employee"
            visible={isEditModalVisible}
            onCancel={handleCancel}
            >
                   <div className="crossicon_class" >
                    <button onClick={()=>handleCancel()} className="btn " tabindex="-1" type="button" aria-label="Clear Search"><span class="MuiIconButton-label"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" aria-label="clear"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></span></button>
                </div>
        <EditUser
            chapterId={id}
            setIsEditModalVisible={setIsEditModalVisible}
            showData={userDetails}
            onCancel={handleCancel}
            editChapterData={editChapterData}
            />
        </Modal>
            </div>
        </div >
        </Spin>
    );
};
export default Dashbord;