import React, { useEffect, useState, forwardRef } from "react";
import Header from "../../Constant/Header/Header";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import { Image, Spin, Tooltip, Button, notification, Popconfirm } from "antd";
import parse from "html-react-parser";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { PlusSquareOutlined, EditOutlined } from "@ant-design/icons";
import { hrAttendence, hrdateshow, userdelete } from "../../Api/user/userapi";
import { API_URL } from "../../Constant";
import { DeleteOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import moment from "moment";

const HrExpensiveTable = (props) => {
  const [loading, setLoading] = useState(false);
  const [array, setArray] = useState([]);
  const [reciept, setRecieptImg] = useState([]);
  const [value, onChange] = useState(new Date());
  const history = useHistory();
  function cancelDeleteHandle(e) {
    notification.error({
      message: "Delete Cancelled",
    });
  }
  // const recieptImg = () => {

  // };

  const renderSubmit = () => {
    props.history.push("/hrexpensive");
  };
  // Delete HREXPENSIVE recode
  const deleterecord = async (id) => {
    let param = {
      id: id,
      model: "users",
    };
    let url = `hrexpenses/delete?id=${id}`;
    ApiClient.put(url)
      .then((res) => {
        onChangeDate();
        notification.success({
          message: "Deleted Successfully",
        });
      })
      .catch((err) => {
        notification.error({
          message: "Some Error Occurred",
        });
      });

    //    ApiClient.delete(url).then(res=>{
    //     onChangeDate();
    //     notification.success({
    //         message:'Deleted Successfully.'
    //     })
    // }).catch(err=>{
    //     notification.error({
    //         message:'Error Occurred while Deleting the Data!!.'
    //     })
    // })
  };

  const showEditModalHandle = (id) => {
    history.push(`/hrexpensive/${id}`);
  };

  // Date & Time format
  const monthArray = [
    { value: "Jan", id: 0 },
    { value: "Feb", id: 1 },
    { value: "Mar", id: 2 },
    { value: "Apr", id: 3 },
    { value: "May", id: 4 },
    { value: "Jun", id: 5 },
    { value: "Jul", id: 6 },
    { value: "Aug", id: 7 },
    { value: "Sep", id: 8 },
    { value: "Oct", id: 9 },
    { value: "Nov", id: 10 },
    { value: "Dec", id: 11 },
  ];
  const monthfind = (id) => {
    let value = 0;
    let ext = monthArray.find((itm) => itm.id === id);
    if (ext) value = ext.value;
    return value;
  };
  const date = (p) => {
    let d = new Date(p);
    return `${d.getDate()} ${monthfind(d.getMonth())} ${d.getFullYear()}`;
  };
  const time = (p) => {
    if (!p) return "";
    let v = p.split("T");
    if (v.length === 2) {
      return new Date(p).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    } else {
      return new Date(`2020-07-19 ${p}`).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    }
  };
  // status of payment paid and pending api
  const handlepaymentStatus = (value, e) => {
    if(e=="pending")return
    let payload = {
      id: value.id,
      payment_status: e
    }
    if (window.confirm(`Do you really want to change Payment Status to Paid ?`)) {
      setLoading(true)
      ApiClient.put(`hrexpenses`, payload).then(res => {
        if (res.success) {
          onChangeDate();
          notification.success({
            message: "Payment Status Change Successfully",
          });
          setLoading(false)
        }
      })
    }
  }

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      field: "date",
      render: (text, record) => (
        <>{text && text.date ? date(text && text.date) : "--"}</>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      field:"description",
      key: "description",
      render: (text, record) => (
        <>
          {text && text.description ? (
            <span>{text.description}</span>
          ) : (
            "--"
          )}
        </>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      field: "amount",
    },

    {
      title: "Reciept",
      dataIndex: "Reciept",
      field: "images",
      render: (text, record) => (
        <>
          {text.file == "" ? (
            "----"
          ) : (<>
        
           <Tooltip title="View">
            <Image src={text.file} alt="empl" className="img_view" />
            </Tooltip>
           
          </>
            
          )}
          {/* <div className="ml-3 ">
            <i
              class="fa-solid fa-receipt reciept_icon"
              title="Reciept"
              data-toggle="modal"
              data-target="#recieptModal"
            ></i>
          </div> */}
        </>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      field: "payment_status",
      render: (text, record) => (
        <>
          {text?.payment_status == "pending" ? <select onChange={e => handlepaymentStatus(text, e.target.value)}>
            <option>pending</option>
            <option>paid</option>
          </select>
            : text?.payment_status ? text.payment_status : "paid"}
        </>
      )
    },

    {
      title: "Action",
      dataIndex: "actions",
      field: "actions",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <span
            className="edit_icon"
            style={{
              verticalAlign: "text-bottom",
            }}
          >
            <Tooltip title="Edit">
              <Button type="primary" className="edit_btn">
                {<EditOutlined onClick={() => showEditModalHandle(text.id)} />}
              </Button>
            </Tooltip>
          </span>
          <span>
            <Tooltip title="Delete">
              <Popconfirm
                title="Are you sure you want to Delete?"
                onConfirm={() => deleterecord(text.id)}
                onCancel={cancelDeleteHandle}
                okText="Yes"
                cancelText="No"
              >
                <Button type=" deletebtn" className="button">
                  <i class="fa-solid fa-trash"></i>
                </Button>
              </Popconfirm>
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];
  const newlyAddedChapter =
    array && array.length
      ? array.map((items) => {
        return {
          ...items,
        };
      })
      : [];
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const onChangeDatesss = async (newDate) => {
    onChange(newDate);
    // const newdate= newDate.split(",")
    // alert(JSON.stringify(newDate))
    const startdate = moment(newDate[0]).format("YYYY-MM-DD");
    const enddat = moment(newDate[1]).format("YYYY-MM-DD");
    try {
      setLoading(true);
      const resp = await hrdateshow(startdate, enddat);
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        setArray(arrayData);
      }
    } catch (res) {
      setArray("");
    }
    setLoading(false);
  };
  useEffect(() => {
    onChangeDate();
  }, []);

  let today = new Date().toISOString().slice(0, 10);
  const onChangeDate = async (newDate) => {
    onChange(newDate);
    const newDates = convert(newDate);
    try {
      setLoading(true);
      const resp = await hrAttendence();
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        setArray(arrayData);
      }
    } catch (res) {
      setArray("");
    }
    setLoading(false);
  };

  //Edit Expense
  const editExpense = (data) => {
    alert("Edit Expense");
  };

  //Delete Expense
  const deleteExpense = () => {
    alert("Delete Expense");
  };

  return (
    <>
      <div>
        <div className="main-wrapper" id="mainPannel">
          <div className="navbar-bg" />
          <Header />
          <MainSideBar />
        </div>
        <div className="main-content">
          <Spin spinning={loading}>
            <section className="section">
              <SectionHeader title="Expenses" />
              <div className="main_div">
                <Calendar
                  selectRange={true}
                  onChange={onChangeDatesss}
                  value={value}
                  className="calender"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Tooltip title="Add Expenses">
                  <Button
                    type="primary"
                    onClick={renderSubmit}
                    shape="circle"
                    icon={<PlusSquareOutlined />}
                    size={"large"}
                    className="btn btn-primary profile"
                  />
                </Tooltip>
              </div>
              <MaterialTable
                options={{
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50],
                  emptyRowsWhenPaging: false,
                  showTitle: false,
                  draggable:false
                }}
                // title="Expensive"
                columns={columns}
                data={newlyAddedChapter}
                icons={tableIcons}
              />
              {/* <Table columns={columns} dataSource={newlyAddedChapter} pagination={{
                            defaultPageSize: 5,
                            showSizeChanger: true,
                            pageSizeOptions: ["5", "10", "15"],
                        }} /> */}
            </section>
          </Spin>
        </div>
      </div>

      {/* modal */}

      {/* <!-- Button trigger modal --> */}
      {/* <button
        type="button"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#recieptModal"
      >
        Launch demo modal
      </button> */}

      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="recieptModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="recieptModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="recieptModalLabel">
                Reciept
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {/* <Image src={text.file} alt="empl" width={200} /> */}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" class="btn btn-primary">Ok</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrExpensiveTable;
