import React, { useState } from "react";
import Header from "../../../Constant/Header/Header";
import MainSideBar from "../../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../../Constant/SectionHeader/SectionHeader";
import {
  Table,
  Spin,
  Select,
  message,
  Button,
  notification,
  Tooltip,
  Popconfirm,
} from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { Formik } from "formik";
import ApiClient from "../../../methods/api/apiClient";
import { useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../../Constant";
import ApiKey from "../../../methods/ApiKey";
export default function AddEditInventory() {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [device, setdevice] = useState("");
  const [category, setcategory] = useState([]);
  const [devicename, setdevicename] = useState(null);

  const navigate = useHistory();
  const [loading, setloading] = useState(false);
  // state for dynamic form
  const [myform, setmyform] = useState([
    {
      code: "",
      category_id: device,
      serial_number: "",
      type: "",
      replacedcode: "",
      replacedserial_number: "",
      Id: "",
      InventoryImage:""
    },
  ]);

  // FOr Pushing the user back to the page
  const goback = () => {
    navigate.goBack();
  };

  // For getting data at the initial time
  const getdata = () => {
    setloading(true);
    ApiClient.get(`inventory?id=${id}`)
      .then((res) => {
        setdevicename(res.data.category_name)
          // setcategory([{ id: res.data.category_id }]);
          setdevice(res.data.category_id);
          setmyform([res.data]); 
          setloading(false);
      })
      .catch((err) => {
        setloading(false);
        notification.error({ message: "Some error occurred.Please try again" });
        navigate.goBack();
      });
  };

  // For SUbmitting the form
  const handlesubmit = (e) => {
    setloading(true);
    e.preventDefault();
    if (id) {
      myform.forEach((item) => (item.Id = id));
      setloading(false);
      axios.put(`${API_URL}inventory`, ...myform).then((res) => {
        navigate.goBack();
        notification.success({ message: "Inventory Updated Successfully" });
      });
    } else {
      myform.forEach(
        (item) => ((item.category_id = device), (item.type = "new"))
      );
      setloading(false);
      axios
        .post(`${API_URL}inventory`, myform)
        .then((res) => {
          navigate.goBack();
          notification.success({ message: "Added Successfully" });
        })
        .catch((err) => alert("error"));
    }
  };

  // For removing the form
  const handledelete = (e, index) => {
    e.preventDefault();
    const myformdata = [...myform];
    myformdata.splice(index, 1);
    setmyform(myformdata);
  };

  const getcategorylist = () => {
    setloading(true);
    ApiClient.get("allcategories?cat_type=Inventory")
      .then((res) => {
        setcategory(res.data);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        notification.error({ message: "Some error occurred" });
      });
  };
  // FOr setting the value of the form in an array
  const handleChange = (e, index) => {
    const field = [...myform];
    setmyform([...myform], (field[index][e.target.name] = e.target.value));
  };
  const handleImageChange =async (e, index) => {
    const field = [...myform];
    const image=await handleInputChange(e); 
    setmyform([...myform], (field[index][e.target.name] =image ));
  };
//  For Uploading Image
const handleInputChange = async (event) => {
  const token=localStorage.getItem("dataToken")
  let file = event.target.files[0];
  if (file != null) {
    setloading(true);
    const data = new FormData();
    data.append('modelName', 'users');
    data.append('file', file);
    console.log(data, 'responseJson');
    // Please change file upload URL
    let res = await fetch(
      `${API_URL}user/uploadProfileImage`,
      {
        method: 'post',
        body: data,
        headers: {
          Authorization: `Bearer ${token}`
        },
      }
    );
    let responseJson = await res.json();
    setloading(false)
    if (responseJson) {      
            notification.success({
              message:" Image Uploaded Successfully."
            })
      return ApiKey.api + responseJson?.data?.imagePath
    }
  } else { 
    return "";
    notification.error({
      message:"Please Upload the file"
    })
  }
}
  // For adding more form
  const handleclick = (e) => {
    const data = {
      code: "",
      serial_number: "",
      type: "",
      replacedcode: "",
      replacedserial_number: "",
      InventoryImage:""
    };
    setmyform([...myform, data]);
  };
  useEffect(() => {
    getcategorylist();
    if (id) {
      getdata();
    }
  }, []);
  const handlecahnge = (e) => { 
categoryfilter(e);
    setdevice(e);
  };
  const categoryfilter=(e)=>{
    category &&
    category.filter((item) => {
      if (item.id == e) {  
        setdevicename(item.name); 
      }
    });
  }

  const Handledelete=(e,index)=>{ 
  e.preventDefault();
  const myformdata = [...myform];
  myformdata[index].InventoryImage=""
  setmyform(myformdata); 
  }
  return (
    <div className="bgdiv">
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <div className="inventry_section">
        <section className="section">
          <SectionHeader title={`${!id ? "Add" : "Edit"} Inventory`} />
          <Tooltip title="Back Button">
            <Button
              type="primary"
              onClick={goback}
              shape="circle"
              icon={<ArrowLeftOutlined />}
              size={"large"}
              className="btn btn-primary profile"
            />
          </Tooltip>
          <Spin spinning={loading}>
            <select
              className="control-label pl-2"
              value={device}
              onChange={(e) => handlecahnge(e.target.value)}
            >
              <option value="">Please Select one of these-:</option>
              {category.map((item, index) => (
                <option className="text-capitalize" value={item.id}>{item.name}</option>
              ))}
            </select>
            {!device == null || !device == "" ? (
              <>
                <form className="my-3" onSubmit={handlesubmit}>
                  {myform.map((item, index) => (
                    <div className="mb-5 p-3 shadow formbg">
                      <h5 className="text-capitalize d-inline-flex">
                        {index + 1 == 1 ? `` : `${devicename} ${index + 1}`}
                      </h5>
                      {index + 1 == 1 ? (
                        ``
                      ) : (
                        <button
                          onClick={(e) => handledelete(e, index)}
                          type="button"
                          className="btn btn-danger d-inline-flex mx-5"
                          style={{ float: "right" }}
                        >
                          <DeleteOutline />
                        </button>
                      )}
                      <div>
                        <label className="control-label">Code:</label>
                        <input
                          className="form-control pl-2"
                          required
                          placeholder="Code.."
                          onChange={(e) => handleChange(e, index)}
                          value={item.code}
                          name="code"
                        />
                        <label className="control-label">Serial Number:</label>
                        <input
                          className="form-control pl-2"
                          required
                          placeholder="Serial Number.."
                          value={item.serial_number}
                          onChange={(e) => handleChange(e, index)}
                          name="serial_number"
                        />
                        <label className="control-label">Type:</label>
                        <select
                          disabled={!id ? true : false}
                          required
                          className="form-control control-label pl-2"
                          onChange={(e) => handleChange(e, index)}
                          value={id ? item.type : "new"}
                          name="type"
                        >
                          <option value="">Please Select one-:</option>
                          <option value="new">New</option>
                          <option value="replaced">Replaced</option>
                        </select>

                        {
                          // When the use select the replace Option then these field will be visible
                          item.type == "replaced" ? (
                            <>
                              <label className="form-label text-capitalize">
                                replaced {devicename} Code
                              </label>
                              <input
                                required={item.type == "replace"}
                                placeholder={`Replaced ${devicename} Code`}
                                className="form-control pl-2"
                                value={item.replacedcode}
                                onChange={(e) => handleChange(e, index)}
                                name="replacedcode"
                              />
                              <label className="form-label text-capitalize">
                                replaced {devicename} Serial No.
                              </label>
                              <input
                                className="form-control pl-2"
                                required={item.type == "replace"}
                                placeholder={`Replaced ${devicename} Serial Number..`}
                                value={item.replacedserial_number}
                                onChange={(e) => handleChange(e, index)}
                                name="replacedserial_number"
                              />
                            </>
                          ) : (
                            ""
                          )
                        }
                        <div>
                        <label className="form-label control-label">Image:</label>
                        {item.InventoryImage==""?<input
                          className="form-control pl-2"
                          required
                          type="file"
                          onChange={(e) => handleImageChange(e, index)}
                          name="InventoryImage"
                        /> :<><img src={item.InventoryImage}   width={50} height={50}/> <i className="fa fa-trash" onClick={e=>Handledelete(e,index)}></i> </>}
                        </div>
                      </div>
                    </div>
                  ))}
                  {!id ? (
                    <button
                      className="btn btn-primary my-3 d-block addbtn"
                      type="button"
                      onClick={(e) => handleclick(e)}
                      style={{ float: "right" }}
                    >
                      Add More
                    </button>
                  ) : null}
                  <Button
                    htmlType="submit"
                    className="btn btn-warning my-3 mx-2"
                  >
                    Submit
                  </Button>
                </form>
              </>
            ) : (
              ""
            )}
          </Spin>
        </section>
        </div>
      </div>
    </div>
  );
}
