import React, { useEffect, useState } from "react";
import { Button, Spin, Tooltip } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import ApiClient from "../../methods/api/apiClient";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import Header from "../../Constant/Header/Header";
import moment from "moment";

export default function ViewSystemComplaint() {
  const {id} = useParams();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState();
  const history = useHistory();

  const backbutton = () => {
    history.goBack();
  };

  const getdata = () => {
    setloading(true);
    ApiClient.get(`complain?id=${id}`).then((res) => {
        setdata(res.data);
        setloading(false);
    });
  };

  useEffect(() => {
    getdata();
  }, []);
  return (
    <div>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title="System Complaint" />
          <Tooltip title="Back Button">
            <Button
              type="primary"
              onClick={(e) => backbutton()}
              shape="circle"
              icon={<ArrowLeftOutlined />}
              size={"large"}
              className="btn btn-primary profile"
            />
          </Tooltip>
          <Spin spinning={loading}>
            <div className="shadow p-5">
                <div className="row">
                    <div className="col-md-6">
                        <p><b>Employee Name:</b> {data&&data.addedBy_name}</p>
                    </div>
                    <div className="col-md-6">
                        <p><b>Device Name:</b> {data&&data.deviceName}</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <p><b>Date:</b> {moment(data&&data.createdAt).format("DD MMM YYYY")}</p>
                    </div>
                    <div className="col-md-6">
                        <p><b>Status:</b> {data&&data.status}</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <p><b>Description:</b> {data&&data.description}</p>
                    </div>
                </div>
            </div>
          </Spin>
        </section>
      </div>
    </div>
  );
}
