import React, { useEffect, useState } from "react";
import Header from "../../Constant/Header/Header";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import { Button, Spin, Tooltip } from "antd";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import { useHistory, useParams } from "react-router-dom";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import ApiClient from "../../methods/api/apiClient";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";

export default function ViewLaptop() {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  const history = useHistory();
  const backbutton = () => {
    history.goBack();
  };
  const getdata = () => {
    setloading(true);
    ApiClient.get(`get/assign/device?id=${id}`).then((res) => {
      setdata(res.data);
      console.log(res.data);
      setloading(false);
    });
  };
  useEffect(() => {
    if(id){

      getdata();
    }
  }, []);

  const editdetails=()=>{
    history.push(`/addeditlaptop/${id}`)
  }
  return (
    <div>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title="Assigned Device Details" />
          <Tooltip title="Back Button">
            <Button
              type="primary"
              onClick={(e) => backbutton()}
              shape="circle"
              icon={<ArrowLeftOutlined />}
              size={"large"}
              className="btn btn-primary profile"
            />
          </Tooltip>
          <Spin spinning={loading}>
            <div className="shadow">
              <span>
                <Tooltip title="Edit">
                    <Button type="primary" className="edit_btn edit-button mr-2 mt-2" onClick={e=>editdetails()}>
                        {<EditOutlined  />}
                    </Button>
                </Tooltip>
              </span>
              <div className="row p-4">
                <div className="col-6">
                  <label className="form-label text-bold">Device Name:</label>
                  <span>{data?.device_name}</span>
                </div>
                <div className="col-6">
                  <label className="form-label text-bold">Serial Number:</label>
                  <span>{data?.serial_no}</span>
                </div>
                <div className="col-6">
                  <label className="form-label text-bold">Status:</label>
                  <span className="text-capitalize" style={data?.status=="assigned"?{color:'#ff8f26'}:{color:'green'}}>{data?.status}</span>
                </div>
                <div className="col-6">
                  <label className="form-label text-bold">StartTime&Date</label>
                  <span className="">
                    {moment(data?.startTime).format("DD MMM YYYY")}{" "}
                    {moment(data?.startTime).format("hh:mm A")}
                  </span>
                </div>
                <div className="col-6">
                  <label className="form-label text-bold">EndTime&Date</label>
                  <span className="">
                    {moment(data?.endTime).format("DD MMM YYYY")}{" "}
                    {moment(data?.endTime).format("hh:mm A")}
                  </span>
                </div>
                <div className="col-6">
                  <label className="form-label text-bold">Device Type:</label>
                  <span className="text-capitalize" >{data?.device_type}</span>
                </div>
                <div className="col-12">
                  <label className="form-label text-bold">Description:</label>
                  <span className="">{data?.description}</span>
                </div>
              </div>
            </div>
          </Spin>
        </section>
      </div>
    </div>
  );
}
