import React, { useState, forwardRef, useEffect } from "react";
import Header from "../../../Constant/Header/Header";
import MainSideBar from "../../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../../Constant/SectionHeader/SectionHeader";
import {
  Spin,
  Button,
  notification,
  Tooltip,
  Popconfirm,
} from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { EditOutlined } from "@ant-design/icons";
import ApiClient from "../../../methods/api/apiClient";
import { Switch } from 'antd';
import axios from "axios";
import { API_URL } from "../../../Constant";
import Item from "antd/lib/list/Item";

export default function Inventory() {
  const navigate = useHistory();
  const showEditModalHandle = (id) => {
    navigate.push(`/addedit/inventory/${id}`);
  };
  // For handling the status of device that is the device should active or deactive
  async function handlestatus(value, event) {
    const Payload = { 
        status:value.status==='active'?'deactive':'active',
        id: value?.id
    }
    try {
        axios.put(`${API_URL}inventory`,Payload).then(res=>{
            if(event==false){
                notification.success({
                    message: 'Device is In-Active.',
                });    
            }
            else{ 
                notification.success({
                    message: 'Device is Active.',
                });
                getinventories();
            }
        }).catch(err=>{
            notification.error({message:"Some error occurred"})
        }) 
    } catch (e) {
        console.log(e);
    }
}   
// for deleting the record of the invenntory
  const deleterecord = (id) => {
    ApiClient.delete(`inventory?id=${id}`)
      .then((res) => {
        getinventories();
        notification.success({ message: "Deleted Success" });
      })
      .catch((err) => notification.error({ message: "Some error Occurred" }));
  };
  const [loading, setloading] = useState(false);
  const [mydata, setmydata] = useState([]); 
// for getting all the data that is in list of inventories
  const getinventories = () => {
    setloading(true);
    ApiClient.get("inventories",{ page: 1,count: 1000 })
      .then((res) => {
        setmydata(res?.data?.data);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        notification.error({ message: "Some error occurred" });
      });
  };
  useEffect(() => {
    getinventories(); 
  }, []);

  const goback = () => {
    navigate.push("/addedit/inventory");
  };
  const handlelist = (id) => {
    navigate.push(`/inventory/details/${id}`);
  };
  //Columns for the table
  const columns = [
    {
      title: "Device-Name",
      dataIndex: "category_name",
      field: "category_name",
      render: (text) => (
        <>
          <Tooltip title="View">
        <span className="text-capitalize text-warning" style={{cursor:"pointer"}} onClick={() => handlelist(text.id)}> 
          {text.category_name && text?.category_name} 
        </span>
        </Tooltip>
        </>
      ),
    },
    {
      title: "Serial Number",
      dataIndex: "serial_number",
      field: "serial_number",
    },
    {
      title: "Code",
      dataIndex: "code",
      field: "code",
    },
    {
title:"Image",
dataIndex:"Image",
field:"Image",
render:(text,record)=>(
  <>
  {text.InventoryImage==""||!text.InventoryImage?<p>No Image</p>:<img src={text.InventoryImage} width={50} height={50}/>}
  </>
)
    },
    {
        title: "Status",
        dataIndex: "isDeleted",
        field: "isDeleted",
        render: (text, record) => ( 
            <>
                {text && text.status ? (
                    <>
                        {text.status =="active" ?
                            <Switch  defaultChecked onChange={(e) => handlestatus(text, e)} />
                            : 
                            <Switch   onChange={(e) => handlestatus(text, e)} />}
                    </>
                ) : (
                    "--"
                )}
            </>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      field: "action",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            // justifyContent: 'space-evenly',
            maxWidth: "118px",
          }}
        >
          <span
            className="edit_icon"
            style={{
              verticalAlign: "text-bottom",
            }}
          >
            <Tooltip title="Edit">
              <Button
                type="primary"
                className="edit_btn"
                onClick={(e) => showEditModalHandle(text && text.id)}
              >
                {<EditOutlined />}
              </Button>
            </Tooltip>
          </span>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to Delete?"
              onConfirm={() => deleterecord(text.id)}
              onCancel={() => console.log("Cancled")}
              okText="Yes"
              cancelText="No"
            >
              <Button type=" deletebtn" className="ml-2">
                <i class="fa-solid fa-trash"></i>
              </Button>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];
  // Data for Table
  const data =
    mydata && mydata.length
      ? mydata.map((items) => {
          return {
            ...items,
          };
        })
      : [];

  // TableIcons for the Table
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <div>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title="Inventory" />
          <Tooltip title="Add Inventory">
            <Button
              type="primary"
              onClick={goback}
              shape="circle"
              icon={<PlusSquareOutlined />}
              size={"large"}
              className="btn btn-primary profile"
            />
          </Tooltip>
          <Spin spinning={loading}>
            {/* Code Here  */}
            <MaterialTable
             options={{
              pageSize:10,
              pageSizeOptions:[10,20,30,40,50], 
               emptyRowsWhenPaging: false,
               showTitle:false,
               draggable:false
         }}   
              columns={columns}
              data={data}
              icons={tableIcons}
            />
          </Spin>
        </section>
      </div>
    </div>
  );
}
