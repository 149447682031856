import React, { useEffect, useState, forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Spin, Select, message, Tooltip, Button, notification } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { alappraisal, getUsers } from '../../Api/user/userapi';
import { useHistory } from 'react-router-dom';
import { EditOutlined, } from '@ant-design/icons';
import ApiClient from '../../methods/api/apiClient';

const Appraisal = (props) => {
    const [loading, setLoading] = useState(false);
    const [array, setArray] = useState([]);
    const history = useHistory();
    const [allappraisal, setallappraisal] = useState([])
    const [cross, setcross] = useState(false)
    const [dropdownvalue, setdropdownvalue] = useState("")
    const [Upcominglappraisal, setalUpcominglappraisal] = useState([])

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    // Date & Time format
    const monthArray = [
        { value: 'Jan', id: 0 },
        { value: 'Feb', id: 1 },
        { value: 'Mar', id: 2 },
        { value: 'Apr', id: 3 },
        { value: 'May', id: 4 },
        { value: 'Jun', id: 5 },
        { value: 'Jul', id: 6 },
        { value: 'Aug', id: 7 },
        { value: 'Sep', id: 8 },
        { value: 'Oct', id: 9 },
        { value: 'Nov', id: 10 },
        { value: 'Dec', id: 11 }
    ]
    const monthfind = (id) => {
        let value = 0
        let ext = monthArray.find(itm => itm.id === id)
        if (ext) value = ext.value
        return value
    }
    const date = (p) => {
        let d = new Date(p)
        return `${d.getDate()} ${monthfind(d.getMonth())} ${d.getFullYear()}`
    }
    const time = (p) => {
        if (!p) return ''
        let v = p.split('T')
        if (v.length === 2) {
            return new Date(p).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        } else {
            return new Date(`2020-07-19 ${p}`).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        }
    }

    const editappraisal = (data) => {
        history.push(`appraisalform/${data._id}`)
    }

    const columns = [
        {
            title: "Employee Name",
            dataIndex: "name",
            field: "name"
        },
        {
            title: "Appraisal Amount",
            dataIndex: "appraisalAmt",
            field: "appraisalAmt",
        },
        {
            title: "Date",
            dataIndex: "date",
            field: "date",
            render: (text, record) => (
                <>
                    {text && text.date
                        ?
                        date(text && text.date)
                        :
                        "--"
                    }
                </>
            ),
        },
        {
            title: "Previous Salary",
            dataIndex: "previousAmt",
            field: "previousAmt",
        },
        {
            title: "Current Salary ",
            dataIndex: "currentAmt",
            field: "currentAmt",
        },
        {
            title: "Action ",
            dataIndex: "action",
            field: "action",
            render: (text, record) => (
                <>
                    <Tooltip title="Edit">
                        {/* <Button  className='edit_btn bg-primary' onClick={e=>editappraisal(text)}><EditOutlined /></Button> */}
                        <Button type="primary" className="edit_btn" onClick={e => editappraisal(text)}>
                            {<EditOutlined />}
                        </Button>
                    </Tooltip>
                </>
            ),
        },
    ];
    const columns2 = [
        {
            title: "Employee Name",
            dataIndex: "name",
            field: "name"
        },
        // {
        //     title: "Date",
        //     dataIndex: "date",
        //     field: "date",
        //     render: (text, record) => (
        //         <>
        //             {text && text.date
        //                 ?
        //                 date(text && text.date)
        //                 :
        //                 "--"
        //             }
        //         </>
        //     ),
        // },
        {
            title: "Upcoming Appraisal Date",
            dataIndex: "date",
            field: "appraisal_date",
            render: (text, record) => (
                <>
                    {text && text.appraisal_date
                        ?
                        date(text && text.appraisal_date)
                        :
                        "--"
                    }
                </>
            ),
        },
        {
            title: "Days Remains",
            dataIndex: "days",
            field: "days",
            render: (text, record) => (
                <>
                    {text && text.days
                        ?
                        Math.trunc(text && text.days)
                        :
                        "--"
                    }
                </>
            ),
        },
        {
            title: "Previous Salary",
            dataIndex: "previousAmt",
            field: "previousAmt",
        },
        {
            title: "Current Salary ",
            dataIndex: "currentAmt",
            field: "currentAmt",
        },
        // {
        //     title: "Action ",
        //     dataIndex: "action",
        //     field: "action",
        //     render: (text, record) => (
        //         <>
        //             <Tooltip title="Edit">
        //                 {/* <Button  className='edit_btn bg-primary' onClick={e=>editappraisal(text)}><EditOutlined /></Button> */}
        //                 <Button type="primary" className="edit_btn" onClick={e => editappraisal(text)}>
        //                     {<EditOutlined />}
        //                 </Button>
        //             </Tooltip>
        //         </>
        //     ),
        // },
    ];

    const newlyAddedChapter =
        allappraisal && allappraisal.length
            ? allappraisal.map((items) => {
                return {
                    ...items,
                };
            })
            : [];
    const newlyAddedChapter1 =
    Upcominglappraisal && Upcominglappraisal.length
            ? Upcominglappraisal.map((items) => {
                return {
                    ...items,
                };
            })
            : [];

    const renderSubmit = () => {
        props.history.push('/appraisalform')
    }
    const getupcomingAppraisal = (p={}) => {
        // setdropdownvalue(id)
        let payload={
            ...p
        }
        setLoading(true)
        ApiClient.get(`upcomingappraisals`,payload).then(res => {
            if (res.success) {
                setalUpcominglappraisal(res?.data)
                setLoading(false)
            } else {
                notification.error({ message: res?.message })
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        Allappraisal();
        userDetails();
        getupcomingAppraisal()
    }, [])

    const userDetails = async () => {
        try {
            setLoading(true);
            const resp = await getUsers();
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                // console.log(arrayData);
                setArray(arrayData)
            }
        } catch (e) {
            localStorage.clear();
            setTimeout(() => {
                notification.error({
                    message: 'Unauthorized',
                    description: 'Your Session Has Expired. Please logIn.',
                });
                localStorage.clear();
            }, 1000);
            history.push("/");
        }
        setLoading(false);
    };

    const Allappraisal = async () => {
        setLoading(true)
        try {
            const response = await alappraisal();
            const maindata = response.data.data
            setallappraisal(maindata)
            setLoading(false)
            // console.log(maindata, "data is here");
        } catch (e) {
            console.log(e, 'error');
            setLoading(false)
        }
    }
    const getappraisal = (id) => {
        setdropdownvalue(id)
        setLoading(true)
        ApiClient.get(`getallappraisal?employee_id=${id}`).then(res => {
            if (res.success) {
                setallappraisal(res?.data)
                setLoading(false)
            } else {
                notification.error({ message: res?.message })
                setLoading(false)
            }
        })
    }
    const getcross = () => {
        setcross(false)
        setdropdownvalue("")
        Allappraisal()
    }

    return (
        <>
            <div >
                <div className="main-wrapper" id="mainPannel">
                    <div className="navbar-bg" />
                    <Header />
                    <MainSideBar />
                </div>
                <div className="main-content">
                    <section className="section">
                        <SectionHeader title="Manage Appraisal" />

                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">All Appraisal</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Upcoming Appraisals</a>
                            </li>
                            {/* <li class="nav-item">
                                <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Contact</a>
                            </li> */}
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">  <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <Tooltip title="Add Appraisal">
                                    <Button type="primary"
                                        onClick={renderSubmit}
                                        shape="circle" icon={<PlusSquareOutlined />}
                                        size={'large'}
                                        className='btn btn-primary profile'
                                    />
                                </Tooltip>
                            </div>
                                <div className='row'>
                                    <div className='col-md-3 mb-3'> <select className='col-md-12 mr-3' value={dropdownvalue} onChange={e => getappraisal(e.target.value) & setcross(true)}>
                                        <option value="" >Select Employees</option>
                                        {array && array.map((item) => {
                                            return <option key={item?._id} value={item?._id}>{item?.fullName} ({item?.role})</option>
                                        })}
                                    </select>
                                    </div>
                                    <div className='col-md-2'>
                                        {cross ? <i class="fa fa-times mt-2" title='Clear' onClick={getcross} ></i>
                                            : <></>}
                                    </div>
                                </div>
                                <Spin spinning={loading}>
                                    <MaterialTable
                                        options={{
                                            showTitle: false,
                                            pageSize: 10,
                                            pageSizeOptions: [10, 20, 30, 40, 50],
                                            emptyRowsWhenPaging: false,
                                            draggable: false
                                        }}
                                        columns={columns}
                                        data={newlyAddedChapter}
                                        icons={tableIcons}
                                    />
                                </Spin></div>
                            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"> <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <Tooltip title="Add Appraisal">
                                    <Button type="primary"
                                        onClick={renderSubmit}
                                        shape="circle" icon={<PlusSquareOutlined />}
                                        size={'large'}
                                        className='btn btn-primary profile'
                                    />
                                </Tooltip>
                            </div>
                                <Spin spinning={loading}>
                                    <MaterialTable
                                        options={{
                                            showTitle: false,
                                            pageSize: 10,
                                            pageSizeOptions: [10, 20, 30, 40, 50],
                                            emptyRowsWhenPaging: false,
                                            draggable: false
                                        }}
                                        columns={columns2}
                                        data={newlyAddedChapter1}
                                        icons={tableIcons}
                                    />
                                </Spin></div>
                            {/* <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">Profile</div> */}
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default Appraisal;