import { React, useEffect, useState, forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Table, Spin, Select, message, Button, Tooltip } from 'antd';
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from 'moment';
import MaterialTable from 'material-table';
import { useHistory, useParams } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import {
    PlusSquareOutlined, DeleteOutlined,
    EditOutlined,
} from '@ant-design/icons';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


export default function Department() {
    const [loading,setloading]=useState(false);
    const history=useHistory();
    const renderSubmitBack = () => {
        alert("Hello")
    };

  return (
    <div> 
                    <div>
                <div className="main-wrapper" id="mainPannel">
                    <div className="navbar-bg" />
                    <Header />
                    <MainSideBar />
                </div>
                <div className="main-content">
                    <section className="section">
                        <SectionHeader title="Departments" />
                        <div>
                            <Tooltip title="Add Department">
                                <Button
                                type="primary"
                                onClick={renderSubmitBack}
                                shape="circle"
                                icon={<PlusSquareOutlined />}
                                size={"large"}
                                className="btn btn-primary profile"
                                />
                            </Tooltip>
                        </div>
                        <Spin spinning={loading}>

                        </Spin>
                        </section>
                        </div>
                        </div>
    </div>
  )
}
