import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "antd/dist/antd.css";
import Login from "./Component/Auth/Login/Login";
import ForgotPassword from "./Component/Auth/Forgot/ForgotPassword";
import Dashbord from "./Component/Dashbord/Dashbord";
import ProjectChart from "./Component/ProjectChart/ProjectChart";
import ResetPassword from "./Component/Auth/ResetPassword/ResetPassword";
import Profile from "./Component/Profile/Profile";
import Platform from "./Component/Platform/Platform";
import AllPlatforms from "./Component/Platform/AllPlatforms";
import AddEditUser from "./Component/User/AddEditUser";
import AllCategories from "./Component/Categories/Categories";
import AllProjects from "./Component/ProjectChart/AllProjects";
import ProjectDetails from "./Component/ProjectChart/ProjectDetails";
import AddRoles from "./Component/Roles/AddRoles";
import AllRoles from "./Component/Roles/AllRoles";
import ProjectAssign from "./Component/ProjectChart/ProjectAssign";
import Attandance from "./Component/Attandance/Attandance";
import Resignation from "./Component/Resignation/Resignation";
import ManageAttandace from "./Component/ManageAttandace/ManageAttandace";
import AllUsers from "./Component/User/AllUsers";
import UserDetails from "./Component/User/UserDetails";
import TabsProvider from "./Provider/Provider";
import Knowledge from "./Component/Knowledge/Knowledge";
import Appraisal from "./Component/Appraisal/Appraisal";
import Appraisalform from "./Component/Appraisal/Appraisalform";
import Xluploadfile from "./Component/Xlfile/Xluploadfile";
import HrDashboard from "./Component/Dashbord/HrDashboard";
import Attandace from "./Component/HrComponent/Attandace";
import HrResignation from "./Component/Resignation/HrResigration";
import ProjectFile from "./Component/ProjectChart/ProjectFile";
import KnowledgeDetails from "./Component/Knowledge/KnowledgeDetails";
import LeaveDetails from "./Component/Attandance/LeaveDetails";
import ResignDetails from "./Component/Resignation/ResignDetails";
import Tabledataxls from "./Component/ProjectChart/Tabledataxls";
import UpdateXLsheet from "./Component/ProjectChart/UpdateXLsheet";
import Dailytask from "./Component/Dailytask/Dailytask";
import Singleuserdailytask from "./Component/Singleusertask/Singleuserdailytask";
import Hrexpensive from "./Component/Hrexpensive/Hrexpensive";
import HrExpensiveTable from "./Component/Hrexpensive/HrExpensiveTable";
import Projecttask from "./Component/ProjectChart/Projecttask";
import Sales from "./Component/SalesModule/Sales";
import AddEditSales from "./Component/SalesModule/AddEditSales";
import ViewSales from "./Component/SalesModule/ViewSales";
import Viewportfolio from "./Component/SalesModule/Viewportfolio";
import Portfolio from "./Component/SalesModule/Portfolio";
import AddEditPortfolio from "./Component/SalesModule/AddEditPortfolio";
import EditProfile from "./Component/Profile/EditProfile";
import ChangePassword from "./Component/Auth/ChangePassword/ChangePassword";
import ViewLinkData from "./Component/SalesModule/ViewLinkData";
import Downloadtask from "./Component/ProjectChart/Downlodtask";
import SingleuserAttendance from "./Component/ManageAttandace/SingleuserAttendance";
import Dailytasklist from "./Component/Dailytask/Dailytasklist";
import System from "./Component/Inventories/System";
import AddSystem from "./Component/Inventories/AddSystem";
import SystemDetails from "./Component/Inventories/SystemDetails";
import Inventory from "./Component/Inventories/AllInventory/Inventory";
import AddEditInventory from "./Component/Inventories/AllInventory/AddEditInventory";
import InventoryDetails from "./Component/Inventories/AllInventory/InventoryDetails";
import OnehrStatusReport from "./Component/Dailytask/OnehrStatusReport";
import AddEditCategory from "./Component/Categories/AddEditCategory";
import ProjectAssociation from "./Component/ProjectChart/ProjectAssociation";
import Setting from "./Component/Settings/Setting";
import Department from "./Component/Departments/Department";
import ViewTask from "./Component/Dailytask/ViewTask";
import AddTask from "./Component/ProjectChart/ProjectTaskAdd/AddTask";
import Bugs from "./Component/ProjectChart/ProjectTaskAdd/Bugs";
import ADDEditbugs from "./Component/ProjectChart/ProjectTaskAdd/ADDEditbugs";
import Laptoplist from "./Component/AssignLaptop/Laptoplist";
import Addeditlaptop from "./Component/AssignLaptop/Addeditlaptop";
import ViewLaptop from "./Component/AssignLaptop/ViewLaptop";
// import ForgotPassword from "./Component/Auth/Forgot/ForgotPassword";
// import SignUp from "./Component/Auth/Signup/Signup";
import Reviewproject from "./Component/ReviewProjects/Reviewproject";
import Addreviewproject from "./Component/ReviewProjects/Addreviewproject";
// import ViewReviewProject from "./Component/ReviewProjects/ViewReviewProject";
import Interview from './Component/Interview/Interviewlist'
import Addeditinterview from './Component/Interview/Addeditinterview'
import Viewinterview from './Component/Interview/Viewinterview'
import SystemComplaint from "./Component/SystemComplaints/SystemComplaint";
import ViewSystemComplaint from "./Component/SystemComplaints/ViewSystemComplaint";
import Hrwallet from "./Component/Hrexpensive/Hrwallet";
import AddAnnouncement from "./Component/ToDo/AddAnnouncement";
import AddDevice from "./Component/AssignLaptop/AddDevice";
import Festivals from "./Component/UpComingEvents/Festivals";
import AddFestival from "./Component/UpComingEvents/AddFestival";
import UpcomingEvents from "./Component/UpComingEvents/UpcomingEvents";
import AddEditAnnounce from "./Component/Annoncements/AddEditAnnounce";
import Announcementslist from "./Component/Annoncements/Announcementslist";

function App() {
  // console.log(minutes)
  //   useEffect(()=>{
  //     var now = new Date();
  // var hour = now.getHours();
  // var day = now.getDay();
  //  const newminutes=minutes.toString();
  //   const newtext=`Please update your one hour status Report`
  //   if( hour >= 11 && newminutes=="00"){
  //           const newvalue= new SpeechSynthesisUtterance(newtext);
  //           newvalue.lang="hi-IN";
  //            window.speechSynthesis.speak(newvalue);
  //   }
  //     // }
  // })

  // useEffect(()=>{
  //   const time=new Date().getMinutes();
  // alert(time);
  // })
  return (
    <Router>
      <TabsProvider>
        <div className="App" id="mainPannel">
          <Switch>
            <Route component={Login} path={`/`} exact />
            {/* <Route exact component={SignUp} path={`/SignUp`}/> */}
            <Route exact path={`/dashboard`} component={Dashbord} />
            <Route exact path={`/hr_dashboard`} component={HrDashboard} />
            <Route exact path={`/laptop`} component={Laptoplist} />
            <Route exact path={`/addeditlaptop`} component={Addeditlaptop} />
            <Route exact path={`/festivals`} component={Festivals} />
            <Route exact path={`/Addfestivals`} component={AddFestival} />
            <Route exact path={`/Addfestivals/:id`} component={AddFestival} />
            <Route exact path={`/upcomingevents`} component={UpcomingEvents} />
            <Route
              exact
              path={`/addeditlaptop/:id`}
              component={Addeditlaptop}
            />
            <Route exact path={`/reviewproject`} component={Reviewproject} />
            <Route
              exact
              path={`/addeditreviewproject`}
              component={Addreviewproject}
            />
            <Route
              exact
              path={`/addeditreviewproject/:id`}
              component={Addreviewproject}
            />
            <Route exact path={`/Attandace`} component={Attandace} />
            <Route
              exact
              path={`/Emplyoee_resignation`}
              component={HrResignation}
            />
            <Route exact path={`/hrexpensive/:id?`} component={Hrexpensive} />
            <Route exact path={`/createProject`} component={ProjectChart} />
            <Route exact path={`/createProject/:id`} component={ProjectChart} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/editprofile/:id" component={EditProfile} />
            <Route exact path="/resetPassword" component={ResetPassword} />
            <Route exact path="/changepassword" component={ChangePassword} />
            <Route exact path="/platform" component={Platform} />
            <Route exact path="/allPlatform" component={AllPlatforms} />
            <Route exact path="/addeditUser" component={AddEditUser} />
            <Route exact path="/addeditUser/:id" component={AddEditUser} />
            <Route exact path="/allUser" component={AllUsers} />
            <Route exact path="/allCategories" component={AllCategories} />
            <Route exact path="/allProject" component={AllProjects} />
            <Route exact path="/projectDetails" component={ProjectDetails} />
            <Route exact path="/userDetails/:id" component={UserDetails} />
            <Route
              exact
              path="/KnowledgeDetails"
              component={KnowledgeDetails}
            />
            <Route exact path="/LeaveDetails" component={LeaveDetails} />
            <Route exact path="/ResignDetails" component={ResignDetails} />
            <Route exact path="/Tabledataxls" component={Tabledataxls} />
            <Route exact path="/addRoles" component={AddRoles} />
            <Route exact path="/allRoles" component={AllRoles} />
            <Route exact path="/projectassign" component={ProjectAssign} />
            <Route exact path="/Leaves" component={Attandance} />
            <Route exact path="/forgotPassword" component={ForgotPassword} />
            <Route exact path="/resignation" component={Resignation} />
            <Route exact path="/manageAttendance" component={ManageAttandace}/>
            <Route exact path="/Knowledge" component={Knowledge} />
            <Route exact path="/appraisal" component={Appraisal} />
            <Route exact path="/appraisalform" component={Appraisalform} />
            <Route exact path="/appraisalform/:id" component={Appraisalform} />
            <Route exact path="/uploadfile" component={Xluploadfile} />
            <Route exact path="/ProjectFile" component={ProjectFile} />
            <Route exact path="/updatedtask" component={UpdateXLsheet} />
            <Route exact path="/dailytasks" component={Dailytask} />
            <Route
              exact
              path="/tasklist/:employeeid?/:projectid?/:value/:enddate"
              component={Dailytasklist}
            />
            <Route
              exact
              path="/singleusertasks"
              component={Singleuserdailytask}
            />
            <Route
              exact
              path="/projectassociation/list/:id?"
              component={ProjectAssociation}
            />
            <Route
              exact
              path="/hrwallet"
              component={Hrwallet}
            />
             <Route
              exact
              path="/hrexpensivetable"
              component={HrExpensiveTable}
            />
             <Route
              exact
              path="/todo"
              component={AddAnnouncement}
            />
            <Route exact path="/projecttask" component={Projecttask} />
            <Route exact path="/projecttask/:id" component={Projecttask} />
            <Route exact path="/sales" component={Sales} />
            <Route exact path="/addeditsales" component={AddEditSales} />
            <Route exact path="/addeditsales/:id" component={AddEditSales} />
            <Route exact path="/viewsales/:id" component={ViewSales} />
            <Route exact path="/viewportfolio/:id" component={Viewportfolio} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route
              exact
              path="/addeditportfolio"
              component={AddEditPortfolio}
            />
            <Route
              exact
              path="/addeditportfolio/:id"
              component={AddEditPortfolio}
            />
            <Route exact path="/viewlinkdata/:key" component={ViewLinkData} />
            <Route exact path="/downloadtask/:id" component={Downloadtask} />
            <Route
              exact
              path="/singleuserattendance/:id/:date/:enddate"
              component={SingleuserAttendance}
            />
            <Route exact path="/system" component={System} />
            <Route exact path={`/addedit/system/:id?`} component={AddSystem} />
            <Route
              exact
              path={`/system/details/:id?`}
              component={SystemDetails}
            />
            <Route
              exact
              path={`/adddevice`}
              component={AddDevice}
            />
            <Route
              exact
              path={`/adddevice/:id`}
              component={AddDevice}
            />
            <Route exact path={`/inventory`} component={Inventory} />
            <Route
              exact
              path={`/addedit/inventory/:id?`}
              component={AddEditInventory}
            />
            <Route
              exact
              path={`/inventory/details/:id?`}
              component={InventoryDetails}
            />
            <Route exact path={`/onehrreport`} component={OnehrStatusReport} />
            <Route
              exact
              path={`/addeditcategory/:id?`}
              component={AddEditCategory}
            />
            <Route exact path={`/settings`} component={Setting} />
            <Route exact path={`/department`} component={Department} />
            <Route exact path={"/task/details/:id"} component={ViewTask} />
            <Route exact path={"/addtasks/:id?"} component={AddTask} />
            <Route exact path={"/bugs/:id?"} component={Bugs} />
            <Route
              exact
              path={`/addeditbugs/:id/:editid?`}
              component={ADDEditbugs}
            />
            <Route exact path={`/view/laptop/:id?`} component={ViewLaptop} /> 
            <Route exact path={"/interview"} component={Interview} />
            <Route exact path="/addeditinterview/:id?" component={Addeditinterview} />
            {/* <Route exact path={`/addeditinterview`} component={Addeditinterview} /> */}
            <Route exact path="/view/interview/:id?" component={Viewinterview}/>
            <Route exact path="/systemcomplaint" component={SystemComplaint}/>
            <Route exact path="/viewsystemcomplaint/:id?" component={ViewSystemComplaint}/>
            <Route exact path="/Add/Edit/announcement" component={AddEditAnnounce}/>
            <Route exact path="/add/edit/announcement/:id" component={AddEditAnnounce}/>
            <Route exact path="/view/announcements" component={Announcementslist}/>
          </Switch>
        </div>
      </TabsProvider>
    </Router>
  );
}

export default App;
