import {ArrowLeftOutlined} from '@ant-design/icons';
import { Button, Spin, Tooltip } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { singlesalesdata } from "../../Api/user/userapi"
import Header from "../../Constant/Header/Header"
import MainSideBar from "../../Constant/MianSideBar/MainSideBar"
import SectionHeader from "../../Constant/SectionHeader/SectionHeader"

const ViewSales=()=>{
    const [data,setdata] = useState()
    const [loading,setloading] = useState(false)
    const {id} = useParams()
    const history=useHistory()

    const backbutton = () => {
        history.goBack();
    }
    const singledata=()=>{
        setloading(true)
        singlesalesdata(`lead?id=${id}`).then(res=>{
            if(res.success){
                setdata(res.data)
                console.log(res.data,"this is data")
                setloading(false)
            }
        })
    }
    useEffect(()=>{
        if(id){
            singledata()
        }
    },[])
    return<>
    <>This is view page</>
        <div className="main-wrapper" id="mainPannel">
            <div className="navbar-bg" />
            <Header />
            <MainSideBar />
        </div>
        <div className="main-content">
        <section className="section">
                <SectionHeader title="View Sales"/>
                <div>
                <Tooltip title="Back Button">
                    <Button type="primary"
                    onClick={e=>backbutton()}
                    shape="circle"
                    icon={<ArrowLeftOutlined />}
                    size={'large'}
                    className='btn btn-primary profile'
                    />
                </Tooltip>
                </div>
                <Spin spinning={loading}>
                    <div>
                        <ul class="list-group">
                            <li class="list-group-item"><b>Title: </b> {data&&data.title}</li>
                            <li class="list-group-item"><b>Country: </b> {data&&data.country}</li>
                            <li class="list-group-item"><b>Date: </b> {moment(data&&data.date).format('d MMM yyyy')}</li>
                            <li class="list-group-item"><b>Upwork URL: </b> {data&&data.upworkurl}</li>
                            <li class="list-group-item"><b>Technology: </b> {data&&data.technology}</li>
                            <li class="list-group-item"><b>Status: </b> {data&&data.status}</li>
                        </ul>
                    </div>
                </Spin>
            </section>
        </div>
    </>
}
export default ViewSales