/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useHistory, } from 'react-router-dom';
import Avatar from 'react-avatar';
import { userDetail } from '../../Api/user/userapi';
import { notification } from 'antd';  
const Header = () => {
  const [Img,setImg]=useState(null);
  const [name, setname] = useState()
  let history = useHistory();
  const handleLogOut = () => {
    localStorage.clear()
    history.push('/');
    setTimeout(() => {
      notification.success({
        message: 'Logout Successfully',
      });
    }, 400);
  }; 
  useEffect(() => {
    userDetails();
  }, []);
  // For getting the user details to show the user information
  const userDetails = async () => {
    try {
      const userId = localStorage.getItem('userID');
      const resp = await userDetail(userId);
      if (resp && resp.status === 200) {
        // console.log(resp.data.data.image);
        setImg(resp.data.data.image)
        setname(resp.data.data.fullName)
      }
    } catch (e) {
      console.log(e);
    }
  };
  const RenderProfile = () => { 
    history.push('/profile');
  }; 
  const ChangePassword = () => {
    history.push('/changepassword');
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light">

    {/* Search Bar */}
      {/* <div className='search w-25 ml-5'id="mainPannel">
        <input type="text" className='form-control inputDiv' />
        <i class="fa fa-search fasearch" aria-hidden="true"></i>
      </div> */}
    
      <ul className="navbar-nav ml-auto  mt-lg-0 ">
        {/* <li className='mr-3'><i class="fa fa-arrows-alt" aria-hidden="true"></i></li>
        <li className='mr-3'><i class="fa fa-envelope" aria-hidden="true"></i><sup className='sup1'>1</sup></li>
        <li className='mr-3'><i class="fa fa-bell" aria-hidden="true"></i><sup  className='sup2'>1</sup></li> */}
        <li className="dropdown">
          {/* <h3 className="headding_class">
          Welcome to Dashboard
          </h3> */}
          <a
           onClick={RenderProfile}
            data-toggle="dropdown"
            className="nav-link dropdown-toggle nav-link-lg nav-link-user"
          >
            {
              Img?
              <img alt='' width={30} height={32} style={{borderRadius:"50%"}} src={`${Img}`}/>: 
              <Avatar name={name} size={40} round="52px" className="avtar" onClick={RenderProfile}/>
            }
          </a>
        </li>
        <li className='mr-3'><b>{name}</b></li>
        <a
            href="#"
            data-toggle="dropdown"
            className="nav-link dropdown-toggle nav-link-lg nav-link-user"
        ><li><i className="fa fa-ellipsis-v" aria-hidden="true"></i></li>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
            <a
              className="dropdown-item has-icon text-primary"
              onClick={RenderProfile}
              style={{ cursor: 'pointer' }}
            >
              <i className="fas fa-user" /> Profile
            </a>

            <a
              className="dropdown-item has-icon text-primary"
              onClick={ChangePassword}
              style={{ cursor: 'pointer' }}
            >
              <i className="fas fa-user" /> Change Password
            </a>
            
            <a
              className="dropdown-item has-icon text-danger"
              onClick={handleLogOut}
              style={{ cursor: 'pointer' }}
            >
              <i className="fas fa-sign-out-alt" /> Logout
            </a>
          </div>
      </ul>
    </nav>
  );
};

export default Header;