import React, { useEffect, useState } from 'react'
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import {Form,Input,Row,Col,Divider,Button,Image, Spin, Tooltip} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import '../User/user.css';
import { useHistory, useParams } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import { EditOutlined } from "@ant-design/icons";

export default function SystemDetails() {
    const [loading,setloading]=useState(false);
    const history=useHistory();
    const [data,setdata]=useState();
    const goback=(e)=>{
        e.preventDefault();
        history.goBack();
    }
    const {id}=useParams();

    // For getting the data on the base of id
    const getdatabyid=()=>{ 
     setloading(true);
  ApiClient.get(`system?id=${id}`).then(res=>{
    setloading(false)
  setdata(res.data)
  }).catch(err=>setloading(false))
    }
    useEffect(()=>{
   getdatabyid();
    },[])
  const system="dekstpfijdshf"
  
  const editdetails=()=>{
    history.push(`/addedit/system/${id}`)
  }
  return (
    <div className="text-left">
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
        <Spin spinning={loading}>
          <SectionHeader title="System Details" />
          <div>
            <Button type="primary"
              onClick={goback}
              shape="circle"
              icon={<ArrowLeftOutlined />}
              size={'large'}
              className="btn btn-primary profile"
            />
          </div>
          {data?.system=="laptop"?
          <div className="container pl-0">
          <div className="shadow w-100">
            <span>
              <Tooltip title="Edit">
                  <Button type="primary" className="edit_btn edit-button mr-2 mt-2 f-right" onClick={e=>editdetails()}>
                      {<EditOutlined  />}
                  </Button>
              </Tooltip>
            </span>
            <div class="row p-4">
              <div class="col-md-4">
                <label className="text-bold ">User Name:</label>
                <span className='text-capitalize'>{data?.user_name} </span>
              </div>
              <div class="col-md-4">
                <label className="text-bold">System Password:</label>
                <span>{data?.system_password}</span>
              </div>
              <div class="col-md-4">
                <label className="text-bold">Laptop Name:</label>
                <span className='text-capitalize'>{data?.laptopName}</span>
              </div>
              <div class="col-md-4">
                <label className="text-bold">IP Address:</label>
                <span className='text-capitalize'>{data?.ip}</span>
              </div>
              <div class="col-md-4">
                <label className="text-bold">Operating System:</label>
                <span className='text-capitalize'>{data?.operating_system}</span>
              </div>
              <div class="col-md-4">
                <label className="text-bold">OS Type:</label>
                <span>{data?.os_type}</span>
              </div>
              <div class="col-md-4">
                <label className="text-bold">OS Version:</label>
                <span>{data?.os_version}</span>
              </div>
              <div class="col-md-4">
                <label className="text-bold">RAM:</label>
                <span>{data?.ram}</span>
              </div>
              <div class="col-md-8">
                <label className="text-bold">Processor:</label>
                <span>{data?.processor}</span>
              </div>
              <div class="col-md-4">
                <label className="text-bold">Status:</label>
                <span className='text-capitalize'>{data?.status}</span>
              </div>
              <div class="col-md-4">
                <label className="text-bold">Disk:</label>
                <span>{data?.drives}</span>
              </div>
              <div class="col-md-4">
                <label className="text-bold">Disk Size:</label>
                <span>{data?.drives_size}</span>
              </div>
              <div class="col-md-4">
                <label className="text-bold">Seat Number:</label>
                <span>{data?.seat_number}</span>
              </div>
              <div class="col-md-4">
                <label className="text-bold">Is Assignable:</label>
                <span className='text-capitalize'>{data?.isAssignable=="Y"?"Yes":"NO"}</span>
              </div>
            </div>
          </div>
        </div>
          // Laptop end
          :
          <div className="container pl-0">
            {/* <h4 className="left detailss ml-2"></h4> */}
            <div className="shadow w-100">
              <span>
                <Tooltip title="Edit">
                    <Button type="primary" className="edit_btn edit-button mr-2 mt-2 f-right" onClick={e=>editdetails()}>
                        {<EditOutlined  />}
                    </Button>
                </Tooltip>
              </span>
              <div class="row p-4">
                <div class="col-md-4">
                  <label className="text-bold ">User Name:</label>
                  <span className='text-capitalize'>{data?.user_name} </span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">System Password:</label>
                  <span>{data?.system_password}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Operating System:</label>
                  <span className='text-capitalize'>{data?.operating_system}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">OS Type:</label>
                  <span>{data?.os_type}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">OS Version:</label>
                  <span>{data?.os_version}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Ram:</label>
                  <span>{data?.ram}</span>
                </div>
                <div class="col-md-8">
                  <label className="text-bold">Processor:</label>
                  <span>{data?.processor}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">CPU Code:</label>
                  <span>{data?.cpu_code}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Status:</label>
                  <span className='text-capitalize'>{data?.status}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">CPU Serial Number:</label>
                  <span>{data?.cpu_serial_number}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Disk:</label>
                  <span>{data?.drives}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Disk Size:</label>
                  <span>{data?.drives_size}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Keyboard Code:</label>
                  <span>{data?.keyboard_code}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Keyboard Serial Number:</label>
                  <span>{data?.keyboard_serial_number}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Mouse Code:</label>
                  <span>{data?.mouse_code}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Mouse Serial Number:</label>
                  <span>{data?.mouse_serial_number}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Screen Code:</label>
                  <span>{data?.screen_code}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Screen Serial Number:</label>
                  <span>{data?.screen_serial_number}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">Seat Number:</label>
                  <span>{data?.seat_number}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">SMPS:</label>
                  <span>{data?.smps}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">UPS Code:</label>
                  <span>{data?.ups_code}</span>
                </div>
                <div class="col-md-4">
                  <label className="text-bold">UPS Serial Number:</label>
                  <span>{data?.ups_serial_number}</span>
                </div>
                <div class="col-md-12">
                  <label className="text-bold">Description:</label>
                  <span>{data?.description}</span>
                </div>
              </div>
            </div>
          </div>}
          </Spin>
        </section>
      </div>
    </div>
  )
}
