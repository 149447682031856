import React, { useEffect, useState } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Tooltip, Button, Select, notification, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { addappraisal, getUsers } from '../../Api/user/userapi';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ApiClient from '../../methods/api/apiClient';
import { Form } from 'antd';
const { Option } = Select;

const Appraisalform = (props) => {
  const [data, setdata] = useState({});
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(data.currentAmt);
  const [mailinglist, setMailinglist] = useState([]);
  const {id} = useParams();
  const [emailerror,setemailerror]=useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()
    if(data.email==""||data.email==null){setemailerror(true)}else{setemailerror(false)
    let employeeid = ''
    if(data.email){
      mailinglist&&mailinglist.map(item=>{
        if(data.email == item.email){
          employeeid = item.id
        }
      })
    }
    const payload = {
      ...data, currentAmt:total, employeeId:employeeid
    }

    if(!id){
      try {
        const response = await addappraisal(payload);
        //   history.push('/');
        props.history.push('/appraisal')
        setTimeout(() => {
          notification.success({
            message: 'Appraisal Added Successfully'
          });
        }, 500);
      } catch (e) {
        notification.error({
          message:e.message
        })
      }
    }
    else{
      ApiClient.put(`appraisal`,payload).then(res=>{
        if(res.success){
          props.history.push('/appraisal')
          setTimeout(() => {
            notification.success({
              message: 'Appraisal Updated Successfully'
            });
          }, 500);
        }
        else{
          notification.error({
            message:res.message
          })
        }
      })
    }
  }
  }

  const renderSubmit = () => {
    props.history.push('/appraisal')
  }
  function calculateTotal() {
    const total=parseInt(data.previousAmt) + parseInt(data.appraisalAmt);
    if(!isNaN(total)){ 
      setTotal(total);
    }else{}
  }
  useEffect(()=>{
    const total=parseInt(data.previousAmt) + parseInt(data.appraisalAmt);
    if(!isNaN(total)){
      calculateTotal();
    }
    else{
      setTotal("")
    }
  })
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() - 0).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

  useEffect(() => {
    userDetails();
    if(id){
      getdata()
    }
  }, []);
  async function handlechange(value){
    setemailerror(false)
    setdata({...data,email:value})
  }

  const userDetails = async () => {
    try {
      setLoading(true);
      const resp = await getUsers();
      if (resp && resp.status === 200) {
        const list = resp;
        const arrayData = list.data.data;
        setMailinglist(arrayData)
      }
    } catch (e) {
      localStorage.clear();
      props.history.push("/")
      setTimeout(() => {
        notification.error({
          message: 'Unauthorized',
          description: 'Your Session Has Expired. Please logIn.',
        });
      }, 1000);
    }
    setLoading(false);
  };

  const getdata=()=>{
    setLoading(true)
    ApiClient.get(`appraisal/single/user?id=${id}`).then(res=>{
      if(res.success){
        // setMailinglist(res.data)
        setdata(res.data)
      }
      setLoading(false)
    })
  }
  useEffect(()=>{
    if(!id&&data.email!=""&&data.email!=null){
       let filterdata= mailinglist.filter((array,index)=>{if(array.email==data.email){return array}})
       if(filterdata.length>0){
          setdata({...data,name:filterdata[0].fullName})
       }else{setdata({...data,name:""})}
    }
  },[data.email])

  return (
    <div>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title={!id?"Add Appraisal":"Edit Appraisal"} />
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
            <Tooltip title="Back">
              <Button type="primary"
                onClick={renderSubmit}
                shape="circle"
                icon={<ArrowLeftOutlined />}
                size={'large'}
                className='btn btn-primary profile'
              />
            </Tooltip>
          </div>

          <div className='apprailsel'>
            <div className="cont_div text-left" >
              <h3 style={{ marginLeft: "-16%" }} className="form"></h3>
              <form onSubmit={handleSubmit} >
                <Spin spinning={loading}>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Email<span className='redstar'> *</span></label>
                      {!id?
                            <Form.Item
                            name="email"
                            rules={[
                              { required: true, message: 'Please Select Email' },
                            ]}
                          >
                        <Select 
                          showSearch={true}
                          placeholder="Select an email" 
                          onChange={handlechange} 
                        > 
                          {mailinglist&&mailinglist.map(item=>(
                            <Option key={item.email} >{item.email}</Option>
                          ))}
                        </Select>
                        </Form.Item>
                      :
                        <select
                          className='w-100 mb-2 border'
                          name='email' 
                          value={data.email?data.email:""}
                          onChange={e=>setdata({ ...data, email:e.target.value})}
                          disabled
                          required
                        >
                          <option selected>Select Email</option>
                          {mailinglist&&mailinglist.map(item=>(
                            <option value={item.email}>{item.email}</option>
                          ))}
                        </select>
                      }
                      <span className='text-danger'>{emailerror?"Please select email":null}</span>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="name" class="ml-3">Employee Name<span className='redstar'> *</span></label>
                      {!id?
                        <select
                        disabled
                          className='w-100 mb-2 border'
                          name='name'
                          value={data.name?data.name:""}
                          onChange={e=>setdata({ ...data, name:e.target.value})}
                          required
                          >
                          <option value="" selected>Select Name</option>
                          {mailinglist&&mailinglist.map(item=>(
                            <option value={item.fullName}>{item.fullName}</option>
                          ))}
                        </select>
                      :
                        <select
                          className='w-100 mb-2 border'
                          name='name'
                          value={data.name?data.name:""}
                          onChange={e=>setdata({ ...data, name:e.target.value})}
                          disabled
                          required
                          >
                          <option selected>Select Name</option>
                          {mailinglist&&mailinglist.map(item=>(
                            <option value={data.name}>{data.name}</option>
                          ))}
                        </select>
                      }
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="inputCity" class="ml-3">Previous Salary<span className='redstar'> *</span></label>
                      <input className='form-control pl-2' value={data.previousAmt?data.previousAmt:""} onChange={e=>setdata({ ...data, previousAmt:e.target.value})} placeholder='Enter previous salary' type="number" name='previoussalary' required></input>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputState" class="ml-3">Appraisal Amount<span className='redstar'> *</span></label>
                      <input className='form-control pl-2' value={data.appraisalAmt?data.appraisalAmt:""} onChange={e=>setdata({ ...data, appraisalAmt:e.target.value})} placeholder='Enter appraisal salary' type="number" name='appraisalsalary' required></input>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputAddress" class="ml-3">Date<span className='redstar'> *</span></label>
                      {/* <input className='form-control pl-2' min={disablePastDate()} value={data.date?data.date:""} onChange={e=>setdata({ ...data, date:e.target.value})} type="date" name='date' required></input> */}
                      <input className='form-control pl-2' value={data.date?data.date:""} onChange={e=>setdata({ ...data, date:e.target.value})} type="date" name='date' required></input>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputZip" className="ml-3">Current Salary</label>
                      <input className='form-control pl-2' disabled value={data.currentAmt?data.currentAmt:total} placeholder='Current salary' type="text" name='currentsalary'></input>
                    </div>
                  </div>
                  <div className='text-center'>
                    <button type='submit' className="btn btn-success w-25" onClick={calculateTotal}>
                      {!id?"Add":"Update"}
                    </button>
                  </div>
                </Spin>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div >
  );
};

export default Appraisalform;