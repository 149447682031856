import React, { useEffect, useState, forwardRef } from 'react'
import Header from "../../Constant/Header/Header"
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import { addAdvance, getwalletDetails } from '../../Api/user/userapi';
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Calendar from "react-calendar";
import {
    Table,
    Spin,
    Select,
    message,
    Button,
    notification,
    Tooltip,
    Popconfirm,
} from "antd";

import moment from 'moment/moment';
import ApiClient from '../../methods/api/apiClient';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';



const Hrwallet = () => {

    const history=useHistory()
    const [toggle, settoggle] = useState(false)
    const [advancepayment, setadvancepayment] = useState()
    const [date, setdate] = useState()
    const [startdate, setstartdate] = useState()
    const [enddate, setenddate] = useState()
    const [data1, setdata1] = useState({ left_amount: '', total_advance: '', totalexpenses: '' })
    const [detail, setdetail] = useState()
    const [value, setvalue] = useState(new Date());
    const [loading, setloading] = useState()
    const [err, seterr] = useState(false)

    useEffect(() => {
        getwalletdetail()
        getadvanvemoney()
    }, [])

    const onChangeDatesss = (newDate) => {
        setloading(true)
        setvalue(newDate);
        const startdate = moment(newDate[0]).format("YYYY-MM-DD");
        const enddat = moment(newDate[1]).format("YYYY-MM-DD");
        let p = {
            start_date: startdate,
            end_date: enddat
        }
        setloading(true)
        ApiClient.get(`get/Hrwallet/details?start_date=${startdate}&end_date=${enddat}`).then(res => {
            if (res.success) {
                setdata1({ ...data1, left_amount: res?.left_amount, total_advance: res?.total_advance, totalexpenses: res?.total_expenses })
            }
            setloading(false)
        })
        getadvanvemoney({ ...p })

    };

    const getwalletdetail = () => {
        // let end_date = moment().format().slice(0, 10)
        // let start_date = moment().subtract(1, 'month').format().slice(0, 10)

        setloading(true)
        ApiClient.get(`get/Hrwallet/details`).then(res => {
            if (res.success) {
                setdata1({ ...data1, left_amount: res?.left_amount, total_advance: res?.total_advance, totalexpenses: res?.total_expenses })
            }
            setloading(false)
        })
    };

    const getadvanvemoney = (p = {}) => {
        let payload = {
            ...p
        }
        setloading(true)
        ApiClient.get(`get/AdvanceMoney/details`, payload).then(res => {
            if (res.success) {
                setdetail(res?.add_advance_data)

            }
            setloading(false)
        })
    }

    const submit = (e) => {
        e.preventDefault()
        if (!advancepayment || !date || advancepayment == 0) {
            seterr(true)
            if (advancepayment == 0 && date){
                notification.error({ message: "Amount cannot be 0" })
            }
        } else {
            seterr(false)
            settoggle(false)
            let data = {
                amount: advancepayment,
                date: date
            }
            setloading(true)
            ApiClient.post(`add/moneyto/Hrwallet`, data).then(res => {
                if (res.success) {
                    setTimeout(() => {
                        notification.success({
                            message: 'Amount Added successfully'
                        })
                        getwalletdetail()
                        getadvanvemoney()
                        setloading(false)
                    }, 10);
                    setadvancepayment()
                    setdate()
                } else {
                    localStorage.clear();
                    history.push("/")
                    setTimeout(() => {
                        notification.error({
                            message: 'Unauthorized',
                            description: 'Your Session Has Expired. Please logIn.',
                        });
                    }, 1000);
                }
                setloading(false);
               
            })
    }
}
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const columns2 = [
    {
        title: "Date",
        dataIndex: "date",
        field: "date",
        render: (text, record) => (
            <span>{moment(text?.date ? text.date : text.createdAt).format("ll")}</span>
        )
    },
    {
        title: "Advance Amount",
        dataIndex: "amount",
        field: "amount",
    },
    // {
    //     title: "Actions",
    //     dataIndex: "actions",
    //     field: "actions",
    //     render: (text, record) => (
    //         <div
    //             style={{
    //                 display: "flex",
    //                 // justifyContent: 'space-evenly',
    //                 maxWidth: "118px",
    //             }}
    //         >
    //             <span
    //                 className="edit_icon"
    //                 style={{
    //                     verticalAlign: "text-bottom",
    //                 }}
    //             >
    //                 <Tooltip title="Edit">
    //                     <Button
    //                         type="primary"
    //                         className="edit_btn"
    //                     >
    //                         {<EditOutlined />}
    //                     </Button>
    //                 </Tooltip>
    //             </span>
    //         </div>
    //     ),
    // },
];

const newlyAddedData = detail
    ? detail.map((items) => {
        return {
            ...items,
        };
    })
    : [];

return (
    <>
        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
                <div className="main-content">
                    <Spin spinning={loading}>
                        <section className='section'>
                            <SectionHeader title='HR Wallet' />
                        </section>
                        <div className='row ml-1'>
                            <div className=" d-flex justify-content-between col-md-12 mb-3">
                                <Calendar
                                    selectRange={true}
                                    onChange={onChangeDatesss}
                                    value={value}
                                    className="calender"
                                />
                            </div>
                            <button
                                className="btn btn-warning mb-4 ml-auto"
                                type="button"
                                id=""
                                onClick={e => settoggle(true)}
                            >
                                Add Advance
                            </button>
                        </div>
                        <div className='row'>
                            <div class="col-md-4">
                                <div
                                    className="card card-statistic-1"
                                    style={{
                                        cursor: 'pointer',
                                        boxShadow: '0 3px 10px rgb(0 0 0 / 20%)'
                                    }}
                                >
                                    <div className="card-wrap">
                                        <div className="card-icon" style={{ backgroundColor: "rgb(227 132 15)" }} >
                                            <i className="fa fa-wallet" style={{ color: '#008000' }} />
                                        </div>
                                        <div className="card-header">
                                            <h4>Total Advance</h4>
                                        </div>
                                        <div className="card-body">{data1 && data1?.total_advance}</div>
                                    </div>
                                </div>
                            </div>
                            {/* <div>total_advance={data1.total_advance}</div> */}
                            <div class="col-md-4">
                                <div
                                    className="card card-statistic-1"
                                    style={{
                                        cursor: 'pointer',
                                        boxShadow: '0 3px 10px rgb(0 0 0 / 20%)'
                                    }}
                                >
                                    <div className="card-wrap">
                                        <div className="card-icon " style={{ backgroundColor: "rgb(227 132 15)" }}  >
                                            <i className="fa fa-wallet" style={{ color: "red" }} />
                                        </div>
                                        <div className="card-header">
                                            <h4>Total Expenses</h4>
                                        </div>
                                        <div className="card-body">{data1?.totalexpenses}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div
                                    className="card card-statistic-1"
                                    style={{
                                        cursor: 'pointer',
                                        boxShadow: '0 3px 10px rgb(0 0 0 / 20%)'
                                    }}
                                >
                                    <div className="card-wrap">
                                        <div className="card-icon " style={{ backgroundColor: "rgb(227 132 15)" }}  >
                                            <i className="fa fa-wallet" style={{ color: "white" }} />
                                        </div>
                                        <div className="card-header">
                                            <h4>Total Remains</h4>
                                        </div>
                                        <div className="card-body">{data1?.left_amount}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {toggle && <form onSubmit={e => submit(e)}>
                            <div className='form-row'>
                                <div className='form-group col-md-6 text-left'>
                                    <div>
                                        <label for="ggg" className='control-label mt-3 '><b>Add Advance (Rs)</b></label>
                                        <input type='number' id='ggg' className="form-control " style={{ paddingLeft: "10px" }} value={advancepayment} onChange={e => setadvancepayment(e.target.value)} placeholder='Add Advance' />
                                        {err && !advancepayment ? <span className='span-red'>Please fill in this field</span> :  advancepayment==0?<span className='span-red'>Amount cannot be zero</span>:<></>}
                                    </div>
                                </div>
                                <div className='form-group col-md-6 text-left'>
                                    <div>
                                        <label for="date" className='control-label mt-3 '><b>Date</b></label>
                                        <input type='Date' id='date' className="form-control " style={{ paddingLeft: "10px" }} value={date} onChange={e => setdate(e.target.value)} placeholder='Add Advance' />
                                        {err && !date ? <span className='span-red'>Please select date</span> : <></>}
                                    </div>
                                </div>

                                <button
                                    className="btn btn-danger mb-4"

                                    type="button"
                                    onClick={e => settoggle(false) & setadvancepayment() & setdate() & seterr(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-warning  ml-auto mb-4"
                                    type="submit"
                                    id=""

                                >Submit

                                </button>
                            </div></form>}
                        <MaterialTable
                            options={{
                                showTitle: false,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 30, 40, 50],
                                emptyRowsWhenPaging: false,
                                draggable: false
                            }}
                            columns={columns2}
                            data={newlyAddedData}
                            icons={tableIcons}
                        />
                    </Spin>
                </div>
            </div>
        </div>

    </>

)
}

export default Hrwallet
