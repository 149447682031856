import Header from "../../Constant/Header/Header"
import React, { forwardRef, useEffect, useState } from 'react'
import MainSideBar from '../../Constant/MianSideBar/MainSideBar'
import { Spin } from 'antd'
import SectionHeader from "../../Constant/SectionHeader/SectionHeader"

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table"
import moment from "moment"
import ApiClient from "../../methods/api/apiClient"

const UpcomingEvents = () => {
    const [loading, setLoading] = useState(false)
    const [birthday, setbirthday] = useState()
    const [anniversary, setanniversary] = useState()
    const [select, setselect] = useState('birthdays')
    const [filter, setfilter] = useState({ page: 1, count: 100000000, sortBy: '' })
    useEffect(() => {
        getbirthdays()
        getanniversary()
    }, [])

    // const Addfestival = () => {

    //     history.push("/Addfestivals" )
    // }
    const getbirthdays = (p = {}) => {
        let payload = { ...filter, ...p }
        setLoading(true)
        ApiClient.get(`birthdays`, payload).then(res => {
            if (res.success) {
                setbirthday(res.data)
            }
            setLoading(false)
        })
    }
    const getanniversary = () => {
        let payload = {page:1,count:10000000 }
        setLoading(true)
        ApiClient.get(`workAnniversary`,payload).then(res => {
            if (res.success) {
                setanniversary(res.data)
            }
            setLoading(false)
        })
    }










    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
        )),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
        )),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => (
            <ArrowDownward {...props} ref={ref} />
        )),
        ThirdStateCheck: forwardRef((props, ref) => (
            <Remove {...props} ref={ref} />
        )),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "fullName",
            field: "fullName",
            // render: (text, record) => (
            //     <span>{moment(text?.date ? text.date : text.createdAt).format("DD MMM YY")}</span>
            // )
        },
        {
            title: "Role",
            dataIndex: "role",
            field: "role",
        },
        // {
        //     title: "DOB",
        //     dataIndex: "dob",
        //     field: 'dob',
        //     render: (text, record) => (
        //         <span>{moment(text?.dob).format("DD MMM YY")}</span>
        //     )
        // },
        {
            title: "Birthday Date",
            dataIndex: "birthday_date",
            field: 'birthday_date',
            render: (text, record) => (
                <span>{moment(text?.birthday_date).format("DD MMM YY")}</span>
            )
        },
        {
            title: "Days Left",
            dataIndex: "days",
            field: 'days',
            render: (text, record) => (
                <span>{parseInt(text?.days)}</span>
            )
        },
        // {
        //     title: "Actions",
        //     dataIndex: "actions",
        //     field: "actions",
        //     render: (text, record) => (
        //         <div
        //             style={{
        //                 display: "flex",
        //                 // justifyContent: 'space-evenly',
        //                 maxWidth: "118px",
        //             }}
        //         >
        //             <span
        //                 className="edit_icon"
        //                 style={{
        //                     verticalAlign: "text-bottom",
        //                 }}
        //             >
        //                 <Tooltip title="Edit">
        //                     <Button
        //                         type="primary"
        //                         className="edit_btn"
        //                         // onClick={e=>editfest(text._id)}
        //                     >
        //                         {<EditOutlined />}
        //                     </Button>
        //                 </Tooltip>
        //             </span>
        //             {/* <Tooltip title="Delete">
        //                 <Popconfirm
        //                     title="Are you sure you want to Delete?"
        //                     onConfirm={() => deleterecord(text._id)}
        //                     onCancel={() => console.log("Cancled")}
        //                     okText="Yes"
        //                     cancelText="No"
        //                 >
        //                     <Button type="deletebtn" className='deletebtn ml-2' >
        //                         <i class="fa-solid fa-trash"></i>
        //                     </Button>
        //                 </Popconfirm>
        //             </Tooltip> */}
        //         </div>

        //     ),
        // },
    ]
    const newlyAddedData = birthday ? birthday.map((items) => {
        return {
            ...items,
        };
    }) : [];
    const newlyAddedData1 = anniversary ? anniversary.map((items) => {
        return {
            ...items,
        };
    }) : [];

    const column1 = [
        {
            title: "Name",
            dataIndex: "fullName",
            field: "fullName",
        },
        {
            title: "Role",
            dataIndex: "role",
            field: "role",
        },
        {
            title: "Date of Joining",
            dataIndex: "dateOfJoining",
            field: 'dateOfJoining',
            render: (text, record) => (
                <span>{moment(text?.dateOfJoining).format("DD MMM YY")}</span>
            )
        },
        {
            title: "Anniversary Date",
            dataIndex: "anniversary_date",
            field: 'anniversary_date',
            render: (text, record) => (
                <span>{moment(text?.anniversary_date).format("DD MMM YY")}</span>
            )
        },
        {
            title: "Days Left",
            dataIndex: "days",
            field: 'days',
            render: (text, record) => (
                <span>{parseInt(text?.days)}</span>
            )
        },
        {
            title: "Total Years in JC Family",
            dataIndex: "years",
            field: 'years',
            render: (text, record) => (
                <span>{parseInt(text?.years)}</span>
            )
        },
    ]

    return (
        <>
            <>
                <div>
                    <div className="main-wrapper" id="mainPannel">
                        <div className="navbar-bg" />
                        <Header />
                        <MainSideBar />
                        <div className="main-content">

                            <section className='section'>
                                <SectionHeader title={select == "anniversary" ? 'Upcoming work Anniversaries' : 'Upcoming Birthdays'} />
                                {/* <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}>
                                    <Tooltip title="Add Events">
                                        <Button type="primary"
                                            onClick={Addfestival}
                                            shape="circle"
                                            icon={<PlusSquareOutlined />}
                                            size={'large'}
                                            className="btn btn-primary profile"
                                        />
                                    </Tooltip>
                                </div> */}
                            </section>
                            <div>
                                <select className="mb-3"
                                    onChange={e => setselect(e.target.value)}>
                                    <option value="birthdays">Birthdays</option>
                                    <option value="anniversary">Anniversaries</option>
                                </select>
                                <Spin spinning={loading}>
                                    {select == "birthdays" ? <div className="">
                                        <MaterialTable
                                            options={{
                                                showTitle: false,
                                                pageSize: 10,
                                                pageSizeOptions: [10, 20, 30, 40, 50],
                                                emptyRowsWhenPaging: false,
                                                draggable:false
                                            }}
                                            columns={columns}
                                            data={newlyAddedData}
                                            icons={tableIcons}
                                        />

                                    </div> : <div className="">
                                        <MaterialTable
                                            options={{
                                                showTitle: false,
                                                pageSize: 10,
                                                pageSizeOptions: [10, 20, 30, 40, 50],
                                                emptyRowsWhenPaging: false,
                                                draggable:false
                                            }}
                                            columns={column1}
                                            data={newlyAddedData1}
                                            icons={tableIcons}
                                        />

                                    </div>}
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div>

            </>

        </>
    )
}

export default UpcomingEvents
