import React, { useEffect, useState, forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Table, Spin, Select, message, Button, notification, Tooltip, Popconfirm } from 'antd';
import { getAllAttandance, mailinglist, updateAttandec } from '../../Api/user/userapi';
import { useSidebar } from '../../Provider/Provider';
import parse from "html-react-parser";
import { PlusSquareOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useHistory } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import {
    DeleteOutlined,
    EditOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import { Switch } from 'antd';
import axios from 'axios';
const { Option } = Select;

export default function System() {
    const [loading, setLoading] = useState(false);
    const [array, setarray] = useState([]);
    const [status, setstatus] = useState();
    const navigate = useHistory();

    // For Redirect to add page
    const renderSubmit = () => {
        navigate.push("/addedit/system");
    }

    const changeStatus = (type, value) => {
        let statuss = ""
        if (type == "active") {
            statuss = "deactive"
        } else { statuss = "active" }
        let payload = { status: statuss, id: value?._id }
        if(window.confirm(`Do you really want to change status ?`)){

            ApiClient.put(`system`, payload).then(res => {
                if (res.success) {
                    
                    if (statuss == "deactive") {
                        notification.success({
                            message: 'System is De-Active.',
                        });
                    }
                    else {
                        notification.success({
                            message: 'System is Active.',
                        });
                    }
                }
                getsystemlist();
                setstatus('')
            })
        }

    }
    // For active Deactive no use of this func. use upper
    async function handleChange(value, event) {
        const Payload = {
            status: event ? "active" : "deactive",
            id: value?._id
        }
        try {
            // const resp = await (Payload);
            ApiClient.put(`system`, Payload).then(res => {
                if (event == false) {
                    notification.success({
                        message: 'System is In-Active.',
                    });
                }
                else {
                    notification.success({
                        message: 'System is Active.',
                    });
                }
                getsystemlist();
                setstatus('')
            }).catch(err => notification.error({ message: "Some error Occurred" }))

        } catch (e) {
            console.log(e);
        }
    }
    const getsystemlist = (p = {}) => {
        setLoading(true);
        let payload = {
            page: '', count: 1000000,
            ...p
        }
        ApiClient.get("systems", payload).then((res) => {
            setarray(res.data.data);
            setLoading(false);

        }).catch(err => setLoading(false))
    }

    // For delete the data
    const removesystem = (id) => {
        setLoading(true)
        ApiClient.delete(`system?id=${id}`).then((res) => {
            getsystemlist();
            notification.success({
                message: "System Information Deleted successfully"
            })
        })
        setLoading(false);
    }

    useEffect(() => {
        getsystemlist();
    }, [])
    const columns = [
        {
            title: "User Name",
            dataIndex: "user_name",
            field: "user_name",
            render: (text, record) => (
                <>
                    {text && text?.operating_system ? (
                        <Link to={`/system/details/${text._id}`}>
                            <Tooltip title="View">
                                <span className="text text-capitalize">
                                    {text.user_name}
                                </span>
                            </Tooltip>
                        </Link>
                    ) : (
                        "--"
                    )}
                </>
            ),
        },
        {
            title: "System Password",
            dataIndex: "system_password",
            field: "system_password",

        },
        {
            title: "Operating System",
            dataIndex: "operating_system",
            field: "operating_system",
            render: (text) => (
                <span className=" text-capitalize">
                    {text.operating_system}
                </span>
            )
        },

        // {
        //     title: "Processor",
        //     dataIndex: "processor",
        //     field: "processor",
        // },
        // {
        //     title: "OS Type",
        //     dataIndex: "os_type",
        //     field: "os_type",
        // },
        {
            title: "Status",
            dataIndex: "isDeleted",
            field: "isDeleted",
            render: (text, record) => (
                <>
                    {/* {text.status =="active" ? <Switch  defaultChecked onChange={(e) => handleChange(text, e)} />  :  <Switch   onChange={(e) => handleChange(text, e)} />}  */}
                    {text.status == "active" ? <Tooltip className='mx-2'>
                        <Button type={`${text.status == "active" ? "primary" : "danger"} `} className='text-capitalize' onClick={() => changeStatus(text.status, text)} >
                            {text.status}
                        </Button>
                    </Tooltip> : <button id="buttons"
                        type="button"
                        className='text-capitalize'
                        onClick={() => changeStatus(text.status, text)}
                        style={{
                            backgroundColor: "#f44336",
                            border: 'none',
                            color: 'white',
                            padding: '3px 7px',
                            textAlign: 'center',
                            textDecoration: 'none',
                            display: 'inline-block',
                            fontSize: '16px',
                            margin: '1px 2px',
                            cursor: 'pointer',
                            borderRadius: '3px'
                        }}
                    >
                        {text.status}
                    </button>}

                </>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            field: "action",
            render: (text, record) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        maxWidth: '118px'
                    }}
                >
                    <span className="edit_icon"
                        style={{
                            verticalAlign: 'text-bottom'
                        }}>
                        <Tooltip placement="left" title="Edit">
                            <Link to={`/addedit/system/${text._id}`}>

                                <Button type="primary" className="edit_btn">

                                    {<EditOutlined />}
                                </Button>
                            </Link>
                        </Tooltip>
                    </span>
                    <span>
                        <Tooltip placement="rightTop" title="Delete">
                            <Popconfirm
                                title="Are you sure you want to delete?"
                                onConfirm={() => removesystem(text._id)}
                                onCancel={() => console.log("Cancelled")}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" className="button">
                                    <DeleteOutlined
                                    />
                                </Button>
                            </Popconfirm>
                        </Tooltip>
                    </span>
                </div>

            ),
        },
    ];

    const newlyAddedChapter =
        array && array.length
            ? array.map((items) => {
                return {
                    ...items,
                };
            })
            : [];
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    const handlestatus = (e) => {
        setstatus(e)
        let payload = {
            status: e,
        }
        getsystemlist({ ...payload })
    }

    return (
        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">
                <section className="section">
                    <SectionHeader title="System Information" />
                    <Tooltip title="Add System">
                        <Button type="primary"
                            onClick={renderSubmit}
                            shape="circle" icon={<PlusSquareOutlined />}
                            size={'large'}
                            className='btn btn-primary profile'
                        />
                    </Tooltip>
                    <div className="form-group col-md-2 text-left">
                        {/* <label className="form-control"> </label> */}
                        <div className="">
                            <select
                                name='Status'
                                value={status}
                                type="text"
                                onChange={e => handlestatus(e.target.value)}

                                className="form-control " style={{ paddingLeft: "10px" }}
                            >
                                <option value="" selected>Status</option>
                                <option value="active">Active</option>
                                <option value="deactive">De-Active</option>

                            </select>
                        </div>
                    </div>
                    <Spin spinning={loading}>
                        {/* Write the code here for more Information */}
                        <MaterialTable
                            options={{
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 30, 40, 50],
                                emptyRowsWhenPaging: false,
                                showTitle: false,
                                draggable:false
                            }}
                            // title="System Information"
                            columns={columns}
                            data={newlyAddedChapter}
                            icons={tableIcons}
                        />
                    </Spin>
                </section>
            </div>
        </div>
    )
}
