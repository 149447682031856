import { Button, notification, Spin, Tooltip } from "antd"
import MaterialTable from "material-table"
import { Link, useHistory } from "react-router-dom"
import MainSideBar from "../../Constant/MianSideBar/MainSideBar"
import SectionHeader from "../../Constant/SectionHeader/SectionHeader"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { forwardRef, useEffect, useState } from "react"
import { PlusSquareOutlined } from '@ant-design/icons';
import Header from "../../Constant/Header/Header"
import { getallPortfolio } from "../../Api/user/userapi"
import ApiClient from "../../methods/api/apiClient"
import { API_URL } from "../../Constant"


const Portfolio = () => {
    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState([])
    const [getdata, setgetData] = useState([])
    const [Share,setShare] =useState()
    const [date,setdate] = useState()
    const [link,setlink] = useState()
    const history = useHistory()

    const handleCheck = (event) => {
        var updatedList = [...getdata];
        if (event.target.checked) {
            updatedList = [...getdata, event.target.value];
        } else {
            updatedList.splice(getdata.indexOf(event.target.value), 1);
        }
        console.log(updatedList, "getdata");
        setgetData(updatedList);
        setShare('')
        setdate('')
        console.log(Share,"Share value")
        console.log(date,"date value")
    };

    const viewdata=(id)=>{
        history.push(`/viewportfolio/${id}`)
    }
    const columns = [
        {
            title: "Checkbox",
            dataIndex: "checkbox",
            field: "checkbox",
            render: (data) => (
                <>
                    <input type="checkbox" value={data.id} onChange={handleCheck} className="ml-4 listcheckbox" id="portfolio" name="portfolio"></input>
                </>
            ),
        },
        {
            title: "Name",
            dataIndex: "name",
            field: "name",
            render: (data) => (
                <>
                    {data && data?.name}
                </>
            ),
        },
        // {
        //     title:"Detail",
        //     dataIndex:"detail",
        //     field:"detail",
        //     render: (text,record) => (
        //         <>

        //         </>
        //     ),
        // },
        {
            title: "Domain",
            dataIndex: "domain",
            field: "domain",
            render: (data) => (
                <>
                    {data && data?.domain}
                </>
            ),
        },
        {
            title: "Technology",
            dataIndex: "technology",
            field: "technology",
            render: (data) => (
                <>
                    {data && data?.technology.charAt(0).toUpperCase() + data.technology.slice(1)}
                </>
            ),
        },
        // {
        //     title:"Generate Link",
        //     dataIndex:"generate-link",
        //     field:"generate-link",
        //     render: (text,record) => (
        //         <>
        //             <Tooltip title="Get link">
        //                 <button className="btn btn-primary p-2" data-toggle="modal" data-target="#exampleModalCenter">Link</button>
        //             </Tooltip>
        //         </>
        //     ),
        // },
        {
            title: "Actions",
            dataIndex: "action",
            field: "action",
            render: (data) => (
                <>
                    <Tooltip title="Edit">
                        <button className="btn btn-primary p-2 mx-2 rounded" data-target="#exampleModalCenter" onClick={e => salesedit(data && data?.id)} ><i class="fa-solid fa-pen"></i></button>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <button className=" p-2 mx-2 rounded deletebtn" data-target="#exampleModalCenter" onClick={e => deletebutton(data.id)}><i class="fa-solid fa-trash"></i></button>
                    </Tooltip>
                    <Tooltip title="View">
                        <button className="btn btn-info p-2 mx-2 rounded" onClick={e=>viewdata(data&&data?.id)}><i class="fa-solid fa-eye"></i></button>
                    </Tooltip>
                </>
            ),
        },
    ];

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const getallPortfolios = () => {
        setLoading(true)
        ApiClient.get(`all/portfolio?page=1&count=100`).then(res => {
            setLoading(false)
            if (res.success) {
                setdata(res.data.data)
            }
        })
    }
    
    const portfolioShare = (e) => {
        e.preventDefault();
        let  payload = {
            "selected_items":
                getdata,
            "end_date":date
        }
        ApiClient.post(`portfolioShare`,payload).then(res => {
            if (res.success) {
                setShare(res.key)
                getlink(res.key)
            }
        })
    }

    const salesedit = (data) => {
        // alert("Edit the data")
        history.push(`/addeditportfolio/${data}`)
    } 

    const deletebutton = (id) => {
        if (window.confirm('Do you want to delete this')) {
            //   loader(true);
            ApiClient.delete('portfolio', { id }).then((res) => {
                if (res.success) {
                    //   ToastsStore.success(res.message);
                    getallPortfolios();
                }
                // loader(false);
            });
        }
    };
    useEffect(() => {
        getallPortfolios()
    }, [])

    const getlink=(key)=>{
            ApiClient.get(`portfolioShare?key=${key}`).then(res=>{
                if(res.success){
                    setlink(res.data)
                    console.log(res.data)
                }
            })
    }
    const viewportfolio=(key)=>{
        window.open(`viewlinkdata/${key}`)
    }
    
    const copytext=()=>{
        var copyText = document.getElementById("copy");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        // alert("Copied text is : " + copyText.value);
        document.getElementById('closemodel').click();
        notification.success({
            message:"Text is Copied."
        })
        setgetData('')
        const nodeList = document.querySelectorAll(".listcheckbox");
        for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].checked = false;
        }
    }

    

    return <>
        <div className="main-wrapper" id="mainPannel">
            <div className="navbar-bg" />
            <Header />
            <MainSideBar />
        </div>
        <div className="main-content">

            <section className="section">

                <SectionHeader title="Portfolio" />

                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }} >
                {/* <Tooltip title="Get link">
                    <button className="btn btn-primary p-2" data-toggle="modal" data-target="#exampleModalCenter">Link</button>
                </Tooltip> */}

                <Link to="/addeditportfolio">
                    <Tooltip title="Add Portfolio">
                        <Button type="primary"
                            shape="circle"
                            icon={<PlusSquareOutlined />}
                            size={'large'}
                            className="btn btn-primary profile" />
                    </Tooltip>
                </Link>
                </div>
                <div className="p_ab">
                    {getdata && getdata.length ?
                        <Tooltip title="Get link">
                            <button className="btn p-2 mx-2 rounded btn-color" data-toggle="modal"  data-target="#exampleModalCenter"><i class="fa-solid fa-link"></i></button>
                        </Tooltip>
                    : 
                        <></>
                    }
                </div>
                <Spin spinning={loading}>
                    <MaterialTable
                        options={{
                            pageSize:10,
                            pageSizeOptions:[10,20,30,40,50], 
                             emptyRowsWhenPaging: false,
                             showTitle:false,
                             draggable:false
                       }}   
                        columns={columns}
                        data={data}
                        icons={tableIcons}
                    />
                </Spin>
            </section>

        </div>
        <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Link</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={portfolioShare}>
                        <div className="modal-body">
                            <div>
                                {Share&&Share?
                                <input type="text" id="copy" value={`${API_URL}viewlinkdata/${Share}`} className="p-2 w-75"/>
                                // <span className="mx-4"><ContentCopyIcon /></span>
                                :
                                ""
                                }
                                {Share&&Share?
                                ""
                                :
                                <input type="text" value={date} onChange={e=>setdate(e.target.value)} onFocus={(e) => e.target.type = 'date'} onBlur={(e) => e.target.type = "text"} placeholder="Set Expiry Date" className="mt-3 w-75" required></input>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" id="closemodel">Close</button>
                            {Share&&Share?
                            <Tooltip title="View">
                                <button className="btn btn-info float-right px-3" onClick={e=>viewportfolio(Share)}><i class="fa-solid fa-eye"></i></button>
                            </Tooltip>
                            :
                            ""
                            }
                            {Share&&Share?
                               <button type="button" onClick={e=>copytext()} className="btn btn-primary" data-clipboard-target="#foo">Copy to clipboard</button>
                                :
                                <button type="submit" className="btn btn-primary">Save</button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
export default Portfolio