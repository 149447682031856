import React, { useEffect, useState, useRef } from "react";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import {Tooltip,Button,Typography,Divider,Spin,notification,} from "antd";
import Header from "../../Constant/Header/Header";
import { API_URL } from "../../Constant";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import { Formik } from "formik"; 

const AddEditUser = (props) => {
  const buttonref=useRef();
  const pressed=(event)=>{  
  if (event.key === "Enter") { 
    event.preventDefault(); 
    buttonref.current.click(); 
  } 
  };

const AllStates=[
  {
  "key": "AN",
  "name": "Andaman and Nicobar Islands"
  },
  {
  "key": "AP",
  "name": "Andhra Pradesh"
  },
  {
  "key": "AR",
  "name": "Arunachal Pradesh"
  },
  {
  "key": "AS",
  "name": "Assam"
  },
  {
  "key": "BR",
  "name": "Bihar"
  },
  {
  "key": "CG",
  "name": "Chandigarh"
  },
  {
  "key": "CH",
  "name": "Chhattisgarh"
  },
  {
  "key": "DH",
  "name": "Dadra and Nagar Haveli"
  },
  {
  "key": "DD",
  "name": "Daman and Diu"
  },
  {
  "key": "DL",
  "name": "Delhi"
  },
  {
  "key": "GA",
  "name": "Goa"
  },
  {
  "key": "GJ",
  "name": "Gujarat"
  },
  {
  "key": "HR",
  "name": "Haryana"
  },
  {
  "key": "HP",
  "name": "Himachal Pradesh"
  },
  {
  "key": "JK",
  "name": "Jammu and Kashmir"
  },
  {
  "key": "JH",
  "name": "Jharkhand"
  },
  {
  "key": "KA",
  "name": "Karnataka"
  },
  {
  "key": "KL",
  "name": "Kerala"
  },
  {
  "key": "LD",
  "name": "Lakshadweep"
  },
  {
  "key": "MP",
  "name": "Madhya Pradesh"
  },
  {
  "key": "MH",
  "name": "Maharashtra"
  },
  {
  "key": "MN",
  "name": "Manipur"
  },
  {
  "key": "ML",
  "name": "Meghalaya"
  },
  {
  "key": "MZ",
  "name": "Mizoram"
  },
  {
  "key": "NL",
  "name": "Nagaland"
  },
  {
  "key": "OR",
  "name": "Odisha"
  },
  {
  "key": "PY",
  "name": "Puducherry"
  },
  {
  "key": "PB",
  "name": "Punjab"
  },
  {
  "key": "RJ",
  "name": "Rajasthan"
  },
  {
  "key": "SK",
  "name": "Sikkim"
  },
  {
  "key": "TN",
  "name": "Tamil Nadu"
  },
  {
  "key": "TS",
  "name": "Telangana"
  },
  {
  "key": "TR",
  "name": "Tripura"
  },
  {
  "key": "UK",
  "name": "Uttar Pradesh"
  },
  {
  "key": "UP",
  "name": "Uttarakhand"
  },
  {
  "key": "WB",
  "name": "West Bengal"
  }
  ]
  
  const userid = useParams().id;
  const [maritalstatus,setmaritalstatus]=useState("");
  const [isTL,setisTL]=useState();
  const [tlTechnology,settlTechnology]=useState("");
  const [aadharurl,setaadharurl]=useState(null);
  const [aadharloading,setaadharloading]=useState(false);
  const [passportimageurl,setpassportimageurl]=useState(null);
  const [passportloading,setpassportloading]=useState(false);
  const [votercardimageurl,setvotercardimageurl]=useState(null);
  const [votercardimageloadding,setvotercardimageloadding]=useState(false);
  const [pancardimageURL, setpancardimageURL] = useState(null);
  const [pancardloading, setpancardloading] = useState(false);
  const numbervalidation = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const passwordvalidation=Yup.string("Please Enter the Password").required("Password is required").min(8,"Password must be equal or greator then 8 characters");
  const emailvalidation=Yup.string('Please Enter your Email').required("Email is Required").matches("@jcsoftwaresolution.com","Please enter a valid email.");
  const newemailvalidation=!userid?emailvalidation:"";
  const newpass=!userid?passwordvalidation:"";
  const pancardvalidation=/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
  const Spousevalidation=Yup.string("Please enter spouse Name").required("Spouse Name is required")
  // const validation=maritalstatus=="married"?Spousevalidation:"";
  
  const Validation=Yup.object().shape({
      firstName:Yup.string().required("First Name is Required").min(3,"The First Name must be greator then 3 Characters!!").max(35,"The First Name can not be greator then 35 Characters!!"),
      // lastName:Yup.string().required("Last Name is Required").min(3,"The Last Name must be greator then 3 Characters!!").max(35,"The Last Name can not be greator then 35 Characters!!"),
      email:newemailvalidation,
      password:newpass,
      fatherName:Yup.string("Please Enter Father's Name").required("Father's Name is Required").min(3,"The Father's Name must be greator then 3 Characters!!").max(35,"The Father's Name can not be greator then 35 Characters!!"),
      motherName:Yup.string("Please Enter Mother's Name").required("Mother's Name is Required").min(3,"The Mother's Name must be greator then 3 Characters!!").max(35,"The Mother's Name can not be greator then 35 Characters!!"),
      designation:Yup.string("Please Enter the Designation").required("Designation is Required"),
      dob:Yup.date("Please enter a valid Date").required("Date Of Birth is required"),
      dateOfJoining:Yup.date().min(Yup.ref('dob'),"Please enter a valid Joining Date").required("Date of Joining is required"),
      gender:Yup.string().required("Please Select the Gender"),
      role:Yup.string("Please Select the Role first").required("Role is Rerquired"),
      aadharCardno:Yup.string("Please enter a valid Aadhar Number").matches(numbervalidation,"Please enter a valid Aadhaar Number").min(12,"Please Enter a Valid Aadhar Number").max(12,"Please Enter a Valid Aadhar Number").required("Aadhar Number is Required"),
      pancardNo:Yup.string("").matches(pancardvalidation,"Please enter a valid Pancard  Number").required("Pancard is Required"),
      votercardNo:Yup.string("Please Enter a Valid Voter Card Number").matches(/^[A-Z]{3}\d{7}$/,"Please enter a valid Voter Card ID").min(10,"Please Enter a Valid Voter Card Number").max(10,"Please Enter a Valid Voter Card Number"),
      primaryAddress1:Yup.string("Please Enter your Current Adsdress").required("Please Enter your Current Address"),
      primaryZipcode:Yup.string("Please Enter a Valid Zip Code").matches(numbervalidation,"Please Enter a Valid Zipcode").min(6,"Please Enter a Valid Zip Code").max(6,"Please Enter a Valid Zip Code").required("Zip Code is Required"),
      primaryState:Yup.string("Please Enter Your State Name").min(2,"Please Enter the full name of state").max(35,"Only 35 Characters are Allowed").required("State is Required"),
      // secondaryEmail:Yup.string().email("It must be an Email.").required("Permanent Email is Required"),
      secondaryAddress1:Yup.string("Please Enter Your Permanent Address").required("Permanent Address is required"),
      mobileNo:Yup.string("Please Enter Mobile Number").nullable("Please Enter Mobile Number").matches(numbervalidation, 'Phone number is not valid').min(10,"Please Enter a Valid Mobile Number").max(10,"Please Enter a Valid Mobile Number").required("Mobile Number is Required "),
      linkedinURL:Yup.string().url("Please Enter a Valid URL"),
      twitterurl:Yup.string().url("Please Enter a Valid URl"),
      contactNumber:Yup.string("Pleae Enter a Valid Contact Number").matches(numbervalidation,"Please Enter a Valid Contact Number").min(10,"Please Enter a Valid Contact Number").max(10,"Please Enter a Valid Contact Number").required("Contact Number is Required"),
      passportNo:Yup.string("Please Enter Passport Number").min(12,"Please Enter A Valid Passport Number").max(12,"Please Enter a Valid Passport Number"),
      gmail:Yup.string("Please Enter Gmail").matches("@gmail.com","Please enter a valid Gmail.").required("Gmail is required"),
      maritalstatus:Yup.string("Please Select one of these").required("Maritalstatus is required"),
      // spouse_name:validation
    })
    
    const token = localStorage.getItem("dataToken");
    const [loading, setLoading] = useState(false);  
    const [document, setdocument] = useState(null);
    const [data,setdata]=useState({});
    const history = useHistory();   
    const { Title } = Typography;  
    const [singleFile, setSingleFile] = useState(null); 
    const [singleFile3, setSingleFile3] = useState(null); 
    const [drop, setDrop] = useState();   
    const [status,setstatus] = useState('active');
    const currentdate=new Date().getFullYear();
    const newdate=currentdate-18;
    const [EmpSkills, setEmpSkills] = useState([])
    const [skillerror,setskillerror]=useState(false);
    const [eyes, setEyes] = useState(false);
    const [unique_id,setunique_id]=useState("")

    const renderSubmitBack = () => {
      history.goBack();
    };
    
    // For Uploading The Last Image In the  Right Thing
    const submitImage = async (e) => {
      setDrop(e);
      let data = new FormData();
      data.append("modelName", "users");   
      e.forEach((file) => {
        data.append('file', file); 
      }); 
      axios
      .post(`${API_URL}project/uploadDocument`,data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => { 
        setdocument(API_URL+response.data.data[0].imagePath);
         notification.success({
          message:"Uploaded Successfully"
         })
      }).catch({
        message:"Some Error Occurred.Please Try after Some Time"
      })
    }; 

    
    const getdata = () => {
      setLoading(true);
      ApiClient.get(`userDetail?id=${userid}`).then((res) => {
        if (res.success) {
          setdata(res.data)
          setisTL(res.data.isTL)
          setskills(res.data?.skill)
          setaadharurl(res.data?.documentImages[0]);
          setvotercardimageurl(res.data?.documentImages[1])
          setpassportimageurl(res.data?.documentImages[2])
          setpancardimageURL(res.data?.documentImages[3])
          setdocument(res.data?.image)
          setstatus(res.data?.status);
          setLoading(false)
          setunique_id(res?.data?.unique_id||"")
        }
        else{
          setTimeout(() => {
            notification.error({
              message: 'Error Occurred',
              description: 'Some Error Occurred while Getting the Data.Please Try after some Time.',
            });
          }, 1000);
          setLoading(false)
        }
      }).catch(err=>setLoading(false))
    };
    useEffect(() => {
      if(userid){
        getdata();
      }
    }, []);
    
    //generate unique code
// const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

// const generateString=(length)=> {
//     let result = ' ';
//     const charactersLength = characters.length;
//     for ( let i = 0; i < length; i++ ) {
//         result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     }
//     result+="JC"
//     setunique_id(result.toUpperCase())
// }
    
    //For Aadhaar Image 
    const submit = async (e) => {
      const fileToUpload = singleFile;
      let file = e.target.files[0];
      const data = new FormData();
      data.append("modelName", "users");
      data.append("file", file);
      setaadharloading(true)
      if(!file)
      {
        setaadharloading(false);
      }
      if (fileToUpload) {
        for (var i = 0; i < fileToUpload.length; i++) {
          data.append("file", fileToUpload[i]);
          console.log(fileToUpload[i], "formDataformData");
        }
      }
      axios.post(`${API_URL}project/uploadDocument`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setaadharurl(response.data.data[0].imagePath)
        if (response.status === "error") {
          setTimeout(() => {
            notification.error({
              message: 'Error',
              description: 'Some Error Occurred while Uploading the Image Please try After some Time.',
            });
          }, 1000);
          this.loading = false;
        } else {
          console.log(response, "here is that");
        }
      }).finally(()=>{
        setaadharloading(false);
      })
      setLoading(false);
    };
    
    //For Voter Card Image
    const submit2 = async (e) => {
      const fileToUpload = singleFile;
      let file = e.target.files[0];
      const data = new FormData();
      data.append("modelName", "users");
      data.append("file", file);
      setvotercardimageloadding(true);
      if(!file){
        setvotercardimageloadding(false);
      }
      if (fileToUpload) {
        for (var i = 0; i < fileToUpload.length; i++) {
          data.append("file", fileToUpload[i]);
          
        }
      }
      axios
      .post(`${API_URL}project/uploadDocument`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async(response) => {  
        console.log(response.data.data[0].imagePath)
        setvotercardimageurl(response.data.data[0].imagePath); 
        if (response.status === "error") {
          setTimeout(() => {
            notification.error({
              message: 'Error',
              description: 'Some Error Occurred while Uploading the Image Please try After some Time.',
            });
          }, 1000);
          this.loading = false;
        } else {
          console.log(response, "here is that");
        }
      }).finally(()=>{
        setvotercardimageloadding(false);
      })
      setLoading(false);
    };    
      
    //For Passport Image
    const submit3 = async (e) => {
      const fileToUpload = singleFile3;
      let file = e.target.files[0];
      const data = new FormData();
      data.append("modelName", "users");
      data.append("file", file);
      setpassportloading(true);
      if(!file)
      {
        setpassportloading(false);
      }
      if (fileToUpload) {
        for (var i = 0; i < fileToUpload.length; i++) {
          data.append("file", fileToUpload[i]);
          console.log(fileToUpload[i], "formDataformData");
        }
      } 
      axios.post(`${API_URL}project/uploadDocument`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }).then(async(response) => {
        console.log(response.data.data, "alldatanew");
        setpassportimageurl(response.data.data[0].imagePath) 
        if (response.status === "error") {
          setTimeout(() => {
            notification.error({
              message: 'Error',
              description: 'Some Error Occurred while Uploading the Image Please try After some Time.',
            });
          }, 1000);
          this.loading = false;
          setpassportloading(false)
        } else {
          setpassportloading(false)
          console.log(response, "here is that");
        }
      });
      setLoading(false);
    };

    //For Pancard Image
    const submit4 = async (e) => {
      const fileToUpload = singleFile;
      let file = e.target.files[0];
      const data = new FormData();
      data.append("modelName", "users");
      data.append("file", file);
      setpancardloading(true)
      if(!file)
      {
        setpancardloading(false);
      }
      if (fileToUpload) {
        for (var i = 0; i < fileToUpload.length; i++) {
          data.append("file", fileToUpload[i]);
          console.log(fileToUpload[i], "formDataformData");
        }
      }
      axios.post(`${API_URL}project/uploadDocument`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setpancardimageURL(response.data.data[0].imagePath)
        if (response.status === "error") {
          setTimeout(() => {
            notification.error({
              message: 'Error',
              description: 'Some Error Occurred while Uploading the Image Please try After some Time.',
            });
          }, 1000);
          this.loading = false;
        } else {
          console.log(response, "here is that");
        }
      }).finally(()=>{
        setpancardloading(false);
      })
      setLoading(false);
    }

    //For Skills
    let [skills, setskills] = useState([])
    const addSkills = (e) => {
      setskillerror(false)
      e.preventDefault();
      if(EmpSkills.length>0)
      {
        setskills([...skills,EmpSkills]) 
        setEmpSkills([])
      }
      else{
setskillerror(true)
      }
    }
    const removeskills = (index) => { 
      skills=skills&&skills.filter((itm,i)=> i!=index )
      setskills(skills) 
    }

    const handlestatus=(value)=>{
      setmaritalstatus(value)
    }

    const handletl=(value)=>{
      setisTL(value)
    }

    const handletltechnology=(value)=>{
      settlTechnology(value)
    }
    return (
      <div>
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title={userid ? "Edit Employee" : "Add Employee"} />
          <div>
            <Tooltip title="Back Button">
              <Button
                type="primary"
                onClick={renderSubmitBack}
                shape="circle"
                icon={<ArrowLeftOutlined />}
                size={"large"}
                className="btn btn-primary profile"
              />
            </Tooltip>
          </div>
  
          <div>
            <Spin spinning={loading}>
              <div className="div_cont">
                <Title
                  className="sign_up_title"
                  level={3}
                  style={{
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                ></Title>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        id: "",
                        gmail:userid?data.gmail:"",
                        firstName:userid?data.firstName:"",
                        lastName:userid?data.lastName:"",
                        email:userid?data.email:"",
                        password:userid?data.password:"",
                        role:userid?data.role:"",
                        designation:userid?data.designation:"",
                        mobileNo:userid?data.mobileNo:"",
                        gender:userid?data.gender:"",
                        dob:userid?data.dob:"",
                        primaryAddress1:userid?data.primaryAddress1:"",
                        primaryCity:userid?data.primaryCity:"",
                        primaryState:userid?data.primaryState:"",
                        primaryZipcode:userid?data.primaryZipcode:"",
                        primaryCountry:userid?data.primaryCountry:"",
                        secondaryAddress1:userid?data.secondaryAddress1:"",
                        gmail:userid?data.gmail:"",
                        fatherName:userid?data.fatherName:"",
                        motherName:userid?data.motherName:"",
                        aadharCardimages:userid?data.aadharCardimages:"",
                        passportImages:userid?data.passportImages:"",
                        votercardImages:userid?data.votercardImages:"",
                        pancardImages:userid?data.pancardImages:"",
                        contactNumber:userid?data.contactNumber:"",
                        dateOfJoining:userid?data.dateOfJoining:"",
                        aadharCardno:userid?data.aadharCardno:"",
                        votercardNo:userid?data.votercardNo:"",
                        passportNo:userid?data.passportNo:"",
                        pancardNo:userid?data.pancardNo:"",
                        linkedinURL:userid?data.linkedinURL:"",
                        facebookURL:userid?data.facebookURL:"",
                        twitterurl:userid?data.twitterURL:"",
                        instagramURL:userid?data.instagramURL:"",
                        maritalstatus:userid?data?.maritalStatus:"",
                        isTL:userid?Boolean(data?.isTL):"",
                        tlTechnology:userid?data?.tlTechnology:"",
                        spouse_name:userid?data?.spouseName:"",
                        employee_code:userid?data?.employee_code:''
                      }}
                      validationSchema={Validation}

                      onSubmit={(values,{resetForm})=>{ 
                      let value = {
                        id: userid,
                        firstName:values.firstName,
                        lastName:values.lastName,
                        email:values.email,
                        password:values.password,
                        role:values.role,
                        status:status,
                        designation:values.designation,
                        mobileNo:values.mobileNo,
                        gender:values.gender,
                        dob:values.dob,
                        primaryAddress1:values.primaryAddress1,
                        primaryCity:values.primaryCity,
                        primaryState:values.primaryState,
                        primaryZipcode:values.primaryZipcode,
                        primaryCountry:values.primaryCountry,
                        secondaryAddress1:values.secondaryAddress1,
                        gmail:values.gmail,
                        fatherName:values.fatherName,
                        motherName:values.motherName,
                        image:document,
                        documentImages:[aadharurl,votercardimageurl,passportimageurl,pancardimageURL],
                        contactNumber:values.contactNumber,
                        dateOfJoining:values.dateOfJoining,
                        aadharCardno:values.aadharCardno,
                        votercardNo:values.votercardNo,
                        passportNo:values.passportNo,
                        pancardNo:values.pancardNo,
                        linkedinURL:values.linkedinURL,
                        facebookURL:values.facebookURL,
                        twitterURL:values.twitterurl,
                        instagramURL:values.instagramURL, 
                        skill:skills,
                        maritalStatus:values.maritalstatus,
                        isTL:Boolean(values.isTL),
                        tlTechnology:values.tlTechnology,
                        spouseName:values.spouse_name,
                        employee_code:values.employee_code
                      };

                      let url = "add/user";
                      let method = "post";
                      if (value.id) {
                        delete value.password; 
                        url = `editProfile?id=${userid}`;
                        method = "put";
                      } else {
                        delete value.id;
                      }
                      console.log(value)
                      ApiClient.allApi(url, value, method).then((res) => {
                        if (res.success) {
                          history.push("/allUser");
                          const Role=values.role;
                          function capitalizeFirstLetter(str) {
                            return str.charAt(0).toUpperCase() + str.slice(1);
                          } 
                          const newrole=capitalizeFirstLetter(Role);
                          if(value.id)
                          {
                            notification.success({
                              description: `${newrole} Updated Successfully!!`,
                            })

                          }
                          else{
                            notification.success({ 
                              description: `${newrole} Saved Successfully!!`,
                            }); 
                          }
                      
                        }
                        else{
                          setTimeout(() => {
                            notification.error({
                              message: 'Email Already Exists',
                            });
                          }, 1000);
                        }
                      });  
                    }}
  >
    {
      ({values,handleBlur,handleChange,handleSubmit,errors,touched})=>(
        <form onSubmit={handleSubmit}>
        <Divider orientation="left">User Information</Divider>
        <div className="form-row">
          <div className="form-group col-md-6 text-left">
            <label className="control-label">First Name</label>
            <div className="">
              <input
                name="firstName"
                id="firstName"
                type="text" 
                className="form-control " style={{paddingLeft:"10px"}}
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.firstName&&touched.firstName?<p className="text-danger">{errors.firstName}</p>:null}
            </div>
          </div>

          
          <div className="form-group col-md-6 text-left">
            <label className="form-label">Last Name</label>
            <div className="">
              <input
                name="lastName"
                id="lastName"
                type="text"
                className="form-control " style={{paddingLeft:"10px"}} onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
              />
            {errors.lastName&&touched.lastName?<p className="text-danger">{errors.lastName}</p>:null}
            </div>
          </div>
        </div>
 {    !userid?<div className="form-row">
          <div className="form-group col-md-6 text-left">
            <label className="control-label">Email</label>
            <div className="">
              <input
                name="email"
                id="email"
                type="email"
                className="form-control " style={{paddingLeft:"10px"}}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {errors.email&&touched.email?<p className="text-danger">{errors.email}</p>:null}
            </div>
          </div> 
          <div className="form-group col-md-6 text-left">
            <label className="control-label">Password</label>
            <div className="">
              <input
                name="password"
                id="password" 
                type={eyes?"text":"password"} 
                maxLength={20}
                className="form-control p_rel" style={{paddingLeft:"10px"}}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={userid}
                value={!userid? values.password:"************"}
              />
              <i className={eyes ? 'fa fa-eye p_absolute' : 'fa fa-eye-slash p_absolute'} onClick={() => setEyes(!eyes)}></i>
              {errors.password&&touched.password?<p className="text-danger">{errors.password}</p>:null}
            </div>
          </div>
          
       
        </div>:""}
        <div className="form-row">
          <div className="form-group col-md-6 text-left">
            <label className="control-label">Father Name</label>
            <div className="">
              <input
                name="fatherName"
                id="fatherName"
                type="text"
                className="form-control" style={{paddingLeft:"10px"}}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.fatherName}
              />
              {errors.fatherName&&touched.fatherName?<p className="text-danger">{errors.fatherName}</p>:null}
            </div>
          </div>
          <div className="form-group col-md-6 text-left">
          <label className="control-label">Mother Name</label>
              <div className="">
                <input
                  name="motherName"
                  id="motherName"
                   
                  type="text"
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.motherName}
                />
                {errors.motherName&&touched.motherName?<p className="text-danger">{errors.motherName}</p>:null}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 text-left">
              <label className="control-label">Contact Number</label>
              <div className="">
                <input
                  placeholder="+91"
                  name="contactNumber"
                  minLength={10}
                  maxLength={10}
                  id="contactNumber"
                  type="number"
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contactNumber}
                />
                {errors.contactNumber&&touched.contactNumber?<p className="text-danger">{errors.contactNumber}</p>:null}
              </div>
            </div>
            <div className="form-group col-md-6 text-left">
              <label className="control-label">Designation</label>
              <div className="">
                <input
                  name="designation"
                  id="designation"
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.designation}
                />
                {errors.designation&&touched.designation?<p className="text-danger">{errors.designation}</p>:null}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 text-left">
              <label className="control-label">Enter D.O.B</label>
              <div className="">
                <input
                  id="dob"
                  name="dob" 
                  max={`${newdate}-01-01`}
                  type="date"
                  placeholder="Enter User Date Of Birth"
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dob}
                />
                {errors.dob&&touched.dob?<p className="text-danger">{errors.dob}</p>:null}
              </div>
            </div>
            <div className="form-group col-md-6 text-left">
              <label className="control-label">Select Gender</label>
              <div className="">
                <select
                  name="gender"
                  as="select"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gender} 
                  id="gender"
                  className="form-control" style={{paddingLeft:"10px"}}
                  defaultValue="Select User Gender"
                >
                  <option>Select Gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
                {errors.gender&&touched.gender?<p className="text-danger">{errors.gender}</p>:null}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 text-left">
              <label className="control-label">
                Select Date of Joining
              </label>
              <div className="">
                <input
                  id="dateOfJoining"
                  name="dateOfJoining"
                  type="date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateOfJoining}
                  className="form-control" style={{paddingLeft:"10px"}}
                />
                {errors.dateOfJoining&&touched.dateOfJoining?<p className="text-danger">{errors.dateOfJoining}</p>:null}
              </div>
            </div>
            <div className="form-group col-md-6 text-left">
              <label className="control-label">Select Role</label>
              <div className="">
                <select
                  name="role"
                  id="role"
                  as="select"
                  
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.role}
                >
                  <option>Select Role</option>
                  <option value="admin">Management</option>
                  <option value="subadmin">Sub Admin</option>
                  <option value="HR">HR</option>
                  <option value="developer">Developer</option>
                  {/* <option value="webdesigner">Web Designer</option> */}
                  <option value="qa">QA</option>
                  <option value="bde">BDE</option>
                  <option value="seo">SEO</option>
                  <option value="designer">Designer</option>
                </select>
                {errors.role&&touched.role?<p className="text-danger">{errors.role}</p>:null}
              </div>
            </div>
          </div>
                    {/* For Marital Status */}
                    <div className="form-row">
            <div className="form-group col-md-6 text-left">
              <label className="control-label">Marital Status:</label>
              <div className="">
                <select
                onClick={(e)=>handlestatus(e.target.value)}
                className="form-control"
                name="maritalstatus"
                value={values.maritalstatus}
                onChange={handleChange}
                onBlur={handleBlur}
                >
                  <option value="">Choose the marital status</option>
                  <option value="married">Married</option>
                  <option value="single" >Single</option>
                </select>
                {errors.maritalstatus&&touched.maritalstatus?<p className="text-danger">{errors.maritalstatus}</p>:null}
              </div>
            </div>
            {values.maritalstatus=="married"?
            <div className="form-group col-md-6 text-left">
              <label className="form-label">Spouse Name </label>
              <div className="">
                <input
                  name="spouse_name"
                  id="spouse_name"
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.spouse_name}
                />
                {errors.spouse_name&&touched.spouse_name?<p className="text-danger">{errors.spouse_name}</p>:null}
              </div>
            </div>:null}
          </div>
          {userid?
            <div className="form-row">
              <div className="form-group col-md-6 text-left">
                <label className="form-label">Is TL or Employee</label>
                <select
                onClick={(e)=>handletl(e.target.value)}
                className="form-control"
                name="isTL"
                value={values.isTL}
                onChange={handleChange}
                onBlur={handleBlur}
                >
                  <option value=''>Choose one</option>
                  <option value={true}>TL</option>
                  <option value={false}>Employee</option>
                </select>
              </div>
              {isTL == true || values.isTL == 'true'?
              <div className="form-group col-md-6 text-left">
                <label className="form-label">Technology</label>
                <select
                onClick={(e)=>handletltechnology(e.target.value)}
                className="form-control"
                name="tlTechnology"
                value={values.tlTechnology}
                onChange={handleChange}
                onBlur={handleBlur}
                >
                  <option value="">Choose TL Technology</option>
                  <option value="angular">Angular</option>
                  <option value="react" >React</option>
                  <option value="nodejs" >Node JS</option>
                  <option value="qa" >QA</option>
                  <option value="bde" >BDE</option>
                  <option value="designing" >Designing</option>
                </select>
              </div>
              :null}
            </div>
          :null}
        { userid&& <div className="form-group col-md-6 text-left p-0 d-flex">
            <label className="control-label">Unique Id</label>
            <div className="">
              <input
                name="employee_code"
                id="unique"
                type="text" 
                className="form-control " 
                value={values.employee_code}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {/* {!userid&&<button type="button" className="btn-btn-primary ml-2" onClick={(e)=>generateString(5)}>Generate Id</button>} */}
          </div>}

          <div className="form-row">
            <div className="form-group col-md-6 text-left">
              <label className="control-label">Aadhar Card no.</label>
              <div className="">
                <input
                  name="aadharCardno"
                  id="aadharCardno"
                  className="form-control " style={{marginRight:"10px"}}
                  type="text"
                  minLength={6}
                  maxLength={12}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.aadharCardno}
                />
                {errors.aadharCardno&&touched.aadharCardno?<p className="text-danger">{errors.aadharCardno}</p>:null}
              </div>
            </div>

            <div className="form-group col-md-6 text-left">
              <div style={{marginTop:"33px"}}>
                  <label className="btn btn-info" style={{padding:"5px"}}>
                    <input
                      type="file"
                      className="d-none"
                      disabled={aadharloading}
                      accept="image/*"
                      multiple="multiple"
                      name="aadharCardimages"
                      id="aadharCardimages"
                      onChange={(e) => submit(e)}
                      icon={<UploadOutlined />}
                    />
                    Upload Aadhar Image
                  </label><br/>
                  {!aadharloading?<img alt="No Image" src={`${API_URL}${aadharurl}`} className={`mt-1 mx-2 d-${!aadharurl?"none":""}`} width="100" height="100"/>:"Uploading......."} 
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 text-left">
              <label className="form-label">
                Voter Card no.
              </label>
              <div className="">
                <input
                  name="votercardNo"
                  id="votercardNo"
                  type="text"
                  minLength={10}
                  maxLength={10}
                  className="form-control" style={{paddingLeft:"10px",textTransform:"uppercase"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.votercardNo}
                />
                 {errors.votercardNo&&touched.votercardNo?<p className="text-danger">{errors.votercardNo}</p>:null}
              </div>
            </div>
            <div className="form-group col-md-6 text-left">
              <div style={{marginTop:"33px"}}>
                <label className="btn btn-info" style={{padding:"5px"}}>
                  <input
                    id="votercardImages"
                    name="votercardImages"
                    multiple="multiple"
                    type="file"
                    accept="image/*"
                    className="d-none "
                    onChange={(e) => submit2(e)}
                    icon={<UploadOutlined />}
                  /> 
                  Upload Voter Card Image
                </label><br/>
                  {!votercardimageloadding?<img alt="No Image" src={`${API_URL}${votercardimageurl}`} className={`mt-1 mx-2 d-${!votercardimageurl?"none":""}`}   width="100" height="100"/>:"Uploading......."} 
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 text-left">
              <label className="form-label">
                Passport no.
              </label>
              <div className="">
                <input
                  name="passportNo"
                  id="passportNo"
                  type="text" 
                  maxLength={12}
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.passportNo}
                />
                {errors.passportNo&&touched.passportNo?<p className="text-danger">{errors.passportNo}</p>:null}
              </div>
            </div>
            <div className="form-group col-md-6 text-left">
              <div style={{marginTop:"33px"}}>
              <label className="btn btn-info " style={{padding:"5px"}}>
                      <input
                      disabled={passportloading}
                      className="d-none"
                      id="passportImages"
                      name="passportImages"
                      multiple="multiple"
                      accept="image/*"
                      type="file"
                      onChange={(e) =>submit3(e)}
                      icon={<UploadOutlined />}
                      /> 
                      Upload Passport Image
                      </label><br/>
                      {!passportloading?<img alt="No Image"   src={`${API_URL}${passportimageurl}`} className={`mt-1 mx-2 d-${!passportimageurl?"none":""}`}   width="100" height="100"/>:"Uploading......."} 
              </div>
            </div>
            <div className="form-group col-md-6 text-left">
              <label className="control-label">Pancard no.</label>
              <div className="">
                <input
                  name="pancardNo" 
                  className="form-control " style={{paddingLeft:"10px",textTransform:"uppercase"}}
                  type="text"
                  minLength={10}  
                  maxLength={10}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pancardNo}
                />
                {errors.pancardNo&&touched.pancardNo?<p className="text-danger">{errors.pancardNo}</p>:null}
              </div>
            </div>
            <div className="form-group col-md-6 text-left">
              <div style={{marginTop:"33px"}}>
                  <label className="btn btn-info" style={{padding:"5px"}}>
                    <input
                      type="file"
                      className="d-none"
                      disabled={pancardloading}
                      accept="image/*"
                      multiple="multiple"
                      name="pancardimage"
                      id="pancardimages"
                      onChange={(e) => submit4(e)}
                      icon={<UploadOutlined />}
                    />
                    Upload Pancard Image
                  </label><br/>
                  {!pancardloading?<img alt="No Image" src={`${API_URL}${pancardimageURL}`} className={`mt-1 mx-2 d-${!pancardimageURL?"none":""}`} width="100" height="100"/>:"Uploading......."} 
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 text-left">
              <label className="control-label">Enter Gmail</label>
              <div className="">
                <input
                  name="gmail"
                  id="gmail"
                  type="email"
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gmail}
                />
                {errors.gmail&&touched.gmail?<p className="text-danger">{errors.gmail}</p>:null}
              </div>
            </div>
            <div className="form-group col-md-6 text-left">
              <label className="">Employee Skills</label>
              <div className='d-flex mb-3 interestFlex'>
                <input type="text"  onKeyPress={(e)=>pressed(e)} onChange={e => setEmpSkills(e.target.value)} value={EmpSkills} className="form-control mb-0 mr-2 pl-2" />
                <button type='button' className='btn btn-primary' ref={buttonref} onClick={(e)=>addSkills(e)}>Add</button>
              </div>
{skillerror==true?<div className="text-danger pl-2">Please fill this field</div>:null}
              {skills&&skills.map((data,i)=>(
                <div className="d-inline-flex m-2">
                  <div style={{cursor:"pointer"}} className="d-flex mb-3 interestFlex ">{data} <i className='fa fa-trash ml-2 mt-1 text-danger' onClick={e=>removeskills(i)}></i></div>
                </div>
              ))}
            </div>
          </div>
          <Divider orientation="left">Current Address</Divider>
          <div className="form-row">
            <div className="form-group col-md-6 text-left">
              <label className="control-label">Current Address</label>
              <div className="">
                <input
                  name="primaryAddress1"
                  id="primaryAddress1"
                  className="form-control " style={{paddingLeft:"10px"}}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.primaryAddress1}
                />
                {errors.primaryAddress1&&touched.primaryAddress1?<p className="text-danger">{errors.primaryAddress1}</p>:null}
              </div>
            </div>
            <div className="form-group col-md-6 text-left">
              <label className="control-label">
                Enter Primary Zip code
              </label>
              <div className="">
                <input
                  name="primaryZipcode"
                  id="primaryZipcode"
                  className="form-control " style={{paddingLeft:"10px"}}
                  type="number"
                  minLength={6}
                  maxLength={6}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.primaryZipcode}
                />
                {errors.primaryZipcode&&touched.primaryZipcode?<p className="text-danger">{errors.primaryZipcode}</p>:null}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 text-left">
              <label className="control-label">
                Enter Current State
              </label>
              <div className="">
                <select
                        className="form-control " style={{paddingLeft:"10px"}}
                        name="primaryState"
                        id="primaryState"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.primaryState}
                >
                  <option value="">Select your State</option>
                  {AllStates&&AllStates.map((item,index)=>(
                    <option value={item.name}>{item.name}</option>
                  ))}
                </select>
                {/* <input
                  name="primaryState"
                  id="primaryState"
                  
                  type="text"
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.primaryState}
                /> */}
                {errors.primaryState&&touched.primaryState?<p className="text-danger">{errors.primaryState}</p>:null}
              </div>
            </div>
          </div>
          <Divider orientation="left">Permanent Address</Divider>
          <div className="form-row">
            <div className="form-group col-md-6 text-left">
              <label className="control-label">
                Enter Permanent Address
              </label>
              <div className="">
                <input
                  name="secondaryAddress1"
                  id="secondaryAddress1"
                  type="text" 
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.secondaryAddress1}
                />
                {errors.secondaryAddress1&&touched.secondaryAddress1?<p className="text-danger">{errors.secondaryAddress1}</p>:null}
              </div>
            </div>

            <div className="form-group col-md-6 text-left ">
              <label className="control-label">Enter Parent Mobile no.</label>
              <div className="">
                <input
                  name="mobileNo"
                  id="mobileNo"
                  type="number"
                  minLength={10}
                  maxLength={10}
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mobileNo}
                />
                {errors.mobileNo&&touched.mobileNo?<p className="text-danger">{errors.mobileNo}</p>:null}
              </div>
            </div>
          </div>
          {/* <Divider orientation="left">URLS</Divider>
          <br></br>
          <br></br>
          <div className="form-row">
            <div className="form-group col-md-6 text-left">
              <label>linkedin URL</label>
              <div className="">
                <input
                  name="linkedinURL"
                  id="linkedinURL" 
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.linkedinURL}
                />
                {errors.linkedinURL&&touched.linkedinURL?<p className="text-danger">{errors.linkedinURL}</p>:null}
              </div>
            </div>
            <div className="form-group col-md-6 text-left">
              <label>facebook URL</label>
              <div className="">
                <input
                  name="facebookURL"
                  id="facebookURL" 
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.facebookURL}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 text-left">
              <label>Twitter URL</label>
              <div className="">
                <input
                  name="twitterurl"
                  id="twitterurl" 
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.twitterurl}
                />
                {errors.twitterurl&&touched.twitterurl?<p className="text-danger">{errors.twitterurl}</p>:null}
              </div>
            </div>
            <div className="form-group col-md-6 text-left">
              <label>instagram URL</label>
              <div className="">
                <input
                  name="instagramURL"
                  id="instagramURL" 
                  className="form-control " style={{paddingLeft:"10px"}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.instagramURL}
                />
              </div>
            </div>
          </div> */}

          {/* <Row>
                
            <Col className="col-md-12 mb-3">
            {!document? 
            <>
              <DropzoneArea
                name="documents"
                acceptedFiles={[
                  "image/*"
                ]}
                onChange={(e) => submitImage(e)}
                filesLimit={1}
                showAlerts={false}
              />
              </>
              :<center>        
              <div onMouseEnter={()=>setdisplay(true)} onMouseLeave={()=>setdisplay(false)}>  <div className="crossicon_class" >
              <button  className={`btn btn-danger  d-${!display?"none":""}`} onClick={()=>setdocument(null)} style={{opacity:"0.8",position:"absolute",top:-10,left:70}}  type="button" >&#x2715;</button>
          </div><img    width={200} className="text-center"  height={200} src={`${document}`}/></div></center>}
            </Col>
          </Row>  */}
        
          <div className="col-md-12 text-right mt-3 mb-3">
            <button className="btn btn-success " type="submit">
              {userid ? "Update" : "Save"}
            </button>
          </div>
        </form> 
  )
      } 
                    </Formik>     
                </div>
              </Spin>
            </div>
          </section>
        </div>
      </div>
      ); 
}
 export default AddEditUser;
