import React, { useState, forwardRef } from 'react';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { notification, Tooltip } from 'antd';
import {
    Form,
    Button,
    Row,
    Col,
    Input,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import MaterialTable from 'material-table'
import XLSX from 'xlsx'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Weeklyplan } from '../../Api/user/userapi';
import { API_URL } from '../../Constant';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const Xluploadfile = (props) => {
    const fileId = localStorage.getItem('fileId')
    const history = useHistory()
    // const { RangePicker } = DatePicker;
    const [colDefs, setColDefs] = useState()
    const [data, setData] = useState()
    const [document, setdocument] = useState();
    const EXTENSIONS = ['xlsx', 'xls', 'csv', 'ods', 'pdf', 'doc', 'docx', 'odt']
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() - 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
    const [form] = Form.useForm();
    const handleSubmit = async (e) => {
        const payload = {
            documents: document[0].imagePath,
            endDate: e.endDate,
            startDate: e.startDate,
            project_id: fileId,
        }
        try {
            const response = Weeklyplan(payload);
            if (response) {
                console.log(response, 'r21211212es');
                // setImages(API_URL + response.data.imagePath);
            }
            props.history.push('/allProject')
            notification.success({
                message: 'Weekly Task Upload Successfully.',
            });
        } catch (e) {
            console.log(e);
            notification.error({
                message: 'Weekly Task Not Uploaded.',
            });
        }
    };
    const token = localStorage.getItem("dataToken")
    const [singleFile, setSingleFile] = useState(null);
    // const [imgUrl, setImagesUrl] = useState('');
    // const [images, setImages] = useState([]);
    const importExcel = async (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (event) => {
            //parse data
            const bstr = event.target.result
            const workBook = XLSX.read(bstr, { type: "binary" })
            //get first sheet
            const workSheetName = workBook.SheetNames[0]
            const workSheet = workBook.Sheets[workSheetName]
            //convert to array
            const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 })
            // console.log(fileData)
            const headers = fileData[0]
            const heads = headers.map(head => ({ title: head, field: head }))
            setColDefs(heads)
            //removing header
            fileData.splice(0, 1)
            setData(convertToJson(headers, fileData))
        }

        if (file) {
            if (getExention(file)) {
                reader.readAsBinaryString(file)
            }
            else {
                notification.error({
                    message: "Please Upload the File with these Extension 'xlsx', 'xls', 'csv','ods'"
                })
                return
            }
        } else {
            setData('')
            setColDefs([])
        }
        setSingleFile(file)
        let formData = new FormData();
        formData.append("file", file);
        axios.post(`${API_URL}project/uploadDocument?modelName=documents`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        }).then((response) => {
            setdocument(response.data.data)
            if (response.status === "error") {
                notification.error({
                    message: "Not Upload."
                })
                this.loading = false;
            } else {
                notification.success({
                    message: "Upload Successfully."
                })
            }
        });
    }
    // const submit = async (e) => {
    //     let formData = new FormData();
    //     console.log(formData, "formdata");
    //     formData.append("file",singleFile);
    //     axios.post(`http://74.208.206.18:6058/project/uploadDocument?modelName=documents`, formData, {
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //             'Content-Type': 'multipart/form-data'
    //         },
    //     }).then((response) => {
    //         console.log(response.data.data, "alldatanew");
    //         setdocument(response.data.data)
    //         if (response.status === "error") {
    //             alert("error");
    //             this.loading = false;
    //         } else {
    //             alert("success");
    //         }
    //     });
    // }

    const getExention = (file) => {
        const parts = file.name.split('.')
        const extension = parts[parts.length - 1]
        return EXTENSIONS.includes(extension) // return boolean
    }

    const convertToJson = (headers, data) => {
        const rows = []
        data.forEach(row => {
            let rowData = {}
            row.forEach((element, index) => {
                rowData[headers[index]] = element
            })
            rows.push(rowData)
        });
        return rows
    }

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    // const disablePastDates = () => {
    //     const today = new Date();
    //     const dd = String(today.getDate() + 1).padStart(2, "0");
    //     const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    //     const yyyy = today.getFullYear();
    //     return yyyy + "-" + mm + "-" + dd;
    // };

    const backbutton = () => {
        history.goBack()
    }

    return (
        <>
            <div>
                <div className="main-wrapper" id="mainPannel">
                    <div className="navbar-bg" />
                    <Header />
                    <MainSideBar />
                </div>
                <div className="main-content">
                    <section className="section">
                        <SectionHeader title="Upload File" />
                        <Tooltip title="Back button">
                            <Button type="primary"
                                onClick={e => backbutton()}
                                shape="circle"
                                icon={<ArrowLeftOutlined />}
                                size={'large'}
                                className="btn btn-primary profile"
                            />
                        </Tooltip>
                        <div >
                            <h3 style={{ marginLeft: "-16%" }} className="form"> </h3>
                            <Form
                                name="basic"
                                initialValues={{ remember: true }}
                                onFinish={handleSubmit}
                                autoComplete="off"
                                form={form}
                            >
                                <Form.Item name="documents" >
                                    <Col>
                                        <div className="App" id="mainPannel">
                                            <MaterialTable
                                                options={{
                                                    showTitle: false,
                                                }}
                                                icons={tableIcons}
                                                // title="Weekly Task"
                                                data={data}
                                                columns={colDefs} />
                                        </div>
                                    </Col>
                                </Form.Item>

                                {/* <div className="upload_btn mt-4 upload_file"
                                >
                                    <button type="submit" className="btn btn-primary mb-3 w-auto submitbtn">Save</button>
                                </div> */}

                                <Row>
                                    <div className="col-md-4">
                                        <Form.Item
                                            label="Start Date"
                                            name="startDate"
                                            rules={[{ required: true, message: 'Please Select Start Date!' }]}
                                        >
                                            <input
                                                className="form-control"
                                                name="startDate"
                                                selectsStart
                                                value={startDate}
                                                onChange={data => setStartDate(data.target.value)}
                                                type="date"
                                                min={disablePastDate()} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Item
                                            label="End Date"
                                            name="endDate"
                                            rules={[{ required: true, message: 'Please Select End Date!' }]}
                                        >
                                            <input
                                                name="endDate"
                                                className="form-control"
                                                selectsEnd
                                                onChange={date => setEndDate(date)}
                                                type="date"
                                                min={startDate}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-4'>
                                        <Input type="file" accept=".xlsx,.xls,.csv,.ods, application/vnd.ms-excel" onChange={importExcel} />
                                    </div>
                                </Row>
                                <Col>
                                    <div className="upload_file mt-3">
                                        <Form.Item>
                                            {!singleFile ?
                                                <Button type="primary" className="sub_btn" htmlType="submit" >
                                                    Submit
                                                </Button>
                                                :
                                                <Button type="submit" className="sub_btn" htmlType="submit">
                                                    Submit
                                                </Button>
                                            }
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Form>
                        </div>
                    </section>
                </div>
            </div >
        </>
    );
};

export default Xluploadfile;