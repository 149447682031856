import React, { useEffect, useState, forwardRef } from 'react';
import { Link } from "react-router-dom";
import { Table, Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import parse from "html-react-parser";
import Highlighter from 'react-highlight-words';
import { useParams } from "react-router-dom";
import { deleteProject, documentsdownload, alldocumentsdownload, getPlatforms, getProjects, updateStatus } from '../../Api/user/userapi';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { Spin, Tooltip, Popconfirm, message, Select, notification } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Modal from 'antd/lib/modal/Modal';
import EditProject from './EditProject';
import Header from '../../Constant/Header/Header';
import './project.css';
import { useSidebar } from '../../Provider/Provider';
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { API_URL } from '../../Constant';

const { Option } = Select;

const AllProjects = (props) => {
    const [array, setArray] = useState([])
    const history = useHistory()
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editChapterData, setEditChapterData] = useState({});
    const [link, setLink] = useState();
    const [imagedata, setImagedata] = useState();
    const [searchText, setsearchText] = useState();
    const [searchedColumn, setsearchedColumn] = useState();
    const [filtervalue, setfiltervalue] = useState("")

    const urls = [
        'https://www..../Cani-spiaggia.png',
        'https://www..../39052fe544a95dbf6ccfdd106fcabb3e_L.jpg'
    ];
    let count = 0;
    const { setCourseId, setChaptersId, courseId } = useSidebar();
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0], dataIndex);
    };
    const handleReset = clearFilters => {
        clearFilters();
        setsearchText(searchText);
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const alldocuments =
        array && array.length
            ? array.map((items) => {
                return {
                    ...items.documents,
                };
            })
            : [];
    useEffect(() => {
        userDetails();
    }, [])
    const userDetails = async (status) => {
        try {
            setLoading(true);
            const resp = await getProjects(status);
            if (resp && resp.status === 200) {
                const list = resp;
                const arrayData = list.data.data;
                setArray(arrayData)
            }
        } catch (e) {
            localStorage.clear();
            props.history.push("/")
            setTimeout(() => {
                notification.error({
                    message: 'Unauthorized',
                    description: 'Your Session Has Expired. Please logIn.',
                });
            }, 1000);
        }
        setLoading(false);
    };

    const chapterIdHandle = (id) => {
        setCourseId(id);
        localStorage.setItem("courseid", id)
    };

    const showEditModalHandle = (data) => {
        history.push(`/createProject/${data._id}`)
        // setIsEditModalVisible(true);
        // setEditChapterData(data)
        // setId(data._id);
    };

    const removeChapterHandle = async (id) => {
        const editID = id;
        try {
            const resp = await deleteProject(id);
            if (resp && resp.status === 200) {
                setTimeout(() => {
                    notification.success({
                        message: 'Project Deleted Successfully'
                    });
                }, 1000);
                userDetails();
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleclicko = (myid) => {
        history.push(`/projectDetails?id=${myid}`)
    }
    const handleprojectview = (e, id) => {
        props.history.push(`/bugs/${id}`)
    }
    function cancelDeleteHandle(e) {
    }
    const handleCancel = (e) => {
        setIsEditModalVisible(false);
    };
    async function handleChange(value, event) {
        const payload = {
            "model": "projects"
        }
        try {
            const resp = await updateStatus(value, event, payload);
            if (resp && resp.status === 200) {
                notification.success({ message: `Project Status is Updated` })
                userDetails();
            } else { notification.error({ message: "Some error Occurred" }) }
        } catch (e) {
            notification.error({ message: "Some error Occurred" })
        }
    }

    const uploadxlfile = (id) => {
        localStorage.setItem("fileId", id)
    }

    // Date & Time format
    const monthArray = [
        { value: 'Jan', id: 0 },
        { value: 'Feb', id: 1 },
        { value: 'Mar', id: 2 },
        { value: 'Apr', id: 3 },
        { value: 'May', id: 4 },
        { value: 'Jun', id: 5 },
        { value: 'Jul', id: 6 },
        { value: 'Aug', id: 7 },
        { value: 'Sep', id: 8 },
        { value: 'Oct', id: 9 },
        { value: 'Nov', id: 10 },
        { value: 'Dec', id: 11 }
    ]
    const monthfind = (id) => {
        let value = 0
        let ext = monthArray.find(itm => itm.id === id)
        if (ext) value = ext.value
        return value
    }
    const hanldeview = (text) => {
        history.push(`/projectassociation/list/${text.id}`)
    }
    const date = (p) => {
        let d = new Date(p)
        return `${d.getDate()} ${monthfind(d.getMonth())} ${d.getFullYear()}`
    }
    const time = (p) => {
        if (!p) return ''
        let v = p.split('T')
        if (v.length === 2) {
            return new Date(p).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        } else {
            return new Date(`2020-07-19 ${p}`).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        }
    }

    const downloadDocument = (document) => {
        window.open(`${document}`)
        notification.success({
            message: "Download Successfully."
        })
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            field: "name",
            render: (text, record) => (
                <>
                    {text && text.name ? (
                        <Link to={`/projectDetails?id=${text._id}`}>
                            <Tooltip title="View">
                                <span className=" text-capitalize text-warning">
                                    {parse(text.name)}
                                </span>
                            </Tooltip>
                        </Link>
                    ) : (
                        "--"
                    )}
                </>
            ),
        },
        // {
        //     title: "Weekly Task",
        //     dataIndex: "frontendtechnology",
        //     field: "frontendtechnology",
        //     render: (text, record) => (
        //         <>
        //             {
        //                 <Tooltip title="Upload Weekly task">
        //                     <Link to={`/uploadfile?id=${text._id}`}>
        //                         <i onClick={() => uploadxlfile(text._id)} class="fa fa-upload" style={{ fontSize: "24px" }}>
        //                         </i>
        //                     </Link>
        //                 </Tooltip>
        //             }
        //         </>
        //     ),
        // },
        // {
        //     title:"Weekly Tasks",
        //     dataIndex:"download",
        //     field:"download",
        //     render: (text, record) => (
        //         <>
        //             <Tooltip title="Download">
        //                 <Link to={`/downloadtask/${text._id}`}>
        //                     <i className="fa fa-download" style={{fontSize:'24px'}}></i>
        //                 </Link>
        //             </Tooltip>
        //         </>
        //     ),
        // },
        {
            title: "Start-Date",
            dataIndex: "startDate",
            field: "startDate",
            render: (text, record) => (
                <>
                    {text && text.startDate
                        ?
                        date(text && text.startDate)
                        :
                        "--"
                    }
                </>
            ),
        },
        {
            title: "Client-Name",
            dataIndex: "clientName",
            field: "clientName",
        }, {
            title: "Added By",
            dataIndex: "addedby",
            field: "addedby",
            render: (text, record) => (
                <>
                    {text && text?.addedBy?.fullName}
                </>
            ),
        },
        {
            title: "Status",
            dataIndex: "projectStatus",
            field: "projectStatus",
            render: (text, record) => (
                <>
                    {text && text.projectStatus ? (
                        <>
                            <select className='form-control table-borderless p-2' onChange={(e) => handleChange(text.id, e.target.value)} value={text.projectStatus}>
                                <option value="onhold" >On Hold</option>
                                <option value="rejected">Rejected</option>
                                <option value="completed">Completed</option>
                                <option value="inProgress">InProgress</option>
                                <option value="notStarted">Not Started</option>
                                <option value="goingon">On Going</option>
                            </select>
                        </>
                    ) : (
                        "--"
                    )}
                </>
            ),
        },
        {
            title: "Document",
            dataIndex: "uploadDocuments",
            field: "uploadDocuments",
            render: (text, record) => (
                <>
                    {text.uploadDocuments == "" || text.uploadDocuments == null || text.uploadDocuments == undefined ? "No Document" :
                        <Tooltip title="Download">
                            <i className='fa fa-download' onClick={e => downloadDocument(text?.uploadDocuments)}></i>
                        </Tooltip>
                    }
                </>
            )
        },
        {
            title: "Actions",
            dataIndex: "actions",
            field: "actions",
            render: (text, record) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly'
                    }}
                >
                    <Tooltip title="Upload Weekly task">
                        <Link to={`/uploadfile?id=${text._id}`}>
                            <i onClick={() => uploadxlfile(text._id)} class="fa fa-upload" style={{ fontSize: "24px" }}>
                            </i>
                        </Link>
                    </Tooltip>

                    <Tooltip title="Download">
                        <Link to={`/downloadtask/${text._id}`}>
                            <i className="fa fa-download" style={{ fontSize: '24px' }}></i>
                        </Link>
                    </Tooltip>

                    <span className="edit_icon"
                        style={{
                            verticalAlign: 'text-bottom'
                        }}
                    >
                        <Tooltip title="Edit">
                            <Button type="primary" onClick={() => showEditModalHandle(text)} className="edit_btn">
                                {<EditOutlined />}
                            </Button>
                        </Tooltip>
                    </span>
                    <span>
                        <Tooltip title="Delete">
                            <Popconfirm
                                title="Are you sure you want to Delete?"
                                onConfirm={() => removeChapterHandle(text._id)}
                                onCancel={cancelDeleteHandle}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" className="button delete_btn ml-2">
                                    <DeleteOutlined />
                                </Button>
                            </Popconfirm>
                        </Tooltip>
                    </span>
                </div>
            ),
        },
        // Work Pending Here
        //     {
        //         title: "Review",
        //         dataIndex: "project task",
        //         field: "project task",
        //         render: (text,record) => (
        //    <>
        //    <Tooltip title="Project View">
        //    <button onClick={(e)=>{handleprojectview(e,text.id)}} className='btn btn-warning mx-1'><i className='fa-solid fa-eye'></i></button>
        //    </Tooltip>
        //    <Tooltip title="Code Review">
        //    <button className='btn btn-warning'><i className='fa fa-users-viewfinder'></i></button>
        //    </Tooltip>
        //    </>
        //         ),
        //     },
    ];

    const newlyAddedChapter =
        array && array.length
            ? array.map((items) => {
                localStorage.setItem('projectId', items.id);
                return {
                    ...items,
                };
            })
            : [];

    const renderSubmit = () => {
        props.history.push('/createProject')
    }
    const token = localStorage.getItem('dataToken');

    const Documents = async (e, data) => {
        try {
            const resp = await documentsdownload(e)
            window.open(resp, '_blank')
        } catch (e) {
            console.log(e);
        }
    }

    async function DownloadFileFromS3(fileToDownload) {
        const result = await Storage.get(fileToDownload.key, { download: true })
        let mimeType = result.ContentType
        let fileName = fileToDownload.key
        let blob = new Blob([result.Body], { type: mimeType })
    }

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    // Task button
    const tasklist = () => {
        props.history.push('/projecttask')
    }

    //Fitler for listing
    const handlefilter = (value) => {
        setfiltervalue(value)
        console.log(value, "jfdshfkjd");
        userDetails(value)
    }
    return (
        <div>
            <div className="main-wrapper" id="mainPannel">
                <div className="navbar-bg" />
                <Header />
                <MainSideBar />
            </div>
            <div className="main-content">
                <section className="section">
                    <SectionHeader title="All Projects" />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',

                    }} >
                        <Tooltip title="Add Project">
                            <Button type="primary"
                                onClick={renderSubmit}
                                shape="circle"
                                icon={<PlusSquareOutlined />}
                                size={'large'}
                                className="btn btn-primary profile" />
                        </Tooltip>
                    </div>
                    <div className='mb-1 filter-dropdown'>
                        <select className='form-control p-2' onChange={e => handlefilter(e.target.value)} value={filtervalue}>
                            <option value="">All Projects</option>
                            <option value="inProgress">InProgress</option>
                            <option value="onhold" >On Hold</option>
                            <option value="rejected">Rejected</option>
                            <option value="completed">Completed</option>
                            <option value="notStarted">Not Started</option>
                            <option value="goingon">On Going</option>
                        </select>
                    </div>
                    <Spin spinning={loading}>
                        <MaterialTable
                            options={{
                                pageSize: 20,
                                pageSizeOptions: [20, 30, 40, 50],
                                emptyRowsWhenPaging: false,
                                showTitle: false,
                                draggable:false
                            }}
                            columns={columns}
                            data={newlyAddedChapter}
                            icons={tableIcons}
                        />
                    </Spin>
                </section>
            </div>
            <div>
                <Modal
                    footer={null}
                    title="Edit Project"
                    visible={isEditModalVisible}
                    onCancel={handleCancel}
                >
                    <div className="crossicon_class" >
                        <button onClick={() => handleCancel()} className="btn " tabindex="-1" type="button" aria-label="Clear Search"><span class="MuiIconButton-label"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" aria-label="clear"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></span></button>
                    </div>
                    <EditProject
                        chapterId={id}
                        setIsEditModalVisible={setIsEditModalVisible}
                        showData={userDetails}
                        editChapterData={editChapterData}
                        onCancel={handleCancel}
                    />
                </Modal>
            </div>
        </div>
    );
};

export default AllProjects;