import { useEffect, useState } from "react"
import ApiClient from "../../methods/api/apiClient"

const SinglePortfolioView = ({id}) => {
    const [data, setdata] = useState()
    const detail=()=>{
        ApiClient.get(`portfolio?id=${id}`).then(res=>{
            if(res.success){
                setdata(res.data)
            }
        })
    }
    useEffect(()=>{
        detail()
    },[])

    return <>
        <div className="mb-5">
            <ul class="list-group">
                <li class="list-group-item"><b>Name: </b> {data&&data.name}</li>
                <li class="list-group-item"><b>Domain: </b> {data&&data.domain}</li>
                <li class="list-group-item"><b>Start Date: </b> {data&&data.start_date}</li>
                <li class="list-group-item"><b>End Date: </b> {data&&data.end_date}</li>
                <li class="list-group-item"><b>Technology: </b> {data&&data.technology}</li>
                <li class="list-group-item"><b>Platform: </b> {data&&data.platform}</li>
                <li class="list-group-item"><b>Description: </b> {data&&data.description}</li>
            </ul>
        </div>
    </>
}
export default SinglePortfolioView