import React, { useEffect, useState } from 'react';

import {
  Button, Spin
} from 'antd';
import {
  ArrowLeftOutlined
} from '@ant-design/icons';
import { useSidebar } from '../../Provider/Provider';
import Header from '../../Constant/Header/Header';
import MainSideBar from '../../Constant/MianSideBar/MainSideBar';
import SectionHeader from '../../Constant/SectionHeader/SectionHeader';
import { getProjectdetails, getsingleleave, getsingleresign } from '../../Api/user/userapi';


const ResignDetails = (props) => {
  const [names, setName] = useState()
  const [desc, setDescription] = useState()
  const [frontTech, setFrontTechnology] = useState()
  const [backTech, setBackTech] = useState()
  const [clientNames, setClientName] = useState()
  const [status, setStatus] = useState()
  const [type, setType] = useState();
  const [loading,setloading]=useState(false);
  const [LeaveStatus, setLeave] = useState()

  


  var HtmlToReactParser = require('html-to-react').Parser;





  var htmlToReactParser = new HtmlToReactParser();




  const { courseId } = useSidebar();


  useEffect(() => {
    getProject();

  }, [])

  const getProject = async (i) => {
    setloading(true);
    const Id  = localStorage.getItem("regiparticularid")
    try {
      const resp = await getsingleresign(Id);
      if (resp && resp.status === 200) {
        const list = resp;

        console.log(list.data.data, 'listasas');
        const name = list.data.data.employeeName;
        const description = list.data.data.description;
        const frontendtechnology = list.data.data.fromEmail;
        const backendtechnology = list.data.data.toEmail;
        const clientName = list.data.data.subject;
        const leave_status = list.data.data.resign_status;
        setName(name);
        setDescription(description);
        setFrontTechnology(frontendtechnology);
        setBackTech(backendtechnology);
        setClientName(clientName);
        setLeave(leave_status)
    setloading(false);
      }
    } catch (e) {
      console.log(e);
      setloading(false);
    }
  };
  const renderSubmitBack = () => {
    props.history.push('/resignation')
  }

  return (
    <div className="text-left">
      <div className="main-wrapper" id="mainPannel">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">

        <section className="section">

          <SectionHeader title="Resignation Details" />
          <div>
            <Button type="primary"
              onClick={renderSubmitBack}
              shape="circle"
              icon={<ArrowLeftOutlined />}
              size={'large'}
              className="btn btn-primary profile"
            />



          </div>
          {/* <h4 className="left detailss">Resignation Details</h4> */}
<Spin spinning={loading}>
          <div className="container pl-0">
            <div className="detail shadow p-3 w-100 d-flex">
              <div class="row">
                <div class="col-md-4">
                  <label className="text-bold">Employee-Name:</label>
                  <span > {names}</span>
                </div>
                
                <div class="col-md-4">
                  <label className="text-bold">Subject:</label>
                  <span>   {clientNames}</span>
                </div>
   
                <div class="col-md-4">
                  <label className="text-bold">Resignation Status:</label>
                  <span> {LeaveStatus}</span>
                </div>
                <div class="col-md-12">
                  <label className="text-bold">From-Email:</label>
                  <span> {frontTech}</span>
                </div>
                <div class="col-md-12">
                  <label className="text-bold">Email:</label>
                  <span> {backTech}</span>
                </div>
                <div class="col-md-12">
                  <label className="text-bold">Description:</label>
                  <span className=''>  {htmlToReactParser.parse(desc)}</span>
                </div>
              </div>

            </div>

          </div>
          </Spin>
        </section>
      </div>


    </div>
  );
};

export default ResignDetails;
