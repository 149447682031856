import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { updateUser } from "../../Api/user/userapi";
import { notification } from "antd/lib";

   

const formItemLayout = {
  labelCol: {
    xs: {
      span: 18,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 18,
    },
    sm: {
      span: 16,
    },
  },
};


function EditUser(props) {
  const { setIsEditModalVisible, editChapterData, chapterId, showData } = props;


  const [courseValue, setCourseValue] = useState({
    designation: "",
    fullName: "",
    mobileNo: "",
    fatherName: "",
    motherName: "",
    primaryAddress: "",
    secondaryAddress: "",
    linkedinURL: "",
    facebookURL: "",
    instagramURL: "",
  });

  const [form] = Form.useForm();

  const inputHandle = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    console.log(value, 'evet');

    setCourseValue({
      ...courseValue,
      [name]: value,
    });
  };

  useEffect(() => {
    if (editChapterData) {
      setCourseValue({
        designation: editChapterData.designation,
        fullName: editChapterData.fullName,
        mobileNo: editChapterData.mobileNo,
        fatherName: editChapterData.fatherName,
        motherName: editChapterData.motherName,
        primaryAddress: editChapterData.primaryAddress,
        secondaryAddress: editChapterData.secondaryAddress,
        linkedinURL: editChapterData.linkedinURL,
        facebookURL: editChapterData.facebookURL,
        instagramURL: editChapterData.instagramURL,
      });
      console.log(editChapterData, "editChapterData");
    }
  }, [editChapterData]);
  const handleCancel = () => {
    setIsEditModalVisible(false);
};

  const editChapterHandle = async () => {
    const payload = {
        designation: courseValue.designation,
        fullName: courseValue.fullName,
        mobileNo: courseValue.mobileNo,
        fatherName: courseValue.fatherName,
        motherName: courseValue.motherName,
        primaryAddress: courseValue.primaryAddress,
        secondaryAddress: courseValue.secondaryAddress,
        linkedinURL: courseValue.linkedinURL,
        facebookURL: courseValue.facebookURL,
        instagramURL: courseValue.instagramURL,


    };
    console.log(payload, 'payload');
    try {
      const resp = await updateUser(chapterId, payload);
      if (resp && resp.status === 200) { 
        showData();
        notification.success({
          message:`Updated Successfully`
        })
        setIsEditModalVisible(false);
      }
    } catch (e) {
      notification.error({
        message:"Some error Occurred. Try after some Time."
      })
    }
  };

  const closemodal=()=>{
    props.onCancel();
  }
  return ( 
    <>
                      <div className="crossicon_class" >
                    <button onClick={()=>handleCancel()} className=" deletebtn" tabindex="-1" type="button" aria-label="Clear Search"><span class="MuiIconButton-label">&#x2715;</span></button>
                </div>
      <div>
        <Form onFinish={editChapterHandle} form={form}
        {...formItemLayout}
        >
            <label className="pt-4 pb-0 mb-1">Full-Name :</label>
            <Input
              placeholder="Enter Full-Name"
              name="fullName"
              value={courseValue.fullName}
              onChange={inputHandle}
              required
            />
            <label className="pt-4 pb-0 mb-1">Mobile Number :</label>
            <Input
              placeholder="Enter Mobile-Number"
              name="mobileNo"
              value={courseValue.mobileNo}
              onChange={inputHandle}
              required
              minLength={10}
              maxLength={10}
            />

            <label className="pt-4 pb-0 mb-1">Designation :</label>
            <Input
              placeholder="Enter Designation"
              name="designation"
              value={courseValue.designation}
              onChange={inputHandle}
              required
            />
            <label className="pt-4 pb-0 mb-1">Mother Name :</label>
            <Input
              placeholder="Mother Name"
              name="motherName"
              value={courseValue.motherName}
              onChange={inputHandle}
              required
            />
            <label className="pt-4 pb-0 mb-1">Father Name :</label>
            <Input
              placeholder="Enter Father-Name"
              name="fatherName"
              value={courseValue.fatherName}
              onChange={inputHandle}
              required
            />
            <label className="pt-4 pb-0 mb-1">Primary Address :</label>
            <Input
              placeholder="Enter Primary-Address"
              name="primaryAddress"
              value={courseValue.primaryAddress}
              onChange={inputHandle}
              required
            />
            <label className="pt-4 pb-0 mb-1">Secondary Address :</label>
            <Input
              placeholder="Enter Secondary-Address"
              name="secondaryAddress"
              value={courseValue.secondaryAddress}
              onChange={inputHandle}
              required
            />
            <label className="pt-4 pb-0 mb-1">Linkedin-URL :</label>
            <Input
              placeholder="Enter Linkedin-URL"
              name="linkedinURL"
              value={courseValue.linkedinURL}
              onChange={inputHandle}
            />
            <label className="pt-4 pb-0 mb-1">Facebook-URL :</label>
            <Input
              placeholder="Enter Facebook-URL"
              name="facebookURL"
              value={courseValue.facebookURL}
              onChange={inputHandle}
            />
            <label className="pt-4 pb-0 mb-1">Instagram-URL :</label>
            <Input
              placeholder="Enter Instagram-URL"
              name="instagramURL"
              value={courseValue.instagramURL}
              onChange={inputHandle}
            />
          <Form.Item
           wrapperCol={{
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 8,
              offset: 10,
            },
          }}
          >
            <div className="forgote mt-3">
            <Button type="primary" htmlType="submit" className="login-form-button" >
              Update User
            </Button>
            </div>
            
          </Form.Item>
        </Form>
        <button className="bg-danger" onClick={e=>closemodal()}>Close</button>
      </div>
    </>
  );
}

export default EditUser;
