import React, { useEffect, useState } from "react";
import MainSideBar from "../../Constant/MianSideBar/MainSideBar";
import Header from "../../Constant/Header/Header";
import SectionHeader from "../../Constant/SectionHeader/SectionHeader";
import {
  Form,
  Button,
  Spin,
  message,
  Select,
  notification,
  Tooltip,
} from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { PlusSquareOutlined } from '@ant-design/icons';

import { addLaptop } from "../../Api/user/userapi";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

function Addeditlaptop() {
  const {id}=useParams()
  const [loading, setLoading] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState("");
  // const [id, setId] = useState("");
  const [arrayproject, setArrayproject] = useState([]);
  const [arrayemp, setArrayepm] = useState([]);
  const [projectValue, setProjectValue] = useState({});
  const [device_type,setDevice_type]=useState("")
  const [Dropdown,setDropdown]=useState("")

  const str = projectValue?.startTime;
  const strend = projectValue?.endTime;

  const chars = str?.split(":00.000Z");
  const charsend = strend?.split(":00.000Z");

  const params = useParams();
  let history = useHistory();
  const renderSubmitBack = () => {
    history.goBack();
  };
  const Projectslist = () => {
    ApiClient.get(`projects?sortBy=asc&count=500`).then((res) => {
      setArrayproject(res.data);
    });
  };
  const Emplist = () => {
    ApiClient.get(`users?sortBy=asc&count=1000&isDeleted=false&status=active`).then((res) => {
      setArrayepm(res.data);
    });
  };

  const Empuserlist = () => {
    setLoading(true);
    ApiClient.get(`get/assign/device?id=${params.id}`).then((res) => {
      setProjectValue(res.data);
      getdropdownvalue(res?.data?.device_type)
      console.log((res.data));
      setDevice_type(res?.data?.device_type)
      setLoading(false);
    });
  };

  useEffect(() => {
    if (params.id) {
      Empuserlist();
    }
    Projectslist();
    Emplist();
  }, []);

  // const handleSubmit1 = async (e) => {
  //   e.preventDefault();
  //   let devicename=Dropdown&& Dropdown.filter((item)=>{
  //     if(item?.serial_no==projectValue?.serial_no){
  //       return item.device_name
  //     }
  //   })
  //   console.log(devicename,"gdgd");
  //   const payload = {
  //     ...projectValue,device_type:device_type,device_name:devicename[0]?.device_name
  //   };
  //   if (!params.id) {
  //     try {
  //       const userResp = await addLaptop({ ...payload });
  //       history.push("/laptop");
  //       setTimeout(() => {
  //         notification.success({
  //           message: "Device Assigned Successfully",
  //         });
  //       }, 1000);
  //     } catch (e) {
  //       notification.error({
  //         message:"",
  //       });
  //     } finally {
  //       setLoading(false);
  //     }
  //   } else {
  //     delete payload.updatedAt;
  //     delete payload.isDeleted;
  //     delete payload.createdAt;
  //     ApiClient.put(`edit/assigned/device`, payload).then((res) => {
  //       if (res.success) {
  //         history.push("/laptop");
  //         setTimeout(() => {
  //           notification.success({
  //             message: "Assign Data Updated Successfully",
  //           });
  //         }, 1000);
  //       }
  //     });
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let devicename=Dropdown&& Dropdown.filter((item)=>{
      if(item?.serial_no==projectValue?.serial_no){
        return item.device_name
      }
    })
    console.log(devicename,"gdgd");
    const payload = {
      ...projectValue,device_type:device_type,device_name:devicename[0]?.device_name
    };
    if (!params.id) {
        ApiClient.post(`assign/device`,payload).then(res=>{
          if(res.success){
            history.push("/laptop");
            setTimeout(() => {
              notification.success({
                message: "Device Assigned Successfully",
              });
            }, 1000);
          }else{
            notification.error({
              message:res.error.message,
            });
          }
        })
    } else {
      delete payload.updatedAt;
      delete payload.isDeleted;
      delete payload.createdAt;
      ApiClient.put(`edit/assigned/device`, payload).then((res) => {
        if (res.success) {
          history.push("/laptop");
          setTimeout(() => {
            notification.success({
              message: "Assign Data Updated Successfully",
            });
          }, 1000);
        }
      });
    }
  };



  const [form] = Form.useForm();
const adddevice=()=>{
  history.push("/adddevice")
}
const changedevice_type=(e)=>{
  setDevice_type(e)
  console.log(e)
  let payload={device_type:e}
  setLoading(true)
  ApiClient.get(`deviceList`,payload).then(res=>{
    if(res.success){
      setDropdown(res?.data)
      console.log(res?.data);
      setLoading(false)
    }
  })
}
const getdropdownvalue=(p)=>{
  let payload={
    device_type:p
  }
  setLoading(true)
  ApiClient.get(`deviceList`,payload).then(res=>{
    if(res.success){
      setDropdown(res?.data)
      console.log(res?.data);
      setLoading(false)
    }
  })
}

  return (
    <div>
      <div className="main-wrapper">
        <div className="navbar-bg" />
        <Header />
        <MainSideBar />
      </div>
      <div className="main-content">
        <section className="section">
          <SectionHeader title="Assign Device" />
          <div className="d-flex">
          <Tooltip title="Add Device">
              <Button
                type="primary"
                onClick={adddevice}
                shape="circle"
                icon={<PlusSquareOutlined />}
                size={"large"}
                className="btn btn-primary profile mr-5"
              />
            </Tooltip>
            <Tooltip title="Back Button">
              <Button
                type="primary"
                onClick={renderSubmitBack}
                shape="circle"
                icon={<ArrowLeftOutlined />}
                size={"large"}
                className="btn btn-primary profile"
              />
            </Tooltip>
          </div>

          <div className="container shadow p-4">
            <Spin spinning={loading}>
              <div>
                {loginErrorMsg && message.error(loginErrorMsg)}

                <form onSubmit={handleSubmit}>
                  <Spin spinning={loading}>
                    <div className="row">
                      <div span={12} className="row">
                      <div className="col-md-6">
                          <label>
                            Device Type<span className="star">*</span>
                          </label>
                          <br />
                          <select
                            className="w-100 mb-2 border"
                            value={device_type}
                            onChange={(e) =>
                              changedevice_type(e.target.value)
                            }
                            required
                          >
                            <option value="">Select Device Type...</option>
                            <option value="Mobile">Mobile</option>
                            <option value="Laptop/Macbook">Laptop/Macbook</option>
                            <option value="Tablet">Tablet</option>
                          </select>
                        </div><div className="col-md-6"></div>
                        <div className="col-md-6">
                          <label>
                            Device Name<span className="star">*</span>
                          </label>
                          <br />
                          <select
                            className="w-100 mb-2 border"
                            value={
                              projectValue.serial_no ? projectValue.serial_no : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                serial_no: e.target.value
                              })
                            }
                            required
                          >
                            <option value="">Select Device...</option>
                            {Dropdown&&Dropdown.map((item)=>{
                              return <option style={item?.assigned_device_details[0]?.status=="assigned"?{cursor:'none'}:{cursor:"auto"}} disabled={item?.assigned_device_details[0]?.status=="assigned"?true:false} value={item?.serial_no}>{item?.device_name}</option>
                            })}
                            {/* <option value="c17nw398G085">Laptop(1)</option>
                            <option value="FvfTMA77H3Qd">Laptop(2)</option>
                            <option value="CIMs2BkvH3Qf">Laptop(3)</option>
                            <option value="co2M719f5v7">Laptop(4)</option>
                            <option value="CIMP11WKDTy3">Laptop(5)</option>
                            <option value="CO2HxzDGTY3">Laptop(6)</option>
                            <option value="C1MQM2ANDTY3">Laptop(7)</option> */}
                          </select>
                        </div>

                        <div className="col-md-6">
                          <label>
                            Select Project<span className="star">*</span>
                          </label>
                          <br />
                          <select
                            className="w-100 mb-2 border"
                            value={
                              projectValue?.projectId
                                ? projectValue.projectId
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                projectId: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="">Select Project...</option>
                            {arrayproject.map((user) => (
                              <option value={user.id}>{user.name}</option>
                            ))}
                          </select>
                        </div>

                        <div className="col-md-6">
                          <label>
                            Select Employee<span className="star">*</span>
                          </label>
                          <br />
                          <select
                            className="w-100 mb-2 border"
                            value={
                              projectValue.employeeId
                                ? projectValue.employeeId
                                : ""
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                employeeId: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="">Select Employee...</option>
                            {arrayemp.map((user) => (
                              <option value={user.id}>{user.fullName}</option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label>
                            Description<span className="star">*</span>
                          </label>
                          <br />
                          <input
                            className="w-100 mb-2 border"
                            placeholder="Description"
                            required
                            type="text"
                            value={projectValue.description}
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                description: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="col-md-6">
                          <label>
                            Start Date and Time<span className="star">*</span>
                          </label>
                          <br />
                          <input
                            className="w-100 mb-2 border"
                            required
                            disabled={id?true:false}
                            type="datetime-local"
                            min={!id?new Date().toISOString().slice(0, 16):false}
                            value={
                              projectValue.id
                                ? chars[0]
                                : projectValue.startTime
                            }
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                startTime: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="col-md-6">
                          <label>
                            End Date and Time<span className="star">*</span>
                          </label>
                          <br />
                          <input
                            className="w-100 mb-2 border"
                            required
                            type="datetime-local"
                            disabled={projectValue?.startTime?false:true}
                            value={
                              projectValue.id
                                ? charsend[0]
                                : projectValue.endTime
                            }
                            min={projectValue.startTime}
                            onChange={(e) =>
                              setProjectValue({
                                ...projectValue,
                                endTime: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <button
                          type="primary"
                          htmlType="submit"
                          className="btn btn-warning"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Spin>
                </form>
              </div>
            </Spin>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Addeditlaptop;
